import React from "react";

const AddPlanningIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 2048 2048" width="512" height="512">
    <path transform="translate(170,819)" d="m0 0h1536l1 1 1 167h-4l-15-8-28-13-33-13-24-8-34-9-36-7-44-5-20-1h-45l-36 3-29 4-38 8-31 9-29 10-31 13-19 9-22 12-20 12-21 14-14 10-14 11-13 11-11 9-2 1h-70l-1 80-7 10-10 15-11 18-9 16-12 23-10 22-11 28-9 27-8 30-7 34-5 37-2 33v33l2 35 4 33 7 36 7 27 8 26 12 31 11 25 12 24 2 5h-655l-19-2-19-4-22-8-16-8-14-9-14-11-18-18-10-13-10-16-10-21-6-19-4-21-1-10-1-36v-624zm172 239v102h169v-101l-21-1zm344 0-3 1v101h169l1-2v-99l-11-1zm-344 342v101l166 1 3-1v-101zm341 0v101h169l1-8v-93z" fill="currentColor"/>
    <path transform="translate(589,119)" d="m0 0h15l15 4 9 6 9 8 7 11 4 11 1 8v174h579v-176l3-12 6-12 10-11 11-7 9-3 5-1h15l12 3 11 6 8 7 9 13 4 12 1 11v157l-1 14 7-1h212l22 3 21 6 16 7 14 7 12 8 16 13 15 15 10 13 11 18 9 19 7 23 3 17 1 10v216l-1 1h-1536l-1-22v-141l1-53 2-17 5-22 8-21 9-17 8-12 13-16 13-13 17-13 13-8 16-8 19-7 17-4 15-2h219l1-172 3-16 5-10 8-10 11-8 12-5zm-43 223 1 178 4 13 6 10 5 6 10 7 12 5 5 1h17l13-4 11-7 7-7 6-9 4-12 1-7v-174zm684 0-1 33v138l2 13 4 10 8 11 8 7 10 5 11 3h17l12-4 10-6 7-6 7-10 4-11 2-17v-166z" fill="currentColor"/>
    <path transform="translate(1435,1023)" d="m0 0h29l30 2 29 4 25 5 26 7 30 10 30 13 29 15 24 15 17 12 14 11 13 11 12 11 5 4v2l4 2 14 15 10 11 9 11 15 20 10 15 8 13 10 17 11 23 8 18 9 24 9 31 6 27 4 27 2 22 1 18v25l-3 39-5 31-7 30-8 26-8 21-11 25-13 25-16 26-12 17-11 14-11 13-9 11h-2v2h-2l-2 4-12 12-8 7-11 10-10 8-20 15-15 10-20 12-18 10-26 12-26 10-29 9-26 6-30 5-29 3-17 1h-22l-29-2-31-4-29-6-26-7-26-9-28-12-26-13-25-15-16-11-12-9-16-13-11-10-2-1v-2l-4-2-22-22-7-8-11-13-11-14-14-20-11-18-10-18-8-16-10-22-10-27-9-32-5-24-4-27-2-21-1-18v-22l2-32 4-30 8-37 9-29 7-19 9-21 8-17 13-23 12-19 12-17 11-14 9-11 3-4h2l2-4 10-10 5-6 8-7 10-10 22-18 15-11 15-10 21-13 29-15 23-10 25-9 23-7 37-8 30-4zm-35 172-1 203-127 1-77 1v101l178 1 26 1 1 203h101v-204h138l66-1 1-1v-100l-35-1h-170v-204z" fill="currentColor"/>
    </svg>
  );
};

export default AddPlanningIcon;
