import React, { useState } from "react";
import SearchModel from "components/atoms/SearchModel";
import { useNavigate } from "react-router-dom";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { DELETE_ROLE } from "routes/ApiEndpoints";
import "static/css/roles.css";
import { t } from "pages/microservices/masterData/translation/Translation";
import TableStructure from "components/atoms/TableStructure";
import { RoleTableHeader } from "TableHeader";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Title from "components/atoms/Title";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BackButton from "components/atoms/BackButton";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import { useDispatch } from "react-redux";
import ResetBtn from "components/atoms/ResetBtn";
import FilterMolecule from "components/molecules/FilterMolecule";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { handleClear } from "services/util/UtilService";
import FilterOrganism from "components/organism/FilterOrganism";

interface RolesType {
  id?: number;
  name?: string;
}

interface Props {
  rolesObj: RolesType[];
  history: any;
}

const ManageRolesPage: React.FC<Props> = ({ rolesObj, history }) => {
  const [roles, setRoles] = useState(rolesObj);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModelBody] = useState(t("Are you sure want to delete?"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filterOrder = roles?.sort((a: any, b: any) => {
    // If `orderBy` is not resent, sort by `created_at`
    if (!history?.filters?.orderBy) {
      const dateA = new Date(a?.created_at).getTime();
      const dateB = new Date(b?.created_at).getTime();
      return dateB - dateA;
    } else {
      let comparison = a?.name.localeCompare(b?.name);
      return ((history?.filters?.sortDirection || 'desc') === 'asc') ? comparison : -comparison;
    }
  });

  // Filter the data based on the search query
  const filteredData = filterOrder?.filter((item: RolesType) =>
    item.name?.trim()?.toLowerCase()?.includes(history?.filters?.name?.trim()?.toLowerCase() ?? "")
  );

  // Get the current items to display on the current page
  const indexOfLastItem = Number(history?.filters?.currentPage ?? 1) * Number(history?.filters?.itemsPerPage ?? 10);
  const indexOfFirstItem = indexOfLastItem - Number(history?.filters?.itemsPerPage ?? 10);
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / Number(history?.filters?.itemsPerPage ?? 10));

  const deleteRole = (RolesType: RolesType | any) => {
    setShowModal(true);
    setDeleteId(RolesType.id);
  };

  const EditRole = (RolesType: RolesType | any) => {
    if (RolesType.id) {
      navigate(`/role/edit/${RolesType.id}`);
    }
  };

  const cloneRole = (RolesType: RolesType | any) => {
    if (RolesType.id) {
      navigate(`/role/clone/${RolesType.id}`)
    }
  }

  // Modal popup for delete confirmation
  const handleModalClose = () => {
    setShowModal(!showModal);
    setModelBody(t("Are you sure want to delete?"));
  };
  const handleSaveChanges = async () => {
    setShowModal(false);
    let body = {
      roleId: deleteID,
      force: false,
    };
    if (modalBody !== t("Are you sure want to delete?")) {
      body.force = true;
    }
    const response = await ApiCall.service(DELETE_ROLE, "POST", body);
    if (response.status === 403) {
      setModelBody(response.msg);
      setShowModal(true);
    } else if (response.status === 200) {
      const newRolesObj = roles.filter(
        (item: RolesType) => item.id !== deleteID
      );
      setRoles(newRolesObj);
      CustomNotify({ type: "success", message: response.msg });
    } else {
      CustomNotify({ type: "error", message: response.msg });
    }
  };

  const columns = ["name"];
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Role",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar" style={{ paddingBottom: "0.25vw" }}>
          <Title title={t("Roles")} />
        </div>
        <div className="position-relative tableMainWrapper">
          <AccessControl
            requiredPermissions={[
              {
                permission: "Role",
                create: true,
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <div className="d-none d-lg-block">
              <div className="row">
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink="/role/create"
                    title={t("Create role")}
                    icon={faPlus}
                  />
                </div>
              </div>
            </div>
            <div className="d-block d-lg-none">
              <div className="row">
                <div className="col-md-6 ms-auto pb-3">
                  <LinkTo
                    pagelink="/role/create"
                    title={t("Create role")}
                    icon={faPlus}
                    className="align-items-center justify-content-center w-100"
                  />
                </div>
              </div>
            </div>
          </AccessControl>
          <div className="table-responsive manage-roles tableSection">
            <FilterOrganism
              dispatch={dispatch}
              history={history}
              updatePageFilters={updatePageFilters}
              filters={[{name: 'name', fieldType: "text", placeholder: "Role name", filterType: 'search' }]}
            />
            <div className="TableHeight">
              <TableStructure
                 isAction
                 history={history}
                 headers={RoleTableHeader}
                 data={currentItems}
                 values={columns}
                 handleEdit={EditRole}
                 handleDelete={deleteRole}
                 handleClone={cloneRole}
                 permissionType="Role"

              />
            </div>

            <div
              className="pagination d-block"
              style={{ paddingTop: "0.25vw" }}
            >
              <PaginationWithPerPage
                dispatch={dispatch}
                history={history}
                pageData={{totalPages: totalPages, totalRecords: filteredData?.length ?? 0}}
              />
            </div>
          </div>
        </div>
        {/* <div
          className="col-md-6 align-self-center"
          style={{ padding: "0.5vw 0" }}
        >
          <BackButton />
        </div> */}
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Delete confirmation")}
          body={modalBody}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={"Yes"}
        />
      </>
    </AccessControl>
  );
};

export default ManageRolesPage;
