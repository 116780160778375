import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "components/atoms/Button";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import {
  PATH_CREATE_TEMP_AGENCY,
  PATH_EDIT_TEMP_AGENCY,
  PATH_TEMP_AGENCY_PROPOSAL,
} from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import {
  DELETE_TEMP_AGENCY,
  DOWNLOAD_TEMP_AGENCY_PDF,
  FETCH_COMPANY_BU,
} from "routes/ApiEndpoints";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import Pagination from "components/atoms/Pagination";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
// import FilterElementsRender from "../FilterElement";
// import { fetchOptions } from "../utils/FetchOptions";

import {
  getStatusColor,
  getTempAgencyColor,
  getTempAgencyTooltipMessage,
  getTooltipMessage,
} from "services/util/UtilService";
import { fetchOptions } from "pages/microservices/fleetAndMaterial/utils/FetchOptions";
import FilterElementsRender from "pages/microservices/fleetAndMaterial/FilterElement";
import EncryptDecryptService from "services/EncryptDecryptService";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BackButton from "components/atoms/BackButton";

interface ManageTempAgencyProps {
  data?: any[];
  handleData: any;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  itemsPerPage: number;
  searchValue: (name: string, value: string | number) => void;
  handleSubmitAction: (clear: boolean) => void;
  searchData: any;
  handleGetVacany: any;
}

interface CompanyItem {
  value: number;
  label: string;
}

const ManageTempAgencyPage: React.FC<ManageTempAgencyProps> = ({
  data,
  handleData,
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  searchValue,
  handleSubmitAction,
  searchData,
  handleGetVacany,
}) => {
  const [id, setId] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    businessUnit: [],
    company: [],
  });
  const navigate = useNavigate();

  const handleSearchChange = (event: any, name: string, type: string) => {
    switch (type) {
      case "text":
      case "date":
        return searchValue(name, event.target.value);

      case "select":
        return searchValue(name, event.value);
    }
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
    let postData = {
      id: id,
    };

    if (id) {
      const response = await ApiCall.service(
        DELETE_TEMP_AGENCY,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleData({
          company: "",
          businessUnit: "",
          tempAgency: "",
        });
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  useEffect(() => {
    getFilterData();
  }, []);

  const getFilterData = async () => {
    try {
      const response = await ApiCall.getService(
        FETCH_COMPANY_BU,
        "GET",
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setFilterOptions((prevState: any) => ({
          ...prevState,
          businessUnit: response.data.businessUnit,
          company: response.data.compnay,
        }));
      }
    } catch (error) {
      //   console.error(error);
    }
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleEdit = (id: number) => {
    navigate(`${PATH_EDIT_TEMP_AGENCY}/${id}`);
  };

  const handleDelete = (id: number) => {
    setShowModal(true);
    setId(id);
  };
  const userData = useSelector(selectAuth);
  const handleSendProjectProposal = (
    tempAgencyId: number,
    clientMailStatus: any,
    managerMailStatus: any,
    user: any
  ) => {
    const userIds = user.map((item: { user_id: any }) => item.user_id);
    const data = {
      id: tempAgencyId,
      clientMailStatus: clientMailStatus,
      ManagerMailStatus: managerMailStatus,
      users: userIds,
    };
    const append = EncryptDecryptService.encryptData(JSON?.stringify(data));
    const encodedData = encodeURIComponent(append);
    navigate(`${PATH_TEMP_AGENCY_PROPOSAL}/?data=${encodedData}`);
  };
  const handleDownload = async (id: number) => {
    const response = await ApiCall.getService(
      `${DOWNLOAD_TEMP_AGENCY_PDF}/${id}`,
      "GET",
      M_MASTER_DATA
    );
    if (response && response.download_url) {
      const link = document.createElement("a");
      link.href = response.download_url;
      link.target = "_blank";
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
      }, 1000);
    } else {
      //   console.error("Invalid response or missing download URL");
    }
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Temp agency",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar temp-agency-search">
          <div className="row">
            <Title title={t("Temp agency")} />
            <FilterElementsRender
              data={searchData?.tempAgency}
              type="text"
              handleChange={(event) =>
                handleSearchChange(event, "tempAgency", "text")
              }
              value={searchData?.tempAgency}
              placeHolder={"Temp agency"}
              name="tempAgency"
            />

            <FilterElementsRender
              data={filterOptions?.company}
              type="multi-select"
              handleChange={(event) =>
                handleSearchChange(event, "company", "select")
              }
              value={searchData?.company}
              placeHolder={"Company"}
              name="company"
            />

            <FilterElementsRender
              data={filterOptions.businessUnit}
              type="multi-select"
              handleChange={(event) =>
                handleSearchChange(event, "businessUnit", "select")
              }
              value={searchData?.businessUnit}
              placeHolder={"Business unit"}
              name="businessUnit"
            />

            <div className="col-3" style={{ paddingTop: "0.1vw" }}>
              <div className="d-flex align-items-center">
                <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
                <SearchBtn handleSearchClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="position-relative tableMainWrapper">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Temp agency",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <div className="row">
                  <div className="ManageCreateBtn">
                    <LinkTo
                      pagelink={PATH_CREATE_TEMP_AGENCY}
                      title={t("Add temp agency")}
                      icon={faPlus}
                    />
                  </div>
                </div>
              </AccessControl>
              <div className="table-responsive manageTempAgency tableSection">
                <div className="manageTempAgencyHeight">
                  <table className="table table-hover">
                    <thead>
                      <tr className="TableHeader">
                        <th>{t("Temp agency")}</th>
                        <th>{t("Company")}</th>
                        <th>{t("Business unit")}</th>
                        <th>{t("Agreement status")}</th>
                        <th className="table_actions">
                          <AccessControl
                            requiredPermissions={[
                              {
                                permission: "Temp agency",
                                update: true,
                                delete: true,
                              },
                            ]}
                            actions={true}
                            strict={false}
                            override={userData.isSuperAdmin}
                          >
                            {t("Action")}
                          </AccessControl>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.length > 0 ? (
                        data.map((record, key) => {
                          const canDownload = record.proposal_status === 1;
                          const isSpecialCase =
                            record.isActive === 2 && record.proposal_status === 1;
                          return (
                            <tr key={key}>
                              <td className="align-middle">{record.name}</td>

                              <td className="align-middle">
                                {record.company_names}
                              </td>
                              <td className="align-middle">
                                {record.business_unit_names}
                              </td>
                              <td className="align-middle">
                                <div
                                  className={getTempAgencyColor(
                                    isSpecialCase ? 3 : record.isActive
                                  )}
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title={getTempAgencyTooltipMessage(
                                    isSpecialCase ? 3 : record.isActive
                                  )}
                                ></div>
                              </td>
                              <td className="align-middle">
                                <TableActions
                                  isAction={true}
                                  handleEdit={handleEdit}
                                  handleDelete={handleDelete}
                                  handleDownload={
                                    canDownload ? handleDownload : undefined
                                  }
                                  handleSendProjectProposal={
                                    record.proposal_status !== 1
                                      ? () =>
                                        handleSendProjectProposal(
                                          record.id,
                                          record.mail_send_client,
                                          record.mail_send_manager,
                                          record.contact_person
                                        )
                                      : undefined
                                  }
                                  value={record.id}
                                  permission={"Temp agency"}
                                />
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10} className="text-center">
                            {t("No records found")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {totalPages > 1 && (
                  <div
                    className="pagination justify-content-center align-items-center"
                    style={{ paddingTop: "0.25vw" }}
                  >
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={onPageChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row backPadding">
          <div className="col-md-6 align-self-center" >
            <BackButton />
          </div>
        </div> */}
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Delete confirmation")}
          body={t("Are you sure want to delete?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
      </>
    </AccessControl>
  );
};
export default ManageTempAgencyPage;
