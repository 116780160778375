
export const MANAGE_PAYROLL_CONSTANTS = {
    EXPORT: 'Export',
    PRINT: "Print",
    MANAGE_PAYROLL: "Manage payroll",
    PAYROLL_OVERVIEW: "Payroll overview",
    SEARCH: "Search",
    SEARCH_BUSINESS_UNIT: "Serach business unit",
    SEARCH_EMPLOYEE: "Search employee",
    SEARCH_CONTRACT_TYPE: "Search contract type",
    SUBMIT: "Submit",
    RESET: "Reset",
    BACK: "Back",
    ITEMS_PER_PAGE: 10,
}

export const initialOverviewProps = {
    data: [],
    headers: {
        maintableheader: [],
        hoursworkedheader: [],
        projecthourheader: [],
    },
};

export const PayRollReducerTypes = {
    SET_OVERVIEW_DATA: 'SET_OVERVIEW_DATA',
}
