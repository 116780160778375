import { selectAuth } from 'features/auth/AuthSlice';
import React from 'react'
import { useSelector } from 'react-redux';
import AccessControl from 'services/AccessControl';
import { OverviewProvider } from './work-pay-bill-roll-redux/context/OverviewContext';
import WorkPayBillRollPage from './WorkPayBillRollPage';

const WorkPayBillRoll = () => {
    const userData = useSelector(selectAuth);

    return (
        <AccessControl
            requiredPermissions={[{
                permission: "PayRoll",
                read: true,
            }]}
            renderNoAccess={true}
            override={userData.isSuperAdmin}
        >
            <OverviewProvider>
                <WorkPayBillRollPage />
            </OverviewProvider>
        </AccessControl>
    );
}

export default WorkPayBillRoll