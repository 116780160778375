import React from "react";

const RemoveFleetIcon: React.FC = () => {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clip-rule="evenodd" d="M26 18C30.4189 18 34 21.5811 34 26C34 30.4189 30.4189 34 26 34C21.5811 34 18 30.4189 18 26C18 21.5811 21.5811 18 26 18ZM22.8175 22.8189C23.2088 22.4276 23.8412 22.4276 24.2325 22.8189L26 24.5864L27.7675 22.8189C28.1588 22.4276 28.7912 22.4276 29.1825 22.8189C29.5725 23.2089 29.5725 23.8426 29.1825 24.2326L27.4138 26.0001L29.1812 27.7676C29.5725 28.1589 29.5725 28.7914 29.1812 29.1826C28.7912 29.5726 28.1575 29.5726 27.7675 29.1826L26 27.4151L24.2325 29.1826C23.8425 29.5726 23.2088 29.5726 22.8188 29.1826C22.4275 28.7914 22.4275 28.1589 22.8188 27.7676L24.5862 26.0001L22.8175 24.2326C22.4275 23.8426 22.4275 23.2089 22.8175 22.8189Z" fill="currentColor" />
            <path d="M19.2853 18.5896C19.042 18.8102 18.8096 19.0426 18.589 19.286H8.57124V18.2146C8.57124 17.6226 9.05068 17.1431 9.64264 17.1431H18.2139C18.8058 17.1431 19.2853 17.6226 19.2853 18.2146V18.5896Z" fill="currentColor" />
            <path fillRule="evenodd" clip-rule="evenodd" d="M21.262 17.1915C20.8349 15.9176 19.632 15.0003 18.2139 15.0003H9.64264C7.86678 15.0003 6.42843 16.4387 6.42843 18.2146V30H3.21421C1.43836 30 0 28.5616 0 26.7858V11.7861C0 10.7737 0.475429 9.82276 1.28569 9.21475L13.2854 0.214951C13.6671 -0.0716504 14.1894 -0.0716504 14.5711 0.214951L26.5708 9.21475C27.3811 9.82276 27.8565 10.7737 27.8565 11.7861V16.172C27.2549 16.0591 26.6344 16 26 16C24.2857 16 22.6722 16.4314 21.262 17.1915ZM19.2853 12.8575H8.57124C7.97927 12.8575 7.49983 12.3781 7.49983 11.7861C7.49983 11.1942 7.97927 10.7147 8.57124 10.7147H19.2853C19.8772 10.7147 20.3567 11.1942 20.3567 11.7861C20.3567 12.3781 19.8772 12.8575 19.2853 12.8575Z" fill="currentColor" />
            <path d="M16.004 25.7144C16.0013 25.8093 16 25.9045 16 26C16 26.6346 16.0591 27.2554 16.1721 27.8572H8.57124V25.7144H16.004Z" fill="currentColor" />
            <path d="M17.1036 21.4288C16.7569 22.1022 16.4844 22.82 16.2969 23.5716H8.57124V21.4288H17.1036Z" fill="currentColor" />
        </svg>

    )

}
export default RemoveFleetIcon