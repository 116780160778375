import React from "react";
import DaysOfWeekTable from "pages/microservices/project/components/shiftTable";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useFormContext } from "pages/microservices/project/context/Context";
import { calculateWeeklyTotal } from "pages/microservices/project/Utils";
import LabelField from "components/atoms/LabelField";
import CheckBoxField from "components/atoms/CheckBoxField";
import { UPDATE_SHIFT_BREAK_FIELDS } from "pages/microservices/project/context/Constants";
interface ShiftTableProps {
  selectedOption: any;
}

const ShiftTable: React.FC<ShiftTableProps> = ({ selectedOption }) => {
  const { state, dispatch } = useFormContext();
  const currentShift: any = state?.shifts?.data?.shiftTable;

  const changeHandler = (event: any, shiftName: any) => {
    const { name, checked } = event.target;
    dispatch({
      type: UPDATE_SHIFT_BREAK_FIELDS,
      payload: {
        field: name,
        value: checked,
        shiftName,
      },
    });
  };

  return (
    <div key={selectedOption.value}>
      <div className="row">
        <div className="col-6">
          <LabelField title={selectedOption.label} className="tab-subtitle marginRight1" />
          <CheckBoxField
            name="breakInvoice"
            label="Break Invoiced"
            isChecked={currentShift?.[selectedOption.value]?.breakInvoice}
            id="breakInvoice"
            lineHeight="1.5vw"
            onChangeHandler={(event) => {
              changeHandler(event, selectedOption.value);
            }}
            className="marginRight1"
          />
          <CheckBoxField
            name="breakPaid"
            label="Break Paid"
            isChecked={currentShift?.[selectedOption.value]?.breakPaid}
            id="breakPaid"
            onChangeHandler={(event) => {
              changeHandler(event, selectedOption.value);
            }}
            lineHeight="1.5vw"
          />
        </div>

        <div className="col-6 text-end tab-subtitle">
          {currentShift?.[selectedOption.value] && (
            <>
              {t("Total weekly time")}:
              {calculateWeeklyTotal(currentShift?.[selectedOption.value])}
            </>
          )}
        </div>
      </div>
      <DaysOfWeekTable
        selectedShift={selectedOption}
        tableIndex={selectedOption.value}
      />
    </div>
  );
};

export default ShiftTable;
