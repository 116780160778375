import { VACANCY_EDIT, VACANCY_CREATE, SUPPLIER_CREATE, SUPPLIER_EDIT } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { PATH_MANAGE_MATERIAL, PATH_MANAGE_SUPPLIER, PATH_VACANCY_MANAGE } from "constants/Paths";
import { FormProviderWrapper } from "utils/multiTabForm/formWrapper/Index";
import { useLocation, useParams } from "react-router-dom";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { activeFunction, getCityCountry, verifyVatNumber } from "./customFunctions";

export type CustomFnType = (payload: any, dataObj: any) => any;

const AddSupplier = () => {
  const location = useLocation();
  const path = location.pathname;
  const { id: routeId } = useParams<{ id: string }>();

  const customActions: CustomFnType = async (payload: any, dataObj: any) => {
    switch (payload.type) {
      case "active":
        return activeFunction(payload, dataObj);
      case "verify-vatnumber":
        return await verifyVatNumber(payload);
      case "zipcode":
        return await getCityCountry(payload, dataObj);
    }
  };

  const permissionType = routeId ? "update" : "create";
  const permissionObject: any = {
    permission: "Supplier",
  };
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);

  return (
    <>
      <AccessControl
        requiredPermissions={[permissionObject]}
        renderNoAccess={true}
        override={userData.isSuperAdmin}
      >
        <FormProviderWrapper
          backToManage={"/fleetOverview/supplier"}
          formName={"Supplier"}
          dataSubmissionEndPoint={`${SUPPLIER_CREATE}/supplier`}
          microservice={M_MASTER_DATA}
          dataFetchEndPoint={`${SUPPLIER_EDIT}/supplier`}
          entryId={routeId}
          customFn={customActions}
          formTitle={routeId ? "Edit supplier" : "Create supplier"}
        />
      </AccessControl>
    </>
  );
};

export default AddSupplier;
