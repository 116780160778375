import React, { useState, useEffect } from "react";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "static/images/EditIcon";
import ViewIcon from "static/images/ViewIcon";
import { ApiCall } from "services/ApiServices";
import "static/css/email-template.css";
import Button from "components/atoms/Button";
import { GET_TEMPLATES } from "routes/ApiEndpoints";
import { t } from "../translation/Translation";
import { M_MASTER_DATA } from "../../../../constants/Constants";
import {
  PATH_EMAIL_TEMPLATE_CREATE,
  PATH_EMAIL_TEMPLATE_EDIT,
  PATH_EMAIL_TEMPLATE_VIEW,
  PATH_MASTER_DATA,
} from "constants/Paths";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BackButton from "components/atoms/BackButton";

const ITEMS_PER_PAGE = 10;

interface EmailTemplate {
  id: number;
  name: string;
  module_name: string;
}

const ManageEmailTemplatesPage: React.FC = () => {
  const [searchData, setSearchData] = useState({
    name: "",
  });

  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]); // State to hold the fetched email templates
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchAllEmailTemplates();
  }, []);

  const fetchAllEmailTemplates = async () => {
    //api call to get all the templates created
    try {
      const response = await ApiCall.getService(
        GET_TEMPLATES,
        "GET",
        M_MASTER_DATA
      );
      if (response.status === 200) {
        setEmailTemplates(response.data); // Update the state with fetched email templates
      }
    } catch (error) {
      // Handle API error
      console.error("Error fetching email templates:", error);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
    setCurrentPage(1);
  };

  const filteredEmailTemplates = emailTemplates.filter((template) =>
    template.name.toLowerCase().includes(searchData.name.toLowerCase())
  );

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedData = filteredEmailTemplates.slice(startIndex, endIndex);
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[{
        permission: "Email template"
      }]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="search-bar pb-3">
        <div className="row">
          <Title title={t("Email templates")} />
          <div className="col-8">
            <Search
              className="form-control"
              handleChange={handleSearchChange}
              placeholder={t("Search by name...")}
              name="name"
              value={searchData.name}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <AccessControl
              requiredPermissions={[{
                permission: "Email template",
                create: true,
              }]}
              override={userData.isSuperAdmin}
            >
              <div className="row">
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink={PATH_EMAIL_TEMPLATE_CREATE}
                    title={t("Create email Template")}
                    icon={faPlus}
                  />
                </div>
              </div>
            </AccessControl>
            <div className="table-responsive tableSection">
              <div className="manageEmailTemplates">
                <table className="table table-responsive table-hover">
                  <thead className="TableHeader">
                    <tr className="TableHeader">
                      <th>{t("Name")}</th>
                      <th>{t("Module name")}</th>
                      <th className="table_actions">
                        <AccessControl
                          requiredPermissions={[{
                            permission: "Email template",
                            update: true,
                            read: true
                          }]}
                          actions={true}
                          strict={false}
                          override={userData.isSuperAdmin}
                        >
                          {t("Action")}
                        </AccessControl>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.length > 0 ? (
                      paginatedData.map((template, index) => (
                        <tr
                          key={template.id}
                          className="border mb-3 box-shadow align-middle"
                        >

                          <td className="text-break" data-label="Name">
                            {template.name}
                          </td>
                          <td className="text-break" data-label="Module name">
                            {template.module_name}
                          </td>
                          <td className="table-action-icons px-2">
                            <AccessControl
                              requiredPermissions={[{
                                permission: "Email template",
                                update: true
                              }]}
                              override={userData.isSuperAdmin}
                            >
                              <Button
                                title={<EditIcon />}
                                className={"table-action-btn border-0 p-0 marginRightPoint5"}
                                handleClick={() => navigate(`${PATH_EMAIL_TEMPLATE_EDIT + "/" + template.id}`)}
                                tooltip={t("Edit")}
                              />
                            </AccessControl>
                            <AccessControl
                              requiredPermissions={[{
                                permission: "Email template",
                                read: true
                              }]}
                              override={userData.isSuperAdmin}
                            >
                              <Button
                                title={<ViewIcon />}
                                className={"table-action-btn border-0 p-0 marginRightPoint5"}
                                handleClick={() => navigate(`${PATH_EMAIL_TEMPLATE_VIEW}/${template.id}`)}
                                tooltip={t("View")}
                              />
                            </AccessControl>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="border">
                        <td colSpan={4} className="border-0 text-center py-3">
                          <span className="text-danger fw-bold">
                            {t("No records")}
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="pagination justify-content-center align-items-center my-3">
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(
                    filteredEmailTemplates.length / ITEMS_PER_PAGE
                  )}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 align-self-center backPadding">
          <BackButton />
        </div>
      </div>
    </AccessControl>
  );
};

export default ManageEmailTemplatesPage;
