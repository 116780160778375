import React, { Suspense, useState, useEffect } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import ManageClothes from "./ManageClothes";
import { M_MASTER_DATA } from "constants/Constants";
import { CLOTHES, CLOTHES_EDIT } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { useNavigate, useParams } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";

// Define the prop types for the component
interface ClothesProps {
  id?: string | number;
  type?: string;
  setfunc?: any;
  dispatch?: any;
  history?: any;
}

// Functional component using TypeScript
const Clothes: React.FC<ClothesProps> = ({ type, history, dispatch }) => {
  const navigate = useNavigate();
  const { id: routeId, userType: userType } = useParams();
  const [materialData, setMaterialData] = useState([]);
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    initialCall: true,
    refresh: true,
  });
  const getSearchData = () => {
    return {
      ownerCompany: history?.filters?.ownerCompany ?? "",
      businessUnits: history?.filters?.businessUnits ?? "",
      brand: history?.filters?.brand ?? "",
      model: history?.filters?.model ?? "",
      status: type == 'fleet' ? '' : history?.filters?.status ?? "",
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
    }
  };

  useEffect(() => {
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      type: 'clothes',
      userId: routeId ?? null,
      userType: userType ?? null,
      search: getSearchData(),
    };
    getMaterialData(postData);
  }, [pageData?.initialCall, pageData?.refresh]);

  const getMaterialData = async (postData: any) => {
    const response = await ApiCall.service(
      CLOTHES_EDIT,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      setMaterialData(response?.data?.data);
      setPageData((prev: any) => ({ ...prev, totalPages: response?.data?.totalPages, totalRecords: response?.data?.totalRecords }))
    } else {
      console.log("error");
    }
  };
  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  return (
    <>
      <Suspense
        fallback={
          <div className="text-center text-danger">{t("Loading")}...</div>
        }
      >
        <ManageClothes
          data={materialData}
          fleetProps={type}
          setMaterialData={setMaterialData}
          handleGetVacany={getMaterialData}
          dispatch={dispatch}
          history={history}
          handleRefresh={handleRefresh}
          pageData={pageData}
        />
      </Suspense>
    </>
  );
};

export default Clothes;
