import { t } from "../../translation/Translation";
import { pcFormData } from "./PCInterface";

export const PCInitialState: pcFormData = {
  tabDetails: [
    {
      id: t("pcDetails"),
      title: t("Paritair comitee details"),
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: t("categoryAndFunctions"),
      title: t("Category and functions"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: t("age"),
      title: t("Age"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: t("employeeType"),
      title: t("Employee type"),
      showStatus: false,
      error: false,
      draft: false,
    }
  ],

  pcDetails: {
    pcName: "",
    pcNumber: "",
    pcAliasName: "",
    bufferTiming: "",
    minWorkTimingPerDay: "",
    maxWorkTimingPerDay: "",
    workUpto: "",
    maxWorkTimingPerWeekWarning: "",
    maxWorkTimingPerWeekError: "",
  },

  categories: [],

  functions: [],

  age: [],

  employeeType: {
    pcCategory: null,
    employeeType: [],
  },

  dropDownData: {
    functionCategoryList: null,
    employeeTypeList: [],
  },

  errors: {
    pcName: "",
    pcNumber: "",
    pcAliasName: "",
    bufferTiming: "",
    minWorkTimingPerDay: "",
    maxWorkTimingPerDay: "",
    workUpto: "",
    maxWorkTimingPerWeekWarning: "",
    maxWorkTimingPerWeekError: "",
    categoryAndFunctions: "",
    pcCategory: "",
    employeeType: "",
    age: "",
  },
};
