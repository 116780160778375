import { t } from "pages/microservices/masterData/translation/Translation";
import { Link } from "react-router-dom";
import ActiveLocation from "static/images/ActiveLocation";
import AddAddressLoactionViaMap, { AddressDetails } from "utils/AddAddressLoactionViaMap";

const LocationMolecule = (props: any) => {
    const { handleAddPopup,
        showPopup,
        handleClosePopup,
        addressObj,
        updateLocationData,
        countryList,
        setShowPopup } = props;

    const handleConfirmData = (details: any) => {
        if (details) {
            const { street, nr, bus, postal, city, country, coordinates } = details;
            let companyOption: any = []; // Declare it as an empty array or object

            // Find the country option if country is not empty
            if (country !== "") {
                companyOption =
                    countryList?.find((option: any) =>
                        option.label.toLowerCase().includes(country.toLowerCase())
                    ) || {}; // If no match found, default to an empty object
            }

            // Ensure coordinates is in the correct format before processing
            if (
                coordinates &&
                coordinates.includes("Lat: ") &&
                coordinates.includes("Lng: ")
            ) {
                const [lat, lng] = coordinates
                    .replace("Lat: ", "")
                    .replace("Lng: ", "")
                    .split(", ")
                    .map(Number); // Convert the values to numbers

                updateLocationData(
                    {
                        street,
                        number: nr,
                        box: bus,
                        zipcode: postal,
                        city,
                        initialLat: lat,
                        initialLn: lng,
                        country: companyOption
                    }
                );
            } else {
                console.error("Invalid coordinates format");
            }
        }

        setShowPopup(false); // Close the popup after confirming the address
    };

    return (
        <div style={{ marginLeft: "1vw" }}>
            <div className="d-flex align-items-center">
                <Link
                    to={""}
                    title={t("Search address via google map...")}
                    onClick={handleAddPopup}
                    className="color-dark-pink marginRightPoint5"
                >
                    <ActiveLocation />
                </Link>
                <Link to={""} className="link-color" onClick={handleAddPopup}>
                    {t("Search address via google map...")}
                </Link>
            </div>
            <AddAddressLoactionViaMap
                initialLat={
                    addressObj?.initialLat === ""
                        ? 50.8503
                        : parseFloat(addressObj?.initialLat)
                } // Replace with your initial latitude
                initialLng={
                    addressObj?.initialLn === ""
                        ? 4.3517
                        : parseFloat(addressObj?.initialLn)
                } // Replace with your initial longitude
                onConfirm={(details) => { handleConfirmData(details) }}
                showAddPopup={showPopup}
                onHide={handleClosePopup}
            />
        </div>
    );
}

export default LocationMolecule
