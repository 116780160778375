
import React from "react";
import { FormProvider } from "../../context/Context";
import CreateContact from "./CreateContactPerson";

export const Contact = () => {
  return (
    <>
      <CreateContact />
    </>
  );
};
export default Contact;