import React, { useEffect, useState } from "react";
import { useFormContext } from "pages/microservices/tempAgancy/context/Context";
import { t } from "pages/microservices/masterData/translation/Translation";

import {
  SWITCH_TAB,
  SET_OPTIONS,
  SET_ERRORS_GENERAL_TAB,
  UPDATE_TAB_DATA,
  UPDATE_ERRORS_CONTACTPERSONS_TAB,
  INITIAL_STATE,
  INITIAL_STATE_OBJECT,
  SET_ERRORS_ADDRESS_TAB,
  SET_ERRORS_LOCATION_TAB,
  SET_COMPOSITION_DATA,
  SET_PC_TABLE_DATA,
  SET_COEFFICIENT_DATA,
  UPDATE_EMPLOYEE_TYPE_PC,
  UPDATE_COEFFICIENT_TAB,
} from "pages/microservices/tempAgancy/context/Constants";

// } from "./validationRules/ProjectFormValidationRules";
import { validateForm } from "services/validation/ValidationService";
import FormNavigationOrganism from "components/organism/Actions/FormNavigationOrganism";
import { FormMode } from "components/common/CommonEnums";
import {
  PATH_MANAGE_TEMP_AGENCY,
  PATH_TEMP_AGENCY_PROPOSAL,
} from "constants/Paths";
import { useNavigate, useParams } from "react-router-dom";
import { isObjectEmpty } from "services/util/UtilService";

import { Option } from "components/common/CommonInterfaces";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import CustomNotify from "components/atoms/CustomNotify";
import Popup from "components/molecules/Popup";
// import { validate } from "./Utils";
import Address from "./componests/Address";
import EmployeeTypeAndCoeffcient from "./componests/EmployeeAndCoeffcient";
import SpecialAgreement from "./componests/SpecialAgrrement";
import Location from "./componests/Location";
import Invoice from "./componests/Invoice";
import {
  ContactPersonTabErrors,
  ContactsPersons as IContactPersons,
  GeneralTabErrors,
  ContactPersonErrors,
  AddressTabErrors,
  LocationTabErrors,
  CoeffObject,
} from "./context/Interface";
import {
  AddressTabValidationRules,
  ContactsTabSubformValidationRules,
  ContactsTabValidationRules,
  GeneralTabValidationRules,
  LocationTabValidationRules,
} from "./validationRules/TempAgencyValidationRules";
import General from "./componests/General";
import ContactsPersons from "./componests/Contact";
import { ApiCall } from "services/ApiServices";
import {
  CREATE_TEMP_AGENCY,
  FETCH_WAGE_ELEMENT,
  GET_TEMP_AGENCY_BY_ID,
  PROJECTS_OPTIONS,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import Coeffcient from "./componests/Coeffcient";
import Coefficient from "./componests/Coeffcient";
import EncryptDecryptService from "services/EncryptDecryptService";

export const TempAgencyForm = () => {
  const { state, dispatch } = useFormContext();
  const { id } = useParams<{ id: string }>();
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();
  const [showModel, setShowModel] = useState(false);
  const [tempAgancyId, setTempAgencyId] = useState("");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 10) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    dispatch({
      type: INITIAL_STATE,
      payload: INITIAL_STATE_OBJECT,
    });

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const projectFormHasErrors = (): boolean => {
    let hasErrors = false;
    state.tabs.forEach((tab, index) => {
      let tabHasErrors = false;

      switch (tab.id) {
        case "general":
          tabHasErrors = !isObjectEmpty(handleFieldValidationsGeneralTab(), {
            createLocationHasError: "",
            createLocation: "",
          });
          break;
        case "contactsPersons":
          const contactPersonsTabErrors =
            handleFieldValidationsContactPersonsTab();
          tabHasErrors =
            !isObjectEmpty(contactPersonsTabErrors, {
              contactsPersons: "",
              subformHasError: "",
            }) || contactPersonsTabErrors.subformHasError;
          break;

        case "address":
          tabHasErrors = !isObjectEmpty(handleFieldValidationsAddressTab());
          break;
        case "location":
          tabHasErrors = !isObjectEmpty(handleFieldValidationsLocationTab());
          break;
        default:
          break;
      }

      dispatch({
        type: "UPDATE_TAB_ERROR",
        tabIndex: index,
        error: tabHasErrors,
        draft: tab.draft,
      });

      if (tabHasErrors) {
        hasErrors = true;
      }
    });

    return hasErrors;
  };

  // todo: move to validationService
  /**
   * @param formData
   * The formData in format {fieldKey: fieldValue}
   * fieldKey:   can be of any type
   * fieldValue: can be of any type
   *
   * @param ignoreKeys
   * Keys that must be ignored, when validating field
   * Like subforms {fieldKey1: "", fieldKey2: ""}
   *
   * @returns true
   * If at least 1 field is filled (has data present)
   *
   * @returns false
   * Otherwise
   */
  const formAnyFieldIsFilled = (formData: Object, ignoreKeys: Object = {}) => {
    for (const [key, value] of Object.entries(formData)) {
      let ignoreCurrentKey = ignoreKeys.hasOwnProperty(key as string);
      if (!ignoreCurrentKey && value) {
        if (Array.isArray(value)) {
          if (value.length === 0) {
            return false;
          }
          if (value.length > 0) {
            if (typeof value[0] === "object") {
              let selectOption = value[0] as Option;
              if (selectOption.value && selectOption.value !== "") {
                return true;
              }
            }
          }
        } else {
          return true;
        }
      }
    }
    return false;
  };

  const handleFieldValidationsGeneralTab = (): GeneralTabErrors => {
    let newGeneralTabErrors = validateForm(
      state.general.data,
      GeneralTabValidationRules
    ) as Object;

    let newGeneralTabSubformErrors = {};

    Object.assign(newGeneralTabErrors, {
      createLocation: newGeneralTabSubformErrors,
    });

    dispatch({
      type: SET_ERRORS_GENERAL_TAB,
      errors: newGeneralTabErrors as GeneralTabErrors,
    });

    return newGeneralTabErrors as GeneralTabErrors;
  };

  const handleFieldValidationsContactPersonsTab =
    (): ContactPersonTabErrors => {
      let contactsTabErrors = validateForm(
        state.contactsPersons.data,
        ContactsTabValidationRules
      ) as Object;

      let contactsPersonsSubformErrorIndexes = [];
      let contactPersonsInSubformErrors: ContactPersonErrors[] = [];
      const contactPersonsInSubform: IContactPersons[] =
        state.contactsPersons.data.contactsPersons;

      contactPersonsInSubform.forEach((subformRow: IContactPersons, i) => {
        let anyFieldFilled = formAnyFieldIsFilled(subformRow, { id: "" });

        if (anyFieldFilled) {
          let subformRowErrors = validateForm(
            subformRow,
            ContactsTabSubformValidationRules
          ) as Object;

          if (!isObjectEmpty(subformRowErrors)) {
            contactsPersonsSubformErrorIndexes.push(i);
          }

          contactPersonsInSubformErrors.push(
            subformRowErrors as ContactPersonErrors
          );
          // }
        } else {
          contactPersonsInSubformErrors.push({} as ContactPersonErrors);
        }
      });

      Object.assign(contactsTabErrors, {
        contactsPersons: contactPersonsInSubformErrors,
      });
      Object.assign(contactsTabErrors, {
        subformHasError: contactsPersonsSubformErrorIndexes.length > 0,
      });

      dispatch({
        type: UPDATE_ERRORS_CONTACTPERSONS_TAB,
        errors: contactsTabErrors as ContactPersonTabErrors,
      });

      return contactsTabErrors as ContactPersonTabErrors;
    };

  const handleFieldValidationsAddressTab = (): AddressTabErrors => {
    let newAddressTabErrors = validateForm(
      state.address.data,
      AddressTabValidationRules
    ) as Object;

    dispatch({
      type: SET_ERRORS_ADDRESS_TAB,
      errors: newAddressTabErrors as AddressTabErrors,
    });

    return newAddressTabErrors as AddressTabErrors;
  };
  const handleFieldValidationsLocationTab = (): LocationTabErrors[] => {
    let newLocationTabErrors: LocationTabErrors[] = [];

    if (Array.isArray(state.location.data)) {
      // Iterate over the location data array
      state.location.data.forEach((locationItem, index) => {
        // Validate each location item
        const locationErrors = validateForm(
          locationItem,
          LocationTabValidationRules
        ) as Object;

        // Store errors for each location item in the array
        if (!isObjectEmpty(locationErrors)) {
          newLocationTabErrors[index] = locationErrors as LocationTabErrors;
        }
      });
    }

    dispatch({
      type: SET_ERRORS_LOCATION_TAB,
      errors: newLocationTabErrors,
    });

    return newLocationTabErrors;
  };
  /**
   * Handling the validations for sequence tab
   * @returns object
   */

  // Save as Draft validation
  const draftValidation = () => {
    // const tabFields = state.general;
    // const fieldName = "projectName";
    // const tabIndex = 0;
    // const tab = "general";
    // const fieldValue: any = tabFields.data[fieldName];
    // const validationError = validateRequired(fieldValue);
    // if (validationError) {
    //   dispatch({
    //     type: "UPDATE_FIELD_ERROR",
    //     tab: tab,
    //     fieldName: fieldName,
    //     error: validationError,
    //   });

    //   dispatch({
    //     type: "UPDATE_TAB_ERROR",
    //     tabIndex: tabIndex,
    //     error: true,
    //     draft: true,
    //   });
    //   return false;
    // } else {
    //   dispatch({
    //     type: "UPDATE_FIELD_ERROR",
    //     tab: tab,
    //     fieldName: fieldName,
    //     error: "",
    //   });
    //   dispatch({
    //     type: "UPDATE_TAB_ERROR",
    //     tabIndex: tabIndex,
    //     error: false,
    //     draft: true,
    //   });
    // }
    return true;
  };

  const ConstructTabs = (tab: string | number) => {
    switch (tab) {
      case "general":
        return <General />;
      case "contactsPersons":
        return <ContactsPersons />;
      case "address":
        return <Address />;
      case "location":
        return <Location />;
      case "employeeTypeAndCoeffcient":
        return <EmployeeTypeAndCoeffcient />;
      case "specialAgreement":
        return <SpecialAgreement />;
      case "invoice":
        return <Invoice />;
      case "coefficient":
        return <Coefficient />;
      default:
        break;
    }
  };

  useEffect(() => {
    getOptions();
  }, [state?.general?.data?.company, id]);

  async function getOptions() {
    if (state.general.data.company) {
      companyOptions();
    } else if (id) {
      tempAgecyDetails(id);
    } else {
      options();
      fetchWageElement();
    }
  }
  async function fetchWageElement() {
    const response = await ApiCall.getService(
      FETCH_WAGE_ELEMENT,
      "GET",
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      const wageElement = response?.data;
      dispatch({
        type: SET_COMPOSITION_DATA,
        field: "wageElement",
        value: wageElement,
      });
    }
  }

  async function options() {
    let postData = {
      options: [
        "businessUnit",
        "projectTypes",
        "company",
        "gender",
        "language",
        "roles",
        "template",
        "shift",
        "country",
        "ParitairComitee",
        "TempAgencyType",
        "PaymentTerm",
        "InvoiceStatus",
        "Coefficient",
        "EmployeeType",
        "RetentionPeriod",
        "CoefficientLevel",
      ],
    };
    const response = await ApiCall.service(
      PROJECTS_OPTIONS,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      const {
        businessUnit,
        company,
        gender,
        language,
        TempAgencyType,
        roles,
        country,
        PaymentTerm,
        InvoiceStatus,
        Coefficient,
        EmployeeType,
        ParitairComitee,
        RetentionPeriod,
        CoefficientLevel,
      } = response?.data;
      const whiteCollar = ParitairComitee.whiteCollar;
      const blueCollar = ParitairComitee.blueCollar;
      const blueEmployeeType = EmployeeType.blueCollar;
      const whiteEmployeeType = EmployeeType.whiteCollar;
      dispatch({
        type: SET_OPTIONS,
        field: "whiteEmployeeType",
        value: whiteEmployeeType,
      });
      dispatch({
        type: SET_OPTIONS,
        field: "blueEmployeeType",
        value: blueEmployeeType,
      });
      dispatch({
        type: SET_OPTIONS,
        field: "whiteCollar",
        value: whiteCollar,
      });
      dispatch({
        type: SET_OPTIONS,
        field: "blueCollar",
        value: blueCollar,
      });
      const selectAllOption: Option = {
        label: "Select All",
        value: "selectAll",
      };

      // Step 2: Add the "Select All" option to the beginning of the array
      const updatedBusinessUnit = [selectAllOption, ...businessUnit];

      // Step 3: Dispatch the updated array with the "Select All" option included
      dispatch({
        type: SET_OPTIONS,
        field: "businessUnit",
        value: updatedBusinessUnit,
      });
      dispatch({
        type: SET_OPTIONS,
        field: "retentionPeriod",
        value: RetentionPeriod,
      });
      dispatch({
        type: SET_OPTIONS,
        field: "coefficientLevel",
        value: CoefficientLevel,
      });
      dispatch({ type: SET_OPTIONS, field: "company", value: company });
      dispatch({ type: SET_OPTIONS, field: "countries", value: country });
      dispatch({ type: SET_OPTIONS, field: "gender", value: gender });
      dispatch({ type: SET_OPTIONS, field: "language", value: language });
      dispatch({ type: SET_OPTIONS, field: "paymentTerm", value: PaymentTerm });
      dispatch({
        type: SET_OPTIONS,
        field: "InvoiceStatus",
        value: InvoiceStatus,
      });
      dispatch({
        type: SET_OPTIONS,
        field: "tempAgencyTypes",
        value: TempAgencyType,
      });
      dispatch({
        type: SET_OPTIONS,
        field: "coefficient",
        value: Coefficient,
      });
      dispatch({ type: SET_OPTIONS, field: "roles", value: roles });
    }
  }
  async function companyOptions() {
    const company = state.general.data.company;
    if (!company) {
      return;
    }
    const postData = {
      options: [
        "contactPersons",
        "companyLocations",
        "companyFunctions",
        "CompanyParitairCommittee",
      ],
      companyId: company.value,
      tempId: id ? id : null,
    };

    const response = await ApiCall.service(
      PROJECTS_OPTIONS,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      const {
        contactPersons,
        companyLocations,
        companyFunctions,
        CompanyParitairCommittee,
      } = response?.data;
      dispatch({
        type: SET_OPTIONS,
        field: "companyUsers",
        value: contactPersons,
      });
      dispatch({
        type: SET_OPTIONS,
        field: "locations",
        value: companyLocations,
      });
      dispatch({
        type: SET_OPTIONS,
        field: "functions",
        value: companyFunctions,
      });

      // dispatch({
      //   type: SET_PC_TABLE_DATA,
      //   field: "companyParitairCommittee",
      //   value: CompanyParitairCommittee,
      // });
      // dispatch({
      //   type: SET_COEFFICIENT_DATA,
      //   field: "companyParitairCommittee",
      //   value: CompanyParitairCommittee,
      // });
    }
  }

  async function tempAgecyDetails(id: string) {
    if (!id) {
      return;
    }
    options();
    companyOptions();
    fetchWageElement();

    const response = await ApiCall.getService(
      `${GET_TEMP_AGENCY_BY_ID}/${id}`,
      "GET",
      M_MASTER_DATA
    );

    if (response?.status === 200) {
      const {
        general,
        contactsPersons,
        address,
        location,
        inVoice,
        specialAgreement,
        employeePc,
        coefficient,
      } = response?.data;
      dispatch({ type: UPDATE_TAB_DATA, field: "general", value: general });

      dispatch({
        type: UPDATE_TAB_DATA,
        field: "contactsPersons",
        value: contactsPersons,
      });

      dispatch({
        type: UPDATE_TAB_DATA,
        field: "address",
        value: address,
      });
      dispatch({
        type: UPDATE_TAB_DATA,
        field: "location",
        value: location,
      });
      dispatch({
        type: UPDATE_TAB_DATA,
        field: "inVoice",
        value: inVoice,
      });
      dispatch({
        type: UPDATE_EMPLOYEE_TYPE_PC,
        field: "employeePc",
        value: employeePc,
      });
      dispatch({
        type: SET_COMPOSITION_DATA,
        field: "wageElement",
        value: specialAgreement,
      });
      dispatch({
        type: UPDATE_COEFFICIENT_TAB,
        field: "coefficient",
        value: coefficient,
      });
    }
  }
  function handleTabSwitch(tab: string) {
    dispatch({
      type: SWITCH_TAB,
      field: "currentTab",
      value: tab,
    });
  }

  // Navigation tabs
  const handlePreviousStep = () => {
    const currentTabIndex = state.tabs.findIndex(
      (tab) => tab.id === state.currentTab
    );
    const nextTabIndex = (currentTabIndex - 1) % state.tabs.length;
    const nextTabId = state.tabs[nextTabIndex].id;
    dispatch({
      type: "UPDATE_CURRENT_TAB",
      field: "currentTab",
      value: nextTabId,
    });
  };

  const handleNextStep = () => {
    const currentTabIndex = state.tabs.findIndex(
      (tab) => tab.id === state.currentTab
    );
    const nextTabIndex = (currentTabIndex + 1) % state.tabs.length;
    const nextTabId = state.tabs[nextTabIndex].id;
    dispatch({
      type: "UPDATE_CURRENT_TAB",
      field: "currentTab",
      value: nextTabId,
    });
  };

  const handleSubmit = async () => {
    const formHasErrors = projectFormHasErrors();
    if (formHasErrors) {
      return;
    }
    const postData = {
      general: state.general.data,
      contactsPersons: state.contactsPersons.data,
      address: state.address.data,
      location: state.location.data,
      inVoice: state.inVoice.data,
      specialAgreement: state.wageElement,
      employeePc: state.employeeCoefficient,
      coefficient: state.coefficient,
    };
    const response = await ApiCall.service(
      CREATE_TEMP_AGENCY,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      CustomNotify({ type: "success", message: `${response.message}` });
      //  navigate(PATH_MANAGE_TEMP_AGENCY);
      const tempAgencyId = response?.result?.tempAgencyId;
      const user = response?.result?.user;
      setShowModel(true);
      setTempAgencyId(response?.result?.tempAgencyId);
      setUsers(user);
    } else if (response?.status === 500) {
      CustomNotify({ type: "error", message: `${response.message}` });
    }
  };
  const handleDraft = () => {
    draftValidation();
  };

  const presentOperation = id ? "update" : "create";
  const currentObj: any = {
    permission: "Project",
  };
  currentObj[presentOperation] = true;
  const userData = useSelector(selectAuth);
  const cancel = () => {
    setShowModel(false);
    navigate(PATH_MANAGE_TEMP_AGENCY);
  };
  const sendLetar = () => {
    navigate(PATH_MANAGE_TEMP_AGENCY);
  };
  const sendNow = () => {
    const data = {
      id: tempAgancyId,
      user: users,
    };
    const append = EncryptDecryptService.encryptData(JSON?.stringify(data));
    const encodedData = encodeURIComponent(append);
    navigate(`${PATH_TEMP_AGENCY_PROPOSAL}/?data=${encodedData}`);
  };

  return (
    <div className="position-relative">
      <AccessControl
        requiredPermissions={[currentObj]}
        renderNoAccess={true}
        override={userData.isSuperAdmin}
      >
        <div
          className="row header-sticky position-sticky"
          style={{ marginBottom: "2.3vw", height: "auto" }}
        >
          <div className="col-12">
            <h1
              className="page-title mb-0 d-flex align-items-center"
              style={{ paddingBottom: "0.7vw" }}
            >
              {t("Temp agency")}
            </h1>
          </div>
        </div>
        <nav
          className={`projectTabs company-step-tabs createFormTabs ${isSticky
              ? "createTabsSticky position-sticky"
              : "company-step-tabs-absolute position-absolute w-100"
            }`}
        >
          <div
            className="d-flex border-0 w-100 m-auto gap-3"
            id="nav-tab"
            role="tablist"
          >
            <div
              className={`d-flex border-0 w-100 m-auto align-items-center justify-content-center`}
              style={{ gap: "1vw" }}
            >
              {state.tabs.map((item: any) => (
                <div className={`flex-1 text-center`} key={item.id}>
                  <div
                    className={`createStepBtn cursor-pointer ${state.currentTab === item.id ? "active" : "inactive"
                      } ${item.error === true ? "error" : ""
                      }`}
                    onClick={() => handleTabSwitch(item.id)}
                  >
                    {t(item.title)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </nav>
        <div className="mt-2">{ConstructTabs(state.currentTab)}</div>
        <FormNavigationOrganism
          isFirstTab={state.currentTab === state.tabs[0].id}
          isSubmitTab={
            state.currentTab === state.tabs[state.tabs.length - 1].id
          }
          draftButtonIsVisible={state.mode === FormMode.CREATE}
          formExitBackTo={PATH_MANAGE_TEMP_AGENCY}
          handlePreviousStep={handlePreviousStep}
          handleNextStep={handleNextStep}
          handleSubmit={handleSubmit}
          handleDraft={handleDraft}
        />
        {showModel && (
          <Popup
            body={t("Send temp agency proposal")}
            nowSendText={t("Send now")}
            letarSendText={t("Send later")}
            cancel={cancel}
            sendLetar={sendLetar}
            sendNow={sendNow}
            footerclass="justify-content-between"
          />
        )}
      </AccessControl>
    </div>
  );
};
export default TempAgencyForm;
