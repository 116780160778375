import SelectWithSearch from "components/atoms/SelectWithSearch"
import { LabelWithInputField } from "components/molecules/LabelWithInputField"
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField"
import AddPlusIcon from "static/images/AddPlusIcon"
import { t } from "../translation/Translation"
import CloseFile from "static/images/CloseFile"

const CompetenceType = (props: any) => {

    const {
        handleChange,
        formData,
        errors,
        handleSelectChange,
        changeHandler,
        handleRemoveType,
        handleAddType,
    } = props


    return (
        <>
            <div className="row d-flex align-items-center">
                {formData?.competenceType?.map((type: any, index: number) => (
                    <>
                        <div className="form-group col-md-5" key={`competenceType-${index}`}>
                            <LabelWithInputField
                                isMandatory={true}
                                name={`competenceType-${index}`}
                                handleChange={(e) => changeHandler(e, index, 'competenceType')}
                                value={type?.label}
                                id={`competenceType${index}`}
                                label={t("Type of competences")}
                                placeholder={t("Type of competences")}
                                type="text"
                                error={errors?.competenceType?.[index]}
                            />
                        </div>
                        <div className="col-3 table-action-icons">
                            {formData?.competenceType?.length !== 1 && (
                                <span
                                    title={t("Delete")}
                                    onClick={() => handleRemoveType(index)}
                                    className="table-action-btn cursor-pointer marginRightPoint5"
                                >
                                    <CloseFile />
                                </span>
                            )}
                            {index === formData?.competenceType?.length - 1 && (
                                <span
                                    onClick={() => handleAddType(index)}
                                    className="table-action-btn cursor-pointer"
                                    title={t("Add another competences")}
                                >
                                    <AddPlusIcon />
                                </span>
                            )}
                        </div>
                    </>
                )
                )}
            </div>
        </>
    )
}
export default CompetenceType