import React from "react";

const FleetIcon: React.FC = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M16.0711 16.0716H7.1427V15.1788C7.1427 14.6855 7.54223 14.286 8.03554 14.286H15.1782C15.6715 14.286 16.0711 14.6855 16.0711 15.1788V16.0716ZM7.1427 17.8573H16.0711V19.643H7.1427V17.8573ZM7.1427 23.2143V21.4287H16.0711V23.2143H7.1427ZM5.35702 25H2.67851C1.19863 25 0 23.8014 0 22.3215V9.82177C0 8.97804 0.396191 8.18563 1.0714 7.67896L11.0712 0.179126C11.3893 -0.0597087 11.8245 -0.0597087 12.1426 0.179126L22.1424 7.67896C22.8176 8.18563 23.2138 8.97804 23.2138 9.82177V22.3215C23.2138 23.8014 22.0151 25 20.5353 25H17.8567V15.1788C17.8567 13.6989 16.6581 12.5003 15.1782 12.5003H8.03554C6.55565 12.5003 5.35702 13.6989 5.35702 15.1788V25ZM7.1427 10.7146H16.0711C16.5644 10.7146 16.9639 10.3151 16.9639 9.82177C16.9639 9.32847 16.5644 8.92893 16.0711 8.92893H7.1427C6.64939 8.92893 6.24986 9.32847 6.24986 9.82177C6.24986 10.3151 6.64939 10.7146 7.1427 10.7146Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default FleetIcon;
