import LocationMolecule from "./LocationElement";


const LinkElements = (props: any) => {
    const { field } = props;

    switch (field?.subType) {
        case "google-maps":
            return (<LocationMolecule />)
        default:
            return <></>
    }
}

export default LinkElements