import React, { useEffect, useState } from "react";
import { ApiCall } from "services/ApiServices";
import { useNavigate } from "react-router-dom";
import { COMPETNCE_GET } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import ManageCompetencePage from "./Index";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";

export interface Competence {
  id?: number;
  competenceName: string;
}

interface DataProps {
  page: number;
  search?: Competence | null;
}

const ManageCompetence: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [Competence, setCompetence] = useState<Competence[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [search, setSearch] = useState({
    competenceName: queryParams.get("name") ?? "",
    type: queryParams.get("type") ?? "",
  });
  const navigate = useNavigate();

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (name: string, value: string | number) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  // Function to create query parameters from the search object
  const createQueryParams = (data: Competence) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handleDeleteData = (deleteId: number) => {
    setCompetence((prevdata) =>
      prevdata.filter((businessUnit) => businessUnit.id !== deleteId)
    );
    setCurrentPage(1);
  };


  return (
    <ManageCompetencePage
      competenceObject={Competence}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={handlePageChange}
      searchValue={handleSearch}
      deleteFilter={handleDeleteData}
      searchData={search}
      submit={setSubmit}
    />
  );
};

export default ManageCompetence;
