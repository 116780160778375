import TitleFieldMolecule from "components/molecules/TitleField"
import { t } from "../masterData/translation/Translation"
import { Link } from "react-router-dom"
import ProjectParameter from "./components/ProjectParameter"
import { Days, MarginParameter } from "./context/Constants"
import TableAllowance from "./components/TableAllowance"
import TableMargin from "./components/TableMargin"
import CommonComponent from "./components/CommonComponent"
import Button from "components/atoms/Button"
import { useFormContext } from "./context/Context"
import { useEffect, useState } from "react"
import { ApiCall } from "services/ApiServices"
import { CALCULATE_MARGIN, GET_PROJECT_OPTIONS_FOR_MARGIN } from "routes/ApiEndpoints"
import { M_MASTER_DATA } from "constants/Constants"
import CustomNotify from "components/atoms/CustomNotify"
import WindowHeightComponent from "components/atoms/WindowHeightComponent"
import { validateForm } from "services/validation/ValidationService"
import { MarginValidation } from "./context/ValidationRules"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons"
import BackButton from "components/atoms/BackButton"
import TitleAtom from "components/atoms/Title"
import "./css/marginCalculator.css";

const MarginCalculator = () => {

  const { state, dispatch } = useFormContext();
  const [isLastColumnOpen, setIsLastColumnOpen] = useState(true);

  const handleCalculate = async () => {

    const { display, durationAndAllowance, options, marginTotal, ...remainingFields } = state?.data;
    let errorsData: any = {};
    for (let eachKey of Object.keys(remainingFields)) {
      const validationErrors = validateForm(state?.data?.[eachKey]?.form ? state?.data?.[eachKey]?.form : state?.data?.[eachKey], MarginValidation[eachKey]);
      if (Object.keys(validationErrors).length > 0) {
        errorsData[eachKey] = validationErrors;
      }
    }


    let durationData = [];
    let validationResult = false;
    for (let eachTime of durationAndAllowance) {
      const pauseTimeData = eachTime.pauseTime.split(':');
      const totalHoursData = eachTime.hours.split(':');
      const pauseHrs = parseInt(pauseTimeData[0]) + parseInt(pauseTimeData[1]) / 60;
      const totalHrs = parseInt(totalHoursData[0]) + parseInt(totalHoursData[1]) / 60;

      if ((pauseHrs >= totalHrs) || (eachTime.projectAllowance == '') || (eachTime.technicianAllowance == '')) {
        console.log(eachTime.projectAllowance)
        durationData.push({
          pauseTime: (pauseHrs >= totalHrs ? "Pause time should be between start and end time" : ""),
          projectAllowance: (eachTime.projectAllowance !== '' ? '' : 'This field is required'),
          technicianAllowance: (eachTime.technicianAllowance !== '' ? '' : 'This field is required')
        });
        validationResult = true;
      }
    }

    if (validationResult) {
      errorsData['durationAndAllowance'] = durationData;
    }

    if (Object.keys(errorsData).length > 0) {
      dispatch({
        type: "UPDATE_OVERALL_ERRORS",
        payload: {
          errors: errorsData,
        }
      })
    }
    else {
      const contractTypesData = state?.data?.statute?.form?.name;
      const contractsOptions = state?.data?.options?.statute;
      const checkedData = contractsOptions.filter((eachOptions: any) => {
        if (contractTypesData[eachOptions.alias] !== undefined && contractTypesData[eachOptions.alias] == true) {
          return eachOptions;
        }
      }).map((eachItem: any) => eachItem.name);

      try {
        const response = await ApiCall.service(
          CALCULATE_MARGIN,
          "POST",
          { data: { ...state.data, type: checkedData } },
          false,
          M_MASTER_DATA,
        );
        if (response?.status == 200) {
          dispatch({
            type: "UPDATE_MARGIN_DATA",
            payload: {
              data: response?.data
            }
          })
        }
        else {
          CustomNotify({ type: "warning", message: response?.message })
        }
      }
      catch (error) {
        console.log("Error");
      }
    }
  }

  console.log(state);

  useEffect(() => {
    getOptions();
  }, []);

  const getOptions = async () => {
    try {
      const response = await ApiCall.service(
        GET_PROJECT_OPTIONS_FOR_MARGIN,
        "POST",
        { data: { classification: '' } },
        false,
        M_MASTER_DATA,
      );
      if (response?.status == 200) {
        dispatch({
          type: "UPDATE_ALL_OPTIONS",
          payload: {
            data: response?.data
          },
        })
      }
      else {
        CustomNotify({ type: "warning", message: response?.message })
      }
    }
    catch (error) {
      console.log("Error");
    }

  }
  const { marginTotal } = state?.data;

  return (
    <>
      <div className="search-bar">
        <div className="row">
          <TitleAtom title={t("Margin calculator")} />
        </div>
      </div>
      {/* <TitleFieldMolecule
                    title={t("Marge/week")}
                /> */}
      <div className="row flex-1 overflow-auto h-100">
        <div className={`col-${isLastColumnOpen ? 8 : 12} overflow-auto marginCalculatorLeftSection`}>
          <table className="table table-bordered form-border p-3">
            <thead className="TableHeader">
              <tr>
                <th></th>
                <th>
                  {t("Hours")}
                </th>
                <th>
                  {t("Project allowance %")}
                </th>
                <th>
                  {t("Technician allowance %")}
                </th>
                <th>
                  {t("Revenue")}
                </th>
                <th>
                  {t("Cost")}
                </th>
                <th>
                  {t("Margin(€)")}
                </th>
                <th>
                  {t("Margin(%)")}
                </th>
              </tr>
            </thead>
            <tbody>
              {Days.map((eachDay: any, index: number) => (
                <>
                  <TableAllowance key={eachDay} day={eachDay} index={index} />
                  <TableMargin key={index} index={index} />
                </>
              ))}
              <tr>
                <td>{t('Total')}</td>
                <td>{marginTotal?.totalHours}</td>
                <td></td>
                <td></td>
                <td>{marginTotal?.revenue}</td>
                <td>{marginTotal?.cost}</td>
                <td>{marginTotal?.margin}</td>
                <td>{marginTotal?.marginPercentage}</td>
              </tr>
            </tbody>
          </table>

          <div className="d-flex justify-content-end position-sticky bottom-0" style={{ paddingBottom: "0.5vw" }}>
            <button
              className="cursor-pointer sidebar-icon"
              onClick={() => setIsLastColumnOpen(!isLastColumnOpen)}
            >
              {!isLastColumnOpen ? <FontAwesomeIcon icon={faAngleLeft} /> : <FontAwesomeIcon icon={faAngleRight} />}
            </button>
          </div>
        </div>

        {isLastColumnOpen && (
          <div className="col-4 overflow-auto marginCalculatorRightSection">
            <div className="row">
              <div className="col-12">
                <ProjectParameter />
              </div>
              {MarginParameter.map((eachPara: any, index: number) => (
                <CommonComponent key={index} parameter={eachPara} index={index} />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-12 text-end">
          {/* <BackButton /> */}
          <Button
            title={t("Calculate margin")}
            type={"button"}
            handleClick={handleCalculate}
            className={
              "form-button shadow-none"
            }
          />
        </div>
      </div>
    </>
  )
}
export default MarginCalculator