import Title from 'components/atoms/Title';
import { t } from 'pages/microservices/masterData/translation/Translation';
import React, { useEffect, useState } from 'react';
import { useFormContext } from '../../context/Context';
import { AGE, CATEGORY_AND_FUNCTIONS, EMPLOYEE_TYPE, PC_DETAILS, SALARY_BENEFITS, SET_DROPDOWN_DATA, UPDATE_TAB_DETAILS } from '../../annotations/PCConstants';
import { PCDetails } from '../form-content/PCDetails';
import { CategoryAndFunctions } from '../form-content/CategoryAndFunctions';
import { Age } from '../form-content/Age';
import { EmployeeType } from '../form-content/EmployeeType';
import { SalaryBenefits } from '../form-content/SalaryBenefits';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { PC_EDIT, PC_GET } from 'routes/ApiEndpoints';
import { ApiCall } from 'services/ApiServices';
import { M_MASTER_DATA } from 'constants/Constants';
import { useParams } from 'react-router-dom';
import { setPcData } from './setPcData';

export const PCForm = () => {
  const { state, dispatch } = useFormContext();
  const params = useParams();
  const id = params.id;
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 20) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    fetchPCData();
  }, []);

  const fetchPCData = async () => {
    let response;
    if (id) {
      response = await ApiCall.service(
        PC_EDIT,
        "POST",
        { id: id },
        false,
        M_MASTER_DATA
      );

    } else {
      response = await ApiCall.service(
        PC_GET,
        "POST",
        {},
        false,
        M_MASTER_DATA
      );
    }

    if (response?.status === 200) {
      dispatch({ type: SET_DROPDOWN_DATA, payload: response?.dropdownData });
      response?.data && setPcData(state, dispatch, id, response?.data);
    }
  }

  const ConstructTabs = (id: string, showStatus: boolean) => {
    switch (id) {
      case PC_DETAILS:
        return <PCDetails />;
      case CATEGORY_AND_FUNCTIONS:
        return <CategoryAndFunctions />;
      case AGE:
        return <Age />
      case EMPLOYEE_TYPE:
        return <EmployeeType />;
      case SALARY_BENEFITS:
        return <SalaryBenefits />;
      default:
        break;
    }
  };

  //For handling the tab navigation
  const handleTabClick = (tabId: string) => {
    const currentTab = state?.tabDetails.findIndex(
      (tab: any) => tabId === tab.id
    );
    state.tabDetails[currentTab].error = false;
    const updatedTabDetails = state.tabDetails.map((tab) => ({
      ...tab,
      showStatus: tab.id === tabId,
    }));

    dispatch({ type: UPDATE_TAB_DETAILS, tabDetails: updatedTabDetails });
  };

  return (<>
    <div className="position-relative">

      {/* {state?.tabDetails?.[0]?.error === true && (
            <div className="px-4">
              <div className="row">
                <div className="px-4 error-msg-tabs col-12">
                  <div
                    className="alert alert-danger alert-dismissible fade show "
                    role="alert"
                  >
                    <span className="me-2">
                      <FontAwesomeIcon icon={faCircleExclamation} />
                    </span>
                    {t("Please fill the mandatory fields to create a vacancy.")}
                    <button
                      type="button"
                      className="btn-close shadow-none"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          )} */}
      <div
        className="row header-sticky position-sticky"
        style={{ marginBottom: "2.3vw", height: "auto" }}
      >
        <div className="col-12">
          <h1
            className="page-title mb-0 d-flex align-items-center"
            style={{ paddingBottom: "0.7vw" }}
          >
            {t("Paritair comitee")}
          </h1>
        </div>
      </div>
      <nav
        className={`projectTabs company-step-tabs createFormTabs ${isSticky
          ? "createTabsSticky position-sticky"
          : "company-step-tabs-absolute position-absolute w-100"
          }`}
      >
        <div
          className="d-flex border-0 w-100 m-auto gap-3"
          id="nav-tab"
          role="tablist"
        >
          <div
            className={`d-flex border-0 w-75 m-auto align-items-center justify-content-center`}
            style={{ gap: "1vw" }}
          >
            {state.tabDetails && state.tabDetails.map((item: any, index) => (
              <button
                key={item?.id}
                className={`nav-link createStepBtn me-lg-3 text-break ${item?.error ? "error" : ""
                  } ${item?.showStatus ? "active" : "inactive"}`}
                id={`${item.id}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#${item.id}`}
                type="button"
                role="tab"
                aria-controls={`nav-${item.id}`}
                aria-selected={item.showStatus}
                onClick={() => handleTabClick(item.id)}
              >
                <div
                  title={`${item.error && t("Please fill all the mandatory fields")}`}
                  className=" d-flex align-items-center justify-content-center"
                >
                  {t(`${item?.title}`)}

                  {/* {item.error && (
                        <span
                          className="exclamation-icon ms-2"
                          title={`${t("Please fill all the mandatory fields")}`}
                          data-toggle="tooltip"
                        >
                          <FontAwesomeIcon icon={faTriangleExclamation} />
                        </span>
                      )} */}
                </div>
              </button>
            ))}
          </div>
        </div>
      </nav>

      <div className="tab-content py-0" id="nav-tabContent">
        {state.tabDetails.map((item: any) => (<>
          <div
            key={item.id}
            className={`tab-pane fade ${item.showStatus ? "show" : ""} ${item.showStatus ? "active" : ""
              }`}
            id={item.id}
            role="tabpanel"
            aria-labelledby={`${item.id}-tab`}
          >
            {ConstructTabs(item.id, item.showStatus)}
          </div>
        </>
        ))
        }
      </div>
    </div>
  </>);
}