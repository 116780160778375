import React, { useEffect, useState } from "react";
import Calender from "components/molecules/Calender";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { useTimesheetModalContext, useTimesheetModalDispatch } from "../timesheets-redux/context/TimesheetModalContext/TimesheetModalContext";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import { TIMESHEET_ACTIONS } from "../timesheets-redux/actions/TimesheetActions";
import { useTImesheetContext, useTimesheetDispatchContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { TIMESHEET_MODAL_ACTIONS } from "../timesheets-redux/actions/TimesheetModalActions";
import Button from "components/atoms/Button";
import { calculateReimbursementTotalPrice } from "../utils/calculateUtils";
import { t } from "pages/microservices/masterData/translation/Translation";

export default function ReimbursementModal() {
  const {initialTimesheetModalState} = useTimesheetModalContext();
  const { filterOptionsState } = useFilterOptions();
  const { timesheetDispatch } = useTimesheetDispatchContext();
  const { initialTimesheetState } = useTImesheetContext();
  const { timesheetModalDispatch } = useTimesheetModalDispatch();
  const [errors, setErrors] = useState({
    // date: "",
    type: "",
    numbers: "",
    price: "",
    totalPrice: ""
  })
  const employee = filterOptionsState?.optionState?.employees.filter((item) => {
    if (filterOptionsState?.selectedState?.employee_id == item.value) {
      return item;
    }
  });
  const project = filterOptionsState?.optionState?.projects?.filter((item) => {
    if (filterOptionsState?.selectedState?.project_id == item?.value) {
      return item;
    }
  });

  const reimbursementData = initialTimesheetState?.timesheet_data?.find(
    (item) => item?.plan_id && item?.plan_id === initialTimesheetModalState?.reimbursement?.planId
  ) || {};

  useEffect(() => {
    if (initialTimesheetModalState.reimbursement.data && initialTimesheetModalState.reimbursement.data?.length <= 0) {      
      const Data = {
        date: initialTimesheetModalState?.reimbursement?.date,
        type: initialTimesheetModalState?.reimbursement?.data?.type ?? null,
        numbers: "",
        price: initialTimesheetModalState?.reimbursement?.data?.price ?? '18',
        totalPrice: calculateReimbursementTotalPrice(initialTimesheetModalState?.reimbursement?.data),
      }
      timesheetModalDispatch({
        type: TIMESHEET_MODAL_ACTIONS.UPDATE_MODAL_DATA,
        payload: {
          modalType: 'reimbursement',
          data: Data,
        },
      });
    }
  }, [initialTimesheetModalState.reimbursement.data]);

  const handleChange = async (value, field, type) => {    
    const updatedData = { ...initialTimesheetModalState?.reimbursement?.data };
    switch (type) {
      case "select":
        updatedData[field] = value?.value;
        break;
      case "input":
        const inputValue = value.target.value;
        const regex = /^[0-9,.]+$/;
        if (inputValue === "" || regex.test(inputValue)) {
          updatedData[field] = inputValue;
        }
        updatedData['totalPrice'] = calculateReimbursementTotalPrice(updatedData);
        break;
      case 'default':
        break;
    }
    timesheetModalDispatch({
      type: TIMESHEET_MODAL_ACTIONS.UPDATE_MODAL_DATA,
      payload: {
        modalType: 'reimbursement',
        data: updatedData,
      },
    });
  };

  const validate = () => {
    let error = { ...errors };   
    Object.keys(errors)?.map((item) => {
      let value = initialTimesheetModalState?.reimbursement?.data;
      error[item] = (value?.[item] && value?.[item] != "") ? "" : "The feild is required"
    })
    setErrors(error);    
    return !Object?.values(error)?.some((value) => value != "");
  }

  const handleSubmit = async (e) => {
    e?.preventDefault();   
    const isValidated = validate();    
    if (isValidated) {
      const { planId } = initialTimesheetModalState?.reimbursement || {};      
      if (planId) { 
        let updatedreimbursementData = { ...reimbursementData?.reimbursement ?? {} };
        if (typeof initialTimesheetModalState?.index === 'number') {
          const reimbursementArray = Object.entries(updatedreimbursementData);
          reimbursementArray[initialTimesheetModalState.index][1] = { ...reimbursementArray[initialTimesheetModalState.index][1], ...initialTimesheetModalState?.reimbursement?.data };
          updatedreimbursementData = Object.fromEntries(reimbursementArray);
        } else {
          updatedreimbursementData = {
            ...updatedreimbursementData,
            [initialTimesheetModalState?.reimbursement?.data?.type]: initialTimesheetModalState?.reimbursement?.data,
          };
        }
        timesheetDispatch({
          type: TIMESHEET_ACTIONS.UPDATE_TIMESHEET_DATA,
          payload: {
            planId: planId,
            value: updatedreimbursementData,
            name: 'reimbursement',
          },
        });
        await handleTimeSheetModelDispatch()
      }
    }
  };

  const handleDelete = async () => {
    const { planId } = initialTimesheetModalState?.reimbursement || {};
    if (planId) {
      if (reimbursementData?.reimbursement && Object.keys(reimbursementData?.reimbursement).length > 0) {
        const { [initialTimesheetModalState?.reimbursement?.data?.type]: removed, ...updatedreimbursementData } = reimbursementData?.reimbursement;
        timesheetDispatch({
          type: TIMESHEET_ACTIONS.UPDATE_TIMESHEET_DATA,
          payload: {
            planId: initialTimesheetModalState?.reimbursement?.planId,
            value: updatedreimbursementData,
            name: 'reimbursement',
          },
        });
      }
    }
    await handleTimeSheetModelDispatch();
  }

  const handleTimeSheetModelDispatch = async () => {
    timesheetModalDispatch({
      type: TIMESHEET_MODAL_ACTIONS.OPEN_AND_CLOSE_MODAL,
      payload: {
        status: false,
        modalType: 'reimbursement',
        date: null,
        planId: null,
        data: []
      },
    });
  }

  return (
    <div className="popup-container">
      <div className="popup">
        <form onSubmit={handleSubmit}>
          <div className="d-flex row mb-2">
            <p>{`${t("Project")}: ${project[0] ? project[0].label : ""}`}</p>
            <p>{`${t("Employee")}: ${employee[0] ? employee[0].label : ""}`}</p>
          </div>
          <div className="d-grid" style={{gridTemplateColumns:"repeat(2, 1fr)", gap:"0 1vw"}}>
            <div className="form-group position-relative">
              <Calender
                handleChange={(value) => handleChange(value, "start_date", "date")}
                selectedDate={initialTimesheetModalState?.reimbursement?.date ? new Date(initialTimesheetModalState?.reimbursement?.date) : null}
                label={t("Date")}
                isMandatory={false}
                name={"start_date"}
                error={errors?.date}
                isDisabled
              ></Calender>
            </div>

            <div>
              <SelectWithSearch
                search
                onChange={(value) => handleChange(value, "type", "select")}
                title={t("Type")}
                placeHolder={t("Select")}
                options={filterOptionsState?.optionState?.reimbursementTypes}
                value={initialTimesheetModalState?.reimbursement?.data?.type}
                isMandatory
                error={errors?.type}
                name={"type"}
                id={"type"}
              ></SelectWithSearch>
            </div>
            <div>
              <LabelWithInputField
                label={t("Number")}
                name="numbers"
                isMandatory
                value={initialTimesheetModalState?.reimbursement?.data?.numbers}
                handleChange={(value) => handleChange(value, "numbers", "input")}
                id="numbers"
                type="text"
                error={errors?.numbers}
              />
            </div>
            <div>
              <LabelWithInputField
                label={t("Price")}
                name="price"
                isMandatory
                value={initialTimesheetModalState?.reimbursement?.data?.price}
                handleChange={(value) => handleChange(value, "price", "input")}
                id="price"
                type="text"
                error={errors?.price}
              />
            </div>
            <div>
              <LabelWithInputField
                label={t("Total price")}
                name="totalPrice"
                isMandatory
                value={calculateReimbursementTotalPrice(initialTimesheetModalState?.reimbursement?.data)}
                handleChange={(value) => handleChange(value, "totalPrice", "input")}
                id="totalPrice"
                type="text"
                isDisabled
                error={errors?.price}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <Button
              title={t("Cancel")}
              type="cancel"
              handleClick={handleTimeSheetModelDispatch}
              className="close-button shadow-none text-uppercase search-btns"
            />
            <div className="d-flex align-items-center justify-content-end">
              <Button
                title={t("Delete")}
                type="delete"
                handleClick={handleDelete}
                className="form-button shadow-none text-uppercase marginRightPoint5 search-btns"
              />
              <Button
                title={t("Save")}
                type="submit"
                handleClick={handleSubmit}
                className="form-button shadow-none text-uppercase search-btns"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
