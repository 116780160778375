import React from "react";
import FormSubTitle from "components/atoms/FormSubTitle";

interface FormSectionTitleProps {
  title?: string;
}

const FormSectionTitle: React.FC<FormSectionTitleProps> = ({ title }) => {
  return <>
    <FormSubTitle title = {title} />
  </>;
};
export default FormSectionTitle;