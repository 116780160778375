import React from 'react';
import { toast, ToastOptions, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export type NotifyType = 'success' | 'error' | 'warning';

interface CustomNotifyProps {
  type?: NotifyType;
  message?: React.ReactNode; // Allows string or JSX
  autoClose?: number;
}

const CustomNotify: React.FC<CustomNotifyProps> = ({ type = 'success', message, autoClose = 1500 }) => {
  const toastOptions: ToastOptions = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: autoClose,
    transition: Zoom,
  };

  // Check the type and display toast accordingly
  if (type === 'success') {
    toast.success(message || 'Logged in successfully!', toastOptions);
  } else if (type === 'error') {
    toast.error(message || 'An error occurred!', toastOptions);
  } else if (type === 'warning') {
    toast.warning(message || 'Some warning!', toastOptions);
  }

  // Return null as this component does not render anything
  return null;
};

export default CustomNotify;
