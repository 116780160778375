import TextEllipsis from 'components/atoms/TextEllipsis';
import React from 'react'


// <PlanningBox data={"a"} bg_color={"red"} />

const EmployeeBox = ({ width, data, bg_color, color }) => {
  return (
    <span className="d-flex align-items-center justify-content-center"
      style={{
        width: `${width}`, height: "100%", background: `${bg_color ? bg_color : "grey"}`,
        color: `${color ? color : "white"}`
      }}>
      <TextEllipsis title={data} label={data} width="6.2vw" />
    </span>
  );
}

export default EmployeeBox;
