import { t } from "pages/microservices/masterData/translation/Translation";
import CheckBoxField from "components/atoms/CheckBoxField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import React, { useEffect, useState } from "react";
import {
  initialFormData,
  jobApplicationProps,
  applicationOptions,
  recruitmentStatus,
} from "./JobApplicationsInterface";
import Button from "components/atoms/Button";
import ModalPopup from "components/atoms/ModalPopup";
// import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import LabelField from "components/atoms/LabelField";
import LoadingIcon from "utils/LoadingIcon";
import { validateForm, validateSelectField } from "services/validation/ValidationService";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { Option } from "utils/TypeAnnotations";
import { M_IDENTITY_MANAGER, M_MASTER_DATA } from "constants/Constants";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";
import TitleAtom from "components/atoms/Title";
import AccessControl from "services/AccessControl";
//import { PHONE_CALL_URL } from "routes/ApiEndpoints";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import ResumeIcon from "static/images/Resume";
import { EMPLOYEE_CONVERSION, GET_USER, SAVE_JOB_APPLICATION } from "routes/ApiEndpoints";
import BackButton from "components/atoms/BackButton";
import { EDIT_CANDIDATE, PATH_MANAGE_APPLICANTS } from "constants/Paths";

interface ValidationRules {
  [key: string]: Function[];
}


const ViewJobApplicationOverView: React.FC = () => {
  const [formData, setFormData] =
    useState<jobApplicationProps>(initialFormData);

  const { applicationId } = useParams<{ applicationId: string }>();
  const navigate = useNavigate();
  const userAuth = useSelector(selectAuth);

  // Fetch job application details
  // const fetchJobApplicationDetails = async () => {
  //     const url = applicationId
  //         ? `${ENDPOINTS.GET_JOB_APPLICATION_DETAILS}/${applicationId}`
  //         : ENDPOINTS.GET_JOB_APPLICATION_DETAILS;
  //     const response = await ApiCall.getService(
  //         url,
  //         "GET",
  //         CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
  //         true
  //     );
  //     if (response.status === 200) {
  //         setFormData(response.data);
  //     }
  // };

  // useEffect(() => {
  //     fetchJobApplicationDetails();
  // }, [applicationId]);

  const { id: routeId } = useParams();

  const fetchJobApplicationDetails = async () => {
    try {
      const response = await ApiCall.service(
        GET_USER,
        "POST",
        { id: routeId },
        false,
      );
      if (response?.status == 200) {
        const { firstName,
          lastName,
          email,
          street,
          houseNumber,
          box,
          zip_code, city, country_id, phone_number, roles, recruitmentStatus } = response?.data;

        setFormData((prevState: any) => ({
          ...prevState,
          candidate: firstName + ' ' + lastName,
          email,
          street,
          number: houseNumber,
          box,
          zipCode: zip_code,
          city: city,
          country: country_id,
          mobNumber: phone_number,
          roles,
          recruitmentStatus,
        }));
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (routeId) {
      fetchJobApplicationDetails();
    }
  }, []);

  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );
  const [modal, setModal] = useState(false);

  const handleSelectChange = (selectedOption: any, name: string) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: selectedOption,
    }));
    validation(name, selectedOption, true);
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = event.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [loading, setLoading] = useState(false);

  const validation = (
    name: string,
    value: string | boolean | Option | null | number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      status: [validateSelectField],
      recruitmentStatus: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };


  const isModalOpen = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const navigateToMatching = async (candidateId: number | null) => {
    navigate(`/matching-list/candidate/${candidateId}`);
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();


    const validationRules = {
      status: [validateSelectField],
      recruitmentStatus: [validateSelectField],
    }

    const validationErrors = validateForm({ status: formData?.status, recruitmentStatus: formData?.recruitmentStatus }, validationRules)

    setLoading(true);
    if (Object.keys(validationErrors).length == 0) {
      try {
        const response = await ApiCall.service(
          SAVE_JOB_APPLICATION,
          'POST',
          { ...formData, id: routeId },
          false,
          M_MASTER_DATA,
        );
        if (response?.status == 200) {
          CustomNotify({ type: "success", message: response?.message });
          navigate(`${PATH_MANAGE_APPLICANTS}`);
        }
      }
      catch (error) {
        console.log(error);
      }
    }
    else {
      setErrors((prevError: any) => ({ ...prevError, ...validationErrors }));
    }

    // if (validation("status", formData.status)) {
    //     const data = {
    //         status: formData.status,
    //         candidateId: formData.candidateId,
    //         externalRef: formData.externalRef,
    //         externalInfo: formData.externalInfo,
    //     };
    //     const url = !applicationId
    //         ? ENDPOINTS.UPDATE_OR_CREATE_JOB_APPLICATION
    //         : `${ENDPOINTS.UPDATE_OR_CREATE_JOB_APPLICATION}/${applicationId}`;
    //     const response = await ApiCall.service(
    //         url,
    //         "POST",
    //         data,
    //         false,
    //         CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    //     );
    //     if (response.status === 200) {
    //         // navigate("/manage/job-applications");
    //         await fetchJobApplicationDetails();
    //         CustomNotify({ type: "success", message: t(response.msg) });
    //     }
    // }
    setLoading(false);
  };

  const userData = useSelector(selectAuth);


  const handleCreateCandidate = async () => {
    setModal(false);
    const appStatus = formData?.status?.value ?? 1;
    try {
      const postData = {
        id: routeId,
        candidateResult: true
      }
      const response = await ApiCall.getService(`${EMPLOYEE_CONVERSION}/${routeId}`, "GET", M_IDENTITY_MANAGER, true);
      if (response?.status == 200) {
        navigate(`${EDIT_CANDIDATE}/${routeId}`);
      }
      else {
        CustomNotify({ type: "error", message: response?.message });
      }
    }
    catch (error) {
      console.log(error);
    }

  };
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Job application overview",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <div className="row">
            <TitleAtom title={t("Applications")} />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <div className="form-border">
                <div className="row">
                  <div className="col-sm-12 col-xxl-4 col-md-4">
                    <SelectWithSearch
                      title={t("Status")}
                      search={true}
                      options={applicationOptions}
                      onChange={(e) => handleSelectChange(e, "status")}
                      isMulti={false}
                      name="status"
                      value={formData.status}
                      isDisabled={false}
                      error={errors.status}
                      placeHolder={t("Status")}
                    />
                  </div>
                  <div className="col-sm-12 col-xxl-4 col-md-4">
                    <SelectWithSearch
                      title={t("Recruitment status")}
                      search={true}
                      options={recruitmentStatus}
                      onChange={(e) => handleSelectChange(e, "recruitmentStatus")}
                      isMulti={false}
                      name="status"
                      value={formData?.recruitmentStatus}
                      isDisabled={false}
                      error={errors?.recruitmentStatus}
                      placeHolder={t("Recruitment status")}
                    />
                  </div>
                  <div className="col-md-4 searchBtnWrapper">
                    {formData?.roles?.find((eachrole: any) => (eachrole.label.toLowerCase() == 'applicant')) !== undefined ?
                      (<Button
                        title={t("Create candidate")}
                        handleClick={isModalOpen}
                        className="form-button shadow-none float-end"
                      />
                      ) :
                      (
                        <Button
                          title={t("Matching")}
                          handleClick={() =>
                            navigateToMatching(formData.candidateId)
                          }
                          className="form-button marginRightPoint5 shadow-none float-end"
                        />
                      )}
                  </div>

                  <ModalPopup
                    show={modal}
                    onHide={closeModal}
                    title={t("Create candidate")}
                    body={
                      t(
                        "You are about to create candidate based on the information from this application. Do you wish to continue"
                      ) + "?"
                    }
                    className="modal-lg jobApplicationModal"
                    onConfirmButtonClick={handleCreateCandidate}
                    confirmTitle={t("Yes")}
                  />
                </div>
                <div className="row">
                  <div className="col-sm-12 col-xxl-3 col-md-3">
                    <LabelWithInputField
                      isMandatory={false}
                      name="dateOfApplication"
                      handleChange={() => { }}
                      value={formData.dateOfApplication}
                      isDisabled={true}
                      id="dateOfApplication"
                      label={t("Date of Application")}
                      type="text"
                      placeholder={t("Date of Application")}
                    />
                  </div>
                  <div className="col-sm-12 col-xxl-3 col-md-3">
                    <LabelWithInputField
                      isMandatory={false}
                      name="channel"
                      handleChange={() => { }}
                      value={formData.channel}
                      isDisabled={true}
                      id="channel"
                      label={t("Channel")}
                      type="text"
                      placeholder={t("Channel")}
                    />
                  </div>
                  <div className="col-sm-12 col-xxl-3 col-md-3">
                    <LabelWithInputField
                      isMandatory={false}
                      name="candidate"
                      handleChange={() => { }}
                      value={formData.candidate}
                      isDisabled={true}
                      id="candidate"
                      label={t("Candidate")}
                      type="text"
                      placeholder={t("Candidate")}
                    />
                  </div>
                  <div className="col-sm-12 col-xxl-3 col-md-3">
                    <SelectWithSearch
                      title={t("Business unit")}
                      search={true}
                      options={[]}
                      onChange={() => { }}
                      isMulti={false}
                      name="office"
                      value={formData.office}
                      isDisabled={true}
                      placeHolder={t("Business unit")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-xxl-3 col-md-3">
                    <LabelWithInputField
                      isMandatory={false}
                      name="jobOffer"
                      handleChange={() => { }}
                      value={formData.jobOffer}
                      isDisabled={true}
                      id="jobOffer"
                      label={t("Job offer")}
                      type="text"
                      placeholder={t("Job offer")}
                    />
                  </div>
                  <div className="col-sm-12 col-xxl-3 col-md-3">
                    <LabelWithInputField
                      isMandatory={false}
                      name="viewApplicationStatus"
                      handleChange={() => { }}
                      value={formData.viewApplicationStatus}
                      isDisabled={true}
                      id="viewApplicationStatus"
                      label={t("Application Status")}
                      type="text"
                      placeholder={t("Application Status")}
                    />
                  </div>
                  <div className="col-sm-12 col-xxl-3 col-md-3 plusIconSPace">
                    <CheckBoxField
                      label={t("Spontaneous")}
                      name="spontaneous"
                      onChangeHandler={() => { }}
                      isChecked={formData.spontaneous === 1}
                      className="me-5"
                      disable={true}
                      id="spontaneous"
                      lineHeight="1vw"
                    />
                    <CheckBoxField
                      label={t("Sponsered")}
                      name="sponsered"
                      onChangeHandler={() => { }}
                      isChecked={formData.sponsered === 1}
                      disable={true}
                      id="sponsered"
                      lineHeight="1vw"
                    />
                  </div>
                  <div className="col-sm-12 col-xxl-3 col-md-3">
                    <LabelWithInputField
                      isMandatory={false}
                      name="companyName"
                      handleChange={() => { }}
                      value={formData.companyName}
                      isDisabled={true}
                      id="companyName"
                      label={t("Company name")}
                      type="text"
                      placeholder={t("Company name")}
                    />
                  </div>
                </div>
                <div className="row marginBotttom1">
                  <div className="col-12">
                    <LabelField
                      title={t("Contact details")}
                      className="tab-subtitle"
                    />
                  </div>
                  <div className="col-sm-12 col-md-4 mb-3">
                    <LabelField
                      title={t("Mobile") + ":"}
                      key="PhoneInput"
                      className="marginRightPoint5 fw-bold"
                    />
                    {/* <a
                                        href={
                                            formData.mobNumber !== null &&
                                                formData.mobNumber !== "" &&
                                                formData.mobNumber !== undefined
                                                ? `${PHONE_CALL_URL}${formData.mobNumber.replace(
                                                    "+",
                                                    "%2B"
                                                )}`
                                                : ""
                                        }
                                        target="_blank"
                                        className="link-color"
                                    >
                                        {formData.mobNumber}
                                    </a> */}
                  </div>
                  <div className="col-sm-12 col-xxl-4 col-md-4">
                    <LabelField
                      title={t("Email") + ":"}
                      key="Email"
                      className="marginRightPoint5 fw-bold"
                    />
                    <a
                      href={`mailto:${formData.email}`}
                      target="_blank"
                      className="link-color"
                    >
                      {formData.email}
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <LabelField title={t("Address")} className="tab-subtitle" />
                  </div>
                  <div className="col-6">
                    <LabelField
                      title={t("Vacancy information")}
                      className="tab-subtitle"
                    />
                  </div>
                  <div className="col-6">
                    <div className="d-grid applicationAddressStyle">
                      <div>
                        <LabelWithInputField
                          isMandatory={false}
                          name="street"
                          handleChange={() => { }}
                          value={formData.street}
                          id="street"
                          label={t("Street")}
                          type="text"
                          isDisabled={true}
                        />
                      </div>
                      <div>
                        <LabelWithInputField
                          isMandatory={false}
                          name="number"
                          handleChange={() => { }}
                          value={formData.number}
                          id="number"
                          label={t("Number")}
                          type="text"
                          isDisabled={true}
                        />
                      </div>
                      <div>
                        <LabelWithInputField
                          isMandatory={false}
                          name="box"
                          handleChange={() => { }}
                          value={formData.box}
                          id="box"
                          label={t("Box")}
                          type="text"
                          isDisabled={true}
                        />
                      </div>
                    </div>
                    <div className="d-grid applicationAddressStyle">
                      <div>
                        <LabelWithInputField
                          isMandatory={false}
                          name="zipCode"
                          handleChange={() => { }}
                          value={formData?.zipCode}
                          id="zipCode"
                          label={t("Zip code")}
                          type="text"
                          isDisabled={true}
                        />
                      </div>
                      <div>
                        <LabelWithInputField
                          isMandatory={false}
                          name="city"
                          handleChange={() => { }}
                          value={formData?.city}
                          id="city"
                          label={t("City")}
                          type="text"
                          isDisabled={true}
                        />
                      </div>
                      <div>
                        <SelectWithSearch
                          title={t("Country")}
                          isMandatory={false}
                          search={true}
                          options={[]}
                          onChange={() => { }}
                          isMulti={false}
                          name="country"
                          value={formData.country}
                          isDisabled={true}
                          placeHolder={t("Country")}
                        />
                      </div>
                    </div>

                    <div>
                      <div className="col-12">
                        <LabelWithTextAreaField
                          name="motivation"
                          handleChange={() => { }}
                          label={t("Motivation")}
                          isMandatory={false}
                          value={formData.motivation}
                          isDisabled={true}
                        />
                      </div>
                      <div className="col-sm-12 col-md-9">
                        <LabelWithInputField
                          isMandatory={false}
                          name="externalRef"
                          handleChange={handleFieldChange}
                          value={formData.externalRef}
                          id="externalRef"
                          label={t("External reference")}
                          type="text"
                          isDisabled={false}
                        />
                      </div>
                      {formData.cvFilePath !== "" &&
                        formData.cvFilePath !== null && (
                          <div className="col-md-3 table-action-icons align-self-center">
                            <Link
                              to={formData.cvFilePath}
                              className="btn p-0 border-0 me-2 mt-2"
                              title={t("View resume")}
                              target="_blank"
                            >
                              <ResumeIcon />
                            </Link>
                          </div>
                        )}
                      <div className="col-sm-12 jobExternalInforWrapper hideHeight">
                        <LabelWithTextAreaField
                          name="externalInfo"
                          handleChange={handleFieldChange}
                          label={t("External info")}
                          isMandatory={false}
                          value={formData.externalInfo}
                          isDisabled={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="internalContentWrapper h-100">
                      <div className="tab-subtitle mb-4">
                        {formData.vacancyName}
                      </div>
                      <div className="tab-subtitle">
                        {t("Job description")}
                      </div>
                      <div>{formData.jobDescription}</div>
                      <div className="tab-subtitle">
                        {t("Offer")}
                      </div>
                      <div>{formData.offer}</div>
                      <div className="tab-subtitle">{t("Profile")}</div>
                      <div>{formData.profile}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row backPadding">
            <div className="col-6 align-self-center">
              <BackButton />
            </div>
            <div className="col-6">
              {!loading ? (
                <Button
                  title={t("Save")}
                  type="submit"
                  className="form-button shadow-none float-end"
                />
              ) : (
                <LoadingIcon
                  iconType="bars"
                  color="#e55496"
                  className="d-inline-block float-end"
                  width={"3vw"}
                  height={"3vw"}
                />
              )}
            </div>
          </div>
        </form>
      </>
    </AccessControl >
  );
};

export default ViewJobApplicationOverView;

