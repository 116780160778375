import TitleAtom from "components/atoms/Title";
import { useNavigate, useParams } from "react-router-dom";
import { t } from "../translation/Translation";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import Button from "components/atoms/Button";
import { useEffect, useState } from "react";
import CustomNotify from "components/atoms/CustomNotify";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import BackButton from "components/atoms/BackButton";
import { validateForm, validateRequired, validateSelectField } from "services/validation/ValidationService";
import { GET_ALL_DOCUMENT_DATA, GET_DOCUMENT_MODULE_OPTIONS, SAVE_DOCUMENTS_DATA } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import { PATH_MANAGE_DOCUMENT_TYPE } from "constants/Paths";

const CreateDocumentType = () => {
  const { id: routeId } = useParams();
  const [options, setOptions] = useState({
    documentModule: [],
  });

  const [formData, setFormData] = useState({
    documentModule: '',
    documentType: '',
  });
  const [formError, setFormError] = useState({
    documentModule: '',
    documentType: '',
  });
  const [editIndex, setEditIndex] = useState(null);

  const [documentsData, setDocumentsData] = useState<any>([]);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const response = await ApiCall.service(
        GET_ALL_DOCUMENT_DATA,
        "POST",
        { id: routeId },
        false,
        M_MASTER_DATA,
      );
      if (response?.status == 200) {
        setFormData(response?.data[0]);
      }
      else {
        CustomNotify({ type: "warning", message: response?.message });
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const getOptions = async () => {
    try {
      const response = await ApiCall.getService(
        GET_DOCUMENT_MODULE_OPTIONS,
        "GET",
        M_MASTER_DATA,
        false
      );
      if (response?.status) {
        setOptions((prevOptions: any) => ({ ...prevOptions, documentModule: response?.data?.documentModule }));
      }
      else {
        CustomNotify({ type: "warning", message: response?.message });
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getOptions();
    if (routeId) {
      getData();
    }
  }, []);

  const uniqueNessCheck = (currentData: any) => {
    const { documentModule, documentType } = currentData;
    const filteredValues = documentsData.filter((eachData: any) => {
      return ((eachData?.documentModule?.label?.toLowerCase() == documentModule?.label?.toLowerCase()) && (eachData?.documentType.toLowerCase() == documentType.toLowerCase()));
    });
    return filteredValues.length == 0 ? true : false;
  }

  const handleOnAddAnother = () => {
    const currentData = formData;
    //const uniqueRes = uniqueNessCheck(currentData);
    const validationRes = validationFunction();
    if (validationRes) {
      setDocumentsData((prevData: any) => ([...prevData, currentData]));
      setFormData({ documentModule: '', documentType: '' });
    }
    else {
      CustomNotify({ type: "warning", message: "Document module and document type combination already exists" });
    }

  }

  const validationRules: any = {
    documentType: [validateRequired],
    documentModule: [validateSelectField],
  };

  const validationFunction = (
    name: any = null,
    value: any = null,
    isSingleFieldValidation = false
  ) => {
    const validationErrors: any = validateForm(
      { ...formData, [name]: value },
      isSingleFieldValidation ? validationRules[name] : validationRules
    );
    if (isSingleFieldValidation) {
      setFormError((prevErrors: any) => {
        if (validationErrors.hasOwnProperty(name)) {
          return { ...prevErrors, [name]: validationErrors[name] };
        }
        const { [name]: ty, ...remains } = prevErrors;
        return { ...remains };
      });
    } else {
      setFormError(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleSaveParticularData = () => {
    validationFunction();
    setDocumentsData((prevstate: any) => {
      const updatedData = prevstate.map(
        (eachDocumentData: any, index: number) => {
          if (index === editIndex) {
            return { ...eachDocumentData, ...formData };
          }
          return eachDocumentData;
        }
      );
      return updatedData;
    });
    setFormData((prevData) => ({
      ...prevData,
      documentModule: '',
      documentType: ''
    }));
    setEditIndex(null);
  }

  const handleOnSubmit = async () => {
    //&& uniqueNessCheck(formData)
    if (validationFunction()) {
      let finalDocumentData = null;
      if (documentsData.length === 0) {
        finalDocumentData = [formData];
      } else if (editIndex === null) {
        finalDocumentData = [...documentsData, formData];
      } else {
        finalDocumentData = [...documentsData];
      }
      try {
        const id = routeId ? { id: routeId } : {};
        const response = await ApiCall.service(
          SAVE_DOCUMENTS_DATA,
          "POST",
          { data: finalDocumentData, ...id },
          false,
          M_MASTER_DATA
        );
        if (response.status === 200) {
          CustomNotify({ type: "success", message: response.message });
          navigate(PATH_MANAGE_DOCUMENT_TYPE);
        } else {
          CustomNotify({ type: "error", message: response.message });
        }
      } catch (error) {
        console.error("Error", error);
      }
    }
  }

  const handleEdit = (index: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      ...documentsData[index],
    }));
    setEditIndex(index);
    setFormError({
      documentModule: "",
      documentType: "",
    });
  }

  const handleArchive = (deleteId: number) => {
    setDocumentsData((prevState: any) => {
      const filteredData = prevState.filter(
        (eachDocumentData: any, ind: number) => ind !== deleteId
      );
      return filteredData;
    });
  }

  const handleChange = (event: any) => {
    const { name, value } = event?.target;
    validationFunction(name, value, true);
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  }

  const handleSelectChange = (event: any, category: string) => {
    validationFunction(category, event, true);
    setFormData((prevState) => ({ ...prevState, [category]: event }));
  }

  return (
    <>
      <div className="search-bar">
        <div className="row">
          <TitleAtom
            title={routeId ? t("Edit document type") : t("Create document type")}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-border">
            <div className="row">
              <div className="col-6">
                <SelectWithSearch
                  onChange={(event) => { handleSelectChange(event, 'documentModule') }}
                  title={t("Document modules")}
                  placeHolder={t("Document modules")}
                  search={true}
                  options={options?.documentModule}
                  value={formData?.documentModule}
                  isDisabled={false}
                  isMulti={true}
                  isMandatory={true}
                  error={formError?.documentModule}
                  name={"documentModule"}
                  id={"documentModule"}
                />
              </div>
              <div className="col-6">
                <LabelWithInputField
                  type={"text"}
                  placeholder={t("Enter document type")}
                  handleChange={handleChange}
                  label={t("Document type")}
                  value={formData?.documentType}
                  readOnly={false}
                  isDisabled={false}
                  isMandatory={true}
                  labelClassName={""}
                  className={""}
                  error={formError?.documentType}
                  name={"documentType"}
                  id={"documentType"}
                />
              </div>
            </div>

            {/* On clicking add another verifying validation and dupliaction for add another and submit  */}

            {routeId == undefined && (<div className="row">
              <div className="col-md-12">
                <Button
                  title={t("+ Add another")}
                  type={"button"}
                  handleClick={
                    editIndex !== null
                      ? handleSaveParticularData
                      : handleOnAddAnother
                  }
                  className={"form-button float-end"}
                />
              </div>
            </div>)}
          </div>
        </div>
      </div>

      <div className="row" style={{ paddingTop: "1vw" }}>
        <div className="col-12">
          {(documentsData.length > 0 && routeId == undefined) && (
            <table className="table table-hover">
              <thead className="TableHeader">
                <tr>
                  <th>{t("Document module type")}</th>
                  <th>{t("Document type")}</th>
                  <th>{t("Actions")}</th>
                </tr>
              </thead>
              <thead className="TableHeader">

              </thead>
              <tbody>
                {documentsData.map((eachData: any, index: number) => {
                  return <tr key={index}>
                    <td className="align-middle">{eachData?.documentModule?.map((eachItem: any) => (eachItem?.label))?.join(', ')}</td>
                    <td className="align-middle">{eachData?.documentType}</td>
                    <td className="table-action-icons">
                      <span
                        onClick={() => handleEdit(index)}
                        className="table-action-btn marginRightPoint5 cursor-pointer"
                        title={t("Edit")}
                      >
                        <EditIcon />
                      </span>
                      <span
                        onClick={() => handleArchive(index)}
                        className="table-action-btn marginRightPoint5 cursor-pointer"
                        title={t("Delete")}
                      >
                        <DeleteIcon />
                      </span>
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          )}
          <div className="d-flex justify-content-between align-items-center backPadding">
            <div>
              <BackButton />
            </div>

            <div>
              <Button
                title={routeId ? t("Save") : t("Submit")}
                type={"submit"}
                handleClick={handleOnSubmit}
                className={"form-button"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateDocumentType;