import React, { ChangeEvent } from "react";
import { LabelWithInputFieldAndUnit } from "../atoms/LabelWithInputFieldAndUnit";
import { t } from "pages/microservices/masterData/translation/Translation";
import CheckBoxField from "components/atoms/CheckBoxField";
import RadioField from "components/atoms/RadioField";
import SelectWithSearch from "components/atoms/SelectWithSearch";

interface MovingCostsMoleculeProps {
  unit?: any;
  field?: any;
  name?: any;
  value?: any;
  colClass?: any;
  disabled: boolean;
  data?: any;
  error?: any;
  handleChange: any;
}

//(section:any,fieldId:any, event: ChangeEvent<HTMLInputElement>) => void;

const MovingCostsMolecule: React.FC<MovingCostsMoleculeProps> = ({
  unit,
  name,
  value,
  colClass,
  disabled,
  error,
  handleChange,
  field,
  data,
}) => {

  const isChecked = data?.['5']?.field_value == '0' ? 'd-block col-4' : 'd-none col-4';

  const renderField = () => {
    switch (field.field_name) {
      case "Do not invoice for km":
        if (field.field_id === 43) {
          return (
            <RadioField
              name={field.form_section_title}
              label={t(field?.field_name)}
              ischecked={field?.field_value === "1"}
              handleChange={handleChange}
              disable={disabled}
              containerClassName="col-3"
            />
          );
        } else {
          return (
            <div className="row" style={{ marginBottom: "0.5vw" }}>
              <div className="col-12">
                <CheckBoxField
                  label={t(field?.field_name)}
                  name={field?.field_name}
                  isChecked={data?.[field?.field_id]?.field_value == '0' ? false : true}
                  onChangeHandler={handleChange}
                  disable={disabled}
                  lineHeight="1.5vw"
                  id={field?.field_name}
                />
              </div>
            </div>
          );
        }
      case "Km compensation ($/km)":
      case "Package per day($)":
      case "":
        return (
          <>
            {/* {field?.field_name === "Km compensation ($/km)" && (
              <RadioField
                name={field.form_section_title}
                label={t("Km compensation (€/km)")}
                ischecked={field?.field_value == "1"}
                handleChange={handleChange}
                disable={disabled}
                containerClassName="col-3"
                className={isChecked}
              />
            )}
            {field?.field_name === "Package per day($)" && (
              <RadioField
                name={field.form_section_title}
                label={t("Package per day(€)")}
                ischecked={field?.field_value == "1"}
                handleChange={handleChange}
                disable={disabled}
                containerClassName={colClass}
                className={isChecked}
              />
            )} */}

            {field?.field_id == 45 && (
              <SelectWithSearch
                search={true}
                title={t("Compensation type")}
                value={field?.field_value}
                name={name}
                options={[
                  {
                    label: t("Km compensation (€/km)"),
                    value: t("km"),
                  },
                  {
                    label: t("Package per day(€)"),
                    value: t("package"),
                  },
                ]}
                onChange={handleChange}
                isDisabled={false}
                placeHolder={t("Select")}
                error={t(error)}
                containerClassName={`col-4 ${isChecked}`}
                className={"field-shadow"}
                labelClassName={""}

                isMandatory={true}
              />
            )}

            {field?.field_id == 8 && (
              <LabelWithInputFieldAndUnit
                isMandatory={true}
                value={value}
                label={t("Compensation value")}
                handleChange={handleChange}
                type="text"
                readOnly={false}
                isDisabled={disabled}
                unit={
                  data?.[field?.field_id - 1]?.field_value == "1"
                    ? t("€")
                    : t("€")
                }
                error={t(error)}
                containerClassName={isChecked}
              />
            )}
          </>
        );

      case "Km to be invoiced(round trip)":
        return (
          <LabelWithInputFieldAndUnit
            isMandatory={true}
            label={t(name)}
            value={value}
            handleChange={handleChange}
            type="text"
            isDisabled={disabled}
            placeholder={t(field?.field_name)}
            error={t(error)}
            unit={t("km")}
            containerClassName={isChecked}
          />
        );
      // case "Extra Km to be invoiced":
      //   return (
      //     <LabelWithInputFieldAndUnit
      //       isMandatory={false}
      //       label={t(name)}
      //       value={value}
      //       handleChange={handleChange}
      //       type="text"
      //       isDisabled={disabled}
      //       placeholder={t(field?.field_name)}
      //       error={error}
      //       unit={t("km")}
      //     />
      //   );
    }
  };

  return <>{renderField()}</>;
};

export default MovingCostsMolecule;
