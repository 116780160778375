import { t } from "pages/microservices/masterData/translation/Translation";
import { GENERAL } from "./VacancyConstants";

export const initialState = {
    tabDetails: [
        {
            id: "general",
            title: t("General"),
            showStatus: true,
            error: false,
            draft: false
        },
        {
            id: "employment",
            title: t("Employment"),
            showStatus: false,
            error: false,
            draft: false
        },
        {
            id: "competences",
            title: t("Competences"),
            showStatus: false,
            error: false,
            draft: false
        },
        {
            id: "offer",
            title: t("Offer"),
            showStatus: false,
            error: false,
            draft: false
        },
        {
            id: "requirement",
            title: t("Requirement"),
            showStatus: false,
            error: false,
            draft: false
        },
        {
            id: "jobDescription",
            title: t("Internal info"),
            showStatus: false,
            error: false,
            draft: false
        }
    ],
    general: {
        company: null,
        location: null,
        pc: [],
        sector: null,
        domain: null,
        function: [],
        empType: [],
        capacity: "",
        formStatus: null,
        office: null,
        consultant: null,
        language: null,
        aliasName: "",
        brightId: null,
        postjobto: [],
        isActive: 'open',
        contactPerson: null,
        targetGroup: null,
        project: null,
        vacancyType: null,
    },
    employment: {
        contract: [],
        employment: null,
        hrs: "",
        regime: null,
        shift: [],
        vdabTemplate: null,
        vdabCompetences: [],
        description: "",
    },
    offer: {
        payType: null,
        starter: 0,
        experienced: 0,
        starterWage: "",
        experiencedWage: "",
        jobOffer: ""
    },
    requirement: {
        experience: null,
        driverLicence: [],
        education: [],
        additional: [],
        other: "",
        equiWorkExperience: 1,
        profileDescription: "",
        tags: [],
    },
    currentTab: GENERAL,
    errors: {
        company: "",
        location: "",
        function: "",
        wage: "",
        domain: "",
        office: "",
        consultant: "",
        language: "",
        pc: "",
        aliasName: "",
        fileId: "",
        vdabTemplate: "",
        vdabCompetences: "",
        targetGroup: "",
        project: "",
        contract: "",
        employment: "",
        hrs: "",
        regime: "",
        capacity: "",
        shift: "",
    },
    jobDescription: {
        reasonForClosure: null,
        info: "",
        extraInfo: "",
        file: null,
    },
    competences: {
        competence: [],
        candidateLevels: [],
        childCompetences: {}
    },
    options: {
        Company: [],
        Project: [],
        ParitairComitee: [],
        PcFunctions: [],
        EmployeeType: [],
        TargetGroup: [],
        ContractType: [],
        PreferredEmployment: [],
        Regimen: [],
        Shift: [],
        VdabTemplate: [],
        Competence: [],
        ReasonForClosure: [],
        PostMediums: [],
        Country: [],
        PayType: [],
        Experience: [],
        DrivingLicense: [],
        EducationLevels: [],
        Additional: [],
        Tag: [],
        VdabCompetence: [],
        ContactPersons: [],
        Language: [],
        BusinessUnit: [],
        Location: [],
        Consultant: [],
        Sector: [],
        PcCategories: [],
        VacancyType: [
            {value: 1, label: "Internal"},
            {value: 2, label: "External"}
        ],
    },
};
