import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "static/css/calender.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import LabelField from "components/atoms/LabelField";

interface CalenderProps {
  onChange: (date: Date | null, name: string) => void;
  selectedDate?: Date | null;
  label?: string;
  isMandatory?: boolean;
  name?: string;
  error?: string | undefined | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  isDisabled?: boolean;
  placeHolder?: string;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  filterDate?: (date: Date) => boolean;
}

const Calender: React.FC<CalenderProps> = ({
  onChange,
  selectedDate,
  label,
  isMandatory = false,
  name = "",
  error,
  minDate = null,
  maxDate = null,
  isDisabled = false,
  placeHolder = "dd-mm-yyyy",
  filterDate,
  showMonthDropdown = true,
  showYearDropdown = true
}) => {
  const datePickerRef = useRef<ReactDatePicker | null>(null);

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  return (
    <>
      <LabelField title={label} isMandatory={isMandatory} />
      <div className="position-relative customDatePicker">
        <ReactDatePicker
          selected={selectedDate}
          onChange={(date) => onChange(date, name || "")}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          dropdownMode="select"
          dateFormat="dd-MM-yyyy"
          className="form-control field-shadow ps-2 position-relative"
          placeholderText={placeHolder}
          minDate={minDate ? new Date(minDate.getTime()) : null}
          maxDate={maxDate ? new Date(maxDate.getTime()) : null}
          calendarStartDay={1}
          name={name}
          customInput={
            <input
            // onBeforeInput={(e) => {
            //   e.preventDefault();
            // }}
            // - 86400000
            />
          }
          closeOnScroll={true}
          fixedHeight
          autoComplete="off"
          ref={datePickerRef}
          disabled={isDisabled}
          filterDate={filterDate}
          popperClassName="custom-datepicker-popper"
        />
        <div className="input-group-append calender-icon position-absolute ">
          <span
            className="input-group-text bg-transparent border-0"
            onClick={handleIconClick}
          >
            <FontAwesomeIcon icon={faCalendarAlt} className="fa-lg" />
          </span>
        </div>
      </div>
      <div className="height-20" style={{ marginBottom: "0.25vw", marginTop: "0.25vw" }}>
        {error && <span className="text-danger">{error}</span>}
      </div>
    </>
  );
};

export default Calender;