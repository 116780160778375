import React, { useEffect, useState } from "react";
import TimesheetsEmployees from "../molecules/timesheetsEmployees";
import TimesheetsProjects from "../molecules/timesheetsProjects";
import { useTImesheetContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { useTimesheetDispatchContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import Button from "components/atoms/Button";
import { ApiCall } from "services/ApiServices";
import { CREATE_TIMESHEET } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { useTimesheetModalContext, useTimesheetModalDispatch } from "../timesheets-redux/context/TimesheetModalContext/TimesheetModalContext";
import { TIMESHEET_MODAL_ACTIONS } from "../timesheets-redux/actions/TimesheetModalActions";
import LoadingOverlay from "utils/LoadingOverlay";
import { useFilterOptions, useFilterOptionsDispatch } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import { TIMESHEET_FILTER_ACTIONS } from "../timesheets-redux/actions/TimesheetFilterActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "pages/microservices/masterData/translation/Translation";
import BackButton from "components/atoms/BackButton";
import DatePicker from "react-multi-date-picker";
import WeekCalendar from "../molecules/weekCalendar";
import PlanTimings from "../atoms/planningTimings";
import Work from "../molecules/work";
import Regime from "../atoms/regime";
import CommonCheckBox from "../atoms/commonCheckbox";
import ProjectParameters from "../molecules/projectParameters";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ProjectParameterHeader from "../atoms/projectParameterHeader";
import Popup from "components/molecules/Popup";
import ReimbursementModal from "../molecules/reimbursementModal";
import PerformanceModal from "../molecules/performanceModal";
import { dateFormat, fetchWeeklyTimesheetData, getTimesheetOptions, getWeekDays } from "../utils/utils";
import { TIMESHEET_ACTIONS } from "../timesheets-redux/actions/TimesheetActions";
import { useSearchParams } from "react-router-dom";
import { InputTextFieldWithDebounce } from "pages/microservices/planning/molecules/InputTextFieldWithDebounce";
import LabelBox from "../atoms/LabelBox";
import "../css/timesheet.css";

export default function TimesheetsOverview() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { project_id, employee_id, currentDate } = Object?.fromEntries(searchParams?.entries());
  const { timesheetDispatch } = useTimesheetDispatchContext();
  const { initialTimesheetState } = useTImesheetContext();
  const { filterOptionsState } = useFilterOptions();
  const { initialTimesheetModalState } = useTimesheetModalContext();
  const { timesheetModalDispatch } = useTimesheetModalDispatch();
  const { dispatchFilterOptions } = useFilterOptionsDispatch();
  const [loading, setLoading] = useState(false);
  const [isFirstColumnOpen, setIsFirstColumnOpen] = useState(true);
  const [isLastColumnOpen, setIsLastColumnOpen] = useState(true);

  let timesheet_data = initialTimesheetState?.timesheet_data ? initialTimesheetState.timesheet_data : [];

  useEffect(() => {
    getOptions();
  }, [filterOptionsState?.selectedState?.project_id])

  useEffect(() => {
    if (filterOptionsState?.selectedState?.employee_id !== null && filterOptionsState?.selectedState?.project_id !== null) {
      fetchTimesheet();
    }
  }, [filterOptionsState?.selectedState?.refresh])

  const getOptions = async () => {
    const payload = {
      project_id: filterOptionsState?.selectedState?.project_id,
      options: ['ReimbursementType', 'PerformanceType', 'Regimen'],
      initialCall: filterOptionsState?.selectedState?.initialCall,
    }
    try {
      const options = await getTimesheetOptions(payload);
      let optionData = {};
      let filteredOptions = {}
      if (filterOptionsState?.selectedState?.initialCall) {
        optionData = {
          projects: options?.Project ?? [],
          employees: options?.Employee ?? [],
          performanceTypes: options?.PerformanceType ?? [],
          reimbursementTypes: options?.ReimbursementType ?? [],
          regimen: options?.Regimen ?? [],
          prjectParameters: options?.Parameters ?? [],
        }
        filteredOptions = {
          projects: options?.Project ?? [],
          employees: options?.Employee ?? [],
        }
        dispatchFilterOptions({
          type: TIMESHEET_FILTER_ACTIONS.SET_SELECTED_STATE,
          payload: {
            selectAll: true, initialCall: false, project_id: project_id ?? null
          }
        });
      } else if (filterOptionsState?.selectedState?.project_id) {
        optionData = {
          prjectParameters: options?.Parameters ?? [],
          employees: options?.Employee ?? []
        }
        filteredOptions = {
          employees: options?.Employee ?? [],
        }
        dispatchFilterOptions({
          type: TIMESHEET_FILTER_ACTIONS.SET_SELECTED_STATE,
          payload: {
            currentDate: currentDate ? new Date(currentDate) : filterOptionsState?.selectedState?.currentDate,
            employee_id: employee_id ? Number(employee_id) : options?.Employee?.[0]?.value ?? null,
            selectAll: true, refresh: !filterOptionsState?.selectedState?.refresh,
          }
        });
        setSearchParams({});
      }
      dispatchFilterOptions({ type: TIMESHEET_FILTER_ACTIONS.SET_OPTIONS, payload: optionData });
      dispatchFilterOptions({ type: TIMESHEET_FILTER_ACTIONS.SET_FILTERED_OPTIONS, payload: filteredOptions });
    } catch (error) {
      console.log(error);
    }
  }

  const fetchTimesheet = async () => {
    const date = filterOptionsState?.selectedState?.currentDate ?? new Date();
    let weekDays = getWeekDays(date);
    const data = {
      project_id: filterOptionsState?.selectedState?.project_id,
      employee_id: filterOptionsState?.selectedState?.employee_id,
      from_date: dateFormat(weekDays[0]),
      to_date: dateFormat(weekDays[6]),
    };
    let response = await fetchWeeklyTimesheetData(data, date, filterOptionsState?.optionState?.prjectParameters);
    timesheetDispatch({ type: TIMESHEET_ACTIONS.CHANGE_WEEK, payload: { data: response || [] } });
  }

  const handleWeekChange = async (value, week = false) => {
    let date = value;
    if (week) {
      date = new Date(filterOptionsState?.selectedState?.currentDate);
      date.setDate(date.getDate() + value);
    }
    dispatchFilterOptions({
      type: TIMESHEET_FILTER_ACTIONS.SET_SELECTED_STATE,
      payload: {
        selectAll: true, currentDate: date, refresh: !filterOptionsState?.selectedState?.refresh
      }
    });
  };

  const renderWeekNumber = () => {
    let currentDate = filterOptionsState?.selectedState?.currentDate ?? new Date();
    const weekNumber = Math.ceil(
      (currentDate - new Date(currentDate.getFullYear(), 0, 1))
      /
      (7 * 24 * 60 * 60 * 1000)
    );
    return <div className="text-center">{t("Week") + " " + weekNumber}</div>;
  };

  const saveTImesheet = async (invoice = false) => {
    let timesheetData = {
      ...initialTimesheetState,
      timesheet_data: timesheet_data?.filter(
        (plan) => plan?.plan_id
      ),
      isInvoiced: invoice != "yes",
    };
    if (isPlanned) {
      let data = {
        data: {
          ...timesheetData,
          project_id: filterOptionsState?.selectedState?.project_id,
          employee_id: filterOptionsState?.selectedState?.employee_id,
        },
      };
      setLoading(true);
      await ApiCall.service(CREATE_TIMESHEET, "POST", data, true, M_MASTER_DATA)
        .then(async (response) => {
          if (response.success) {
            CustomNotify({ type: "success", message: response.message });
            invoice && handleModalDispatch("invoicenow");
            dispatchFilterOptions({
              type: TIMESHEET_FILTER_ACTIONS.SET_SELECTED_STATE,
              payload: { name: 'refresh', value: !filterOptionsState?.selectedState?.refresh }
            });
          } else {
            CustomNotify({ type: "error", message: response.message });
          }
        })
        .catch((error) => {
          CustomNotify({ type: "error", message: error });
        });
      setLoading(false);
    }
  };

  const handleModalDispatch = (modalType) => {
    if (isPlanned) {
      timesheetModalDispatch({
        type: TIMESHEET_MODAL_ACTIONS.OPEN_AND_CLOSE_MODAL,
        payload: {
          status: !initialTimesheetModalState[modalType].isOpen,
          modalType: modalType,
          data: [],
          date: "",
          planId: "",
        },
      });
    }
  };

  const isPlanned = timesheet_data?.some((plan) => plan?.plan_id);

  const handleChange = (value, name) => {
    let payload = {};
    let filteredData = [];
    switch (name) {
      case 'employee':
        filteredData = filterOptionsState?.optionState?.employees?.filter(employee =>
          employee?.label?.trim()?.toLowerCase()?.includes(value?.trim()?.toLowerCase())
        );
        payload = { employees: filteredData ?? [] };
        break;

      case 'project':
        filteredData = filterOptionsState?.optionState?.projects?.filter(project =>
          project?.label?.toLowerCase()?.trim()?.includes(value?.trim()?.toLowerCase())
        );
        payload = { projects: filteredData ?? [] };
        break;

      default:
        break;
    }
    dispatchFilterOptions({ type: TIMESHEET_FILTER_ACTIONS.SET_FILTERED_OPTIONS, payload: payload });
  }

  return (
    <div className="container-fluid ps-0 d-flex flex-column">
      <LoadingOverlay display={loading ? "block" : "none"} />
      <div className="row mx-0 marginBottomPoint5" style={{ marginTop: "0.2vw" }}>
        <div className="col-12 ps-1 pe-0 d-flex align-items-center">
          <div style={{ width: "16%" }} className="hideHeight">
            <div className="d-grid" style={{ gridTemplateColumns: "repeat(2, 1fr)", gap: "0 1vw" }}>
              <div>
                <InputTextFieldWithDebounce
                  name="project"
                  handleChange={(e) => handleChange(e, 'project')}
                  // value={filterOptionsState?.selectedState?.project ?? ""}
                  id="project"
                  type={"text"}
                  placeholder={t("Project")}
                />
              </div>
              <div>
                <InputTextFieldWithDebounce
                  name="employee"
                  handleChange={(e) => handleChange(e, 'employee')}
                  // value={filterOptionsState?.selectedState?.employee ?? ""}
                  id="employee"
                  type={"text"}
                  placeholder={t("Employee")}
                />
              </div>
            </div>
          </div>
          <div style={{ width: "60%" }} className="mx-auto fw-bold">
            {filterOptionsState?.selectedState?.employee_id && t("Performance of ") + filterOptionsState?.optionState?.employees?.find((item) => item?.value == filterOptionsState?.selectedState?.employee_id)?.label} 
          </div>
          <div style={{ width: "23%" }}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="planning_cal_btn"
                  onClick={() => handleWeekChange(-7, true)}
                  style={{ cursor: "pointer", marginRight: "0.8vw" }}
                  title={t("Previous")}
                >
                  <FontAwesomeIcon icon="fa-solid fa-circle-arrow-left" />
                </div>
                <span className="tab-subtitle pb-0">{renderWeekNumber()}</span>
                <div
                  className="planning_cal_btn"
                  onClick={() => handleWeekChange(7, true)}
                  style={{ cursor: "pointer", marginLeft: "0.8vw" }}
                  title={t("Next")}
                >
                  <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                </div>
              </div>
              <div className="customDatePicker">
                <DatePicker
                  inputClass="form-control field-shadow"
                  name="date"
                  placeholder={"mm-yyyy"}
                  value={filterOptionsState?.selectedState?.currentDate ?? new Date()}
                  onChange={(date) => {
                    const parsedDate = new Date(date);
                    if (!isNaN(parsedDate)) {
                      handleWeekChange(parsedDate);
                    }
                  }}
                  format="MM-YYYY"
                  onlyMonthPicker
                  minDate={new Date(2014, 0, 1)}
                  zIndex={1000}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="timesheetContentWrapper">
        <div className="row mx-0 flex-1 overflow-auto">
          {/* First Column with Toggle */}
          {isFirstColumnOpen && (
            <div className="col-2 overflow-auto px-0 timeSheetLeftSection" >
              <div className="d-grid h-100 timeSheetGrid">
                <div className="text-center p-0 overflow-auto timeSheetProjectAndEmployee" style={{ height: "calc(100vh - 11vw)" }}>
                  <TimesheetsProjects />
                </div>
                <div className="text-center p-0 overflow-auto timeSheetProjectAndEmployee" style={{ height: "calc(100vh - 11vw)" }}>
                  <TimesheetsEmployees />
                </div>
              </div>
            </div>
          )}
          <div
            className={`col-${isFirstColumnOpen && isLastColumnOpen
              ? 12 - 4 : isFirstColumnOpen ? 12 - 2
                : isLastColumnOpen ? 12 - 2 : 12
              } text-center p-0 overflow-auto`}
            style={{ height: "calc(100vh - 11vw)" }}
          >
            <table className="w-100 h-100 marginBotttom1 overflow-auto timesheetTable">
              <thead className="position-sticky top-0">
                <WeekCalendar />
              </thead>
              <tbody>
                <PlanTimings />
                <Work />
                <Regime
                  planTimingsData={timesheet_data}
                  header1="Regime"
                  header2=""
                  name="regime"
                />
                <CommonCheckBox
                  planTimingsData={timesheet_data}
                  header1="Holiday"
                  header2=""
                  name={"holiday"}
                />
                <CommonCheckBox
                  planTimingsData={timesheet_data}
                  header1="Invoice milage"
                  header2=""
                  total={""}
                  name={"invoice_milage"}
                />
                <LabelBox
                  planTimingsData={timesheet_data}
                  header1="Worksheet no."
                  header2=""
                  isDisable={true}
                  name="worksheet_number"
                />
                <LabelBox
                  planTimingsData={timesheet_data}
                  header1="Invoice no."
                  header2=""
                  isDisable={true}
                  name={"invoice_no"}
                />
                <CommonCheckBox
                  planTimingsData={timesheet_data}
                  header1="Purchase invoice"
                  header2=""
                  name={"purchase_invoice"}
                />
                <ProjectParameters planTimingsData={timesheet_data} />
              </tbody>
            </table>
            <div className="d-flex justify-content-between position-sticky bottom-0" style={{ padding: "0.25vw" }}>
              <button
                className="cursor-pointer sidebar-icon d-flex align-items-center justify-content-center"
                onClick={() => setIsFirstColumnOpen(!isFirstColumnOpen)}
              >
                {isFirstColumnOpen ? (
                  <FontAwesomeIcon icon={faAngleLeft} />
                ) : (
                  <FontAwesomeIcon icon={faAngleRight} />
                )}
              </button>
              <button
                className="cursor-pointer sidebar-icon d-flex align-items-center justify-content-center"
                onClick={() => setIsLastColumnOpen(!isLastColumnOpen)}
              >
                {!isLastColumnOpen ? (
                  <FontAwesomeIcon icon={faAngleLeft} />
                ) : (
                  <FontAwesomeIcon icon={faAngleRight} />
                )}
              </button>
            </div>
          </div>
          {/* Last Column with Toggle */}
          {isLastColumnOpen && (
            <div className="col-2 overflow-auto p-0 timeSheetRightSection" style={{ height: "calc(100vh - 11vw)" }}>
              <div className="text-center p-0 h-100 overflow-auto">
                <ProjectParameterHeader
                  initialTimesheetState={initialTimesheetState}
                  projectParameters={filterOptionsState?.optionState?.prjectParameters || {}}
                />
              </div>
            </div>
          )}
          <div className="d-flex justify-content-end">
            {/* {initialTimesheetModalState["invoice"].isOpen && (
              <Popup
                title={"Invoice"}
                body={
                  <InvoiceModal
                    onClose={() => {
                      handleModalDispatch("invoice");
                    }}
                  />
                }
                cancel={() => {
                  handleModalDispatch("invoice");
                }}
                modalSize="lg"
                bodyclassName="text-start"
              />
            )} */}
            {initialTimesheetModalState["performance"].isOpen && (
              <Popup
                title={t("Perfomance")}
                body={
                  <PerformanceModal
                    onClose={() => {
                      handleModalDispatch("performance");
                    }}
                  />
                }
                cancel={() => {
                  handleModalDispatch("performance");
                }}
                modalSize="lg"
                bodyclassName="text-start"
              />
            )}
            {initialTimesheetModalState["reimbursement"].isOpen && (
              <Popup
                title={t("Reimbursements")}
                body={
                  <ReimbursementModal
                    onClose={() => {
                      handleModalDispatch("reimbursement");
                    }}
                  />
                }
                cancel={() => {
                  handleModalDispatch("reimbursement");
                }}
                modalSize="lg"
                bodyclassName="text-start"
              />
            )}
            {initialTimesheetModalState["invoicenow"]?.isOpen && (
              <Popup
                title={t("Confirmation")}
                body={t("Do you want to invoice this now") + "?"}
                close={() => {
                  handleModalDispatch("invoicenow");
                }}
                modalSize="lg"
                bodyclassName="text-start"
                yestext={t("Yes")}
                notext={t("No")}
                submit={() => saveTImesheet("yes")}
                cancel={() => saveTImesheet("no")}
              />
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end backPadding">
        {isPlanned && <Button
          title={t("Validate")}
          type="submit"
          handleClick={() =>
            initialTimesheetState?.isInvoiced
              ? saveTImesheet()
              : handleModalDispatch("invoicenow")
          }
          className="form-button float-end"
        />}
      </div>
    </div>
  );
}
