import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import CheckBoxField from "components/atoms/CheckBoxField";
import CustomNotify from "components/atoms/CustomNotify";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import TitleFieldMolecule from "components/molecules/TitleField";
import { M_MASTER_DATA } from "constants/Constants";
import { MANAGE_HOLIDAY_PER_PC } from "constants/Paths";
import { t } from "pages/microservices/masterData/translation/Translation";
import { ApiCall } from "services/ApiServices";
import {
  EDIT_HOLIDAY_PER_PC,
  GET_PUBLIC_HOLIDAYS_AND_PC,
  SAVE_HOLIDAY_PER_PC,
} from "routes/ApiEndpoints";
import {
  validateForm,
  validateRequired,
} from "services/validation/ValidationService";
import DatePicker from "react-multi-date-picker";
import TitleAtom from "components/atoms/Title";
import BackButton from "components/atoms/BackButton";

interface Holiday {
  id: number;
  name: string;
  date: string;
  holiday_code_id: any;
  isChecked: boolean;
}

interface ParitairComiteeOption {
  name: string;
  value: number;
  label: string;
}

const LinkHolidayToPc = () => {
  const [holidayData, setHolidayData] = useState<Holiday[]>([]);
  const [paritairComitee, setParitairComitee] = useState<
    ParitairComiteeOption[]
  >([]);
  const [data, setData] = useState({
    pc_name: "",
    year: "2024",
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});
  useEffect(() => {
    fetchPCHolidayData();
  }, [id]);

  const fetchPCHolidayData = async () => {
    try {
      const response = await ApiCall.getService(
        GET_PUBLIC_HOLIDAYS_AND_PC,
        "GET",
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setHolidayData(response?.data?.holidayData);
        if (id) {
          fetchParticularPCHolidays(id, response?.data?.holidayData);
        } else {
          setHolidayData(response?.data?.holidayData);
        }
        setParitairComitee(response?.data?.pcOptions);
      }
    } catch (error) {
      console.error("Error occurred while fetching PC holiday data", error);
    }
  };

  const fetchParticularPCHolidays = async (id: string, holidays?: any) => {
    if (id) {
      try {
        const response = await ApiCall.service(
          EDIT_HOLIDAY_PER_PC,
          "POST",
          { id: id },
          false,
          M_MASTER_DATA
        );
        if (response?.status === 200 && response.data.length > 0) {
          const firstItem = response?.data[0];
          const holiday_ids = firstItem?.holiday_id;
          setData({
            pc_name: firstItem.pc_name,
            year: firstItem.year,
          });
          const updated: any[] = [];
          holidays.forEach((holiday: any) => {
            if (holiday_ids.includes(holiday.id)) {
              updated.push({
                ...holiday,
                isChecked: true,
              });
            } else {
              updated.push({
                ...holiday,
                isChecked: false,
              });
            }
          });
          setHolidayData(updated);
        }
      } catch (error) {
        console.error(
          "Error occurred while fetching particular PC holidays",
          error
        );
      }
    }
  };
  const handleSelectChange = (event: any, category: string) => {
    setData((prevState) => ({ ...prevState, [category]: event?.value }));
    validationFunction(category, event, true);
  };
  const handleDateChange = (e: any, name: any) => {
    const value = e.year;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };
  const validationFunction = (
    name: null | string = null,
    value: any = null,
    isSingle = false
  ) => {
    const currentHolidayList =
      isSingle && name == "action" ? value : holidayData;
    const checkedStatusValue = currentHolidayList.find((eachItem: any) => {
      if (eachItem.isChecked === true) {
        return true;
      }
      return false;
    });

    let validationError = validateForm(
      { pc_name: isSingle ? value : data.pc_name },
      { pc_name: [validateRequired] }
    );
    validationError = id ? {} : validationError;
    console.log(validationError);
    setErrors((prevState: any) => {
      if (
        Object.keys(validationError).length == 0 &&
        checkedStatusValue === undefined
      ) {
        const { pc_name, ...remainAction } = prevState;
        return { action: "Please select atleast one action" };
      } else if (
        Object.keys(validationError).length !== 0 &&
        checkedStatusValue !== undefined
      ) {
        return { pc_name: validationError["pc_name"] };
      } else if (
        Object.keys(validationError).length !== 0 &&
        checkedStatusValue === undefined
      ) {
        return {
          pc_name: validationError["pc_name"],
          action: "Please select atleast one action",
        };
      } else {
        return {};
      }
    });
    if (
      Object.keys(validationError).length == 0 &&
      checkedStatusValue !== undefined
    ) {
      return true;
    }
    return false;
  };
  console.log(data);
  const handleOnSubmit = async () => {
    const selectedHolidays = holidayData
      .filter((holiday) => holiday.isChecked)
      .map((holiday) => holiday.id);
    const postData = {
      id: id || "",
      pc_name: data.pc_name,
      year: data.year,
      holiday_ids: selectedHolidays,
    };
    if (validationFunction()) {
      try {
        const response = await ApiCall.service(
          SAVE_HOLIDAY_PER_PC,
          "POST",
          postData,
          false,
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          CustomNotify({ type: "success", message: response?.message });
          navigate(MANAGE_HOLIDAY_PER_PC);
        } else {
          CustomNotify({ type: "error", message: response?.message });
        }
      } catch (error) {
        console.error("Error occurred while submitting data", error);
      }
    }
  };

  const onCheckEventHandler = (id: number) => {
    const updatedHolidayData = holidayData.map((eachHoliday) => {
      if (eachHoliday.id === id) {
        return { ...eachHoliday, isChecked: !eachHoliday.isChecked };
      }
      return eachHoliday;
    });
    setHolidayData(updatedHolidayData);
    validationFunction("action", updatedHolidayData, true);
  };

  return (
    <>
      <div className="search-bar">
        <div className="row">
          <TitleAtom title={t("Link holiday per PC")} />
        </div>
        <div className="row">
          <div className="col-6 hideLabel">
            <SelectWithSearch
              containerClassName="mb-0 position-relative"
              onChange={(event) => {
                handleSelectChange(event, "pc_name");
              }}
              title={t("Paritair comitee")}
              placeHolder={t("Select paritair commitee")}
              search={true}
              options={paritairComitee}
              value={data.pc_name}
              isDisabled={!!id}
              isMandatory={true}
              name={"pc_name"}
              id={"pc_name"}
              error={errors?.pc_name}
            />
          </div>
          {/* <div className="col ms-5">
          <div className="form-label field-label">Year</div>
          <DatePicker
          //  value={data.year}
            inputClass="form-control field-shadow"
            name="date"
            placeholder={new Date().getFullYear().toString()}
            onChange={(e) => handleDateChange(e, "year")}
            format="YYYY"
            onlyYearPicker={true}
            minDate={new Date(2014, 0, 1)}
            zIndex={1000}
            // filterDate={(date: Date) => date.getFullYear() >= minimumYear} // Filter out dates before the minimum year
          />
        </div> */}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <div className="tableSection">
              <div className="table-responsive link-holiday-per-pc">
                <table className="table table-hover">
                  <thead>
                    <tr className="border-0 TableHeader">
                      <th className="border-0"> </th>
                      <th className="border-0">{t("Holiday title")}</th>
                      <th className="border-0">{t("Date")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {holidayData.map((eachHoliday) => (
                      <tr key={eachHoliday.id}>
                        <td>
                          <CheckBoxField
                            name={""}
                            id={eachHoliday.id.toString()}
                            isChecked={eachHoliday.isChecked}
                            onChangeHandler={() =>
                              onCheckEventHandler(eachHoliday.id)
                            }
                          />
                        </td>
                        <td>{eachHoliday.name}</td>
                        <td>{eachHoliday.date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {errors?.action && (
                  <div className="text-danger">{errors.action}</div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center backPadding">
            <div>
              <BackButton />
            </div>
            <div>
              <Button
                title={t("Submit")}
                type={"submit"}
                handleClick={handleOnSubmit}
                className={"form-button"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkHolidayToPc;
