import { useState } from "react";
import Navigation from "../form-navigation/Navigation";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import { TextAreaMolecule } from "components/molecules/TextAreaMolecule";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { JOB_PROFILE, SET_DROPDOWN_DATA, UPDATE_ERROR_FIELD, UPDATE_FIELD } from "../../annotations/CandidateConstants";
import { useFormContext } from "../../context/Context";
import { validateForm, validateMultiSelectField, validateSelectField } from "services/validation/ValidationService";
import { ValidationRules } from "utils/TypeAnnotations";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import { SetCompetenceData } from "../form-data/SetCompetenceData";
import { extractIds } from "../../helpers/CandidateHelperFunctions";
import { FETCH_CANDIDATE_SALARY_TYPE } from "routes/ApiEndpoints";
import { SetJobProfileData } from "../form-data/SetJobProfileData";

const JobProfileTab: React.FC = () => {
  const { state, dispatch } = useFormContext();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };
  const validation = (
    name: string,
    value: any,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      desiredFunctions: [validateMultiSelectField],
    };
    const validationErrors = validateForm(
      { ...state.jobProfile, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    dispatch({
      type: UPDATE_ERROR_FIELD,
      field: name,
      error: validationErrors[name]
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;
    if (isFieldValid) {
      dispatch({
        type: UPDATE_ERROR_FIELD,
        field: name,
        error: ''
      });
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSelectChange = async (selectedOption: any, fieldName: string) => {
    dispatch({
      type: UPDATE_FIELD,
      tab: JOB_PROFILE,
      field: fieldName,
      value: selectedOption,
    });

    if (fieldName === "desiredPartiarComitte") {
      SetJobProfileData(dispatch, selectedOption, fieldName);
      dispatch({
        type: UPDATE_FIELD,
        tab: JOB_PROFILE,
        field: "desiredCategory",
        value: null,
      });
      dispatch({
        type: UPDATE_FIELD,
        tab: JOB_PROFILE,
        field: "desiredFunctions",
        value: null,
      });
    } else if (fieldName === "desiredCategory") {
      SetJobProfileData(dispatch, selectedOption, fieldName);

      dispatch({
        type: UPDATE_FIELD,
        tab: JOB_PROFILE,
        field: "desiredFunctions",
        value: null,
      });
    } else if (fieldName === "desiredFunctions") {
      const response = await ApiCall.service(
        FETCH_CANDIDATE_SALARY_TYPE,
        "POST",
        selectedOption,
        true,
        M_MASTER_DATA
      );

      dispatch({
        type: UPDATE_FIELD,
        tab: JOB_PROFILE,
        field: "salaryExpectation",
        value: response?.data ?? null,
      });
    }
    //Competence options
    let { desiredPartiarComitte, desiredCategory, desiredFunctions } = state.jobProfile; // Assuming you are storing the selected values in the state
    if (fieldName === "desiredPartiarComitte" || fieldName === "desiredCategory" || fieldName === "desiredFunctions") {
      const selectedIds = selectedOption.map((option: any) => option?.value);
      const pcIds = extractIds(desiredPartiarComitte);
      const categoryIds = extractIds(desiredCategory);
      const functionIds = extractIds(desiredFunctions);
      const postData = {
        pc_id: fieldName === "desiredPartiarComitte" ? selectedIds : pcIds,
        category_id: fieldName === "desiredCategory" ? selectedIds : categoryIds,
        function_id: fieldName === "desiredFunctions" ? selectedIds : functionIds,
        onChangeOptions: true,
        options: ["Competence"],
      }
      if (
        postData.pc_id.length > 0 &&
        postData.category_id.length > 0 &&
        postData.function_id.length > 0
      ) {
        SetCompetenceData(dispatch, state, postData);
      }
    }
    validation(fieldName, selectedOption, false);
  }

  const handleFieldChange = (e: any) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === "maximunDistanceFromHome") {
      updatedValue = Number(updatedValue.replace(/[^0-9,]/g, ''));
    } else if (name === "minimunGrossSalary") {
      updatedValue = updatedValue.replace(/[^0-9,]/g, '');
    }
    dispatch({
      type: UPDATE_FIELD,
      tab: JOB_PROFILE,
      field: name,
      value: updatedValue,
    });
  }

  return (<>

    <div className="row">
      <div className="col-12">
        <div className="form-border" style={{ paddingTop: "3vw" }}>
          <div className="row">
            <div className="col-4">
              <SelectWithSearch
                title={t("Desired Sector")}
                isMandatory={false}
                search={true}
                options={state.dropDownData.paritairComiteeList}
                onChange={(e) => handleSelectChange(e, "desiredPartiarComitte")}
                isMulti={true}
                name="desiredPartiarComitte"
                value={state.jobProfile.desiredPartiarComitte}
                error={""}
                placeHolder="Select"
              />
            </div>
            <div className="col-4">
              <SelectWithSearch
                title={t("Desired domain")}
                isMandatory={false}
                search={true}
                options={(state.dropDownData.pcCategoriesList?.length > 0) ? state.dropDownData.pcCategoriesList : state.dropDownData.pcCategoryInitialList}
                onChange={(e) => handleSelectChange(e, "desiredCategory")}
                isMulti={true}
                name="desiredCategory"
                value={state.jobProfile.desiredCategory}
                error={""}
                placeHolder="Select"
              />
            </div>
            <div className="col-4">
              <SelectWithSearch
                title={t("Desired functions")}
                isMandatory={true}
                search={true}
                options={(state.dropDownData.pcFunctionsList?.length > 0) ? state.dropDownData.pcFunctionsList : state.dropDownData.pcFunctionInitialList}
                onChange={(e) => handleSelectChange(e, "desiredFunctions")}
                isMulti={true}
                name="desiredFunctions"
                value={state.jobProfile.desiredFunctions}
                error={state.errors.desiredFunctions}
                placeHolder="Select"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <SelectWithSearch
                title={t("Undesired sector")}
                isMandatory={false}
                search={true}
                options={state.dropDownData.paritairComiteeList}
                onChange={(e) => handleSelectChange(e, "undesiredSector")}
                isMulti={true}
                name="undesiredSector"
                value={state.jobProfile.undesiredSector}
                error={""}
                placeHolder="Select"
              />
            </div>
            <div className="col-4">
              <SelectWithSearch
                title={t("Undesired function")}
                isMandatory={false}
                search={true}
                options={((state.dropDownData.pcFunctionsList?.length > 0) ? state.dropDownData.pcFunctionsList : state.dropDownData.pcFunctionInitialList)}
                onChange={(e) => handleSelectChange(e, "undesiredFunction")}
                isMulti={true}
                name="undesiredFunction"
                value={state.jobProfile.undesiredFunction}
                error={""}
                placeHolder="Select"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <LabelWithInputField
                isMandatory={false}
                name="maximunDistanceFromHome"
                handleChange={(e) => handleFieldChange(e)}
                id="maximunDistanceFromHome"
                label="Maximum distance from home"
                type="text"
                value={state.jobProfile.maximunDistanceFromHome}
                error=""
              />
            </div>
            <div className="col-4">
              <SelectWithSearch
                title={t("Desired regimen")}
                isMandatory={false}
                search={true}
                options={state.dropDownData.regimenList}
                onChange={(e) => handleSelectChange(e, "desiredRegimen")}
                isMulti={true}
                name="desiredRegimen"
                value={state.jobProfile.desiredRegimen}
                error={""}
                placeHolder="Select"
              />
            </div>
            <div className="col-4">
              <SelectWithSearch
                title={t("Preferred employment")}
                isMandatory={false}
                search={true}
                options={state.dropDownData.preferredEmploymentList}
                onChange={(e) => handleSelectChange(e, "preferredEmployment")}
                isMulti={false}
                name="preferredEmployment"
                value={state.jobProfile.preferredEmployment}
                error={""}
                placeHolder="Select"
              />
            </div>
            <div className="col-4">
              <SelectWithSearch
                title={t("Salary expectation")}
                isMandatory={false}
                search={true}
                options={state.dropDownData.salaryExpectationsList}
                onChange={(e) => handleSelectChange(e, "salaryExpectation")}
                isMulti={false}
                name="salaryExpectation"
                value={state.jobProfile.salaryExpectation}
                error={""}
                placeHolder="Select"
              />
            </div>
            <div className="col-4">
              <LabelWithInputField
                isMandatory={false}
                name="minimunGrossSalary"
                handleChange={(e) => handleFieldChange(e)}
                id="minimunGrossSalary"
                label="Minimum gross salary"
                type="text"
                value={state.jobProfile.minimunGrossSalary}
                error=""
              />
            </div>
            <div className="col-12">
              <TextAreaMolecule
                label={t('Desired jobs info')}
                name={t('desiredJobsInfo')}
                id={t('desiredJobsInfo')}
                placeholder={"Desired jobs info"}
                handleChange={(e) => handleFieldChange(e)}
                isMandatory={false}
                value={state.jobProfile.desiredJobsInfo}
                error={''}
                textAreaWrapper="hideHeight"
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
              Any form of discrimination based on sex, perceived race, colour, descent, nationality or ethnic origin, sexual orientation, marital status, birth, property, age, religious or philosophical beliefs, current or future state of health, disability or physical characteristic is prohibited.
            </div>
          </div>
        </div>
      </div>
    </div >
    <div style={{ padding: "1vw 0" }}>
      <Navigation validStatus={validStatus} />
    </div>
  </>);
}
export default JobProfileTab;