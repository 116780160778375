import React, { useEffect, useState } from "react";
import TitleFieldMolecule from "components/molecules/TitleField";
import { CompetenceHeader } from "TableHeader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { t } from "pages/microservices/masterData/translation/Translation";
import { COMPETENCE_ARCHIVE, COMPETNCE_GET } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { M_MASTER_DATA } from "constants/Constants";
import {
  PATH_COMPETENCE_CREATE,
  PATH_COMPETENCE_EDIT,
  PATH_COMPETENCE_VIEW,
  PATH_MASTER_DATA,
} from "constants/Paths";
import { Competence } from "./Manage";
import Button from "components/atoms/Button";
import AccessControl from "services/AccessControl";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LinkTo from "components/atoms/LinkTo";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BackButton from "components/atoms/BackButton";
import MultiSelectAtom from "components/atoms/MultiSelectAtom";
import FilterOrganism from "components/organism/FilterOrganism";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";

interface ManageCompetencePageProps {
  competenceObject: Competence[];
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  searchValue: (name: string, value: string | number) => void;
  deleteFilter: (deleteId: number) => void;
  searchData: any;
  submit?: any;
}

const ManageCompetencePage: React.FC<ManageCompetencePageProps> = ({
  competenceObject,
  currentPage,
  totalPages,
  onPageChange,
  searchValue,
  deleteFilter,
  searchData,
  submit,
}) => {

  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}

  const [Competence, setCompetence] = useState<Competence[]>([]);

  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('type');

  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);

  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    initialCall: true,
    refresh: true,
  });

  const [options, setOptions] = useState({
    paritairComitee: [],
    pcFunctions: [],
    initialCall: true,
  });

  const getSearchData = () => {
    return {
      competenceName: history?.filters?.competenceName ?? "",
      pcName: history?.filters?.pcName ?? "",
      pcFunctions: history?.filters?.pcFunctions ?? "",
      type: history?.filters?.type ?? paramValue,
    }
  };


  useEffect(() => {
    const data = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getSearchData(),
      initialCall: options?.initialCall,
    };
    fetchData(data);
  }, [pageData?.refresh, pageData?.initialCall,paramValue]);

  const fetchData = async (data: any) => {
    const response = await ApiCall.service(
      COMPETNCE_GET,
      "POST",
      data,
      false,
      M_MASTER_DATA
    );

    if (response?.status === 200) {
      setCompetence(response?.data);
      setPageData((prev: any) => ({ ...prev, totalPages: response?.totalPages, totalRecords: response?.totalRecords }));
      if (options?.initialCall) {
        const { pcFunctions, pc } = response?.options;

        setOptions((prevState: any) => ({ ...prevState, paritairComitee: pc, pcFunctions: pcFunctions, initialCall: false }));
      }
    }
  };


  const navigate = useNavigate();

  const tabs = [
    {
      id: 'general',
      tabName: 'General'
    },
    {
      id: 'function',
      tabName: 'Function'
    }
  ]

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      searchValue(name, value);
    }, debounceTime);
  };

  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${COMPETENCE_ARCHIVE}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
      if (response?.status === 200) {
        deleteFilter(deleteID);
        handleRefresh();
        CustomNotify({ type: "success", message: response.message });
      }
    }
  };

  const handleDelete = (Competence: Competence | any) => {
    setShowModal(true);
    setDeleteId(Competence.id);
  };

  const handleEdit = (competence: Competence | any) => {
    if (competence.id) {
      navigate(`${PATH_COMPETENCE_EDIT}/${competence.id}/${paramValue}`);
    }
  };

  const handleView = (competence: Competence | any) => {
    if (competence.id) {
      navigate(`${PATH_COMPETENCE_VIEW}/${competence.id}`);
    }
  };

  const userData = useSelector(selectAuth);

  const handleClick = (event: any) => {
    const { id } = event?.target;
    const filteredList = tabs.filter((eachItem: any) => (
      eachItem?.id == id
    ));
    navigate(`?type=${filteredList[0]?.id}`);
  }

  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  const filterFields = [
    { name: 'competenceName', fieldType: "text", placeholder: "Competence name", filterType: 'search' },
    { name: 'pcName', fieldType: "singleSelect", options: options?.paritairComitee, placeholder: "Paritair comitee", filterType: 'search' },
    { name: 'pcFunctions', fieldType: "multiSelect", options: options?.pcFunctions, placeholder: "Functions", filterType: 'search' },
  ];

  const generalFields=[
    { name: 'competenceName', fieldType: "text", placeholder: "Competence name", filterType: 'search' },
  ]


  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Competence",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="search-bar">
        <div className="row">
          <Title title={t("Competence")} />
          <div className=" vacancy-tabs question-tabs">
            {tabs.map((eachItem: any) => (
              <button className={`text-decoration-none border-0 bg-transparent marginRight1 ps-0 ${eachItem?.id == paramValue ? "active" : "inactive"
                }`} onClick={handleClick} id={eachItem?.id}
                style={{ marginBottom: "0.75vw" }}
              >
                {t(eachItem?.tabName)}
              </button>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <FilterOrganism
              dispatch={dispatch}
              history={history}
              updatePageFilters={updatePageFilters}
              filters={paramValue=='general'?generalFields :filterFields}
              handleRefresh={handleRefresh}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <div>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Competence",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <div className="row">
                  <div className="ManageCreateBtn">
                    <LinkTo
                      pagelink={`${PATH_COMPETENCE_CREATE}/${paramValue}`}
                      title={t("Create competence")}
                      icon={faPlus}
                    />
                  </div>
                </div>
              </AccessControl>
            </div>
            <div className="table-responsive tableSection">
              <div className="manageCompetencesHeight">
                <DynamicTableStructure
                  isAction
                  headers={CompetenceHeader}
                  data={Competence}
                  handleEdit={handleEdit}
                  handleArchive={handleDelete}
                  //   handleView={handleView}
                  permission={"Competence"}
                  history={history}
                  handleRefresh={handleRefresh}
                />
              </div>
              {/* <div
                className="pagination justify-content-center align-items-center"
                style={{ paddingTop: "0.25vw" }}
              >
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                />
              </div> */}
              <div
                className=""
                style={{ paddingTop: "0.25vw" }}
              >
                <PaginationWithPerPage
                  handleRefresh={handleRefresh}
                  dispatch={dispatch}
                  history={history}
                  pageData={pageData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: "0.85vw 0" }} className="row">
        <div className="col-12">
          <BackButton />
        </div>
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title={t("Archive confirmation")}
        body={t("Are you sure want to archive?")}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </AccessControl>
  );
};
export default ManageCompetencePage;

{
  /* <div className="table-responsive">
<DynamicTableStructure
  isAction
  headers={CompetenceHeader}
  data={competenceObject}
  handleEdit={handleEdit}
  handleArchive={handleDelete}
  handleView={handleView}
  permission={"Competence"}
/>
</div>
<div className="pagination justify-content-center align-items-center my-3">
<Pagination
  currentPage={currentPage}
  totalPages={totalPages}
  onPageChange={onPageChange}
/>
</div>
<div className="">
  <BackButton />
</div> */
}
