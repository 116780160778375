import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import classes from "static/css/Header.module.css";
import Logout from "static/images/Logout.svg";
import ImageField from "../atoms/ImageField";
import ButtonWithChildren from "../atoms/ButtonWithChildren";
import {
  selectAuth,
  removeUserDetails,
  addUserDetails,
} from "features/auth/AuthSlice";
import { useSelector, useDispatch } from "react-redux";
import { t } from "pages/microservices/masterData/translation/Translation";
import { PATH_PROFILE } from "constants/Paths";
import MultiSelectField from "components/atoms/MultiSelectField";
import { ApiCall } from "services/ApiServices";
import { FETCH_TRANSLATIONS } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { getPageName } from "services/util/UtilService";
import { addPageToHistory, removeHistory, selectPageHistory } from "store/pageHistory/pageHistorySlice";
import Button from "components/atoms/Button";
import { BUPages } from "store/pageHistory/ConstantPageNames";

const Header: React.FC = () => {
  const userAuth = useSelector(selectAuth);
  const pageHistory = useSelector(selectPageHistory);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getInitialDate = () => {
    const savedDate = localStorage.getItem("savedDate");
    return savedDate ? new Date(savedDate) : new Date();
  };
  const [date, setDate] = useState(getInitialDate);
  const location = useLocation();
  const isDashboard = location.pathname.includes("/dashboard") || location.pathname === "/";

  useEffect(() => {
    localStorage.setItem("savedDate", date.toISOString());
  }, [date]);

  useEffect(() => {
    let filters = {};
    const pageName = getPageName(location.pathname);
    if (BUPages?.includes(pageName) && !pageHistory?.history?.some((item: any) => item?.pageName === pageName)) {
      filters = { businessUnits: userAuth?.businessUnit?.map((item: any) => item?.value) };
    }
    dispatch(addPageToHistory({ pageName, route: location.pathname, filters }));
  }, [location.pathname]);

  const logOutClick = () => {
    localStorage.clear();
    dispatch(removeUserDetails());
    dispatch(removeHistory());
    navigate("/login");
  };

  const handleLanguageChange = async (e: any) => {
    let postdata = {
      language_id: Number(e?.target?.value),
    };
    try {
      dispatch(addUserDetails({ user_language: Number(e?.target?.value) }));
      const response = await ApiCall.service(
        FETCH_TRANSLATIONS,
        "POST",
        postdata,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        localStorage.setItem(
          "translations",
          JSON.stringify(response?.data?.data)
        );
        window?.location?.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getStartOfWeek = (date: Date): Date => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
    return new Date(date.setDate(diff));
  };

  const incrementWeek = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7);
  };

  const decrementWeek = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
  };

  const getWeekNumber = (date: Date): number => {
    const startDate = new Date(date.getFullYear(), 0, 1);
    const days = Math.floor(
      (date.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)
    );
    return Math.ceil((date.getDay() + 1 + days) / 7);
  };

  const setToCurrentDate = (selected: string) => {
    const currentDate = new Date();
    setDate(new Date(currentDate));
    if (selected === "month") {
      const user = {
        dateInfo: {
          type: "month",
          value: currentDate.getMonth() + 1,
          label:
            currentDate.toLocaleString("default", { month: "long" }) +
            " " +
            currentDate.getFullYear(),
          year: currentDate.getFullYear(),
        },
      };
      dispatch(addUserDetails(user));
    } else if (selected === "year") {
      const user = {
        dateInfo: {
          type: "year",
          value: currentDate.getFullYear(),
          label: currentDate.getFullYear(),
          year: currentDate.getFullYear(),
        },
      };
      dispatch(addUserDetails(user));
    } else if (selected === "week") {
      const startOfWeek = getStartOfWeek(currentDate);
      const weekNumber = getWeekNumber(startOfWeek);
      const user = {
        dateInfo: {
          type: "week",
          value: weekNumber,
          label: `Week ${weekNumber} of ${startOfWeek.getFullYear()}`,
          year: startOfWeek.getFullYear(),
        },
      };
      dispatch(addUserDetails(user));
    }
  };

  const increment = () => {
    let newDate;
    if (userAuth.dateInfo.type === "year") {
      newDate = new Date(date.getFullYear() + 1, date.getMonth(), 1);
    } else if (userAuth.dateInfo.type === "month") {
      newDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    } else if (userAuth.dateInfo.type === "week") {
      newDate = incrementWeek(date);
    } else {
      // This case should not happen if userAuth.dateInfo.type is correctly managed.
      newDate = date;
    }
    setDate(newDate);

    const user = {
      dateInfo: {
        type: userAuth.dateInfo.type,
        value:
          userAuth.dateInfo.type === "year"
            ? newDate.getFullYear()
            : userAuth.dateInfo.type === "month"
              ? newDate.getMonth() + 1
              : getWeekNumber(newDate), // for week
        label:
          userAuth.dateInfo.type === "year"
            ? newDate.getFullYear().toString()
            : userAuth.dateInfo.type === "month"
              ? newDate.toLocaleString("default", { month: "long" }) +
              " " +
              newDate.getFullYear()
              : `Week ${getWeekNumber(newDate)} of ${newDate.getFullYear()}`, // for week
        year: newDate.getFullYear(),
      },
    };
    dispatch(addUserDetails(user));
  };

  const decrement = () => {
    let newDate;
    if (userAuth.dateInfo.type === "year") {
      newDate = new Date(date.getFullYear() - 1, date.getMonth(), 1);
    } else if (userAuth.dateInfo.type === "month") {
      newDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    } else if (userAuth.dateInfo.type === "week") {
      newDate = decrementWeek(date);
    } else {
      // This case should not happen if userAuth.dateInfo.type is correctly managed.
      newDate = date;
    }
    setDate(newDate);

    const user = {
      dateInfo: {
        type: userAuth.dateInfo.type,
        value:
          userAuth.dateInfo.type === "year"
            ? newDate.getFullYear()
            : userAuth.dateInfo.type === "month"
              ? newDate.getMonth() + 1
              : getWeekNumber(newDate), // for week
        label:
          userAuth.dateInfo.type === "year"
            ? newDate.getFullYear().toString()
            : userAuth.dateInfo.type === "month"
              ? newDate.toLocaleString("default", { month: "long" }) +
              " " +
              newDate.getFullYear()
              : `Week ${getWeekNumber(newDate)} of ${newDate.getFullYear()}`, // for week
        year: newDate.getFullYear(),
      },
    };
    dispatch(addUserDetails(user));
  };

  return (
    <div className="row">
      <div className="col-12">
        <Navbar
          collapseOnSelect
          expand="lg"
          style={{ paddingTop: "0.25vw", paddingBottom: "0.25vw" }}
        >
          <>
            {/* <div className="d-block d-lg-none">
              <Navbar.Brand href="/">
                <img
                  src="/static/images/logo.svg"
                  alt={t("logo")}
                  className="img-fluid"
                />
              </Navbar.Brand>
            </div> */}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          </>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="headerPageLink">
              {pageHistory?.history?.map((page, index) => (
                (page?.pageName && page?.pageName !== "") &&
                (<span key={index} className="d-flex align-items-center headerViewLink">
                  <Link
                    to={page?.route ?? '/'}
                    title={t(page?.pageName)}
                    className={`btn headerBtn ps-0 paddingRightPoint5 border-0 ${index >= pageHistory?.history?.length - 1 ? '' : 'present'}`}
                  >
                    {t(page?.pageName)}
                  </Link>
                  {/* <Button
                      title={t(page?.pageName)}
                      tooltip={t(page?.pageName)}
                      handleClick={() => navigate(page?.route ?? '/')}
                      className={`btn headerBtn ps-0 pe-1 border-0 ${index >= pageHistory?.history?.length - 1 ? '' : 'present' }`}
                    /> */}
                  <span className="arrowHeightHeader">{index < pageHistory?.history?.length - 1 && ' > '}</span>
                </span>)
              ))}
            </Nav>
            <Nav className="ms-auto">
              {isDashboard && (
                <Dropdown className="this-week-section d-flex align-items-center gap-2">
                  <button
                    className="monthPrevIcon dashboardCurrentCalendarIcon"
                    onClick={decrement}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} className="fa-xl" />
                  </button>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="this-week-link d-flex align-items-center"
                  >
                    {userAuth?.dateInfo?.label ??
                      new Date()?.getFullYear().toString()}
                  </Dropdown.Toggle>
                  <button
                    className="monthNextIcon dashboardCurrentCalendarIcon"
                    onClick={increment}
                  >
                    <FontAwesomeIcon icon={faAngleRight} className="fa-xl" />
                  </button>

                  <Dropdown.Menu className="profile-dropdown-links">
                    <Dropdown.Item
                      onClick={() => {
                        setToCurrentDate("week");
                      }}
                    >
                      {t("Current week")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setToCurrentDate("month");
                      }}
                    >
                      {t("Current month")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setToCurrentDate("year");
                      }}
                    >
                      {t("Current year")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Nav>
            <Nav>
              {userAuth && userAuth ? (
                <>
                  <MultiSelectField
                    name="language"
                    selectedValue={
                      userAuth?.languageOptions?.find(
                        (item: any) => item?.value === userAuth?.user_language
                      )
                        ? userAuth.user_language
                        : userAuth?.languageOptions?.find(
                          (item: any) => item.key === "en"
                        )?.value
                    }
                    emptyOptionNotRequired
                    options={userAuth?.languageOptions}
                    handleChange={handleLanguageChange}
                    placeholder=""
                    className="mt-0 headerLanguage"
                    errorClass="d-none"
                  />
                </>
              ) : null}
            </Nav>
            <Nav>
              {userAuth && userAuth ? (
                <Link
                  to={PATH_PROFILE}
                  className="text-decoration-none form-button mx-3 profileLink"
                >
                  {t("Profile")}
                </Link>
              ) : null}
            </Nav>
            <Nav>
              {userAuth && userAuth ? (
                <ButtonWithChildren
                  className="logOutIcon border-0 bg-transparent p-0"
                  onClick={logOutClick}
                >
                  <img src={Logout} alt="Logout icon" title="Logout" />
                </ButtonWithChildren>
              ) : (
                <Link
                  to="/login"
                  className="btn mt-5 rounded-0 form-button"
                >
                  {t("Login")}
                </Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;
