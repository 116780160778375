import Calender from 'components/molecules/Calender'
import React from 'react'
import { t } from 'pages/microservices/masterData/translation/Translation';

interface ManagePayrollHeadersProps {
    handleChange: (e: any, name: any) => void;
    pageData?: any;
}
const ExportPopup: React.FC<ManagePayrollHeadersProps> = ({ handleChange, pageData }) => {
    return (
        <>
            <div className="d-flex ms-auto exportPopup" style={{gap:"1vw"}}>
                <Calender
                    onChange={(e, name) => handleChange(e, name)}
                    selectedDate={pageData?.export_popup?.start_month}
                    maxDate={pageData?.export_popup?.end_month}
                    placeHolder={t("Select from date")}
                    pickerType='month'
                    name="start_month"
                />
                <Calender
                    onChange={(e, name) => handleChange(e, name)}
                    selectedDate={pageData?.export_popup?.end_month}
                    minDate={pageData?.export_popup?.start_month}
                    placeHolder={t("Select to date")}
                    pickerType='month'
                    name="end_month"
                />
            </div>
        </>  
    )
}

export default ExportPopup