import Button from 'components/atoms/Button';
import FilterOrganism from 'components/organism/FilterOrganism';
import { M_MASTER_DATA } from 'constants/Constants';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CREATE_PC_SALARY_BENEFITS, GET_PC_SALARY_BENEFITS } from 'routes/ApiEndpoints';
import { ApiCall } from 'services/ApiServices';
import { selectPageHistory, updatePageFilters } from 'store/pageHistory/pageHistorySlice';
import { mapToSelect } from 'utils/MapToSelect';
import { Option, ValidationRules } from 'utils/TypeAnnotations';
import { t } from '../translation/Translation';
import ModalPopup from 'components/atoms/ModalPopup';
import SelectWithSearch from 'components/atoms/SelectWithSearch';
import { initialSalaryBenefits } from '../wage-proposal/WageInterfaces';
import { LabelWithInputFieldAndUnit } from '../templates/atoms/LabelWithInputFieldAndUnit';
import { validateForm, validateMultiSelectField, validateNumber, validatePercentage, validateSelectField } from 'services/validation/ValidationService';
import EditIcon from 'static/images/EditIcon';
import TitleAtom from 'components/atoms/Title';

export interface SalaryBenefitProps {
  value: number | null;
  label: string;
  benefitValueType: number | Option | null;
  benefitValue: number | null;
}

interface DropdownData {
  pc: Option | null;
  functions: Option[];
  salaryBenefits: [];
}


interface FormDataProps {
  pc: string | undefined;
  function: [];
  salaryBenefits: SalaryBenefitProps[] | null;
}

// interface ManageData {
//     pc: string;
//     function: Option|null;
//     salaryBenefits: SalaryBenefitProps[] | null;
// }


export const PcSalaryBenefits = () => {
  const params = useParams();
  const pcId = params.id;
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true
  });
  const [dropdownData, setDropdownData] = useState({
    pc: null,
    functions: [],
    salaryBenefits: []
  });
  const [salaryBenefits, setSalaryBenefits] = useState<SalaryBenefitProps[]>([
    initialSalaryBenefits,
  ]);
  const [modalPopup, setModalPopup] = useState(false);
  const initalFormData: FormDataProps = {
    pc: pcId,
    function: [],
    salaryBenefits: []
  }
  const [formData, setFormData] = useState<FormDataProps>(initalFormData)
  const [errors, setErrors] = useState<any>({
    benefitValue: "",
  });
  const [manageData, setManageData] = useState([]
    //     {
    //     pc: '',
    //     function: null,
    //     salaryBenefits: []
    // }
  );

  useEffect(() => {
    const data: any = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getSearchData(),
      initialCall: pageData?.initialCall,
      id: pcId
    };
    fetchData(data);
  }, [pageData?.refresh, pageData?.initialCall]);

  const filters = [
    { name: 'pc', fieldType: "singleSelect", options: dropdownData?.pc, placeholder: "Paritair comitee name", filterType: 'search' },
    { name: 'functions', fieldType: "multiSelect", options: mapToSelect(dropdownData?.functions), placeholder: "Function", filterType: 'search' },
    { name: 'salaryBenefits', fieldType: "multiSelect", options: dropdownData?.salaryBenefits, placeholder: "Benefit", filterType: 'search' },
  ];

  const getSearchData = () => {
    return {
      pc: history?.filters?.pc ?? "",
      functions: history?.filters?.functions ?? "",
      salaryBenefits: history?.filters?.salaryBenefits ?? "",
    }
  };

  const validation = (
    name: any = null,
    value: any = null,
    benefitType: any = null,
    isSingleFieldValidation = false,
    index?: number | undefined
  ) => {
    let validationRules: ValidationRules = {
      function: [validateSelectField],
      salaryBenefits: [validateMultiSelectField],
      salaryType: [validateSelectField],
      salaryCategory: [validateSelectField],
    };

    // Add specific validation rules for benefitValue or benefitValueType
    if (name === "benefitValue" || name === "benefitValueType") {
      validationRules = {
        ...validationRules,
        benefitValue:
          benefitType === 2 ? [validatePercentage] : [validateNumber],
      };
    }

    // Construct formData object for validation
    const formDataToValidate = index === undefined
      ? { ...formData, [name]: value }
      : { ...(formData?.salaryBenefits?.[index] || {}), [name]: value };

    // Validate the form and get the validation errors
    const validationErrors = validateForm(formDataToValidate, validationRules, isSingleFieldValidation ? name : undefined);

    // Handle errors based on whether it's single field validation or not
    if (isSingleFieldValidation) {
      if (index !== undefined) {
        // If it's a single field validation and index is defined, update only the errors for the specific salaryBenefit at the given index
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          salaryBenefits: prevErrors.salaryBenefits
            ? prevErrors.salaryBenefits.map((benefit: any, idx: number) =>
              idx === index
                ? { ...benefit, 'benefitValue': 'Invalid' }
                : benefit
            )
            : [],
        }));
      } else {
        // If it's a single field validation and no index, update the specific field error
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          [name]: validationErrors[name],
        }));
      }
    } else {
      // If it's not single field validation, set all errors
      setErrors(validationErrors);
    }
    // Return false if there are validation errors
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };



  const fetchData = async (data: any) => {
    const response = await ApiCall.service(GET_PC_SALARY_BENEFITS, "POST", data, false, M_MASTER_DATA);
    if (response?.status === 200) {
      setDropdownData(response?.data?.resultantData);
      setSalaryBenefits(response?.data?.resultantData?.salaryBenefits);
      setManageData(response?.data?.manageRecords);
      setPageData((prev: any) => ({ ...prev, totalPages: response?.totalPages, totalRecords: response?.totalRecords, initialCall: false }));
    }
  }

  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  const handleAddRequest = () => {
    setModalPopup(true);
  }

  const handleClosePopup = () => {
    setModalPopup(false);
    setFormData(initalFormData);
  }

  const handleSelectChange = (selectedOption: Option | Object[] | any, fieldName: string, index?: number) => {
    if (fieldName === "salaryBenefits") {
      // Handle salary benefits selection
      const selectedBenefits = selectedOption && selectedOption.map((option: any) => ({
        value: option.value,
        label: option.label,
        benefitValueType: option.benefitValueType,
        benefitValue: option.benefitValue,
      }));
      setSalaryBenefits(selectedBenefits);
      setFormData((prevData: FormDataProps) => ({
        ...prevData,
        salaryBenefits: selectedBenefits || [],
      }));
      validation(fieldName, selectedOption, null, true);
      return;
    } else if (fieldName === "benefitValueType") {
      // Handle changes to benefit value type or value
      const updatedSalaryBenefits = [...(formData.salaryBenefits || [])];
      if (index !== undefined) {
        updatedSalaryBenefits[index] = {
          ...updatedSalaryBenefits[index],
          [fieldName]: selectedOption?.value, // For benefit value, can be a number
        };
      }
      setSalaryBenefits(updatedSalaryBenefits);
      setFormData((prevData: FormDataProps) => ({
        ...prevData,
        salaryBenefits: updatedSalaryBenefits,
      }));
      if (selectedOption?.value === 2) {
        validation(fieldName, selectedOption, 2, true, index);
      }
      return;
    }

    // Update the form data for other fields
    setFormData((prevData: FormDataProps) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
    validation(fieldName, selectedOption, null, true);
  }

  const handleFieldChange = (event: any, index?: number) => {
    const { name, value } = event?.target;
    if (name === "benefitValue") {
      // Handle changes to benefit value type or value
      const updatedSalaryBenefits = [...(formData.salaryBenefits || [])];
      if (index !== undefined) {
        updatedSalaryBenefits[index] = {
          ...updatedSalaryBenefits[index],
          [name]: Number(value), // For benefit value, can be a number
        };
      }
      setSalaryBenefits(updatedSalaryBenefits);
      setFormData((prevData: FormDataProps) => ({
        ...prevData,
        salaryBenefits: updatedSalaryBenefits || [],
      }));
      const benefitType =
        index !== undefined
          ? updatedSalaryBenefits[index].benefitValueType
          : null;
      validation(name, value, benefitType, true);
      return;
    }
    setFormData((prevData: FormDataProps) => ({
      ...prevData,
      [name]: value,
    }));
    validation(name, value, null, true);
  }

  const handleAddSalaryBenefit = async () => {
    const response = await ApiCall.service(CREATE_PC_SALARY_BENEFITS, "POST", formData, false, M_MASTER_DATA);
    if (response?.status === 200) {
      handleRefresh();
      setModalPopup(false);
      setFormData(initalFormData); // Reset form data
    }
  };


  const handleEdit = (data: FormDataProps) => {
    setFormData((prevData: any) => ({
      ...prevData,
      function: data?.function,
      salaryBenefits: data?.salaryBenefits
    }));
    setModalPopup(true);
  }

  return (
    <>
      <div className="search-bar">
        <TitleAtom title={t("Salary benefits")} />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <div className="row">
              <div className="ManageCreateBtn">
                <span onClick={handleAddRequest} className='form-button cursor-pointer'>
                  {"+ " + t("Add salary benefit")}
                </span>
              </div>
            </div>
            <div className="table-responsive tableSection manageSalaryBenefit">
              <FilterOrganism
                dispatch={dispatch}
                history={history}
                updatePageFilters={updatePageFilters}
                filters={filters}
                handleRefresh={handleRefresh}
              />
              <div className="manageSalaryBenefitHeight">
                <table cellPadding="5" className='table'>
                  <thead className='TableHeader'>
                    <tr>
                      <th>{t("Paritair comitee")}</th>
                      <th>{t("Functions")}</th>
                      <th>{t("Salary benefits")}</th>
                      <th>{t("Actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {manageData && Object.entries(manageData).length > 0 && Object.entries(manageData).map(([key, value]: [string, any]) => (
                      <tr key={key}>
                        <td>{value?.pc}</td>
                        <td>{value?.function?.label}</td>
                        <td>{value?.salaryBenefits && value.salaryBenefits.map((benefit: SalaryBenefitProps) =>
                          benefit?.label
                        ).join(',  ')}</td>
                        <td><span title={t("Edit")} onClick={() => handleEdit(value)} className='table-action-btn cursor-pointer'> <EditIcon /></span></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
      {modalPopup &&
        <ModalPopup
          show={modalPopup}
          onHide={handleClosePopup}
          title={t("Add salary benefits")}
          modalBodyClassName="py-0"
          modalFooterClassName="py-0"
          body={
            <>
              <div className="row">
                <SelectWithSearch
                  title={t("Functions")}
                  isMandatory={false}
                  search={true}
                  options={mapToSelect(dropdownData.functions)}
                  onChange={(e) => handleSelectChange(e, "function")}
                  isMulti={false}
                  name="function"
                  value={formData.function}
                  // error={""}
                  placeHolder="Select"
                />
                <SelectWithSearch
                  title={t("Salary benefits")}
                  isMandatory={false}
                  search={true}
                  options={dropdownData.salaryBenefits}
                  onChange={(e) => handleSelectChange(e, "salaryBenefits")}
                  isMulti={true}
                  name="fusalaryBenefitnction"
                  value={formData.salaryBenefits}
                  // error={""}
                  placeHolder="Select"
                />
                {formData.salaryBenefits &&
                  formData.salaryBenefits.length > 0 &&
                  formData.salaryBenefits.map(
                    (benefit: SalaryBenefitProps, index: number) => (
                      <div key={index}>
                        <div className="fw-bold">{benefit.label}:</div>
                        <div className="d-grid" style={{ gridTemplateColumns: "repeat(2, 1fr)", gap: "0 1vw" }}>
                          <div>
                            <SelectWithSearch
                              name="benefitValueType"
                              id="benefitValueType"
                              isMandatory={true}
                              search={true}
                              options={[
                                { name: "euro", label: "Salary in euro", value: 1 },
                                {
                                  name: "percentage",
                                  label: "Salary in percentage",
                                  value: 2,
                                },
                              ]}
                              placeHolder="Select"
                              value={benefit.benefitValueType}
                              onChange={(e) =>
                                handleSelectChange(e, "benefitValueType", index)
                              }
                              isMulti={false}
                              className="select-field"
                              containerClassName="col-12 position-relative"
                            />
                          </div>
                          <div>
                            <LabelWithInputFieldAndUnit
                              labelClassName="form-label field-label"
                              name="benefitValue"
                              id="benefitValue"
                              isMandatory={true}
                              handleChange={(e) => handleFieldChange(e, index)}
                              value={benefit.benefitValue ?? ""} // Convert null to an empty string
                              placeholder={t("Select")}
                              className="form-control field-shadow"
                              type="text"
                              unit={
                                (benefit && benefit?.benefitValueType === 1) ||
                                  (salaryBenefits.length > 0 &&
                                    salaryBenefits[index]?.benefitValueType === 1)
                                  ? "€"
                                  : "%"
                              }
                              containerClassName="col-12 position-relative"
                              error={errors[index]?.benefitValue}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </>
          }
          onConfirmButtonClick={handleAddSalaryBenefit}
          confirmTitle={t("Add")}
          className='salaryBenefitPopup'
        />}


    </>
  );
}