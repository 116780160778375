import React from 'react';
import { Navigation } from '../../form-navigation/Navigation';

export const SalaryBenefits = () => {
    return (
        <>
            <div style={{ padding: "1vw 0" }}>
                <Navigation />
            </div>
        </>
    );
}