export const initialFilterProps = {
    optionState: {
        projects: [],
        employees: [],
        performanceTypes: [],
        reimbursementTypes: [],
        regimen: [],
        prjectParameters: []
    },
    selectedState: {
        project_id: null,
        employee_id: null,
        currentDate: new Date(),
        refresh: false,
        initialCall: true,
    },
    filteredOptions: {
        projects: [],
        employees: [],
    },
}
