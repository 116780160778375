import {
  EDIT_STUDY_FIELD,
  EUROPEAN_CITIZEN,
  GENERAL,
  INTERNAL_INFO,
  JOB_PROFILE,
  OTHERS,
  PROFILE_CARD,
  SET_DROPDOWN_DATA,
  UPDATE_TAB_DATA,
} from "../../annotations/CandidateConstants";
import { SetJobProfileData } from "./SetJobProfileData";

// Helper function to dispatch tab data if valid and convert date fields
export const dispatchTabData = (
  dispatch: any,
  tab: string,
  tabData: any,
  dateFields: string[] = []
) => {
  // Convert fields in dateFields array to Date if they exist and are strings
  dateFields.forEach((field) => {
    if (tabData[field] && typeof tabData[field] === "string") {
      tabData[field] = new Date(tabData[field]);
    }
  });

  // Dispatch data if there are any keys in tabData
  if (Object.keys(tabData).length > 0) {
    dispatch({
      type: UPDATE_TAB_DATA,
      tab,
      data: tabData,
    });
  }
};

const dispatchFieldUpdates = (
  dispatch: any,
  data: any,
  tab: string,
  dateFields: string[] = []
) => {
  const list = data[tab];
  if (!list) {
    return;
  }

  if (list && list.length > 0) {
    list.forEach((item: Record<string, any>, index: number) => {
      Object.entries(item).forEach(([field, value]) => {
        // Convert specified date fields to Date object if necessary
        if (dateFields.includes(field) && value && typeof value === "string") {
          value = new Date(value);
        }
        if (tab === "studies") {
          dispatch({
            type: EDIT_STUDY_FIELD,
            index,
            field,
            value,
          });
        }
        // Dispatch the update with the common "EDIT_FIELD" action
        dispatch({
          type: "EDIT_FIELD",
          field,
          value,
          index,
          tab,
        });
      });
    });
  }
};

export const FillCandidateForm = (candidateData: any, dispatch: any) => {
  //profile card details
  const profileCardDetails = candidateData?.profileCard || [];
  if (profileCardDetails && Object.entries(profileCardDetails)?.length > 0) {
    dispatchTabData(dispatch, PROFILE_CARD, profileCardDetails);
  }
  //general tab
  const generalTabData = candidateData?.general || [];
  if (generalTabData && Object.entries(generalTabData)?.length > 0) {
    dispatchTabData(dispatch, GENERAL, generalTabData, ["dob"]);
  }
  //other details
  const otherTabData = candidateData?.others;
  if (otherTabData && Object.entries(otherTabData)?.length > 0) {
    dispatchTabData(dispatch, OTHERS, candidateData?.others, [
      "civilStateDate",
      "partnerDateOfBirth",
    ]);
  }
  //job profile details
  const jobProfileTabData = candidateData?.jobProfile;
  if (jobProfileTabData && Object.entries(jobProfileTabData)?.length > 0) {
    dispatchTabData(dispatch, JOB_PROFILE, candidateData?.jobProfile);
  }
  //european citizen details
  const europeanCitizenTabData = candidateData?.europeanCitizen;
  if (
    europeanCitizenTabData &&
    Object.entries(europeanCitizenTabData)?.length > 0
  ) {
    dispatchTabData(dispatch, EUROPEAN_CITIZEN, europeanCitizenTabData, [
      "from",
      "to",
    ]);
  }
  //internal info
  const internaInfoData = candidateData?.internalInfo || [];
  if (internaInfoData && Object.entries(internaInfoData)?.length > 0) {
    dispatchTabData(dispatch, INTERNAL_INFO, internaInfoData);
  }

  //dynamic tabs
  // Emergency Contact Details
  dispatchFieldUpdates(dispatch, candidateData, "emergencyContactDetails");
  // Study Details with date fields "from" and "to"
  dispatchFieldUpdates(dispatch, candidateData, "studies", ["from", "to"]);
  // Work Experience Details with date fields "from" and "to"
  dispatchFieldUpdates(dispatch, candidateData, "workExperience", [
    "from",
    "to",
  ]);
  // Certificate Details with date fields "validFrom" and "validUntill"
  dispatchFieldUpdates(dispatch, candidateData, "certificates", [
    "validFrom",
    "validUntil",
  ]);
  // Document Details with date fields "validFrom" and "validUntil"
  dispatchFieldUpdates(dispatch, candidateData, "documents", [
    "validFrom",
    "validUntil",
  ]);

  if (candidateData?.jobProfile) {
    //Pc
    const pcId = candidateData.jobProfile?.desiredPartiarComitte;
    SetJobProfileData(dispatch, pcId, "desiredPartiarComitte");
    //Category
    const categoryId = candidateData.jobProfile?.desiredCategory;
    SetJobProfileData(dispatch, categoryId, "desiredCategory");
  }
};

export const SetCandidateData = (
  data: any,
  dispatch: any,
  id: string | null | undefined = null,
  state: any
) => {
  const dropdownData = data?.dropDownDetails;
  // Dispatch the action to update the dropdown data
  dispatch({ type: SET_DROPDOWN_DATA, payload: dropdownData });

  if (id) {
    FillCandidateForm(data?.candidateData, dispatch);
  }
};
