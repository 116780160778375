import React from 'react'

const ActiveLocation = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="1.5vw" height="1.5vw" viewBox="0 0 18.069 26.033">
        <g id="Active_location" data-name="Active location" transform="translate(-60.531)">
          <path id="Path_14334" data-name="Path 14334" d="M69.565,0a9.045,9.045,0,0,0-9.034,9.035c0,4.8,8.2,16,8.546,16.473l.326.443a.2.2,0,0,0,.326,0l.326-.443C70.4,25.033,78.6,13.83,78.6,9.035A9.045,9.045,0,0,0,69.565,0Zm0,5.8a3.236,3.236,0,1,1-3.236,3.236A3.239,3.239,0,0,1,69.565,5.8Z" fill="currentColor" />
        </g>
      </svg>

    </>
  )
}

export default ActiveLocation