import CustomNotify from "components/atoms/CustomNotify";
import { M_MASTER_DATA, M_WEB_CONNECTOR } from "constants/Constants";
import { COMPANY_VAT_VALIDATION, VERIFY_SUPPLIER_CLIENT_VAT_NUMBER } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { getCityAndCountry, getCountryFormat } from "services/util/UtilService";

export const activeFunction = (payload: any, dependentFields: any) => {

  let { fieldData, state } = payload;
  const fieldValue = fieldData.value; //field value

  const currentState = [...state];
  const currentForm = currentState[0].form;

  let newState = currentForm.map((item: any) => {
    return item?.map((eachList: any) => {
      if (eachList.name == fieldData.field) {
        return { ...eachList, value: fieldValue, error: '' }
      }
      return eachList;
    });
  });

  currentState[0].form = newState;
  return currentState;

}

export const verifyVatNumber = async (payload: any) => {

  const { state, fieldData } = payload;
  const { field, dynamic, mainIndex, subIndex } = fieldData;

  const vatData = {
    vat_number: field?.value,
    key: `${process.env.REACT_APP_WEBCONNECTOR_UNIQUE_KEY}`,
  };
  const vatExistence = await ApiCall.service(
    VERIFY_SUPPLIER_CLIENT_VAT_NUMBER,
    "POST",
    vatData,
    false,
    M_MASTER_DATA
  );
  if (vatExistence?.status === 400) {
    CustomNotify({ type: "warning", 'message': vatExistence?.message });
    return [...state];
  }
  else {
    const url = COMPANY_VAT_VALIDATION;
    const dependentFields: any = {
      d_street: 'street',
      d_number: 'number',
      d_box: 'box',
      d_postalCode: 'postal',
      d_city: 'city',
      name: 'name',
      d_country: 'country',
    };
    const response = await ApiCall.service(
      url,
      "POST",
      vatData,
      true,
      M_WEB_CONNECTOR,
      false
    );
    if (response?.status !== 200) {
      CustomNotify({ type: "error", message: response?.error });
      const newState = [...state].map((eachData: any) => {
        if (Object.keys(dependentFields).includes(eachData?.name)) {
          return { ...eachData, value: '', error: '' };
        }
        return eachData;
      });
      // dispatch({
      //   type: "UPDATE_BASIC_FIELD",
      //   field: "companyName",
      //   value: "",
      // });
      // dispatch({
      //   type: "UPDATE_BASIC_FIELD",
      //   field: "officialName",
      //   value: "",
      // });
      //handleAddressUpdate("", "", "", "", "");
      //dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
      //state.fieldError.vat = response?.error;
      return newState;
    } else {
      const value = response?.data?.name;
      const addressData = {
        name: value?.toUpperCase(),
        ...response?.data?.address,
        country: 29,
      }

      //update errors to empty string for dependency fields


      const currentState = [...state];
      const currentForm = currentState[0].form;

      let updatedState = currentForm.map((item: any) => {
        return item?.map((eachList: any) => {
          if (Object.keys(dependentFields).includes(eachList?.name)) {
            return { ...eachList, value: addressData[dependentFields[eachList?.name]], error: '' };
          }
          return eachList;
        });
      });

      currentState[0].form = updatedState;
      CustomNotify({ type: "success", message: "VAT number is verified" });
      return currentState;
    }
  }
};

export const getCityCountry = async (payload: any, dataObj: any) => {
  const { fieldData, state } = payload;
  const { field, value } = fieldData

  const fieldValue = fieldData?.value;

  const zipcodeDependency: any = {
    d_city: 'city',
    d_country: 'country'
  }

  try {
    const response: any = await getCityAndCountry(fieldValue);

    const currentState = [...state];
    const currentForm = currentState[0].form;

    let updatedState = currentForm.map((item: any) => {
      const countryList = currentForm[0]?.filter((eachEle: any) => {
        return eachEle?.label?.toLowerCase() == 'country'
      });

      return item?.map((eachList: any) => {
        if (Object.keys(zipcodeDependency).includes(eachList?.name)) {
          const countryName = getCountryFormat(countryList[0]?.options, response?.country);

          const data = { ...response, country: countryName ?? '' };
          return { ...eachList, value: data[zipcodeDependency[eachList?.name]], error: '' };
        }
        if (eachList?.name == field) {
          return { ...eachList, value };
        }
        return eachList;
      });
    });

    currentState[0].form = updatedState;
    return currentState;
  }
  catch (error) {
    console.log(error);
  }
}
