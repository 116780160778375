import {
  addDynamicForm,
  competenceRange,
  competenceSelected,
  competenceType,
  getUpdatedForm,
  manageIndex,
  removeDynamicForm,
  updateDeletePopUp,
  updateFieldDependency,
  updateLocationDisplayModel,
  updateLocationValues,
  updatePreviousTableData,
  updateSalaryBenefit,
  updateSalaryBenefitFields,
  updateStateAfterButtonClick,
  updateStateWithEmptyValues,
  updateTabErrors,
  updateTableDataInPopUp,
} from "../components/common/ReducerService";
import {
  ADD_DYNAMIC_FORM,
  COMPETENCE_RANGE,
  COMPETENCE_SELECTED,
  EDIT_PREVIOUS_DATA_IN_TABLE,
  HIDE_PREVIOUS_DATA_IN_TABLE,
  MANAGE_INDEX,
  REMOVE_DYNAMIC_FORM,
  SET_FORM_ELEMENTS,
  SWITCH_TAB,
  UPDATE_ADDRESS_DATA,
  UPDATE_AFTER_BUTTON_CLICK,
  UPDATE_DELETE_POPUP,
  UPDATE_DEPENDENT_TAB,
  UPDATE_FIELD_DATA,
  UPDATE_MAP_SHOW_POPUP,
  UPDATE_PREVIOUS_DATA,
  UPDATE_SALARY_BENEFIT,
  UPDATE_SALARY_BENEFIT_FIELD_DATA,
  UPDATE_STATE_WITH_EMPTY_VALUES,
  UPDATE_TAB_FIELD,
} from "./Constants";

type FormReducerProps = {
  state: any;
  action: any;
};

const FormReducer = (
  state: FormReducerProps["state"],
  action: FormReducerProps["action"]
) => {
  switch (action.type) {
    case SWITCH_TAB:
    case SET_FORM_ELEMENTS:
      return { ...state, [action.field]: action.value };
    case UPDATE_TAB_FIELD:
      return updateTabErrors(state, action);
    case UPDATE_FIELD_DATA:
      return { ...state, form: getUpdatedForm(state, action) };
    case ADD_DYNAMIC_FORM:
      return addDynamicForm(state, action);
    case REMOVE_DYNAMIC_FORM:
      return removeDynamicForm(state, action);
    case UPDATE_DEPENDENT_TAB:
      return updateFieldDependency(state, action);
    case MANAGE_INDEX:
      return manageIndex(state, action);
    case COMPETENCE_SELECTED:
      return competenceSelected(state, action);
    case COMPETENCE_RANGE:
      return competenceRange(state, action);
    case UPDATE_SALARY_BENEFIT:
      return updateSalaryBenefit(state, action);
    case UPDATE_SALARY_BENEFIT_FIELD_DATA:
      return updateSalaryBenefitFields(state, action);
    case EDIT_PREVIOUS_DATA_IN_TABLE:
      return updateTableDataInPopUp(state, action);
    case UPDATE_PREVIOUS_DATA:
      return updatePreviousTableData(state, action);
    case UPDATE_STATE_WITH_EMPTY_VALUES:
      return updateStateWithEmptyValues(state, action);
    case UPDATE_DELETE_POPUP:
      return updateDeletePopUp(state, action);
    case UPDATE_AFTER_BUTTON_CLICK:
      return updateStateAfterButtonClick(state, action);
    case UPDATE_MAP_SHOW_POPUP:
      return updateLocationDisplayModel(state, action);
    case UPDATE_ADDRESS_DATA:
      return updateLocationValues(state,action);
    default:
      return state;
  }
};

export default FormReducer;
