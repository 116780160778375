import LabelField from 'components/atoms/LabelField';
import React, { useState, useEffect, useRef } from 'react';

interface AutocompleteProps {
  className?: string;
  suggestions: Suggestion[];
  onSuggestionSelected: (selectedSuggestion: Suggestion) => void;
  onInputValueChange: (inputValue: string) => void;
  label?: string;
  labelClassName?: string;
  isMandatory?: boolean;
  id?: string;
  error?: string;
  value: string;
  isDisabled?: boolean;
}

interface Suggestion {
  label: string;
  value: string;
}

const Autocomplete: React.FC<AutocompleteProps> = ({
  value,
  className,
  suggestions,
  onSuggestionSelected,
  onInputValueChange,
  label,
  labelClassName,
  isMandatory,
  id,
  error,
  isDisabled = false
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [filteredSuggestions, setFilteredSuggestions] = useState<Suggestion[]>([]);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  useEffect(() => {
    setFilteredSuggestions(suggestions);
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setIsFocused(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [suggestions]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    value = event.target.value;
    setSelectedValue(null);
    onInputValueChange(inputValue);
    const filtered = suggestions.filter(suggestion =>
      suggestion.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredSuggestions(filtered);
  };

  const handleSuggestionClick = (selectedValue: string) => {
    const selectedSuggestion = suggestions.find(suggestion => suggestion.value === selectedValue);
    if (selectedSuggestion) {
      value = selectedSuggestion.label;
      setSelectedValue(selectedSuggestion.value);
      onSuggestionSelected(selectedSuggestion);
    }
    setFilteredSuggestions([]);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  return (
    <div className='col-md-12'>
      <LabelField
        title={label}
        className={labelClassName}
        isMandatory={isMandatory}
        htmlfor={id}
      />
      <input
        ref={inputRef}
        type="text"
        value={value}
        onChange={handleInputChange}
        placeholder={"Type to search..."}
        className={`form-control field-shadow ${className}`}
        onFocus={handleFocus}
        disabled={isDisabled}
      />

      {isFocused && value.length > 0 && filteredSuggestions.length > 0 &&
        <ul className='mt-2 list-unstyled bg-white optionSection shadow p-0'>
          {filteredSuggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSuggestionClick(suggestion.value)} className='p-2'>
              <span className='cursor-pointer'>
                {suggestion.label}
              </span>
            </li>
          ))}
        </ul>
      }
      <div className="height-20" style={{marginBottom: "0.5vw"}}>
        {error && <span className="text-danger">{error}</span>}
      </div>

    </div>
  );
};

export default Autocomplete;