import React from 'react';
import { PCForm } from '../create-pc/form-data/PCForm';
import { FormProvider } from './Context';

export const MultiFormProviderPC = () => {
    return (
        <FormProvider>
            <PCForm />
        </FormProvider>
    );
}