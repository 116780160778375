import { selectAuth } from "features/auth/AuthSlice";
import { t } from "pages/microservices/masterData/translation/Translation";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import AccessControl from "services/AccessControl";

interface PopupProps {
  title?: string;
  body: React.ReactNode;
  cancel?: () => void;
  submit?: (e?: any) => void;
  close?: () => void;
  sendLetar?: () => void;
  sendNow?: () => void;
  approveNow?: () => void;
  extraButtonFunction?: () => void;
  yesicon?: React.ReactNode;
  noicon?: React.ReactNode;
  yestext?: string;
  notext?: string;
  letarSendText?: string;
  nowSendText?: string;
  approveNowText?: string;
  extraButtonText?: string;
  modalSize?: "sm" | "lg" | "xl";
  bodyclassName?: string;
  modalclass?: string;
  cancelButtonClass?: string;
  footerclass?: string;
  permissions?: any;
}

const Popup: React.FC<PopupProps> = ({
  title,
  body,
  cancel,
  submit,
  close,
  sendLetar,
  sendNow,
  approveNow,
  extraButtonFunction,
  yestext,
  notext,
  letarSendText,
  nowSendText,
  approveNowText,
  extraButtonText,
  yesicon,
  noicon,
  modalSize,
  bodyclassName,
  modalclass,
  cancelButtonClass,
  footerclass,
  permissions,
}: PopupProps) => {
  //   const [status, setStatus] = useState(0);
  const userData = useSelector(selectAuth);
  return (
    <Modal
      size={`${modalSize ?? "lg"}`}
      show={true}
      centered
      className={`${modalclass} modalPopupClass`}
      onHide={close ?? cancel}
    >
      <Modal.Header className="color-dark-skyblue font-CalbriBold pb-2 px-0" style={{fontSize:"1vw"}} closeButton title="Close">
        {title && <Modal.Title className="">{title}</Modal.Title>}
        {/* <Button
          variant="link"
          className="btn-close shadow-none mt-1"
          onClick={close ?? cancel}
          aria-label="Close"
          title={t("Close")}
        ></Button> */}
      </Modal.Header>
      <Modal.Body
        className={` text-break  px-0 border-0 ${
          bodyclassName ? "" : "text-center mb-3"
        }`}
      >
        {body}
      </Modal.Body>
      {(notext ||
        yestext ||
        letarSendText ||
        nowSendText ||
        extraButtonText) && (
        <Modal.Footer
          className={`border-0 p-0 ${
            footerclass
              ? footerclass
              : `justify-content-${yestext && notext ? "" : "center"}`
          }`}
        >
          {notext && (
            <Button className={ cancelButtonClass || "close-button ms-0 my-0 marginRightPoint5" }
              onClick={cancel}
            >
              {noicon && noicon} {notext}
            </Button>
          )}
          <div className="d-flex" style={{gap:"0.5vw"}}>
            {yestext && (
              <AccessControl
                requiredPermissions={[permissions?.submitPermissions]}
                override={
                  userData.isSuperAdmin || !permissions?.submitPermissions
                }
              >
                <Button
                  className="form-button m-0"
                  onClick={submit}
                >
                  {yesicon && yesicon}
                  {yestext}
                </Button>
              </AccessControl>
            )}
            {extraButtonText && (
              <AccessControl
                requiredPermissions={[permissions?.extraButtonPermissions]}
                override={
                  userData.isSuperAdmin || !permissions?.extraButtonPermissions
                }
              >
                <Button
                  className="shadow-none form-button"
                  onClick={extraButtonFunction}
                >
                  {yesicon && yesicon}
                  {extraButtonText}
                </Button>
              </AccessControl>
            )}
          </div>

          {letarSendText && (
            <Button
              className={
                "btn submit-btn shadow-none d-flex align-items-center form-button"
              }
              onClick={sendLetar}
            >
              {noicon && noicon} {letarSendText}
            </Button>
          )}
          {nowSendText && (
            <Button
              className="btn submit-btn shadow-none d-flex align-items-center form-button"
              onClick={sendNow}
            >
              {yesicon && yesicon}
              {nowSendText}
            </Button>
          )}
          {approveNowText && (
            <Button
              className="btn submit-btn shadow-none d-flex align-items-center form-button"
              onClick={approveNow}
            >
              {yesicon && yesicon}
              {approveNowText}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default Popup;
