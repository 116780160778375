import { Reducer } from 'react';
import * as CONST from '../annotations/CandidateConstants'
import { candidateFormData } from '../annotations/CandidateInterface';
import { FormAction } from './Actions';
import { emergencyContactInitialData, studiesInitialState, workExperienceInitialState } from '../helpers/CandidateHelperFunctions';

export const CandidateReducer: Reducer<candidateFormData, FormAction> = (
  state: any,
  action: any
) => {
  switch (action.type) {
    case CONST.NEXT_STEP:
      const currentTab = state.tabDetails.find((tab: any) => tab.showStatus);
      if (currentTab) {
        const currentIndex = state.tabDetails.findIndex(
          (tab: any) => tab.id === currentTab.id
        );
        const nextIndex = (currentIndex + 1) % state.tabDetails.length;

        const updatedTabDetails = state.tabDetails.map((tab: any) => ({
          ...tab,
          showStatus: tab.id === state.tabDetails[nextIndex].id,
        }));

        return {
          ...state,
          tabDetails: updatedTabDetails,
        };
      }
      return state;

    case CONST.PREVIOUS_STEP:
      const currentTabPrev = state.tabDetails.find(
        (tab: any) => tab.showStatus
      );
      if (currentTabPrev) {
        const currentIndexPrev = state.tabDetails.findIndex(
          (tab: any) => tab.id === currentTabPrev.id
        );
        const previousIndex =
          (currentIndexPrev - 1 + state.tabDetails.length) %
          state.tabDetails.length;

        const updatedTabDetailsPrev = state.tabDetails.map((tab: any) => ({
          ...tab,
          showStatus: tab.id === state.tabDetails[previousIndex].id,
        }));

        return {
          ...state,
          tabDetails: updatedTabDetailsPrev,
        };
      }
      return state;

    case CONST.UPDATE_TAB_DETAILS:
      return {
        ...state,
        tabDetails: action.tabDetails,
      };

    case CONST.UPDATE_TAB_ERROR:
      const { tabIndex, error } = action;
      return {
        ...state,
        tabDetails: state.tabDetails.map((tab: any, index: any) => ({
          ...tab,
          error: index === tabIndex ? error : tab.error,
        })),
      };

    case CONST.REMOVE_CERTIFICATE:
      const existingCertificates = [...state.certificates];

      // Remove the item at the specified index
      existingCertificates.splice(action.indexToRemove, 1);

      // Return the new state with updated details
      return {
        ...state,
        certificates: existingCertificates,
      };

    case CONST.ADD_CERTIFICATE:
      const { data: certificateData, index: certificateIndex } = action;
      const newCerticates = [
        ...state.certificates,
      ];
      // Update the specific coefficient at the given index
      newCerticates[certificateIndex] = {
        ...certificateData,
      };
      // Return the new state, updating details specifically
      return {
        ...state,
        certificates: newCerticates
      };

    case CONST.REMOVE_DOCUMENT:
      const existingDocuments = [...state.documents];

      // Remove the item at the specified index
      existingDocuments.splice(action.indexToRemove, 1);

      // Return the new state with updated details
      return {
        ...state,
        documents: existingDocuments,
      };

    case CONST.ADD_DOCUMENT:
      const { data: documentData, index: documentIndex } = action;
      const newDocuments = [
        ...state.documents,
      ];

      // Update the specific coefficient at the given index
      newDocuments[documentIndex] = {
        ...documentData,
      };
      // Return the new state, updating details specifically
      return {
        ...state,
        documents: newDocuments
      };

    case CONST.ADD_STUDY_FIELDS_SET:
      return {
        ...state, studies: [...state.studies, studiesInitialState], studyErrors: [...state.studyErrors, {
          educationLevel: "", completed: ""
        }]
      }

    case CONST.REMOVE_STUDY_FIELDS_SET:
      const existingStudies = [...state.studies];
      existingStudies.splice(action.indexToRemove, 1);
      return {
        ...state,
        studies: existingStudies
      }

    case CONST.UPDATE_STUDY_FIELD:
      return {
        ...state,
        studies: state.studies.map((study: any, index: number) => {
          if (index === action.index) {
            return {
              ...study,
              [action.field]: action.value,
            };
          }
          return study;
        }),
      };

    case CONST.ADD_EXPERIENCE_FIELDS_SET:
      return {
        ...state, workExperience: [...state.workExperience, workExperienceInitialState],
        workExperienceErrors: [...state.workExperienceErrors, {
          email: '',
          phoneNumber: ''
        }]
      };

    case CONST.REMOVE_EXPERIENCE_FIELDS_SET:
      const existingExperiences = [...state.workExperience];
      existingExperiences.splice(action.indexToRemove, 1);
      const existingExpErrors = [...state.workExperience];
      existingExpErrors.splice(action.indexToRemove, 1);
      return { ...state, workExperience: existingExpErrors, workExperienceErrors: existingExpErrors };

    case CONST.UPDATE_EXPERIENCE_FIELD:
      return {
        ...state,
        workExperience: state.workExperience.map((experience: any, index: number) => {
          if (index === action.index) {
            return {
              ...experience,
              [action.field]: action.value,
            };
          }
          return experience;
        }),
      };

    case CONST.UPDATE_FIELD:
      return {
        ...state,
        [action.tab]: { ...state[action.tab], [action.field]: action.value }
      };

    case CONST.ADD_EMERGENCY_CONTACT_FIELDS:
      return {
        ...state, emergencyContactDetails: [...state.emergencyContactDetails, emergencyContactInitialData],
        emergencyContactErrors: [...state.emergencyContactErrors, {
          emergencyContactName: "",
          emergencyContactEmail: "",
          emergencyContactPhoneNumber: "",
        }]
      };

    case CONST.REMOVE_EMERGENCY_CONTACT_FIELDS:
      const existingEmergencyDetails = [...state.emergencyContactDetails];
      existingEmergencyDetails.splice(action.indexToRemove, 1);
      const existingEmerErrors = [...state.emergencyContactErrors];
      existingEmerErrors.splice(action.indexToRemove, 1);

      return {
        ...state,
        emergencyContactDetails: existingEmergencyDetails,
        emergencyContactErrors: existingEmerErrors
      }

    case CONST.UPDATE_EMERGENCY_CONTACT_DETAILS:
      return {
        ...state,
        emergencyContactDetails: state.emergencyContactDetails.map((contactDetails: any, index: number) => {
          if (index === action.index) {
            return { ...contactDetails, [action.field]: action.value };
          }
          return contactDetails;
        })
      };

    case CONST.UPDATE_EMERGENCY_CONTACT_FIELD_ERROR:
      return {
        ...state,
        emergencyContactErrors: state.emergencyContactErrors.map((error: any, index: number) => {
          if (index === action.index) {
            return {
              ...error,
              [action.field]: action.error,
            };
          }
          return error;
        }),
      };

    case CONST.UPDATE_STUDIES_ERROR:
      return {
        ...state,
        studyErrors: state.studyErrors.map((error: any, index: number) => {
          if (index === action.index) {
            return {
              ...error,
              [action.field]: action.error,
            };
          }
          return error;
        }),
      };

    case CONST.UPDATE_WORK_EXPERIENCE_ERROR:

      return {
        ...state,
        workExperienceErrors: state.workExperienceErrors.map((error: any, index: number) => {
          if (index === action.index) {
            return {
              ...error,
              [action.field]: action.error,
            };
          }
          return error;
        }),
      };

    case CONST.UPDATE_DOMICILE_ADDRESS_FIELDS:
      const updatedAddress = {
        'domStreet': state.general.street,
        'domNumber': state.general.number,
        'domBox': state.general.box,
        'domZipCode': state.general.zipCode,
        'domCity': state.general.city,
        'domCountry': state.general.country,
      };
      const initialAddress = {
        'domStreet': '',
        'domNumber': '',
        'domBox': '',
        'domZipCode': '',
        'domCity': '',
        'domCountry': null,
      }
      return {
        ...state,
        general: {
          ...state.general, ...(action.checked ? updatedAddress : initialAddress)

        }
      };

    case CONST.UPDATE_ERROR_FIELD:

      return { ...state, errors: { ...state.errors, [action.field]: action.error } };

    case CONST.SET_DROPDOWN_DATA:
      return {
        ...state,
        dropDownData: {
          ...state.dropDownData,
          ...action.payload
        }
      };

    case CONST.SET_COMPETENCE_DATA:
      return {
        ...state,
        dropDownData:
        {
          ...state?.dropDownData,
          competence: action.payload
        },
      };

    case CONST.UPDATE_TAB_DATA:
      const { tab, data } = action;
      return {
        ...state,
        [tab]: {
          ...state[tab],
          ...data
        }
      };

    case "EDIT_FIELD":
      const { index: editIndex, field: editField, value: editValue, tab: editTab } = action;
      const updatedList = [...state[editTab]];
      // Update the specific item in the list
      updatedList[editIndex] = {
        ...updatedList[editIndex],
        [editField]: editValue,
      };
      // Return the updated state with the modified list under the specific tab
      return {
        ...state,
        [editTab]: updatedList,
      };

    case CONST.EDIT_STUDY_FIELD:
      const { index: studyIndex, field: studyField, value: studyValue } = action;
      // If the index matches the current length of the studies array, add a new study and errors object
      if (studyIndex === state.studies.length) {
        const newStudy = {
          educationLevel: null
        };
        const newStudyErrors = {
          educationLevel: ''
        };
        return {
          ...state,
          studies: [...state.studies, newStudy],
          studyErrors: [...state.studyErrors, newStudyErrors],
        };
      }
      return state;

    case CONST.COMPETENCE_CHANGE:
      return {
        ...state,
        competence: action?.field
          ? competenceSelected(state, action)
          : (action?.payload?.index == undefined ? competenceInput(state, action) : competenceRange(state, action)),
      };


    case CONST.RESET_ALL_FIELDS:
      return {
        ...state,
        general: {
          ...state.general, firstName: '', lastName: '', email: '',
          phoneNumber: '',
          dob: null,
          gender: null,
          street: '',
          number: '',
          box: '',
          zipCode: '',
          country: null,
          city: ''
        },
        studies: [studiesInitialState],
        workExperience: [workExperienceInitialState],
        jobProfile: { ...state.jobProfile, desiredJobsInfo: '' }
      };

    // case CONST.COMPETENCE_CHANGE:
    //   return {
    //     ...state,
    //     competence: action?.field ? competenceSelected(state, action) : competenceRange(state, action)
    //   }
    default:
      return state;
  }
};


export const competenceSelected = (state: any, action: any) => {

  const selection = action.payload.selection;
  const selectedData = [...action.payload.selected];
  const selectedId = action.payload.id;
  let updatedForm: any = [...state.competence];
  if (selectedData.length === 0) {
    // Remove the item if selectedData is empty
    updatedForm = updatedForm.filter((item: { key: any }) => item.key !== selection);
  } else {
    const existingSelection = updatedForm.find((item: { key: any }) => item.key === selection);
    if (existingSelection) {
      // Update existing selection options
      const filteredData = selectedData.map((eachItem: any) => {
        const optionData = existingSelection.options.find((eachIt: any) => (eachIt.value == eachItem.value))
        if (optionData) {
          return { ...eachItem, range: optionData.range };
        }
        return eachItem;
      });
      existingSelection.options = filteredData;
    } else {
      // Add new selection
      const newSelection = {
        competence_id: selectedId,
        key: selection,
        label: selection,
        options: selectedData,
      };
      updatedForm.push(newSelection);
    }
  }
  // Return  updated competence array
  return updatedForm;
};

// Reducer function to handle COMPETENCE_RANGE action
export const competenceRange = (state: any, action: any) => {
  const { value, section, index } = action.payload;
  let updatedForm: any = [...state.competence];
  updatedForm.forEach((item: any) => {
    if (item.key === section && item.options[index]) {
      item.options[index].range = value; // Update range
    }
  });
  // Return updated competence array
  return updatedForm;
};

//Function to update input text fields in competence
export const competenceInput = (state: any, action: any) => {
  const { value, section } = action.payload;

  let currentState: any = [...state?.competence];

  const updatedData = currentState?.map((eachItem: any) => {
    if (eachItem?.key?.toLowerCase() == section.toLowerCase()) {
      return { ...eachItem, additionalInfo: value };
    }
    return eachItem;
  });
  return updatedData;
}


