import { TIMESHEET_MODAL_ACTIONS } from "../../actions/TimesheetModalActions";

export const TimesheetModalReducer = (modalData, actions) => {
  switch (actions.type) {
    case TIMESHEET_MODAL_ACTIONS.OPEN_AND_CLOSE_MODAL:
      return updateModalStatus(modalData, actions.payload);

    case TIMESHEET_MODAL_ACTIONS.UPDATE_MODAL_DATA:      
      return updateModalData(modalData, actions.payload);
  }
};
function updateModalStatus(modalData, data) {      

  // Return a new modalData object with the updated modalType data
  let updatedModalData = {
    ...modalData,
    [data.modalType]: {
      ...modalData[data.modalType],
      isOpen: data.status,
      date: data.date,
      planId: data?.planId,
      data: data?.data ?? [],
    },
    index: data?.index ?? null
  };
  return updatedModalData;
}

function updateModalData(modalData, data) {  
  let updatedData = {
    ...modalData,
    [data.modalType]: {
      ...modalData[data.modalType],
      data: data?.data
    }
  };
  return updatedData;
}