import React, { useEffect, useState } from "react";
import { PLANNING_MODAL_ACTIONS } from "../planning-redux/actions/PlanningModalActions";
import { usePlanningsModalDispatch } from "../planning-redux/context/PlanningModalContext/PlanningModalContext";
import { SALES_VIEW_EXCEPTION_LIST } from "../constants/planningConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dateUtils } from "../utils/dateUtils";

const PlanningDateFilters = ({ weeklydates, navigateTo}) => {
    const groupBy = (data, key) => {
        return data.reduce((acc, item) => {
          const value = item[key];
          if (!acc[value]) {
            acc[value] = { value, count: 1 };
          } else {
            acc[value].count += 1;
          }
          return acc;
        }, {});
      };
    
      // Group weeklydates by month and week
      const monthGroups = groupBy(weeklydates, 'month');
      const weekGroups = groupBy(weeklydates, 'week');
    
      return (
        <table className="table border border-white table-bordered weeklyPlaniingTable mb-0">
          <thead className="sidebar_bg">
            {/* Month row */}
            <tr className="border-0 planningTableHeader">
              {/* <th colSpan={weeklydates.length} className="border-0">
                <div onClick={(e) => navigateTo(e, 'prev', 'month')}><FontAwesomeIcon icon="fa-solid fa-chevron-left" /></div>
              </th> */}
              {Object.values(monthGroups).map((month, index) => (
                <th
                  key={index}
                  className="border border-white align-middle p-0 text-left planningMonthHeaderContent"
                  colSpan={month.count}
                >
                  {month.value}
                </th>
              ))}
              <th 
              // {/*colSpan={weeklydates.length} */} 
              className="border-0">
                <span onClick={(e) => navigateTo(e, 'next', 'month')} className="cursor-pointer"><FontAwesomeIcon icon="fa-solid fa-chevron-right" /></span>
              </th>
            </tr>
    
            {/* Week row */}
            <tr className="border-0 planningTableHeader planningMonthHeader">
              {/* <th colSpan={weeklydates.length} className="border-0">
                <span onClick={(e) => navigateTo(e, 'prev', 'week')}><FontAwesomeIcon icon="fa-solid fa-chevron-left" /></span>
              </th> */}
              {Object.values(weekGroups).map((week, index) => (
                <th
                  key={index}
                  className="border border-white align-middle p-0 text-left planningMonthHeaderContent"
                  colSpan={week.count}
                >
                  {week.value}
                </th>
              ))}
              <th colSpan={weeklydates.length} className="border-0">
                <span onClick={(e) => navigateTo(e, 'next', 'week')} className="cursor-pointer"><FontAwesomeIcon icon="fa-solid fa-chevron-right" /></span>
              </th>
            </tr>
    
            {/* Date row */}
            <tr className="border-0 planningTableHeader planningWeekly">
              {/* <th colSpan={weeklydates.length} className="border-0">
                <span onClick={(e) => navigateTo(e, 'prev', 'date')}><FontAwesomeIcon icon="fa-solid fa-chevron-left" /></span>
              </th> */}
              {/* #d6e3e5 */}
              {weeklydates.map((date, index) => {
                const isweekend = dateUtils.checkIsWeekend(date.value);
                return(
                <th
                  key={index}
                  className="border border-white align-middle p-0 text-center planningMonthHeaderContent"
                  style={{
                  backgroundColor: isweekend ? "#cad6d9" : null }}
                >
                  {date.label}
                </th>
               )})}
              <th colSpan={weeklydates.length} className="border-0">
                <span onClick={(e) => navigateTo(e, 'next', 'date')} className="cursor-pointer"><FontAwesomeIcon icon="fa-solid fa-chevron-right" /></span>
              </th>
            </tr>
          </thead>
        </table>
      );
  };
  
  export default PlanningDateFilters;
  
