import React from "react";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import ManagePayrollOverviewOrganism from "./organisms/ManagePayrollOverviewOrganism";

export default function ManagePayRoll() {
    const userData = useSelector(selectAuth);
    return (
        <AccessControl
            requiredPermissions={[{
                permission: "PayRoll",
                read: true,
            }]}
            actions={true}
            strict={false}
            override={userData.isSuperAdmin}
        >
            <ManagePayrollOverviewOrganism />
        </AccessControl>
    );
}
