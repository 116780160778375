import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import LabelField from "./LabelField";
import { Option } from "components/common/CommonInterfaces";

// interface Option {
//   value?: number | string | null;
//   label?: string;
// }

/**
 * @property onChange
 * Expects a function to handle the changes.
 * value: Option | Option[]
 * actionMetadata: ActionMeta<any>, passes metadata like field name, action name, etc.
 */
interface DropdownSelectProps {
  search: boolean;
  options: any;
  isMulti?: boolean;
  value?: any;
  // todo: update value: type
  //* onChange: (value: Option | Option[], actionMetadata?: ActionMeta<any>) => void;
  // onChange: (value: any, actionMetadata?: ActionMeta<any>) => void;
  onChange: (value: any) => void;
  placeHolder: string;
  name: string;
  id?: string;
  error?: number | null | undefined | string;
  className?: string;
  containerClassName?: string;
  title?: string;
  isMandatory?: boolean;
  isDisabled?: boolean;
  isMenuPlacement?: boolean;
  isClearable?: boolean;
  emptyOptionNotRequired?: boolean;
  labelClassName?: string;
  style?: any;
  onOptionsLengthChange?: (length: number) => void; // New prop for length
}

/**
 * Dropdown field with label and search and multi-select feature.
 *
 * For a simple dropdown use: `src/components/molecules/LabelwithSelectField.tsx`
 *
 * @param properties DropdownSelectProps
 *
 * @example
 * <SelectWithSearch
  onChange={}
  title={t("")}
  placeHolder={t("")}
  search={true}
  options={}
  value={}
  isDisabled={false}
  isMulti={true}
  isMandatory={true}
  // className={""}
  error={}
  name={"myFieldName"}
  id={"myFieldId"}
></SelectWithSearch>;
 *
 */
const SelectWithSearch: React.FC<DropdownSelectProps> = ({
  search,
  options,
  isMulti,
  value,
  id,
  onChange,
  placeHolder,
  name,
  error,
  className,
  containerClassName = "position-relative",
  title,
  isMandatory,
  isDisabled = false,
  isMenuPlacement = false,
  isClearable = false,
  emptyOptionNotRequired = false,
  labelClassName,
  style,
  onOptionsLengthChange
}) => {
  const defaultOption: Option = { value: "", label: "Select" };
  const updatedOptions = [defaultOption, ...(options ? options : [])];
  const [filteredOptions, setFilteredOptions] = useState(options);

  const getValues = (value: any, list: any, isMulti: any) => {
    if (value && (typeof value === "object") && (value?.[0]?.value || !isMulti)) {
      return value;
    } else if (isMulti && value) {
      return value?.map((item: any) => {
        return list?.find((option: Option) => option?.value == item);
      })
    }
    let selectedObj = list?.find((option: Option) => option?.value == value);
    return selectedObj !== undefined ? selectedObj : "";
  };
  const menuPortalTarget = useRef(document.body);

  const handleInputChange = (inputValue: string) => {
    const filtered = options.filter((option: Option) =>
      (option?.label?.toLowerCase() ?? "").includes(inputValue.toLowerCase()) // Ensuring label is defined
    );
    setFilteredOptions(filtered); // Update the filtered options state
  };

  useEffect(() => {
    if (onOptionsLengthChange && filteredOptions.length >= 0) {
      onOptionsLengthChange(filteredOptions.length); // Pass filtered options length to parent
    }
  }, [filteredOptions, onOptionsLengthChange]);

  return (
    <div className={containerClassName}>
      {!!title && <LabelField title={title} isMandatory={isMandatory} className={labelClassName} />}

      <Select
        isSearchable={search}
        id={id}
        className={`form-control p-0 form-select field-shadow  select-field ${className}`}
        options={isMulti || emptyOptionNotRequired ? options : updatedOptions}
        isMulti={isMulti}
        value={getValues(value, options, isMulti)}
        // value={value}
        placeholder={placeHolder}
        onChange={onChange}
        onInputChange={handleInputChange} // Attach the input change handler for the search functionality
        name={name}
        isClearable={isClearable}
        isDisabled={isDisabled}
        menuPlacement={isMenuPlacement ? "top" : "auto"}
        menuPortalTarget={menuPortalTarget.current}
        classNamePrefix="multiSelectDropdown"
        // menuIsOpen
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            ...style,
            border: "0",
            minHeight: style?.minHeight || "2.25vw", // Ensure minHeight is applied from style prop or use fallback
          }),
          menu: (provided: any, state) => ({
            ...provided,
            overflow: "visible",
          }),
          menuPortal: (base: any) => ({ ...base, zIndex: 99999999 }),
          menuList: (provided: any, state) => ({
            ...provided,
            maxHeight: "150px",
            overflowY: "auto",
            zIndex: "99999999",
          }),
          singleValue: (baseStyles: any, state) => ({
            ...baseStyles,
            color: isDisabled ? "#212529" : baseStyles.color,
          }),
          multiValue: (baseStyles: any, state) => ({
            ...baseStyles,
            color: isDisabled ? "#212529" : baseStyles.color,
          }),
          indicatorSeparator: (baseStyles: any) => ({
            ...baseStyles,
            display: "none",
          }),
        }}
      // menuIsOpen
      />
      <div
        className="height-20 text-danger"
        style={{ marginBottom: "0.25vw", marginTop: "0.25vw" }}
      >
        {error && (error !== undefined ? error : "")}
      </div>
    </div>
  );
};

export default SelectWithSearch;
