export const PLANNING_ACTIONS = {
    SET_PLANNING_VIEW: 'SET_PLANNING_VIEW',
    SET_PLANNING_EVENTS: 'SET_PLANNING_EVENTS',
    SET_TYPE: 'SET_TYPE',
    SET_SALES_VIEW: 'SET_SALES_VIEW',
    SET_CURRENT_VIEW_UNIT:'SET_CURRENT_VIEW_UNIT',
    UPDATE_PLANNING_EVENTS: 'UPDATE_PLANNING_EVENTS',
    UPDATE_CURRENT_VIEW_YEAR: 'UPDATE_CURRENT_VIEW_YEAR',
    UPDATE_MUTIPLE_FIELDS: 'UPDATE_MUTIPLE_FIELDS',
    UPDATE_CELL_STATE: 'UPDATE_CELL_STATE'
}