import React, { useEffect, useState, useRef } from "react";
import PlanningBox from "../atoms/planningBox";
import { useEmployee } from "../planning-redux/context/EmployeeContext/EmployeeContext";
import { usePlanning, usePlanningsDispatch } from "../planning-redux/context/PlanningContext/PlanningContext";
import { PLANNING_MODAL_ACTIONS } from "../planning-redux/actions/PlanningModalActions";
import { getRandomInt, renderPlannedEvents } from "../utils/utils";
import { usePlanningsModalDispatch } from "../planning-redux/context/PlanningModalContext/PlanningModalContext";
import { SALES_VIEW_EXCEPTION_LIST } from "../constants/planningConstants";
import { PLANNING_ACTIONS } from "../planning-redux/actions/PlanningActions";
import { useFilterOptions } from '../planning-redux/context/FiltersContext/FiltersContext';
import CustomNotify from "components/atoms/CustomNotify";
import { getTitle } from "../utils/utils";

const PlanningWeekly = ({ weeklyDates, openPlanningModal, savePlanning, type, status }) => {
  const { initialEmployeeState } = useEmployee();
  const { filterOptionsState } = useFilterOptions();
  const { planningState } = usePlanning();
  const { dispatchPlanning } = usePlanningsDispatch();
  const { dispatchPlanningModal } = usePlanningsModalDispatch();

  const [highlightedDiv, setHighlightedDiv] = useState(null);
  const cardRef = useRef(null);
  const [detailslist, setDetails] = useState({
    employee_id: null,
    date: null,
    plan: null
  });
  const [contextMenuId, setContextMenuId] = useState(null);
  let clickTimer = null;
  const [params, setParams] = useState({
    employee: "",
    project: "",
  });
  const [tooltipPosition, setTooltipPosition] = useState("bottom right");

  const setMenuPosition = (e) => {
    if (cardRef.current) {
      const { top, bottom, left, right } = cardRef.current.getBoundingClientRect();
      // const spaceBelow = right - e.clientY;
      // const spaceAbove = e.clientY- top;
      const spaceBelow = window.innerHeight - bottom;
      const spaceAbove = top;
      const spaceRight = right - e.clientX;
      const spaceLeft = e.clientX - left;
      let classname = '';
      if (spaceBelow < 200 && spaceAbove > 200) {
        classname = "top"
      } else {
        classname = "bottom"
      }
      if (spaceRight < 200 && spaceLeft > 200) {
        classname = classname + " left"
      } else {
        classname = classname + " right"
      }
      setTooltipPosition(classname)
    }
  }

  // useEffect(() => {
  //   window.addEventListener('keydown', handleKeyDown);
  //   return () => window.removeEventListener('keydown', handleKeyDown); // Clean up event listener
  // }, [highlightedDiv]);
  useEffect(() => {
    const container = document.getElementById('weeklyPlaniingTable-mouse-events');
    if (container) {
      container.tabIndex = 0;
      container.addEventListener('keydown', handleKeyDown);
      container.focus();
    }
    return () => {
      if (container) {
        container.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [highlightedDiv]);
  


  const handleClick = (e, id, employeeid, date, plan = null) => {
    e.preventDefault()
    clearTimeout(clickTimer);
    setContextMenuId(null);
    clickTimer = setTimeout(() => {
      if (SALES_VIEW_EXCEPTION_LIST.includes(employeeid)) return;
      updateBulkPlanningOptions(employeeid, date, id, plan)
      // if (highlightedDiv == id) {
      //   console.log(highlightedDiv);
      //   console.log(id);

      //   setHighlightedDiv(null);
      // } else {
      setHighlightedDiv(id);
      // }
      setDetails({
        ...detailslist,
        employee_id: employeeid,
        plan: plan,
        date: date
      })
    }, 250); // Adjust timeout duration if necessary
  };

  const handleRightClick = (e, id, employeeid, date, plan = null) => {
    e.preventDefault();
    console.log(id);
    if (SALES_VIEW_EXCEPTION_LIST.includes(employeeid)) return;
    updateBulkPlanningOptions(employeeid, date, id, plan)
    setHighlightedDiv(id);
    setDetails({
      ...detailslist,
      employee_id: employeeid,
      plan: plan,
      date: date
    })
    setMenuPosition(e)
    setContextMenuId(id);
  };


  const handleKeyDown = (e) => {
    e.preventDefault();
    // if (!highlightedDiv) return;
    switch (true) {
      case e.ctrlKey && e.key === 'c':
        handleCopy(e)
        break;
      case e.ctrlKey && e.key === 'v':
        handlePaste(e)
        break;
      case e.key === 'Delete':
        handleDelete(e)
        break;
      default:
        break;
    }
  };

  const handleWeeklyClickEventOnPlannedUnit = (
    e, employee_id, date, id, plan
  ) => {
    clearTimeout(clickTimer);
    if (SALES_VIEW_EXCEPTION_LIST.includes(employee_id)) return;
    clearStates()
    createNewPlanning(e, employee_id, date, plan)
  };

  const createNewPlanning = (e, employee_id, date, plan) => {
    dispatchPlanningModal({
      type: PLANNING_MODAL_ACTIONS.BULK_UPDATE_PLANNING_MODAL_DEPENDENT_FIELD,
      payload: {
        employee_id: employee_id,
        start_date: date,
        frequency: "weekly",
      },
    });
    if (plan != "" && plan != null) {
      updatePlanningModalId(plan.planid)
    }
    let planid = plan != null ? plan.planid : null;
    openPlanningModal(e, planid);
  }

  const setWeeklyPlanningUnit = () => {
    if (
      initialEmployeeState.employees &&
      initialEmployeeState.employees.length > 0
    ) {
      return initialEmployeeState.employees.map((employee) => (
        <tr key={getRandomInt(1, 1000)} className={(employee.id == 'total' ? "planningCostSticky" : "")}>
          {/* <td></td> */}
          {weeklyDates.map((date) => (
            <td
              key={getRandomInt(1, 1000)}
              style={{
                position: "relative",
              }}
            >
              <PlanningBox
                key={getRandomInt(1, 1000)}
                date={date["value"]}
                employee_id={employee.id}
                bg_color={"#ebebeb"}
                handleClick={handleClick}
                handleRightClick={handleRightClick}
                highlightedDiv={highlightedDiv}
                handleWeeklyClickEventOnPlannedUnit={
                  handleWeeklyClickEventOnPlannedUnit
                }
                handleAction={handleAction}
                tooltipPosition={tooltipPosition}
                contextMenuId={contextMenuId}
              />
              {renderPlannedEvents(
                planningState,
                employee,
                date,
                highlightedDiv,
                handleWeeklyClickEventOnPlannedUnit,
                handleClick,
                handleRightClick,
                handleAction,
                contextMenuId,
                tooltipPosition
              )}
            </td>
          ))}
          <td></td>
        </tr>
      ));
    }
  };

  const handlePaste = (e) => {
    console.log(planningState.copydetails);
    if (planningState.copydetails == null) return;
    console.log(planningState.copydetails == null);
    savePlanning(e, false, null, true)
  }

  const handleDelete = (e) => {

    let celstate = planningState.cellState
    if (celstate.plan == null) return;
    updatePlanningModalId(celstate.plan.planid)
    savePlanning(e, true, celstate.plan.planid)
  }

  const updatePlanningModalId = (planid) => {
    dispatchPlanningModal({
      type: PLANNING_MODAL_ACTIONS.UPDATE_PLANNING_MODEL_FIELD,
      payload: {
        field: "id",
        value: planid,
      },
    });
  }
  const handleAction = (e, type, plan) => {
    e.preventDefault();
    clearStates()
    switch (type) {
      case 'new':
        createNewPlanning(e, detailslist.employee_id, detailslist.date, null)
        break;
      case 'copy':
        handleCopy(e)
        break;
      case 'delete':
        handleDelete(e)
        break;
      case 'paste':
        handlePaste(e)
        break;
      default:
        break;
    }
  }

  const updateBulkPlanningOptions = (employee, date, id, plan) => {
    let payload = {
      plan: plan,
      id: id,
      employee: employee,
      date: date
    }
    dispatchPlanning({
      type: PLANNING_ACTIONS.UPDATE_CELL_STATE,
      payload: payload,
    });
  }

  const handleCopy = (e) => {
    e.preventDefault();
    let celstate = planningState.cellState
    if (celstate.plan == null) return;
    let payload = {
      copydetails: celstate.plan
    }
    planningActionsState('UPDATE_MUTIPLE_FIELDS', payload)
    let companyname = getTitle(filterOptionsState.state.companies, celstate.plan.company_id)
    let projectname = getTitle(filterOptionsState.state.projects, celstate.plan.project_id)
    let employeename = getTitle(initialEmployeeState.employees, celstate.employee, null, 'id', 'name')

    let message = (
      <div>
        <div>Planning copied:</div>
        <div>{projectname}</div>
        <div>{celstate.plan.plan_date}</div>
        <div>{`${celstate.plan.start_time} - ${celstate.plan.end_time}`}</div>
        <div></div>
        <div>{employeename}</div>
      </div>
    );
    CustomNotify({ type: "success", message: message });
  }

  const planningActionsState = (type, payload) => {
    dispatchPlanning({
      type: PLANNING_ACTIONS[type],
      payload: payload,
    });
  }
  const clearStates = () => {
    setHighlightedDiv(null);
    setContextMenuId(null);
  }

  return (
    <>
      <table className="table border border-white table-bordered weeklyPlaniingTable mb-0" id="weeklyPlaniingTable-mouse-events">
        <tbody ref={cardRef} >{setWeeklyPlanningUnit()}</tbody>
      </table>
      {/* {optionsVisible && (
        <PlanningContextMenu
          handleWeeklyClickEventOnPlannedUnit={handleWeeklyClickEventOnPlannedUnit}
          handleAction={handleAction}
          employee_id={detailslist.employee_id}
          date={detailslist.date}
          plan={detailslist.plan} />
      )} */}
    </>
  );
};

export default PlanningWeekly;
