import { M_IDENTITY_MANAGER } from "constants/Constants";
import { GET_PARSING_DETAILS } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { FillCandidateForm } from "./SetCandidateData";

export const SetCvParsingData = async (cvUrl: any, dispatch: any) => {
  const postData = {
    cvUrl: cvUrl,
  };

  const response = await ApiCall.service(
    GET_PARSING_DETAILS,
    "POST",
    postData,
    true,
    M_IDENTITY_MANAGER
  );

  if (response?.status === 200) {
    response?.data && FillCandidateForm(response?.data, dispatch);
  }
};
