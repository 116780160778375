import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "components/atoms/Button";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import { PATH_CREATE_FLEET_LOCATION, PATH_CREATE_SUPPLIER, PATH_EDIT_FLEET_LOCATION, PATH_EDIT_SUPPLIER } from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import {

  FLEET_LOCATION_DELETE,
  PROJECTS_OPTIONS,
  SUPPLIER_DELETE,
} from "routes/ApiEndpoints";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import Pagination from "components/atoms/Pagination";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import FilterElementsRender from "../FilterElement";
import { fetchOptions } from "../utils/FetchOptions";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FilterOrganism from "components/organism/FilterOrganism";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { FleetLocationTableHeader } from "TableHeader";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";

interface ManageSupplierProps {
  data?: any;
  setMaterialData: any;
  handleGetVacany: any;
  dispatch: any;
  history: any;
  pageData: any;
  handleRefresh: () => void;
}

const ManageFleetLocationPage: React.FC<ManageSupplierProps> = ({
  data,
  setMaterialData,
  dispatch,
  history,
  pageData,
  handleRefresh,
  handleGetVacany,
}) => {
  const [id, setId] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    countries: [],
  });

  const navigate = useNavigate();

  const handleSaveChanges = async () => {
    setShowModal(false);
    let postData = {
      id: id,
    };

    if (id) {
      const response = await ApiCall.service(
        FLEET_LOCATION_DELETE,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleRefresh();
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  useEffect(() => {
    getFilterData();
  }, []);

  const getFilterData = async () => {
    try {
      const optionsResponse = await ApiCall.service(
        PROJECTS_OPTIONS,
        'POST',
        { options: ["Country"] },
        false,
        M_MASTER_DATA,
      );
      if (optionsResponse?.status == 200) {
        setFilterOptions((prevOptions) => ({ ...prevOptions, countries: optionsResponse?.data?.Country }))
      }
      else {
        CustomNotify({ type: "warning", message: optionsResponse.message })
      }
    }
    catch (error) {
      console.log("Error");
    }
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleEdit = (id: number) => {
    navigate(`${PATH_EDIT_FLEET_LOCATION}/${id}`);
  };

  const handleArchive = (id: number) => {
    setShowModal(true);
    setId(id);
  };
  const userData = useSelector(selectAuth);
  const filters = [
    { name: 'locationName', fieldType: "text", placeholder: "Location", filterType: 'search' },
    { name: 'city', fieldType: "text", placeholder: "City", filterType: 'search' },
    { name: 'country', fieldType: "singleSelect", options: filterOptions?.countries, placeholder: "Country", filterType: 'search' },
  ];
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "FleetLocation",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          {/* <Title title={t("Fleet location")} /> */}
          <FilterOrganism
            dispatch={dispatch}
            history={history}
            updatePageFilters={updatePageFilters}
            filters={filters}
            class={{
              rowClass: 'd-grid manageCarSearchCol manageFleetLocationSearchCol',
              searchBtnClass: 'col-2 ms-auto'
            }}
            handleRefresh={handleRefresh}
          />
        </div>

        <div className="position-relative tableMainWrapper">
          <div>
            <AccessControl
              requiredPermissions={[
                {
                  permission: "FleetLocation",
                  create: true,
                },
              ]}
              override={userData.isSuperAdmin}
            >
              <div className="row">
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink={PATH_CREATE_FLEET_LOCATION}
                    title={t("Create fleet location")}
                    icon={faPlus}
                  />
                </div>
              </div>
            </AccessControl>
          </div>
          <div className="table-responsive manage-fleetLocation tableSection">
            <div className="manageSupplierHeight">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    {FleetLocationTableHeader?.map((header) => (
                      <th key={header.alias}>
                        <>
                          {t(header.name)}
                          {header.issort && (
                            <SortAtomForManagePage
                              value={{ alias: header.alias }}
                              dispatch={dispatch}
                              history={history}
                              updatePageFilters={updatePageFilters}
                              handleRefresh={handleRefresh}
                            />
                          )}
                        </>
                      </th>
                    ))}
                    <th style={{ width: "20%" }}>
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "FleetLocations",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                        override={userData.isSuperAdmin}
                      >
                        {t("Actions")}
                      </AccessControl>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data && data?.length > 0 ? (
                    data?.map((record: any, key: any) => {
                      return (
                        <tr key={key}>
                          <td className="align-middle">{record.locationName}</td>
                          <td className="align-middle">{record.city}</td>
                          <td className="align-middle">{record?.countryName}</td>
                          <td className="align-middle">
                            <TableActions
                              isAction={true}
                              handleEdit={handleEdit}
                              handleArchive={handleArchive}
                              value={record.id}
                              permission={"FleetLocations"}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center">
                        {t("No records found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className=""
              style={{ paddingTop: "0.25vw" }}
            >
              <PaginationWithPerPage
                handleRefresh={handleRefresh}
                dispatch={dispatch}
                history={history}
                pageData={pageData}
              />
            </div>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
      </>
    </AccessControl>
  );
};
export default ManageFleetLocationPage

