import TimeField from "react-simple-timefield";
import { useFormContext } from "../context/Context";
import InputTextfield from "components/atoms/InputTextField";
import { t } from "pages/microservices/masterData/translation/Translation";
import { calculateTotalTime } from "services/util/TotalTimeCalculator";

const TableMargin = (props: any) => {

  const { index } = props;
  const { state, dispatch } = useFormContext();
  const { durationAndAllowance } = state?.data;

  const handleChange = (event: any, value: any, index: number, name: string, entityType: string) => {
    dispatch({
      type: "UPDATE_INPUT_FIELDS",
      payload: {
        value,
        entityType,
        name,
        index
      }
    })
  }

  const handleInputChange = (event: any, index: number, entityType: string) => {
    const { name, value } = event.target;
    if (name == 'projectAllowanceHours' || name == 'technicianAllowanceHours') {
      const fieldValue = (/^[0-9.,]*$/).test(value);
      if (fieldValue) {
        dispatch({
          type: "UPDATE_INPUT_FIELDS",
          payload: {
            value,
            entityType,
            name,
            index
          }
        })
      }
    }
    else {
      dispatch({
        type: "UPDATE_INPUT_FIELDS",
        payload: {
          value,
          entityType,
          name,
          index
        }
      })
    }
  }

  const { startTime, endTime, pauseTime, hours } = durationAndAllowance[index];


  return (
    <>
      <tr>
        <td className="text-end">{t('Start - Stop')}</td>
        <td>
          <div className="d-flex align-items-center justify-content-center h-100" style={{ gap: "0.3vw" }}>
            <div className="marginCalculatorTime">
              <TimeField
                value={durationAndAllowance?.[index]?.['startTime']}
                onChange={(event, value) => { handleChange(event, value, index, 'startTime', 'durationAndAllowance') }}
                colon=":"
                showSeconds={false}
                style={{
                  border: '0.052vw solid #dee2e6',
                  width: "100%",
                  borderRadius: "0vw",
                  outline: "0",
                  padding: "0.2vw",
                  textAlign:"right"
                }}
              />
            </div>
            <div className="marginCalculatorTime">
              <TimeField
                value={durationAndAllowance?.[index]?.['endTime']}
                onChange={(event, value) => { handleChange(event, value, index, 'endTime', 'durationAndAllowance') }}
                colon=":"
                showSeconds={false}
                style={{
                  border: '0.052vw solid #dee2e6',
                  width: "100%",
                  borderRadius: "0vw",
                  outline: "0",
                  padding: "0.2vw",
                  textAlign:"right"
                }}
              />
            </div>
          </div>
        </td>
        <td rowSpan={2}>
          <div className="marginAllowanceField">
            <p className="text-end marginBottomPoint5">{t('Hour')}</p>
            <InputTextfield
              type="text"
              handleChange={(event) => { handleInputChange(event, index, 'durationAndAllowance') }}
              placeholder="0,00"
              value={hours} //calculateTotalTime(startTime, endTime, pauseTime)
              name={"projectAllowanceHours"}
              id={`projectAllowanceHours-${index + 1}`}
              error={""}
              className="col-8 form-control"
            />
          </div>
        </td>
        <td rowSpan={2}>
          <div className="marginAllowanceField">
            <p className="text-end marginBottomPoint5">{t('Hour')}</p>
            <InputTextfield
              type="text"
              handleChange={(event) => { handleInputChange(event, index, 'durationAndAllowance') }}
              placeholder="0,00"
              value={calculateTotalTime(startTime, endTime, pauseTime)}
              name={"technicianAllowanceHours"}
              id={`technicianAllowanceHours-${index + 1}`}
              error={""}
              className="col-8 form-control"
            />
          </div>
        </td>
        <td rowSpan={2}></td>
        <td rowSpan={2}></td>
        <td rowSpan={2}></td>
        <td rowSpan={2}></td>
      </tr>
      <tr>
        <td className="text-end">{t('Pause')}</td>
        <td className="marginCalculatorTime">
          <TimeField
            value={durationAndAllowance?.[index]?.['pauseTime']}
            onChange={(event, value) => { handleChange(event, value, index, 'pauseTime', 'durationAndAllowance') }}
            colon=":"
            input={<input value={durationAndAllowance?.[index]?.['pauseTime']} title={state?.errors?.durationAndAllowance?.[index]?.['pauseTime']} className={`${state?.errors?.durationAndAllowance?.[index]?.['pauseTime'] !== '' ? 'border-danger' : ''}`} />}
            showSeconds={false}
            style={{
              border: '0.052vw solid #dee2e6',
              width: "100%",
              borderRadius: "0vw",
              outline: "0",
              padding: "0.2vw",
              textAlign:"right"
            }}
          />
        </td>
      </tr>
    </>
  );
}

export default TableMargin;
