import React from "react";
import ManagePayrollHeaders from "../molecules/ManagePayrollHeaders";

interface WorkPayBillRollFiltersProps {
  handlePrint: (type: string, page?: string) => void;
}

const WorkPayBillRollFilters: React.FC<WorkPayBillRollFiltersProps> = ({
  handlePrint,
}) => {
  return (
    <>
      <ManagePayrollHeaders handlePrint={handlePrint} />
      <div style={{ paddingBottom: "1vw" }}>
        {/* <ManagePayrollFilters /> */}
      </div>
    </>
  );
};

export default WorkPayBillRollFilters;
