import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { t } from "pages/microservices/masterData/translation/Translation";
import React, { useEffect, useState } from "react";
import { useFormContext } from "../../context/Context";
import { functions } from "../../annotations/PCInterface";
import { initialPCFunctionList } from "../../helpers/PCHelperFunctions";
import LabelField from "components/atoms/LabelField";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import { ADD_FUNCTION, FUNCTIONS, REMOVE_FUNCTION, SET_DROPDOWN_DATA, UPDATE_ERROR_FIELD, UPDATE_FIELD, UPDATE_TAB_ERROR } from "../../annotations/PCConstants";
import ModalPopup from "components/atoms/ModalPopup";
import Button from "components/atoms/Button";
import { ValidationRules } from "utils/TypeAnnotations";
import { validateForm, validateNumber, validateRequired } from "services/validation/ValidationService";
import SelectWithSearch from "components/atoms/SelectWithSearch";

export const Functions = () => {
  const { state, dispatch } = useFormContext();
  const [functionList, setFunctionList] = useState<functions>(initialPCFunctionList);
  const [modalPopup, setModalPopup] = useState(false);
  const [index, setIndex] = useState(0);
  const intialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{
    [key: string]: string;
  }>(intialErrors);

  useEffect(() => {
    const data = {
      functionCategoryList: state.categories.map((category: any, index: number) => {
        return {
          value: category.id ?? index,
          label: category.name,
        };
      }),
    };
    dispatch({ type: SET_DROPDOWN_DATA, payload: data });
  }, [modalPopup]);

  const validation = (
    name: string,
    value: string | number | null | boolean | Date | object[] | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      name: [validateRequired],
      minSalary: [validateRequired, validateNumber],
      maxSalary: [validateRequired, validateNumber],
    };
    const validationErrors = validateForm(
      { ...functionList, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleFieldChange = (event: any) => {
    const { name, value } = event.target;
    setFunctionList((prevData: any) => ({
      ...prevData,
      [name]: value
    }));
    validation(name, value, true);
  }

  const addFunction = (length: number) => {
    setIndex(length);
    setModalPopup(true);
    setErrors({});
    setFunctionList(initialPCFunctionList);
  }

  const editData = (index: number) => {
    setIndex(index); // Set the index for dispatch
    setModalPopup(true);
    const FunctionToEdit =
      state.functions[index]; // Get the function to edit
    if (FunctionToEdit) {
      // Populate the  data in the modal fields
      setFunctionList({
        id: FunctionToEdit.id,
        name: FunctionToEdit.name,
        category: FunctionToEdit.category,
        minSalary: FunctionToEdit.minSalary,
        maxSalary: FunctionToEdit.maxSalary,
      });
    }
  };

  const removeFieldSet = (indexToRemove: number) => {
    dispatch({ type: REMOVE_FUNCTION, indexToRemove });
  };

  const handleClosePopup = () => {
    setModalPopup(false);
    setErrors({});
    setFunctionList(initialPCFunctionList);
  }

  const onOkButtonClick = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      dispatch({
        type: ADD_FUNCTION,
        data: functionList,
        index: index,
      });
      dispatch({
        type: UPDATE_TAB_ERROR,
        tabIndex: 1,
        error: false,
      });
      dispatch({
        type: UPDATE_ERROR_FIELD,
        field: 'categoryAndFunctions',
        error: "",
      }); setModalPopup(false); // Close modal
      resetFields();
      setErrors({});
    }
  };

  const resetFields = () => {
    setFunctionList(initialPCFunctionList);
  };

  const handleSelectChange = (event: any, fieldName: string) => {
    const categoryDetails = state.categories.filter((category: any) => category?.name === event?.label);
    setFunctionList((prevData: any) => ({
      ...prevData,
      [fieldName]: event,
      minSalary: categoryDetails[0]?.minSalary ?? '',
      maxSalary: categoryDetails[0]?.maxSalary ?? ''
    }));
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <LabelField title={t(" Add function")} className="tab-subtitle" />
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader bg-white">
                  <th className="border-0 align-middle col">
                    {t("Function name")}
                  </th>
                  <th className="border-0 align-middle col">
                    {t("Maximum salary")}
                  </th>
                  <th className="border-0 align-middle col">
                    {t("Minimum salary ")}
                  </th>
                  <th
                    className="border-0 align-middle col">
                    {t("Action")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {state.functions && state.functions.length > 0 && state.functions.map(
                  (functions, index) => (
                    <tr key={index}>
                      <td
                        className="align-middle text-break"
                        data-label={t("Funtion name")}
                      >
                        {functions.name}
                      </td>
                      <td
                        className="align-middle text-break"
                        data-label={t("Minimum salary")}
                      >
                        {functions.minSalary}
                      </td>
                      <td
                        className="align-middle text-break"
                        data-label={t("Maximum salary")}
                      >
                        {functions.maxSalary}
                      </td>
                      <td className="align-middle text-break table-action-icons">
                        <span
                          title={t("Edit")}
                          onClick={() => editData(index)}
                          className="table-action-btn cursor-pointer shadow-none marginRightPoint5"
                        >
                          <EditIcon />
                        </span>

                        <span
                          title={t("Delete")}
                          onClick={() => removeFieldSet(index)}
                          className="table-action-btn cursor-pointer shadow-none"
                        >
                          <DeleteIcon />
                        </span>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row" style={{ marginTop: "1vw" }}>
        <div className="col me-0">
          <Button
            title={"+ " + t("Add")}
            handleClick={() =>
              addFunction(state.functions.length)
            }
            className="form-button float-end"
          />
        </div>
      </div>
      {modalPopup &&
        <ModalPopup
          show={modalPopup}
          onHide={handleClosePopup}
          title={t("Functions")}
          body={
            <>
              <div className="d-grid" style={{ gridTemplateColumns: "repeat(2, 1fr)", gap: "0 1vw" }}>
                <div>
                  <LabelWithInputField
                    isMandatory={true}
                    name="name"
                    handleChange={(e) => handleFieldChange(e)}
                    value={functionList.name}
                    id="name"
                    label={t("Function name")}
                    type="text"
                    error={errors.name}
                    placeholder="Function name"
                  />
                </div>
                <div>
                  <SelectWithSearch
                    title={t("Category")}
                    isMandatory={true}
                    search={true}
                    options={state.dropDownData.functionCategoryList}
                    onChange={(e) => handleSelectChange(e, "category")}
                    isMulti={false}
                    name="category"
                    value={functionList.category}
                    error={""}
                    placeHolder="Select"
                  />
                </div>
                <div>
                  <LabelWithInputField
                    isMandatory={true}
                    name="minSalary"
                    handleChange={(e) => handleFieldChange(e)}
                    value={functionList.minSalary}
                    id="minSalary"
                    label={t("Minimum salary")}
                    type="text"
                    error={errors.minSalary}
                    placeholder="Minimum salary"
                  />
                </div>
                <div>
                  <LabelWithInputField
                    isMandatory={true}
                    name="maxSalary"
                    handleChange={(e) => handleFieldChange(e)}
                    value={functionList.maxSalary}
                    id="maxSalary"
                    label={t("Maximum salary")}
                    type="text"
                    error={errors.maxSalary}
                    placeholder="Maximum salary"
                  />
                </div>

              </div>
              <div className="text-end">
                <Button
                  title={t('Add')}
                  handleClick={onOkButtonClick}
                  className="form-button"
                />
              </div>
            </>
          }
        />
      }
    </>
  );
}
