import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormContext } from "../../context/Context";
import { COMPETENCES, DRAFT, EMPLOYMENT, GENERAL, JOB_DESCRIPTION, NEXT, NEXT_STEP, OFFER, PREVIOUS_STEP, REQUIREMENT, SUBMIT, UPDATE_FIELD_ERROR, UPDATE_TAB_DETAILS, UPDATE_TAB_ERROR } from "../../annotation/VacancyConstants";
import { scrollToTop, validateMultiSelectField, validateRequired, validateSelectField } from "services/validation/ValidationService";
import { t } from "pages/microservices/masterData/translation/Translation";
import Button from "components/atoms/Button";
import LoadingIcon from "utils/LoadingIcon";
import { checKVdabIsSelectedOrNot } from "../../utils/Utils";
import { VACANCY_CREATE } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { M_MASTER_DATA } from "constants/Constants";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import BackButton from "components/atoms/BackButton";

export interface MandatoryFields {
  [key: string]: string[];
}

interface NavProps {
  validStatus: (value: { isValid: boolean; type: string }) => void;
  isLoading?: boolean;
}

const Navigation: React.FC<NavProps> = ({ validStatus, isLoading = false }) => {
  const navigate = useNavigate();
  const authData = useSelector(selectAuth);
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useFormContext();
  const validateMandatoryFields = (type: string) => {
    const activeTab = state.tabDetails.find((tab) => tab.showStatus);
    const activeTabId = activeTab?.id;

    const mandatoryFieldMapping: { [key: string]: string[] } =
      type?.toLowerCase() === "draft"
        ? {
          general: ["company"],
        }
        : {
          general: ["company", "location", "function", "office", "language", "targetGroup", "consultant"],
          employment: ["shift", "regime", "employment", "contract", "hrs", "vdabTemplate", "vdabCompetences"]
        };

    const errorsByTab: { [tabId: string]: { [fieldName: string]: string }; } = {};
    state.tabDetails.slice(0, state.tabDetails.findIndex((tab) => tab.id === activeTabId) + 1)
      .forEach((tab) => {
        const tabId = tab.id;
        let tabFields = {};
        switch (tabId) {
          case GENERAL:
            tabFields = state.general;
            break;
          case EMPLOYMENT:
            tabFields = state.employment;
            break;
          case OFFER:
            tabFields = state.offer;
            break;
          default:
            break;
        }
        const validationRules: {
          [fieldName: string]: ((
            value: any
          ) => string | null | undefined)[];
        } = {
          company: [validateSelectField],
          location: [validateSelectField],
          function: [validateSelectField],
          targetGroup: [validateSelectField],
          office: [validateSelectField],
          consultant: [validateSelectField],
          language: [validateSelectField],
          capacity: [validateRequired],
          contract: [validateMultiSelectField],
          employment: [validateSelectField],
          hrs: [validateRequired],
          regime: [validateSelectField],
          shift: [validateMultiSelectField],
          vdabTemplate: checKVdabIsSelectedOrNot(state) ? [validateSelectField] : [],
          vdabCompetences: checKVdabIsSelectedOrNot(state) ? [validateMultiSelectField] : []
        };
        const mandatoryFields = (mandatoryFieldMapping[tabId] || []) as string[];
        const errorsInTab: { [fieldName: string]: string } = {};
        for (const fieldName of mandatoryFields) {
          const fieldValidationRules = validationRules[fieldName];
          const fieldValue =
            tabFields[fieldName as keyof typeof tabFields];
          if (fieldValidationRules) {
            for (const rule of fieldValidationRules) {
              const validationError = rule(fieldValue);
              if (validationError) {
                errorsInTab[fieldName] = validationError;
                dispatch({
                  type: UPDATE_FIELD_ERROR,
                  field: fieldName,
                  error: validationError
                });
                break;
              } else {
                dispatch({
                  type: UPDATE_FIELD_ERROR,
                  field: fieldName,
                  error: ""
                });
              }
            }
          }
        }

        if (Object.keys(errorsInTab).length > 0) {
          errorsByTab[tabId] = errorsInTab;
          return false;
        }
      });
    updateTabsStatus(errorsByTab);
    if (Object.keys(errorsByTab).length > 0) {
      return false;
    }

    return true;
  };

  const findTabObjectById = (idToFind: string) => {
    return state.tabDetails.findIndex((tab) => tab.id === idToFind);
  };

  const updateTabsStatus = (errors: object) => {
    const keysToGet = [GENERAL, EMPLOYMENT, COMPETENCES, OFFER, REQUIREMENT, JOB_DESCRIPTION];
    const selectKeys = keysToGet.map((key) => {
      if (errors.hasOwnProperty(key)) {
        const tabIndex = findTabObjectById(key);
        if (tabIndex !== undefined && tabIndex !== null) {
          dispatch({ type: UPDATE_TAB_ERROR, tabIndex, error: true });
        }
        return tabIndex;
      }
      return null;
    }).filter((key) => key !== null);
    const tabIndexes = [0, 1, 2, 3, 4, 5];
    tabIndexes.forEach((index) => {
      if (!selectKeys.includes(index)) {
        dispatch({ type: UPDATE_TAB_ERROR, tabIndex: index, error: false });
      }
    });
  };
  const currentTab = state.tabDetails.find((tab) => tab.showStatus);
  const isGeneralTab = currentTab && currentTab.id === GENERAL;
  const isJobDescriptionTab = currentTab && currentTab.id === JOB_DESCRIPTION;
  const formStatus = state?.general?.formStatus;

  const handleNextStep = () => {
    dispatch({ type: NEXT_STEP });
  };

  const { id } = useParams<{ id: string }>();
  const handleSubmitFormData = async (type: string) => {
    if (type === SUBMIT) {
      setLoading(true);
    }
    const data = {
      id: Number(id) ?? null,
      general: { ...state.general, user_id: authData?.userId, status: type?.toLowerCase() === 'submit' ? 'active' : 'draft' },
      employment: { ...state.employment },
      requirement: { ...state.requirement },
      offer: { ...state.offer },
      jobDescription: { ...state.jobDescription },
      competences: { ...state.competences }
    };
    const response = await ApiCall.service(VACANCY_CREATE, "POST", data, true, M_MASTER_DATA);
    if (response.status === 200) {
      CustomNotify({ type: "success", message: response.message });
      navigate("/vacancies");
    } else {
      CustomNotify({ type: "error", message: t('Some error occurred') });
    }
    setLoading(false);
  }

  const handlePreviousStep = () => {
    dispatch({ type: PREVIOUS_STEP });
  };

  const handleDraft = () => {
    const update = state.tabDetails.map((tab: any, index: any) => ({
      ...tab,
      draft: true
    }));
    dispatch({ type: UPDATE_TAB_DETAILS, tabDetails: update });
    const valid = validateMandatoryFields(DRAFT);
    if (!valid) {
      validStatus({ isValid: false, type: DRAFT });
      scrollToTop();
    } else {
      validStatus({ isValid: true, type: DRAFT });
      handleSubmitFormData(DRAFT);
    }
  };

  const handleSubmit = () => {
    const update = state.tabDetails.map((tab, index) => ({
      ...tab,
      draft: false
    }));
    dispatch({ type: UPDATE_TAB_DETAILS, tabDetails: update });
    const valid = validateMandatoryFields(SUBMIT);
    if (!valid) {
      validStatus({ isValid: false, type: SUBMIT });
      scrollToTop();
    } else {
      validStatus({ isValid: true, type: SUBMIT });
      handleSubmitFormData(SUBMIT);
    }
  };

  return (
    <div className="row" style={{paddingTop:"0.85vw", paddingBottom: "0.85vw"}}>
      <div className="col-md-6 align-self-center">
        {isGeneralTab && (
          <BackButton />
        )}
        {!isGeneralTab && (
          <Button
            type="button"
            title={t("Back")}
            handleClick={handlePreviousStep}
            className="btn backBtn p-0 text-decoration-underline border-0 bg-none"
          />
        )}
      </div>
      <div className="col-md-6 ">
        {!loading ? (
          <Button
            type="submit"
            title={
              isJobDescriptionTab
                ? t("Submit")
                : t("Next")
            }
            handleClick={
              isJobDescriptionTab
                ? handleSubmit
                : handleNextStep
            }
            className="form-button float-end"
          />
        ) : (
          <LoadingIcon
            iconType="bars"
            color="#00a5ce"
            className="ms-auto mb-3"
            height="3vw"
            width="3vw"
          />
        )}
        {formStatus !== 1 && !isJobDescriptionTab && (
          <Button
            type="submit"
            title={t("Save as draft")}
            handleClick={handleDraft}
            className="form-button float-end marginRight1"
          />
        )}
      </div>
    </div >
  );
};

export default Navigation;
