import { ApiCall } from "services/ApiServices";
import { PayRollReducerTypes } from "../constants/WorkPayBillRollConstants";
import { GET_PAYROLL } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CommonServices from "services/CommonService";
import CustomNotify from "components/atoms/CustomNotify";

const getManageData = async (props: any) => {    
    return new Promise(async (resolve, reject) => {
        try {
            // let selectedData = props?.history?.filters?.selected_data ?? [];
            let postdata: any = {
                manage: true,
                initialCall: props?.pageData?.initialCall ?? false,
                date: CommonServices?.convertToISODate(props?.history?.filters?.selected_date ?? new Date()),
                search: {
                    businessUnits: props?.history?.filters?.businessUnits ?? [],
                    contract_type: props?.history?.filters?.contract_type ?? "",
                    employee: props?.history?.filters?.employee ?? "",
                    orderBy: props?.history?.filters?.orderBy ?? "id",
                    sortDirection: props?.history?.filters?.sortDirection ?? "desc",
                },
            }
            if (props?.pageData?.export_popup?.start_month && props?.pageData?.export_popup?.end_month) {
                postdata = {
                    ...postdata,
                    start_month: CommonServices?.convertToISODate(props?.pageData?.export_popup?.start_month),
                    end_month: CommonServices?.convertToISODate(props?.pageData?.export_popup?.end_month),
                }
            }
            const response = await ApiCall?.service(GET_PAYROLL, 'POST', postdata, false, M_MASTER_DATA);
            
            // let selecteddata = props?.history?.filters?.select_all ? response?.data?.map((item: any) => (!selectedData?.includes?.(item?.id) && item?.id)) : [];
            let resdata = response?.data;
            if (props?.export && response?.data?.length <= 0) {
                resdata = props?.pageData?.data;
                CustomNotify({ type: "error", message: "Data not found" });
                reject("error");
            }
            let pageData = {
                ...props?.pageData, totalPages: response?.totalPages,
                totalRecords: response?.totalRecords, initialCall: false, data: resdata,
            }
            if (postdata?.initialCall) {
                pageData.options = response?.options ?? [];
            }            
            props?.setPageData(pageData);
            // props?.dispatch(
            //     props?.updatePageFilters({
            //         filters: {
            //             selected_data: [...selectedData, ...selecteddata],
            //         },
            //     })
            // );
            resolve(true);
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });
}

const getOverviewData = async (props: any) => {
    return new Promise(async (resolve, reject) => {        
        try {            
            let postdata: any = {
                date: CommonServices?.convertToISODate(props?.filters?.selected_date ?? new Date()),
                employees: props?.filters?.employees ?? [],
            }
            const response = await ApiCall?.service(GET_PAYROLL, 'POST', postdata, true, M_MASTER_DATA);            
            props?.overViewDispatch({
                type: PayRollReducerTypes?.SET_OVERVIEW_DATA,
                payload: {
                    ...props?.overViewState,
                    headers: response?.headers,
                    data: response?.data,
                }
            });
            resolve(true);
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });
}

export {
    getManageData,
    getOverviewData
}
