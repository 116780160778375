import React from 'react'

const DocumentsIcon: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1.5vw" height="1.5vw" viewBox="-3 -6 58.934 85.943">
            <g id="My_documents" data-name="My documents" transform="translate(-4.175 -1.125)">
                <path id="Path_31505" data-name="Path 31505" d="M28.409,26.308,13.188,10.865V26.308Z" transform="translate(13.298 11.162)" fill="currentColor" />
                <path id="Path_31506" data-name="Path 31506" d="M22.909,10.125H3.175V60.677H42.44V29.657H22.909Z" transform="translate(1 10.391)" fill="currentColor" />
                <path id="Path_31507" data-name="Path 31507" d="M25.313,15.29h13.2L25.313,1.865c0,13.039,0,13.425,0,13.425Z" transform="translate(22.862 0.771)" fill="currentColor" />
                <path id="Path_31508" data-name="Path 31508" d="M37.394,18.639V1.125H11.25v15.8l7.681.468,19.89,20.373v18.5H54.907V18.639Z" transform="translate(8.202 0)" fill="currentColor" />
            </g>
        </svg>
    )
}

export default DocumentsIcon

