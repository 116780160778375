import React, { useEffect, useState } from "react";
import { useFormContext } from "pages/microservices/project/context/Context";
import General from "pages/microservices/project/components/General";
import ContactsPersons from "pages/microservices/project/components/ContactsPersons";
import Billing from "pages/microservices/project/components/Billing";
import ProjectParameters from "pages/microservices/project/components/ProjectParameters";
import Shifts from "pages/microservices/project/components/Shifts";
import { t } from "pages/microservices/masterData/translation/Translation";
import { M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import { PROJECTS_OPTIONS, PROJECT, PROJECT_CREATE } from "routes/ApiEndpoints";
import {
  SWITCH_TAB,
  SET_OPTIONS,
  SET_ERRORS_GENERAL_TAB,
  UPDATE_TAB_DATA,
  UPDATE_ERRORS_CONTACTPERSONS_TAB,
  INITIAL_STATE,
  INITIAL_STATE_OBJECT,
  UPDATE_ERRORS_SEQUENCE_TAB,
  DISABLED_DAYS,
  SHIFT_UPDATE_FIELD,
  UPDATE_SELECTED_CONTACT_FIELD,
  SET_FIELD_GENERAL_TAB,
  COMPETENCE_TAB_DATA,
  UPDATE_CONTACT_SUBFORM_FIELD,
  TITLE_ALREADY_EXISTS,
  DailyCosts,
} from "pages/microservices/project/context/Constants";
import {
  UPDATE_ERRORS_BILLING_TAB,
  UPDATE_ERRORS_PARAMETER_TAB,
} from "pages/microservices/project/context/Constants";
import {
  BillingTabValidationRules,
  ContactsTabValidationRules,
  GeneralTabValidationRules,
  ProjectParametersTabValidationRules,
} from "./validationRules/ProjectFormValidationRules";
import {
  validateEmail,
  validateForm,
  validateMultiSelectField,
  validateRequired,
  validateSelectField,
  validateSequenceForm,
} from "services/validation/ValidationService";
import FormNavigationOrganism from "components/organism/Actions/FormNavigationOrganism";
import { FormMode } from "components/common/CommonEnums";
import { PATH_PROJECTS_MANAGE, PATH_PROJECT_PROPOSAL } from "constants/Paths";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isObjectEmpty } from "services/util/UtilService";
import {
  ContactPersonTabErrors,
  ContactsPersons as IContactPersons,
  GeneralTabErrors,
  BillingTabErrors,
  projectParametersTabErrors,
  SequenceErrors,
} from "./context/Interfaces";
import { Option } from "components/common/CommonInterfaces";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Sequence from "./components/Sequence";
import EncryptDecryptService from "services/EncryptDecryptService";
import CustomNotify from "components/atoms/CustomNotify";
import Popup from "components/molecules/Popup";
import { projectParametersValidations, validate } from "./Utils";
import ProjectCompetences from "./components/ProjectCompetences";
import { bool } from "aws-sdk/clients/signer";
import ProjectQuestion from "./components/ProjectQuestion";

export const ProjectForm = () => {
  const { state, dispatch } = useFormContext();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [showModel, setShowModel] = useState(false);
  const [users, setUsers] = useState([]);
  const [projectId, setProjectId] = useState("");

  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();

  const accessType = location?.pathname?.split("/");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    dispatch({
      type: INITIAL_STATE,
      payload: INITIAL_STATE_OBJECT,
    });

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const projectFormHasErrors = (): boolean => {
    let hasErrors = false;

    state.tabs.forEach((tab, index) => {
      let tabHasErrors = false;

      switch (tab.id) {
        case "general":
          tabHasErrors = !isObjectEmpty(handleFieldValidationsGeneralTab(), {
            createLocationHasError: "",
            createLocation: "",
          });
          break;
        case "contactsPersons":
          const contactPersonsTabErrors =
            handleFieldValidationsContactPersonsTab();
          tabHasErrors = !isObjectEmpty(contactPersonsTabErrors, {
            contactsPersons: "",
          });
          break;
        case "projectParameters":
          tabHasErrors = handleFieldValidationsProjectParametersTab();
          break;
        case "shifts":
          tabHasErrors = handleFieldValidationsShiftTab();
          break;
        case "sequence":
          tabHasErrors = !isObjectEmpty(handleFieldValidationsSequenceTab());
          break;
        case "billing":
          tabHasErrors = !isObjectEmpty(handleFieldValidationsBillingTab());
          break;
        default:
          break;
      }

      dispatch({
        type: "UPDATE_TAB_ERROR",
        tabIndex: index,
        error: tabHasErrors,
        draft: tab.draft,
      });

      if (tabHasErrors) {
        hasErrors = true;
      }
    });

    return hasErrors;
  };

  const handleFieldValidationsGeneralTab = (): GeneralTabErrors => {
    let newGeneralTabErrors = validateForm(
      state.general.data,
      GeneralTabValidationRules
    ) as Object;
    let functionError: any =
      state?.general?.data?.functions?.tableData?.length > 0
        ? {}
        : {
            capacity: "The field is required",
            functions: "The field is required",
          };

    dispatch({
      type: SET_ERRORS_GENERAL_TAB,
      errors: { ...newGeneralTabErrors, ...functionError } as GeneralTabErrors,
    });
    return { ...newGeneralTabErrors, ...functionError } as GeneralTabErrors;
  };

  const handleFieldValidationsContactPersonsTab =
    (): ContactPersonTabErrors => {
      let contactsTabErrors = validateForm(
        state.contactsPersons.data,
        ContactsTabValidationRules
      ) as Object;

      dispatch({
        type: UPDATE_ERRORS_CONTACTPERSONS_TAB,
        errors: contactsTabErrors as ContactPersonTabErrors,
      });

      return contactsTabErrors as ContactPersonTabErrors;
    };

  const handleFieldValidationsShiftTab = (): boolean => {
    let errors: any = {
      name: "",
      shifts: {},
    };
    let hasError: any = state?.shifts?.data?.shifts?.length < 1;

    if (hasError) {
      errors = {
        ...errors,
        name: "The field is required",
      };
    } else {
      Object.keys(state?.shifts?.data?.shiftTable).map((shiftId: any) => {
        Object.keys(state?.shifts?.data?.shiftTable[shiftId].timeTable).map(
          (day: string) => {
            if (!DISABLED_DAYS.includes(day)) {
              const { from, till, pause } =
                state?.shifts?.data?.shiftTable[shiftId].timeTable[day];
              if (!errors[shiftId]) errors[shiftId] = {};
              if (!errors[shiftId][day]) errors[shiftId][day] = {};
              errors[shiftId][day]["from"] = validate(
                state,
                dispatch,
                day,
                { field: "from", value: from },
                shiftId
              );
              errors[shiftId][day]["till"] = validate(
                state,
                dispatch,
                day,
                { field: "till", value: till },
                shiftId
              );
              errors[shiftId][day]["pause"] = validate(
                state,
                dispatch,
                day,
                { field: "pause", value: pause },
                shiftId
              );

              if (
                !hasError &&
                (errors[shiftId][day]["from"] ||
                  errors[shiftId][day]["till"] ||
                  errors[shiftId][day]["pause"])
              ) {
                hasError = true;
              }
            }
          }
        );
      });
    }
    return hasError;
  };

  /**
   * Handling the validations for sequence tab
   * @returns object
   */
  const handleFieldValidationsSequenceTab = (): SequenceErrors => {
    let SequenceTabErrors = validateSequenceForm(state.sequence.data) as Object;

    dispatch({
      type: UPDATE_ERRORS_SEQUENCE_TAB,
      errors: SequenceTabErrors as SequenceErrors,
    });

    return SequenceTabErrors as SequenceErrors;
  };

  const handleFieldValidationsBillingTab = (): BillingTabErrors => {
    const BillingTabErrors = validateForm(state.billing.data, {
      ...BillingTabValidationRules,
      poNumber: state?.billing?.data?.referenceRequired
        ? [validateRequired]
        : [],
      reference: state?.billing?.data?.referenceRequired
        ? [validateRequired]
        : [],
      email: state?.billing?.data?.proformaInvoice
        ? [validateMultiSelectField]
        : [],
    }) as Object;

    dispatch({
      type: UPDATE_ERRORS_BILLING_TAB,
      errors: BillingTabErrors as BillingTabErrors,
    });

    return BillingTabErrors as BillingTabErrors;
  };

  const handleFieldValidationsProjectParametersTab = () => {
    const projectParametersTabErrors = validateForm(
      state.projectParameters.data,
      ProjectParametersTabValidationRules
    ) as Object;

    let validationData: any = {};
    let hasErrors = [];
    const sectionName: any = "Premium";
    let premiumValidation = state.projectParameters.data.sections;
    let premiumValue =
      premiumValidation?.[sectionName]?.["44"]?.["field_value"];
    let premiumDependencies = [36, 37, 38, 39];

    let costSectionName: any = "Moving costs";
    let costValue =
      premiumValidation?.[costSectionName]?.["5"]?.["field_value"];

    for (let eachsection of Object.keys(
      state.projectParameters.data.sections
    )) {
      const sectionData =
        state.projectParameters.data.sections[
          eachsection as keyof typeof state.projectParameters.data.sections
        ];
      let eachSectionData: any = {};
      for (let section of Object.keys(sectionData)) {
        const validate =
          premiumValue == "0"
            ? premiumDependencies.includes(
                parseInt(sectionData[section].field_id)
              )
              ? false
              : true
            : true;

        const costValidate =
          costValue == "1" &&
          DailyCosts.includes(parseInt(sectionData[section].field_id))
            ? false
            : true;

        if (
          sectionData[section].field_type !== "checkbox" &&
          sectionData[section].field_type !== "radio" &&
          validate &&
          costValidate
        ) {
          const validationRules: any = projectParametersValidations(
            parseInt(section)
          );
          const validationresult = validateForm(
            { [section]: sectionData[section].field_value },
            validationRules
          );
          eachSectionData[section] = {
            ...sectionData[section],
            field_error:
              Object.keys(validationresult).length > 0
                ? validationresult[section]
                : "",
          };
          hasErrors.push(Object.keys(validationresult).length > 0);
        } else {
          eachSectionData[section] = {
            ...sectionData[section],
            field_eroor: "",
          };
        }
      }
      validationData[eachsection] = eachSectionData;
    }

    dispatch({
      type: "UPDATE_PROJECT_PARAMETERS",
      payload: validationData,
    });

    dispatch({
      type: UPDATE_ERRORS_PARAMETER_TAB,
      errors: projectParametersTabErrors as projectParametersTabErrors,
    });

    //return projectParametersTabErrors as projectParametersTabErrors;
    return (
      hasErrors.includes(true) ||
      Object.keys(projectParametersTabErrors).length > 0
    );
  };

  // Save as Draft validation
  const draftValidation = () => {
    const tabFields = state.general;
    const fieldName = "projectName";
    const tabIndex = 0;
    const tab = "general";
    // const fieldValue: any = tabFields.data[fieldName];
    // const validationError = validateRequired(fieldValue);

    const validationRules = {
      projectName: [validateRequired],
      company: [validateSelectField],
      businessUnitNumber: [validateSelectField],
    };

    const validateValues = {
      projectName,
      company,
      businessUnitNumber,
    };

    const validationErrors = validateForm(validateValues, validationRules);

    if (Object.keys(validationErrors).length > 0) {
      dispatch({
        type: SET_ERRORS_GENERAL_TAB,
        errors: { ...validationErrors } as GeneralTabErrors,
      });

      dispatch({
        type: "UPDATE_TAB_ERROR",
        tabIndex: tabIndex,
        error: true,
        draft: true,
      });
      return false;
    }

    dispatch({
      type: "UPDATE_FIELD_ERROR",
      tab: tab,
      fieldName: fieldName,
      error: "",
    });
    dispatch({
      type: "UPDATE_TAB_ERROR",
      tabIndex: tabIndex,
      error: false,
      draft: true,
    });

    return true;

    // if (validationError) {
    //   dispatch({
    //     type: "UPDATE_FIELD_ERROR",
    //     tab: tab,
    //     fieldName: fieldName,
    //     error: validationError,
    //   });

    // } else {
    //   dispatch({
    //     type: "UPDATE_FIELD_ERROR",
    //     tab: tab,
    //     fieldName: fieldName,
    //     error: "",
    //   });
    //   dispatch({
    //     type: "UPDATE_TAB_ERROR",
    //     tabIndex: tabIndex,
    //     error: false,
    //     draft: true,
    //   });
    // }
    // return true;
  };

  const ConstructTabs = (tab: string | number) => {
    switch (tab) {
      case "general":
        return <General type={type} />;
      case "contactsPersons":
        return <ContactsPersons type={type} />;
      case "projectParameters":
        return <ProjectParameters type={type} />;
      case "competences":
        return (
          <ProjectCompetences
            competenceOptions={state?.options?.Competence}
            state={state}
            dispatch={dispatch}
            type={type}
          />
        );
      case "question":
        return <ProjectQuestion />;
      case "shifts":
        return <Shifts type={type} />;
      case "sequence":
        return <Sequence type={type} />;
      case "billing":
        return <Billing type={type} />;
      default:
        break;
    }
  };

  useEffect(() => {
    getOptions();
  }, [state?.general?.data?.company, id]);

  async function getOptions() {
    if (state.general.data.company) {
      companyOptions(!!id);
    } else if (id) {
      projectDetails(id);
    } else {
      await options(!!id);
    }
  }

  async function options(isEdit: boolean) {
    let postData = {
      options: [
        "businessUnit",
        "projectTypes",
        "company",
        "gender",
        "language",
        "roles",
        "template",
        "shift",
        "country",
        "ParitairComitee",
        "Competence",
        "tittle",
        "projectConsultant",
        "Question",
      ],
    };
    const response = await ApiCall.service(
      PROJECTS_OPTIONS,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      const {
        businessUnit,
        company,
        gender,
        language,
        projectTypes,
        roles,
        template,
        shift,
        country,
        ParitairComitee,
        Competence,
        tittle,
        projectConsultant,
        Question,
      } = response?.data;
      let whiteCollarFunctions = ParitairComitee?.whiteCollar?.flatMap(
        (item: any) => item?.pcFunctions
      );
      let blueCollarFunctions = ParitairComitee?.blueCollar?.flatMap(
        (item: any) => item?.pcFunctions
      );
      if (!isEdit) {
        dispatch({
          type: SET_OPTIONS,
          field: "Question",
          value: Question,
        });
      }

      if (!isEdit) {
        const option = {
          label: userData.name,
          value: userData.userId,
        };
        dispatch({
          type: SET_FIELD_GENERAL_TAB,
          field: "consultant",
          value: option,
        });
        dispatch({
          type: SET_FIELD_GENERAL_TAB,
          field: "businessUnitNumber",
          value:
            Array.isArray(userData?.businessUnit) &&
            userData.businessUnit.length > 0
              ? userData.businessUnit[0]
              : null, // Provide a fallback value
        });
      }
      dispatch({
        type: SET_OPTIONS,
        field: "businessUnit",
        value: businessUnit,
      });
      dispatch({
        type: SET_OPTIONS,
        field: "consultant",
        value: projectConsultant,
      });
      dispatch({ type: SET_OPTIONS, field: "Competence", value: Competence });
      if (!isEdit) {
        dispatch({
          type: COMPETENCE_TAB_DATA,
          field: "competences",
          value: { competence: Competence },
        });
      }

      dispatch({
        type: SET_OPTIONS,
        field: "PcFunctions",
        value: [...whiteCollarFunctions, ...blueCollarFunctions],
      });
      dispatch({ type: SET_OPTIONS, field: "company", value: company });
      dispatch({ type: SET_OPTIONS, field: "countries", value: country });
      dispatch({ type: SET_OPTIONS, field: "gender", value: gender });
      dispatch({ type: SET_OPTIONS, field: "language", value: language });
      dispatch({ type: SET_OPTIONS, field: "tittle", value: tittle });
      dispatch({
        type: SET_OPTIONS,
        field: "projectTypes",
        value: projectTypes,
      });
      dispatch({ type: SET_OPTIONS, field: "roles", value: roles });
      dispatch({ type: SET_OPTIONS, field: "templates", value: template });
      dispatch({ type: SET_OPTIONS, field: "shifts", value: shift });
    }
  }

  async function companyOptions(isEdit: boolean) {
    const company = state.general.data.company;
    if (!company) {
      return;
    }
    const postData = {
      options: [
        "contactPersons",
        "companyLocations",
        "companyBusinessUnits",
        "ParitairComitee",
        "Competence",
      ],
      function_id:
        state?.general?.data?.functions?.tableData?.map(
          (item: any) => item?.value
        ) ?? [],
      companyId: company.value,
    };
    const response = await ApiCall.service(
      PROJECTS_OPTIONS,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      const {
        contactPersons,
        companyLocations,
        companyFunctions,
        companyBusinessUnits,
        ParitairComitee,
        Competence,
      } = response?.data;
      let whiteCollarFunctions = ParitairComitee?.whiteCollar?.flatMap(
        (item: any) => item?.pcFunctions
      );
      let blueCollarFunctions = ParitairComitee?.blueCollar?.flatMap(
        (item: any) => item?.pcFunctions
      );

      dispatch({
        type: SET_OPTIONS,
        field: "companyUsers",
        value: contactPersons,
      });
      dispatch({
        type: SET_OPTIONS,
        field: "PcFunctions",
        value: [...whiteCollarFunctions, ...blueCollarFunctions],
      });
      dispatch({ type: SET_OPTIONS, field: "Competence", value: Competence });
      // dispatch({ type: SET_FIELD_GENERAL_TAB,field: "functions",
      //   value: {
      //     isEdit: false, selected: {value: "", label: "Select", capacity: 0},
      //     tableData: state?.general?.data?.functions?.tableData.filter(
      //       option => ![...whiteCollarFunctions, ...blueCollarFunctions].some(item => item?.value === option?.value)
      //     ),
      //   }
      // });
      if (!isEdit) {
        dispatch({
          type: UPDATE_SELECTED_CONTACT_FIELD,
          field: "selectedContactPersons",
          value: contactPersons,
        });

        dispatch({
          type: UPDATE_CONTACT_SUBFORM_FIELD,
          field: "businessUnit",
          value: companyBusinessUnits as Option[],
        });
      }
      dispatch({
        type: SET_OPTIONS,
        field: "locations",
        value: companyLocations,
      });
      // dispatch({
      //   type: SET_OPTIONS,
      //   field: "companyBusinessUnit",
      //   value: companyBusinessUnits as Option[],
      // });
    }
  }

  async function projectDetails(id: string) {
    if (!id) {
      return;
    }
    options(true);
    companyOptions(true);

    const response = await ApiCall.getService(
      `${PROJECT}/${id}`,
      "GET",
      M_MASTER_DATA
    );

    if (response?.status === 200) {
      const {
        general,
        contactsPersons,
        responsiblePerson,
        projectParameters,
        shifts,
        sequence,
        billing,
        competences,
        question,
      } = response?.data;

      let sequencedata = {
        sequenceName: "",
        weeklyShifts: {},
        sequence: sequence,
        edit: "",
        deleteModal: false,
      };
      let generalData = {
        ...general,
        functions: {
          tableData: general?.functions,
          isEdit: false,
          selected: { value: "", label: "Select", capacity: 0 },
        },
      };
      dispatch({ type: UPDATE_TAB_DATA, field: "general", value: generalData });

      dispatch({
        type: UPDATE_TAB_DATA,
        field: "contactsPersons",
        value: contactsPersons,
      });
      dispatch({
        type: SET_OPTIONS,
        field: "Question",
        value: question,
      });

      dispatch({
        type: UPDATE_TAB_DATA,
        field: "responsiblePerson",
        value: responsiblePerson,
      });

      dispatch({
        type: UPDATE_TAB_DATA,
        field: "projectParameters",
        value: projectParameters,
      });

      let shiftdata = {
        data: {
          shiftTable: shifts?.shiftTable,
          shifts: shifts?.shifts,
        },
        errors: {
          ...state?.shifts?.errors,
          shifts: shifts?.shiftErrors,
        },
      };

      dispatch({ type: SHIFT_UPDATE_FIELD, field: "shifts", value: shiftdata });
      dispatch({
        type: COMPETENCE_TAB_DATA,
        field: "competences",
        value: { competence: competences },
      });
      dispatch({
        type: UPDATE_TAB_DATA,
        field: "sequence",
        value: sequencedata,
      });
      dispatch({ type: UPDATE_TAB_DATA, field: "billing", value: billing });
    }
  }
  function handleTabSwitch(tab: string) {
    dispatch({
      type: SWITCH_TAB,
      field: "currentTab",
      value: tab,
    });
  }

  // Navigation tabs
  const handlePreviousStep = () => {
    const currentTabIndex = state.tabs.findIndex(
      (tab) => tab.id === state.currentTab
    );
    const nextTabIndex = (currentTabIndex - 1) % state.tabs.length;
    const nextTabId = state.tabs[nextTabIndex].id;
    dispatch({
      type: "UPDATE_CURRENT_TAB",
      field: "currentTab",
      value: nextTabId,
    });
  };

  const handleNextStep = () => {
    const currentTabIndex = state.tabs.findIndex(
      (tab) => tab.id === state.currentTab
    );
    const nextTabIndex = (currentTabIndex + 1) % state.tabs.length;
    const nextTabId = state.tabs[nextTabIndex].id;
    dispatch({
      type: "UPDATE_CURRENT_TAB",
      field: "currentTab",
      value: nextTabId,
    });
  };

  const handleSubmit = async () => {
    const formHasErrors = projectFormHasErrors();
    if (formHasErrors) {
      return;
    }

    let sequenceDetails = state?.sequence?.data;
    let sequenceData: any = [];

    if (sequenceDetails?.edit?.length == 0) {
      const res = formatSequenceData(state.sequence.data);
      sequenceData = res?.data;
      if (res?.validate !== true) {
        return;
      }
    }

    const postData = {
      general: state.general.data,
      contactsPersons: state.contactsPersons.data.selectedContactPersons,
      responsiblePerson: state.responsiblePerson.data,
      projectParameters: state.projectParameters.data,
      shifts: state.shifts.data,
      competences: state?.competences?.competence,
      sequence: {
        ...state.sequence.data,
        sequence: [...state.sequence.data.sequence, ...sequenceData],
      },
      billing: state.billing.data,
      signatureImage: state.signatureImage || "",
      save: true,
      loginUserId: userData?.userId,
      questions: state.options.Question,
    };
    const response = await ApiCall.service(
      PROJECT_CREATE,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      const projectId = response?.result?.project_id;
      const status = response?.result?.status;
      if (status == "1") {
        navigate(PATH_PROJECTS_MANAGE);
      } else {
        setProjectId(projectId);
        const users = response?.result?.users;
        setUsers(users);
        CustomNotify({ type: "success", message: `${response.message}` });
        setShowModel(true);
      }
    } else if (response?.status === 500) {
      CustomNotify({ type: "error", message: `${response.message}` });
    }
  };

  const formatDate = (inputDate: any) => {
    if (inputDate) {
      const date = new Date(inputDate);
      return `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}-${
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1
      }-${date.getFullYear()}`;
    }
  };

  interface WeeklyShift {
    [week: string]: { value?: string } | string | any[];
  }

  interface SequenceData {
    sequenceName: string;
    weeklyShifts: WeeklyShift;
    edit?: any;
  }

  const formatSequenceData = (sequenceData: SequenceData) => {
    if (
      Object.keys(sequenceData?.weeklyShifts).length == 0 &&
      sequenceData?.sequenceName == ""
    ) {
      return { validate: true, data: [] };
    }

    const weeks = Object.entries(sequenceData.weeklyShifts)
      .filter(([_, value]) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        } else if (typeof value === "object" && value !== null) {
          return value.value !== "";
        }
        return value !== "";
      })
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as WeeklyShift);

    //Check for title uniqueness

    const currentState = state?.sequence?.data?.sequence;

    const titleValidate = currentState.filter((eachItem: any) => {
      return (
        eachItem.name.toLowerCase() == sequenceData?.sequenceName.toLowerCase()
      );
    });

    if (titleValidate.length > 0) {
      dispatch({
        type: UPDATE_ERRORS_SEQUENCE_TAB,
        errors: {
          sequenceName: TITLE_ALREADY_EXISTS,
        },
      });
      return { validate: false, data: [] };
    }

    if (sequenceData?.sequenceName == "" && Object.keys(weeks).length > 0) {
      dispatch({
        type: UPDATE_ERRORS_SEQUENCE_TAB,
        errors: {
          sequenceName: "This field is required",
        },
      });
      return { validate: false, data: [] };
    }

    const formattedData = {
      validate: true,
      data: [
        {
          name: sequenceData.sequenceName,
          weeks,
        },
      ],
    };
    return formattedData;
  };

  const handleDraft = async () => {
    try {
      if (draftValidation()) {
        let sequenceDetails = state?.sequence?.data;
        let sequenceData: any = [];

        if (sequenceDetails?.edit.length == 0) {
          const res = formatSequenceData(state.sequence.data);
          sequenceData = res?.data;
          if (res?.validate !== true) {
            return;
          }
        }

        const postData = {
          general: { ...state.general.data },
          contactsPersons: state.contactsPersons.data.selectedContactPersons,
          responsiblePerson: state.responsiblePerson.data,
          projectParameters: state.projectParameters.data,
          shifts: state.shifts.data,
          competences: state?.competences?.competence,
          sequence: {
            ...state.sequence.data,
            sequence: [...state.sequence.data.sequence, ...sequenceData],
          },
          billing: state.billing.data,
          loginUserId: userData?.userId,
          questions: state.options.Question,
          status: 0,
        };
        const response = await ApiCall.service(
          PROJECT_CREATE,
          "POST",
          postData,
          false,
          M_MASTER_DATA
        );
        if (response?.status == 200) {
          CustomNotify({ type: "success", message: response?.message });
          navigate(PATH_PROJECTS_MANAGE);
        } else {
          CustomNotify({ type: "warning", message: response?.message });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const presentOperation = id ? "update" : "create";
  const currentObj: any = {
    permission: "Project",
  };
  currentObj[presentOperation] = true;
  const userData = useSelector(selectAuth);
  const cancel = () => {
    setShowModel(false);
    navigate(PATH_PROJECTS_MANAGE);
  };
  const sendLetar = () => {
    navigate(PATH_PROJECTS_MANAGE);
  };
  const sendNow = () => {
    const data = {
      id: projectId,
      users: users,
      throughMail: false,
    };

    const append = EncryptDecryptService.encryptData(JSON?.stringify(data));
    const encodedData = encodeURIComponent(append);
    navigate(`${PATH_PROJECT_PROPOSAL}/?data=${encodedData}`);
  };

  const type = accessType.includes("view")
    ? "View"
    : accessType.includes("create")
    ? "Create"
    : "Edit";

  // console.log(state?.competences);

  const {
    company,
    businessUnitNumber,
    projectName,
    projectCode,
    startDate,
    endDate,
  } = state?.general?.data;

  return (
    <div className="position-relative">
      <AccessControl
        requiredPermissions={[currentObj]}
        renderNoAccess={true}
        override={userData.isSuperAdmin}
      >
        <div
          className="row header-sticky position-sticky"
          style={{ height: "auto" }}
        >
          <div className="col-12">
            <h1
              className="page-title mb-0 d-flex align-items-center"
              style={{ paddingBottom: "0.7vw" }}
            >
              {t(`${type} project`)}
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div
              style={{
                backgroundColor: "#e8f8fb",
                padding: "1vw",
                borderRadius: "1vw",
                marginBottom: "2.5vw",
                marginTop: "0.5vw",
              }}
            >
              <h4 className="name-text projectName cardNameText">
                <strong>{t("Project")}: </strong>
                {projectName && t(projectName)}
              </h4>
              <span className="marginRight1">
                <strong>{t("Company")}: </strong>
                {company?.label && t(company?.label)}
              </span>
              <span className="marginRight1">
                <strong>{t("Business unit")}: </strong>
                {businessUnitNumber?.label}
              </span>
              <span className="marginRight1">
                <strong>{t("Project code")}: </strong>
                {projectCode}
              </span>
              <span>
                <strong>{t("Start date")}:</strong>
                {formatDate(startDate)}
              </span>
            </div>
          </div>
        </div>

        <nav
          className={`projectTabs project-form company-step-tabs createFormTabs ${
            isSticky
              ? "createTabsSticky position-sticky"
              : "company-step-tabs-absolute position-absolute w-100"
          }`}
        >
          <div
            className="d-flex border-0 w-100 m-auto gap-3"
            id="nav-tab"
            role="tablist"
          >
            <div
              className={`d-flex border-0 w-75 m-auto align-items-center justify-content-center`}
              style={{ gap: "0 1vw" }}
            >
              {state.tabs.map((item: any) => (
                <div className={`flex-1 text-center`} key={item.id}>
                  <div
                    className={`createStepBtn  cursor-pointer ${
                      state.currentTab === item.id ? "active" : "inactive"
                    } ${item.error === true ? "error" : ""}`}
                    onClick={() => handleTabSwitch(item.id)}
                  >
                    {t(item.title)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </nav>

        <>{ConstructTabs(state.currentTab)}</>
        <FormNavigationOrganism
          isFirstTab={state.currentTab === state.tabs[0].id}
          isSubmitTab={
            state.currentTab === state.tabs[state.tabs.length - 1].id
          }
          draftButtonIsVisible={state.mode === FormMode.CREATE}
          formExitBackTo={PATH_PROJECTS_MANAGE}
          handlePreviousStep={handlePreviousStep}
          handleNextStep={handleNextStep}
          {...(type !== "View" && {
            handleSubmit: handleSubmit,
            ...((state?.general?.data?.status == 0 || type == "Create") && {
              handleDraft: handleDraft,
            }),
          })}
          isHideNext={
            state.currentTab === state.tabs[state.tabs.length - 1].id &&
            type == "View"
          }
        />
        {showModel && (
          <Popup
            body={t("Send project proposal")}
            nowSendText={t("Send now")}
            letarSendText={t("Send later")}
            cancel={cancel}
            sendLetar={sendLetar}
            sendNow={sendNow}
            footerclass="justify-content-center"
          />
        )}
      </AccessControl>
    </div>
  );
};
export default ProjectForm;
