import { LabelWithInputField } from 'components/molecules/LabelWithInputField';
import { LabelWithInputFieldAndUnit } from 'pages/microservices/masterData/templates/atoms/LabelWithInputFieldAndUnit';
import { t } from 'pages/microservices/masterData/translation/Translation';
import React from 'react';
import { Navigation } from '../../form-navigation/Navigation';
import { useFormContext } from '../../context/Context';
import { PC_DETAILS, UPDATE_ERROR_FIELD, UPDATE_FIELD } from '../../annotations/PCConstants';
import { ValidationRules } from 'utils/TypeAnnotations';
import { validateForm, validateMaxTimeFormat, validateRequired, validateTextFiled, validateTimeFormat } from 'services/validation/ValidationService';

export const PCDetails = () => {
  const { state, dispatch } = useFormContext();
  const validation = (
    name: string,
    value: any,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      pcName: [validateRequired],
      pcNumber: [validateRequired],
      pcAliasName: [validateTextFiled],
      bufferTiming: [validateRequired, validateTimeFormat],
      minWorkTimingPerDay: [validateRequired, validateTimeFormat],
      maxWorkTimingPerDay: [validateRequired, validateTimeFormat],
      workUpto: [validateRequired, validateTimeFormat],
      maxWorkTimingPerWeekWarning: [validateRequired, validateMaxTimeFormat],
      maxWorkTimingPerWeekError: [validateRequired, validateMaxTimeFormat],
    };
    const validationErrors = validateForm(
      { ...state.pcDetails, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: UPDATE_ERROR_FIELD,
      field: name,
      error: validationErrors[name]
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;
    if (isFieldValid) {
      dispatch({
        type: UPDATE_ERROR_FIELD,
        field: name,
        error: ''
      });
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleFieldChange = (e: any) => {
    const { name, value } = e.target;
    dispatch({
      type: UPDATE_FIELD,
      tab: PC_DETAILS,
      field: name,
      value: value
    });
    validation(name, value, true);
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="form-border" style={{ paddingTop: "3vw" }}>
            <div className="row">
              <div className="col">
                <LabelWithInputField
                  isMandatory={true}
                  name="pcName"
                  handleChange={(e) => handleFieldChange(e)}
                  value={state.pcDetails.pcName}
                  id="pcName"
                  label={t("Pc name")}
                  type="text"
                  error={state.errors.pcName}
                  placeholder="Pc name"
                />
              </div>
              <div className="col">
                <LabelWithInputField
                  isMandatory={true}
                  name="pcNumber"
                  handleChange={(e) => handleFieldChange(e)}
                  value={state.pcDetails?.pcNumber}
                  id="pcNumber"
                  label={t("Pc number")}
                  type="text"
                  error={state.errors.pcNumber}
                  placeholder="Pc number"
                />
              </div>
              <div className="col">
                <LabelWithInputField
                  isMandatory={false}
                  name="pcAliasName"
                  handleChange={(e) => handleFieldChange(e)}
                  value={state.pcDetails?.pcAliasName}
                  id="pcAliasName"
                  label={t("Pc alias name")}
                  type="text"
                  error={state.errors.pcAliasName}
                  placeholder="Pc alias name"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <LabelWithInputFieldAndUnit
                  type="text"
                  placeholder="Buffer timing"
                  handleChange={handleFieldChange}
                  label={t("Buffer timing between two plannings")}
                  value={state.pcDetails?.bufferTiming}
                  name={'bufferTiming'}
                  id="bufferTiming"
                  isMandatory={true}
                  error={state?.errors.bufferTiming}
                  min={0}
                  unit={"Hours"}
                  containerClassName="col-12"
                />
              </div>
              <div className="col">
                <LabelWithInputFieldAndUnit
                  type="text"
                  placeholder="Minimun work timing"
                  handleChange={handleFieldChange}
                  label={t("Minimum work timing per day")}
                  value={state.pcDetails.minWorkTimingPerDay}
                  name={'minWorkTimingPerDay'}
                  id="minWorkTimingPerDay"
                  isMandatory={true}
                  error={state?.errors.minWorkTimingPerDay}
                  min={0}
                  unit={"Hours"}
                  containerClassName="col-12"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <LabelWithInputFieldAndUnit
                  type="text"
                  placeholder="Maximum work timing"
                  handleChange={handleFieldChange}
                  label={t("Maximum work timing per day")}
                  value={state.pcDetails?.maxWorkTimingPerDay}
                  name={'maxWorkTimingPerDay'}
                  id="maxWorkTimingPerDay"
                  isMandatory={true}
                  error={state?.errors.maxWorkTimingPerDay}
                  min={0}
                  unit={"Hours"}
                  containerClassName="col-12"
                />
              </div>
              <div className="col">
                <LabelWithInputFieldAndUnit
                  type="text"
                  placeholder="Employee work timing"
                  handleChange={handleFieldChange}
                  label={t("Employee can work upto")}
                  value={state.pcDetails?.workUpto}
                  name={'workUpto'}
                  id="workUpto"
                  isMandatory={true}
                  error={state?.errors.workUpto}
                  min={0}
                  unit={"Hours"}
                  containerClassName="col-12"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <LabelWithInputFieldAndUnit
                  type="text"
                  placeholder="Maximum work timing per week"
                  handleChange={handleFieldChange}
                  label={t("Maximum work timing per week (Warning)")}
                  value={state.pcDetails?.maxWorkTimingPerWeekWarning}
                  name={'maxWorkTimingPerWeekWarning'}
                  id="maxWorkTimingPerWeekWarning"
                  isMandatory={true}
                  error={state?.errors.maxWorkTimingPerWeekWarning}
                  min={0}
                  unit={"Hours"}
                  containerClassName="col-12"
                />
              </div>
              <div className="col">
                <LabelWithInputFieldAndUnit
                  type="text"
                  placeholder="Maximum work timing per week"
                  handleChange={handleFieldChange}
                  label={t("Maximum work timing per week (Error)")}
                  value={state.pcDetails?.maxWorkTimingPerWeekError}
                  name={'maxWorkTimingPerWeekError'}
                  id="maxWorkTimingPerWeekError"
                  isMandatory={true}
                  error={state?.errors.maxWorkTimingPerWeekError}
                  min={0}
                  unit={"Hours"}
                  containerClassName="col-12"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div style={{ padding: "0.85vw 0" }}>
            <Navigation />
          </div>
        </div>
      </div>

    </>
  );
}