import { PLANNING_ACTIONS } from "../../actions/PlanningActions"

export const planningReducer = (planningState, actions) => {
    switch(actions.type){
        case PLANNING_ACTIONS.SET_PLANNING_VIEW: 
            {return set_planning_view(planningState, actions);}
        
        case PLANNING_ACTIONS.SET_PLANNING_EVENTS:
            {return set_planning_events(planningState, actions)}
        
        case PLANNING_ACTIONS.SET_CURRENT_VIEW_UNIT:
            {return set_current_view_unit(planningState, actions)}

        case PLANNING_ACTIONS.SET_TYPE:
            {return set_type(planningState, actions)}

        case PLANNING_ACTIONS.SET_SALES_VIEW:
            {return set_sales_view(planningState, actions)}
        
        case PLANNING_ACTIONS.UPDATE_CURRENT_VIEW_YEAR:
            {return set_current_view_year(planningState, actions)}
        
        case PLANNING_ACTIONS.UPDATE_PLANNING_EVENTS:
            {return update_planning_events(planningState, actions)}

        case PLANNING_ACTIONS.UPDATE_MUTIPLE_FIELDS:
            {return update_multiple_fields(planningState, actions)}

        case PLANNING_ACTIONS.UPDATE_CELL_STATE:
            {return update_cell_state(planningState, actions)}

        default:
            throw Error('Unknown action: ' + actions.type);
    }
}

function set_planning_view(planningState, actions){
    return {...planningState, view: actions.payload}
}

function set_planning_events(planningState, actions){
    return {
        ...planningState,
        planningEvents: {
          ...planningState.planningEvents, // Spread existing planningEvents
          ...actions.payload, // Spread new payload
        },
      };
}

function set_current_view_unit(planningState, actions){
    return {...planningState, currentViewUnit: actions.payload}
}
function set_current_view_year(planningState, actions){
    return {...planningState, currentViewYear: actions.payload}
}

function set_type(planningState, actions){
    return {...planningState, type: actions.payload}
}

function set_sales_view(planningState, actions){
    return {...planningState, salesview: actions.payload}
}

function update_planning_events(planningState, actions) {
    const { employee_id, plans } = actions.payload;

    // Create a copy of the planning state
    const updatedPlanningState = { ...planningState };

    // Check if the employee already exists in the planningEvents
    if (updatedPlanningState.planningEvents.hasOwnProperty(employee_id)) {
        const existingPlans = updatedPlanningState.planningEvents[employee_id].plans;
        // Filter out plans that already exist
        const newPlans = plans.filter(newPlan => {
            return !existingPlans.some(existingPlan => existingPlan.plan_date === newPlan.plan_date);
        });

        // Add the new plans to the existing plans array
        updatedPlanningState.planningEvents[employee_id].plans.push(...newPlans);
    } else {
        // If the employee does not exist, add the employee with the plans
        updatedPlanningState.planningEvents[employee_id] = { plans };
    }

    return updatedPlanningState;
}

function update_cell_state(planningState, actions){
    return {
        ...planningState,
        cellState: {
            ...planningState.cellState,
            ...actions.payload
        }
    };
}

function update_multiple_fields(planningState, actions){
    return {
        ...planningState,
        ...actions.payload
    };
}