import React from 'react'
import Button from './Button'
import { handleBackClick } from 'services/util/UtilService'
import { addPageToHistory, selectPageHistory } from 'store/pageHistory/pageHistorySlice'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { t } from 'pages/microservices/masterData/translation/Translation'

const BackButton = (props?: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const pageHistory = useSelector(selectPageHistory);

    return (
        <>
            <Button
                title={t(props?.title ?? "Back")}
                handleClick={() => handleBackClick({ dispatch, pageHistory, navigate, addPageToHistory })}
                className={props?.className ?? "back-btn btn border-0 text-decoration-underline p-0"}
            />
        </>
    )
}

export default BackButton