import React from "react";
import CheckBoxField from "components/atoms/CheckBoxField";
import { useTimesheetDispatchContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { TIMESHEET_ACTIONS } from "../timesheets-redux/actions/TimesheetActions";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import { t } from "pages/microservices/masterData/translation/Translation";

const CommonCheckBox = (props) => {
  const { planTimingsData, header1, header2, name } = props;
  const { timesheetDispatch } = useTimesheetDispatchContext();
  const { filterOptionsState } = useFilterOptions()
  const handleChange = (event, planId) => {
    const { checked, name } = event.target;
    if (planId) {
      timesheetDispatch({
        type: TIMESHEET_ACTIONS.UPDATE_TIMESHEET_DATA,
        payload: {
          planId: planId,
          value: checked,
          name: name,
          extraprops: {
            filterOptionsState
          }
        },
      });
    }
  };
  const renderItem = () => {
    const weekDays = [
      <td key={"heading1"} className="border text-start">
        {t(header1)}
      </td>,
      <td key={"heading2"} className="border text-start">{t(header2)}</td>,
    ];
    planTimingsData.map((data, i) => {
      weekDays.push(
        data?.plan_id ? (
          <td key={i} className="border">
            <CheckBoxField
              label={""}
              name={name}
              id={name}
              isChecked={data[name]}
              onChangeHandler={(event) => handleChange(event, data?.plan_id)}
            />
          </td>
        ) : (
          <td key={i} className="border"> </td>
        )
      );
    });
    return weekDays;
  };

  return (
    <tr>
      {renderItem()}
      <td key={'last'} className="border"></td>
    </tr>
  );
};
export default CommonCheckBox;
