import TitleAtom from "components/atoms/Title";
import React from "react";

const PageLayout = ({ children }) => {
  return (
    <>
      {/* <div className="search-bar" style={{top:"0px"}}>
        <TitleAtom title={("Timesheets")} />
      </div> */}
      <div className="content flex-1 overflow-auto">{children}</div>
    </>
  );
};

export default PageLayout;
