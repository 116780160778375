import { EDIT_FIELD, EMPLOYEE_TYPE, FUNCTIONS, PC_DETAILS, UPDATE_TAB_DATA } from "../../annotations/PCConstants";

export const setPcData = (state: any, dispatch: any, id: string | undefined, data: any) => {
    const dispatchFieldUpdates = (
        dispatch: any,
        data: any,
        tab: string,
    ) => {
        const list = data[tab];

        if (!list) {
            return;
        }

        if (list && list.length > 0) {
            list.forEach((item: Record<string, any>, index: number) => {
                Object.entries(item).forEach(([field, value]) => {
                    // Dispatch the update with the common "EDIT_FIELD" action
                    dispatch({
                        type: EDIT_FIELD,
                        field,
                        value,
                        index,
                        tab,
                    });
                });
            });
        }
    };

    // Helper function to dispatch tab data if valid


    const dispatchTabData = (dispatch: any, tab: string, tabData: any) => {
        if (Object.keys(tabData).length > 0) {
            dispatch({
                type: UPDATE_TAB_DATA,
                tab,
                data: tabData,
            });
        }
    };


    if (id) {
        //pc details
        const pcDetails = data?.pcDetails || [];
        if (pcDetails && Object.entries(pcDetails)?.length > 0) {
            dispatchTabData(dispatch, PC_DETAILS, pcDetails);
        }
        //functions
        dispatchFieldUpdates(dispatch, data, "functions");

        //categories
        dispatchFieldUpdates(dispatch, data, "categories");

        //age
        dispatchFieldUpdates(dispatch, data, "age");

        //employee type
        const employeeType = data?.employeeType || [];
        if (employeeType && Object.entries(employeeType)?.length > 0) {
            dispatchTabData(dispatch, EMPLOYEE_TYPE, employeeType);
        }

    }
    // }
}