import { ITagAtomProps } from "components/atoms/TagAtom";
import {
  SWITCH_TAB,
  SET_OPTIONS,
  SHIFT_UPDATE_FIELD,
  ADD_CONTACT,
  REMOVE_CONTACT,
  UPDATE_SELECTED_CONTACT_FIELD,
  UPDATE_CONTACT_SUBFORM_FIELD,
  UPDATE_CURRENT_TAB,
  UPDATE_RESPONSIBLE_PERSON_FIELD,
  SET_FIELD_GENERAL_TAB,
  SET_FIELD_ERROR_GENERAL_TAB,
  SET_ERRORS_GENERAL_TAB,
  UPDATE_BILLING_FIELD,
  UPDATE_SHIFT_TABLE,
  SET_CREATE_LOCATION_GENERAL_TAB,
  SET_ERROR_SUBFIELD_GENERAL_TAB,
  UPDATE_TAB_DATA,
  UPDATE_SHIFT_TIME,
  NEW_SHIFT,
  UPDATE_SELECTED_CONTACT_TAG_EDIT,
  PROJECT_OVERVIEW,
  UPDATE_FIELD_ERROR,
  UPDATE_ERRORS_CONTACTPERSONS_TAB,
  UPDATE_ERRORS_RESPONSIBLEPERSONS_TAB,
  UPDATE_ERRORS_BILLING_TAB,
  UPDATE_ERRORS_SHIFT_TAB,
  UPDATE_ERRORS_PARAMETER_TAB,
  INITIAL_STATE,
  SIGNATURE_IMAGE,
  SET_SEQUENCE_NAME,
  ADD_ANOTHER_SEQUENCE,
  SET_WEEKLY_SHIFTS,
  EDIT_SEQUENCE,
  DELETE_SEQUENCE,
  UPDATE_ERRORS_SEQUENCE_TAB,
  TITLE_ALREADY_EXISTS,
  UPDATE_PARAMETERS_ERRORS,
  OverTimeDependency,
  OverTimeFieldDependency,
  UPDATE_FETCH_DATA,
  UPDATE_CONTACT_FIELD_ERROR,
  SET_CONTACT_PERSON_OPTION,
  SET_LOCATION_OPTION,
  SET_PROJECT_LOCATION_FIELD_EMPTY,
  UPDATE_SHIFT_BREAK_FIELDS,
  COMPETENCE_CHANGE,
  COMPETENCE_TAB_DATA,
  SET_MODE,
} from "pages/microservices/project/context/Constants";
import {
  ContactPersonErrors,
  ContactPersonsData,
  ContactsPersons,
} from "./Interfaces";
import { isValidTime } from "services/validation/ValidationService";
import { calculateTotalTime } from "services/util/TotalTimeCalculator";

type ProjectReducerProps = {
  state: any;
  action: any;
};

const ProjectReducer = (
  state: ProjectReducerProps["state"],
  action: ProjectReducerProps["action"]
) => {
  switch (action.type) {
    case SET_MODE:
      return {
        ...state,
        [action.mode]: action.payload.value,
      };
    case SWITCH_TAB:
      return {
        ...state,
        [action.field]: action.value,
      };
    case SET_OPTIONS:
      const options = { ...state.options };
      options[action.field] = action.value;
      return { ...state, options };
    case UPDATE_SELECTED_CONTACT_FIELD:
      // Create a copy of the current contactPersons data
      let updatedSelectedContactTabData = {
        ...state.contactsPersons.data,
        contactsPersons: {
          fName: "",
          lName: "",
          email: "",
          company: state.general.data.company,
          mobNumber: "",
          language: null,
          roles: [],
          locations: [],
          info: "",
        },
      };

      // Set the specific field from the action
      updatedSelectedContactTabData[action.field] = action.value;

      // Resetting errors for contactsPersons
      let updatedContactPersonErrors = {
        fName: "",
        lName: "",
        email: "",
        company: "",
        mobNumber: "",
        language: "",
        roles: "",
        locations: "",
        info: "",
      };

      const updatedSelectedContactTabDataWithSelection = {
        ...state,
        contactsPersons: {
          data: updatedSelectedContactTabData,
          errors: {
            ...state.contactsPersons.errors,
            contactsPersons: updatedContactPersonErrors,
          },
        },
      };

      return updatedSelectedContactTabDataWithSelection;

    case "UPDATE_CONTACT_SUBFORM_FIELD": {
      return {
        ...state,
        contactsPersons: {
          ...state.contactsPersons,
          data: {
            ...state.contactsPersons.data,
            contactsPersons: {
              ...state.contactsPersons.data.contactsPersons,
              [action.field]: action.value, // Directly update the field value
            },
          },
        },
      };
    }

    case UPDATE_SHIFT_BREAK_FIELDS:
      const { field, value, shiftName } = action?.payload;

      return {
        ...state,
        shifts: {
          ...state.shifts,
          data: {
            ...state.shifts.data,
            shiftTable: {
              ...state.shifts.data.shiftTable,
              [shiftName]: {
                ...state.shifts.data.shiftTable[shiftName],
                [field]: value,
              },
            },
          },
        },
      };

    // Shift tab
    case SHIFT_UPDATE_FIELD:
      return {
        ...state,
        shifts: {
          data: action.value.data,
          errors: action.value.errors,
        },
      };

    case UPDATE_SHIFT_TIME:
      const shifts = state.shifts;
      const shiftTable = shifts.data.shiftTable;
      const singleShift = JSON.parse(
        JSON.stringify(shiftTable?.[action?.shiftName])
      );
      let day: any = singleShift["timeTable"][action.day];
      day[action.field] = action.value;
      if (
        !isValidTime(day?.from) ||
        !isValidTime(day?.till) ||
        (day?.from === "00:00" && day?.till === "00:00")
      ) {
        day = { ...day, pause: "00:00", total: "00:00" };
      }
      day["total"] = calculateTotalTime(day?.from, day?.till, day?.pause);
      singleShift.timeTable[action.day] = day;
      shiftTable[action.shiftName] = singleShift;
      shifts.data.shiftTable = shiftTable;
      return { ...state, shifts };

    // Responsible persons tab
    case UPDATE_RESPONSIBLE_PERSON_FIELD:
      let updatedResponsiblePerson = state.responsiblePerson.data;
      updatedResponsiblePerson[action.field] = action.value;

      const updatedResponsiblePersonData = {
        ...state,
        responsiblePerson: {
          data: updatedResponsiblePerson,
          errors: state.responsiblePerson.errors,
        },
      };
      return updatedResponsiblePersonData;

    case UPDATE_CURRENT_TAB:
      return {
        ...state,
        [action.field]: action.value,
      };

    // General tab
    case SET_FIELD_GENERAL_TAB:
      let updatedGeneralTabData = state.general.data;
      updatedGeneralTabData[action.field] = action.value;
      if (action.field === "createLocationIsVisible") {
      }

      const updatedGeneralTabWithData = {
        ...state,
        general: {
          data: updatedGeneralTabData,
          errors: state.general.errors,
        },
      };
      return updatedGeneralTabWithData;

    case SET_FIELD_ERROR_GENERAL_TAB:
      let updatedGeneralTabErrors = state.general.errors;
      updatedGeneralTabErrors[action.fieldName] = action.error;

      const updatedGeneralTabWithFieldError = {
        ...state,
        general: {
          data: state.general.data,
          errors: updatedGeneralTabErrors,
        },
      };
      return updatedGeneralTabWithFieldError;

    case SET_ERRORS_GENERAL_TAB:
      const updatedGeneralTabWithErrors = {
        ...state,
        general: {
          data: state.general.data,
          errors: action.errors,
        },
      };
      return updatedGeneralTabWithErrors;

    case SET_CREATE_LOCATION_GENERAL_TAB:
      let locationData = state.projectLocation.data;
      locationData[action.field] = action.value;
      const updatedWithCreateLocationData = {
        ...state,
        projectLocation: {
          data: locationData,
          errors: state.projectLocation.errors,
        },
      };
      return updatedWithCreateLocationData;

    case SET_ERROR_SUBFIELD_GENERAL_TAB:
      let locationTabErrors = state.projectLocation.errors;
      locationTabErrors[action.field] = action.error;

      const updatedWithCreateLocationErrors = {
        ...state,
        projectLocation: {
          data: state.projectLocation.data,
          errors: locationTabErrors,
        },
      };
      return updatedWithCreateLocationErrors;

    case UPDATE_BILLING_FIELD:
      const billing = state.billing;
      billing.data[action.field] = action.value;
      return { ...state, billing };

    // Overall tab related
    case UPDATE_TAB_DATA:
      const tabData = state[action.field];
      tabData.data = action.value;
      return { ...state, [action.field]: tabData };

    case "UPDATE_TEMPLATE_FIELD":
      const templateData = state.projectParameters.data;
      // templateData[action.field] = action.value;
      return {
        ...state,
        projectParameters: {
          data: {
            ...templateData,
            [action.field]: action.value,
          },
          errors: state.projectParameters.errors,
        },
      };

    /**
     * Project parameter state update
     */
    case "UPDATE_PROJECT_PARAMETERS":
      let parameterData = state.projectParameters.data;
      let section = parameterData.sections;
      section = {
        ...section,
        ...action.payload,
      };

      parameterData.sections = action.payload;
      return {
        ...state,
        projectParameters: {
          data: parameterData,
          errors: state.projectParameters.errors,
        },
      };

    /**
     * Project parameter field state update.
     */
    case "UPDATE_PARAMETER_FORM":
      const { fieldId, updatedValue } = action;
      let dependencyFields: any = {};

      const currentFormData =
        state.projectParameters.data.sections[action.section];

      if (Object.keys(OverTimeDependency).includes(fieldId.toString())) {
        dependencyFields[fieldId] = {
          ...currentFormData[fieldId],
          field_value: updatedValue,
        };
        for (let eachId of OverTimeDependency[fieldId.toString()]) {
          if (currentFormData[eachId].field_value == "1") {
            if (
              Object.keys(OverTimeFieldDependency).includes(eachId.toString())
            ) {
              for (let Id of OverTimeFieldDependency[eachId.toString()]) {
                dependencyFields[Id] = {
                  ...currentFormData[Id],
                  field_value: "00:00",
                };
              }
            }
            dependencyFields[eachId] = {
              ...currentFormData[eachId],
              field_value: "0",
            };
          }
          //
          if (
            Object.keys(OverTimeFieldDependency).includes(fieldId.toString())
          ) {
            for (let i of OverTimeFieldDependency[fieldId.toString()]) {
              dependencyFields[i] = {
                ...currentFormData[i],
                field_value:
                  updatedValue == "0"
                    ? "00:00"
                    : currentFormData[i].field_value,
              };
            }
          }
        }
        return {
          ...state,
          projectParameters: {
            ...state.projectParameters,
            data: {
              ...state.projectParameters.data,
              sections: {
                ...state.projectParameters.data.sections,
                [action.section]: {
                  ...state.projectParameters.data.sections[action.section],
                  ...dependencyFields,
                },
              },
            },
          },
        };
      } else if (
        Object.keys(OverTimeFieldDependency).includes(fieldId.toString())
      ) {
        for (let eachId of OverTimeFieldDependency[fieldId.toString()]) {
          dependencyFields[eachId] = {
            ...currentFormData[eachId],
            field_value:
              updatedValue == "0"
                ? "00:00"
                : currentFormData[eachId].field_value,
          };
        }
      }

      // Update the main field
      dependencyFields[fieldId] = {
        ...currentFormData[fieldId],
        field_value: updatedValue,
      };

      return {
        ...state,
        projectParameters: {
          ...state.projectParameters,
          data: {
            ...state.projectParameters.data,
            sections: {
              ...state.projectParameters.data.sections,
              [action.section]: {
                ...state.projectParameters.data.sections[action.section],
                ...dependencyFields,
              },
            },
          },
        },
      };

    // let param = state.projectParameters.data;
    // if (
    //   param.sections[action.section] &&
    //   param.sections[action.section][action.fieldId]
    // ) {
    //   param.sections[action.section][action.fieldId].field_value =
    //     action.updatedValue;
    // }
    // return { ...state };

    case "UPDATE_TAB_DETAILS":
      return {
        ...state,
        tabs: action.tabs,
      };

    case UPDATE_FIELD_ERROR:
      //* used from https://git.infanion.com/i-projects/maxicon-frontend/-/commit/a8730988ddac2d576c298684a898659ed7456978
      const tabId = action.tab;
      const tabState = state[tabId];
      const updatedTabState = {
        ...tabState,
        errors: {
          ...tabState.errors,
          [action.fieldName]: action.error,
        },
      };
      return {
        ...state,
        [tabId]: updatedTabState,
      };

    case "UPDATE_TAB_ERROR":
      return {
        ...state,
        tabs: state.tabs.map((tab: any, index: any) => ({
          ...tab,
          error: index === action.tabIndex ? action.error : tab.error,
          draft: action.draft,
        })),
      };
    case PROJECT_OVERVIEW:
      let sequenceData = state.sequence;

      // adding the unsaved sequences to sequence array
      sequenceData.data.sequence = sequenceData.data.sequence.filter(
        (sequence: any) =>
          sequence.name.toLocaleLowerCase() !==
          sequenceData.data.edit?.toLocaleLowerCase()
      );
      if (sequenceData.data.sequenceName) {
        sequenceData.data.sequence.push({
          name: sequenceData.data.sequenceName,
          weeks: sequenceData.data.weeklyShifts,
        });
      }
      sequenceData.data.sequenceName = "";
      sequenceData.data.weeklyShifts = {};
      sequenceData.data.edit = "";

      return {
        ...state,
        sequence: sequenceData,
        projectOverview: action.payload.value,
      };

    case UPDATE_ERRORS_CONTACTPERSONS_TAB:
      const updatedContactPersonsTabWithErrors = {
        ...state,
        contactsPersons: {
          data: state.contactsPersons.data,
          errors: action.errors,
        },
      };

      return updatedContactPersonsTabWithErrors;

    case UPDATE_ERRORS_RESPONSIBLEPERSONS_TAB:
      const updatedRespPersonsTabWithErrors = {
        ...state,
        responsiblePerson: {
          data: state.responsiblePerson.data,
          errors: action.errors,
        },
      };
      return updatedRespPersonsTabWithErrors;

    case UPDATE_ERRORS_BILLING_TAB:
      const updatedBillingTabWithErrors = {
        ...state,
        billing: {
          data: state.billing.data,
          errors: action.errors,
        },
      };
      return updatedBillingTabWithErrors;

    case UPDATE_ERRORS_PARAMETER_TAB:
      const updatedParameterTabWithErrors = {
        ...state,
        projectParameters: {
          data: state.projectParameters.data,
          errors: action.errors,
        },
      };
      return updatedParameterTabWithErrors;

    case UPDATE_ERRORS_SHIFT_TAB:
      const updatedShiftsTabWithErrors = {
        ...state,
        shifts: {
          data: state.shifts.data,
          errors: action.errors,
        },
      };
      return updatedShiftsTabWithErrors;

    case INITIAL_STATE:
      return { ...JSON.parse(JSON.stringify(action.payload)) };

    case SIGNATURE_IMAGE:
      return {
        ...state,
        signatureImage: action.payload.value,
      };

    /**
     * Handling the change of sequence name in sequence tab
     */
    case SET_SEQUENCE_NAME:
      let updatedSequenceTabData = state.sequence.data;
      updatedSequenceTabData[action.field] = action.value;

      const updatedSequenceTabWithData = {
        ...state,
        sequence: {
          data: updatedSequenceTabData,
          errors: state.sequence.errors,
        },
      };
      return updatedSequenceTabWithData;

    /**
     * Handling the change of weeks select tags
     */
    case SET_WEEKLY_SHIFTS:
      let shiftsData = state.sequence.data;

      if (action.value.value === "") {
        delete shiftsData[action.field][action.handle];
      } else {
        shiftsData[action.field] = {
          ...state.sequence.data.weeklyShifts,
          [action.handle]: { ...action.value },
        };
      }

      const updatedShiftsData = {
        ...state,
        sequence: {
          data: shiftsData,
          errors: state.sequence.errors,
        },
      };
      return updatedShiftsData;

    /**
     * Adding sequence
     */
    case ADD_ANOTHER_SEQUENCE:
      let addAnotherSequenceTabData = state.sequence.data;
      const newName = addAnotherSequenceTabData.sequenceName;

      if (newName) {
        // Adding the seuqence only if it is not empty
        addAnotherSequenceTabData.sequence =
          addAnotherSequenceTabData?.sequence?.filter(
            (sequence: any) =>
              sequence?.name?.toLocaleLowerCase() !==
              addAnotherSequenceTabData?.edit?.toLocaleLowerCase()
          );
        addAnotherSequenceTabData.sequence.push({
          name: newName,
          weeks: addAnotherSequenceTabData.weeklyShifts,
        });
        addAnotherSequenceTabData.sequenceName = "";
        addAnotherSequenceTabData.weeklyShifts = {};
        addAnotherSequenceTabData.edit = "";
      }

      const addAnotherData = {
        ...state,
        sequence: {
          data: addAnotherSequenceTabData,
          errors: {},
        },
      };
      return addAnotherData;

    /**
     * Handling the edit sequence, making the seuqence editable by prefilling in form
     */
    case EDIT_SEQUENCE:
      let editSequencedata = { ...state.sequence.data };
      let sequence = editSequencedata?.sequence.find(
        (sequence: any) => sequence.name === action.handle
      );

      editSequencedata.sequenceName = sequence.name;
      editSequencedata.edit = action.handle;
      editSequencedata.weeklyShifts = sequence.weeks;

      return {
        ...state,
        sequence: {
          data: editSequencedata,
          errors: {},
        },
      };

    /**
     * Handling the delete sequence
     */
    case DELETE_SEQUENCE:
      let deleteSequencedata = state.sequence.data;
      deleteSequencedata.sequence = deleteSequencedata.sequence.filter(
        (sequence: any) => sequence.name !== action.handle
      );

      return {
        ...state,
        sequence: {
          data: deleteSequencedata,
          errors: {},
        },
      };

    /**
     * Updating the errors in sequence tab
     */
    case UPDATE_ERRORS_SEQUENCE_TAB:
      const updatedSequenceTabWithErrors = {
        ...state,
        sequence: {
          data: state.sequence.data,
          errors: action.errors,
        },
      };
      return updatedSequenceTabWithErrors;

    //Updating errors in project parameters tab
    case UPDATE_PARAMETERS_ERRORS:
      return {
        ...state,
        projectParameters: {
          ...state.projectParameters,
          data: {
            ...state.projectParameters.data,
            sections: {
              ...state.projectParameters.data.sections,
              [action.section]: {
                ...state.projectParameters.data.sections?.[action.section],
                [action.fieldId]: {
                  ...state.projectParameters.data.sections?.[action.section]?.[
                    action.fieldId
                  ],
                  field_error:
                    Object.keys(action.value).length > 0
                      ? action.value[action.fieldId]
                      : "",
                },
              },
            },
          },
        },
      };
    case "UPDATE_CONTACT_FIELD_ERROR": {
      const { fieldName, error, tab } = action;
      // Check if the tab is 'contactsPersons'
      if (tab === "contactsPersons") {
        return {
          ...state,
          contactsPersons: {
            ...state.contactsPersons,
            errors: {
              ...state.contactsPersons.errors,
              contactsPersons: {
                ...state.contactsPersons.errors.contactsPersons,
                [fieldName]: error,
              },
            },
          },
        };
      }
      // Return the original state if tab is not 'contactsPersons'
      return state;
    }
    case UPDATE_FETCH_DATA:
      return {
        ...state,
        projectParameters: {
          ...state.projectParameters,
          data: {
            ...state.projectParameters.data,
            fetchData: action.status,
          },
        },
      };

    case SET_CONTACT_PERSON_OPTION:
      const contactOptions = { ...state.options };

      // Check if the field already has an array, if not, initialize it as an empty array
      if (!Array.isArray(contactOptions[action.field])) {
        contactOptions[action.field] = [];
      }

      // Add new data to the existing array
      contactOptions[action.field] = [
        ...contactOptions[action.field],
        action.value,
      ];

      return { ...state, options: contactOptions };

    case SET_LOCATION_OPTION:
      const locations = { ...state.options };
      const newLocation = action.value[0];
      if (locations[action.field]) {
        locations[action.field] = [
          ...locations[action.field], // existing locations
          newLocation, // add the new location
        ];
      } else {
        // If it doesn't exist, create a new array with the new value
        locations[action.field] = [newLocation];
      }

      // Return the updated state with the new locations
      return { ...state, options: locations };
    case SET_PROJECT_LOCATION_FIELD_EMPTY:
      const emptiedProjectLocationData = {
        company: state.general.data.company,
        location: "",
        phone: "",
        street: "",
        number: "",
        box: "",
        zipcode: "",
        city: "",
        country: null,
        info: "",
      };

      const emptiedProjectLocationErrors = {
        company: "",
        location: "",
        phone: "",
        street: "",
        number: "",
        zipcode: "",
        city: "",
        country: "",
      };

      const updatedWithEmptyProjectLocation = {
        ...state,
        projectLocation: {
          data: emptiedProjectLocationData,
          errors: emptiedProjectLocationErrors,
        },
      };

      return updatedWithEmptyProjectLocation;

    case COMPETENCE_TAB_DATA:
      return {
        ...state,
        competences: {
          ...state?.competences,
          ...action?.value,
        },
      };
    case COMPETENCE_CHANGE:
      return {
        ...state,
        competences: action?.field
          ? competenceSelected(state, action)
          : competenceRange(state, action),
      };
    default:
      return state;
  }
};

export default ProjectReducer;
export const competenceSelected = (state: any, action: any) => {
  const selection = action.payload?.selection;
  const selectedData = JSON.parse(JSON.stringify(action?.payload?.selected)); // Deep copy
  const selectedId = action?.payload?.id;

  let updatedForm: any = JSON.parse(
    JSON.stringify(state.competences?.competence)
  ); // Deep copy
  if (selectedData?.length === 0) {
    updatedForm = updatedForm?.filter(
      (item: { key: any }) => item?.key !== selection
    );
  } else {
    const existingSelection = updatedForm.find(
      (item: { key: any }) => item.key === selection
    );

    if (existingSelection) {
      const filteredData = selectedData.map((eachItem: any) => {
        const optionData = existingSelection.options.find(
          (eachIt: any) => eachIt.value == eachItem.value
        );
        if (optionData) {
          return { ...eachItem, range: optionData.range };
        }
        return eachItem;
      });

      existingSelection.options = filteredData;
    } else {
      const newSelection = {
        competence_id: selectedId,
        key: selection,
        label: selection,
        options: selectedData,
      };
      updatedForm?.push(newSelection);
    }
  }

  return { ...state.competences, competence: updatedForm };
};

// Reducer function to handle COMPETENCE_RANGE action
export const competenceRange = (state: any, action: any) => {
  const { value, section, index } = action.payload;
  let updatedForm: any = JSON.parse(
    JSON.stringify(state?.competences?.competence)
  ); // Deep copy

  const updatedData = updatedForm?.map((item: any) => {
    if (item.key === section && item.options[index]) {
      item.options[index].range = value;
    }
    return item;
  });

  return {
    ...state.competences,
    competence: updatedData,
  };
};
