import { Link, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import VerifiedUser from "static/images/VerifiedUser";
import Staricons from "static/images/Staricons";
import Microphone from "static/images/Microphone";
import { ApiCall } from "services/ApiServices";
import { M_IDENTITY_MANAGER } from "constants/Constants";
import AccessControl from "services/AccessControl";
import CardTitle from "components/atoms/CardTitle";
import CardCircle from "components/atoms/CardCircle";
import { t } from "pages/microservices/masterData/translation/Translation";
import Count from "components/atoms/Count";
import RecruitmentCard from "components/atoms/RecruitmentCard";
import MatchingProfileCard from "components/Matching/MatchingCandidateProfileCard";
import { MatchingCandidateProfileCardType } from "components/Matching/MatchingCandidateProfileCardType";
import StarterIcon from "static/images/StarterIcon";
import { GET_COUNTS_FOR_CANDIDATES } from "routes/ApiEndpoints";
import ShortlistIcon from "static/images/ShortlistIcon";
import XmarkIcon from "static/images/XmarkIcon";
import { PATH_EMPLOYEE_MANAGE, EDIT_CANDIDATE, PATH_MANAGE_APPLICANTS } from "constants/Paths";
import BackButton from "components/atoms/BackButton";
import CandidateCentralIcon from "static/images/CandidateCentralIcon";
// import ShortlistIcon from "static/images/ShortlistIcon";

interface PersonalInformation {
  skills: string;
  languages: string;
  internalInfo: string;
  totalExperience: string;
  currentOrganization: string;
  salaryExpectation: string;
}

const CandidateCorner = () => {
  const [stageCounts, setStageCounts] = useState<any[]>([]);
  const [personalInformation, setPersonalInformation] =
    useState<PersonalInformation>({
      skills: "",
      languages: "",
      internalInfo: "",
      totalExperience: "",
      currentOrganization: "",
      salaryExpectation: "",
    });

  const [profileCardData, setProfileCardData] =
    useState<MatchingCandidateProfileCardType>({
      candidateId: null,
      firstName: "",
      lastName: "",
      functions: "",
      mobNumber: "",
      email: "",
      street: "",
      number: "",
      box: "",
      zipCode: "",
      city: "",
      picFileName: "",
      picFilePath: "",
      country: "",
      cvData: "",
      profilePicData: "",
    });

  const { candidateId } = useParams<{ candidateId: string }>();

  const location = useLocation();
  const pathName = location.pathname.split('/')[1];
  const isCandidate = location.search?.includes('candidate');
  const [candidateStatus, setCandidateStatus] = useState(0);
  const fetchCounts = async () => {
    const data = {
      candidateId: candidateId,
    };
    const response = await ApiCall.service(
      GET_COUNTS_FOR_CANDIDATES,
      "POST",
      data,
      true,
      M_IDENTITY_MANAGER
    );
    if (response?.status === 200) {
      setCandidateStatus(response?.data?.userDetails?.status);
      setStageCounts([response?.data?.userDetails]);
      setProfileCardData((prev) => ({
        ...prev,
        ...response.data?.userDetails,
        // ...{ functions: response.data[1].candidateInfo?.functions },
      }));
      setPersonalInformation((prev) => ({
        ...prev,
        ...response?.data?.personalDetails,
      }));
      // setPersonalInformation((prev) => ({
      //   ...prev,
      //   skills: response.data[1].candidateInfo?.softskills,
      //   languages: response.data[1].candidateInfo?.languages,
      //   internalInfo:
      //     response.data[1].candidateInfo?.candidateWorkExperience[3],
      //   totalExperience:
      //     response.data[1].candidateInfo?.candidateWorkExperience[0],
      //   currentOrganization:
      //     response.data[1].candidateInfo?.candidateWorkExperience[1],
      //   salaryExpectation:
      //     response.data[1].candidateInfo?.candidateWorkExperience[2],
      // }));
    }
  };

  useEffect(() => {
    fetchCounts();
  }, []);

  const getTotalCountForStage = (stageName: string): number => {
    const stage = stageCounts.find((stage) => stage?.stageName === stageName);
    return stage ? stage.totalcount : 0;
  };

  const CardStyle = {
    width: "3vw",
    height: "3vw",
    boxShadow: "rgba(120, 203, 233, 0.5) 0px 0.156vw 0.365vw 0.156vw"
  }

  return (
    <>
      <div style={{ marginBottom: "1.5vw" }}>
        <MatchingProfileCard profileCardProps={profileCardData} isCandidateCreation={isCandidate} />
      </div>
      <div>
        <div className="row equal-cols recruiter-action-tabs candidateCornerTabs">
          {!isCandidate && pathName === "applicant" && candidateStatus === 1 && (
            <div className="col-3">
              <Link to={`${EDIT_CANDIDATE}/${candidateId}?sourcePage=${"applicant"}`}>
                <RecruitmentCard
                  className={`card border-0 card-shadow justify-content-center`}
                >
                  <div className="row">
                    <div className="col-xxl-10 col-xl-9 col-lg-8 align-self-center col-md-6">
                      <CardTitle
                        title={t("Candidate creation")}
                        className={`recruitment-card-title`}
                      />
                    </div>
                    <div className="col-xxl-2 col-xl-3 col-lg-4 px-0 align-self-center col-md-6">
                      <CardCircle
                        {...CardStyle}
                        className="float-end justify-content-center"
                      >
                        <CandidateCentralIcon />
                      </CardCircle>
                    </div>
                  </div>
                </RecruitmentCard>
              </Link>
            </div>
          )}
          {pathName === "candidate" || pathName === "applicant" && (
            <div className="col-md-3">
              <Link to={`/manage-screening/${candidateId}`}>
                <RecruitmentCard
                  className={`card border-0 card-shadow justify-content-center`}
                >
                  <div className="row">
                    <div className="col-xxl-10 col-xl-9 col-lg-8 align-self-center col-md-6">
                      <CardTitle
                        title={t("Screening")}
                        className={`recruitment-card-title`}
                      />
                    </div>
                    <div className="col-xxl-2 col-xl-3 col-lg-4 px-0 align-self-center col-md-6">
                      <CardCircle
                        {...CardStyle}
                        className="float-end justify-content-center"
                      >
                        {getTotalCountForStage("Screening") === 1 ? (
                          <StarterIcon />
                        ) : (
                          <VerifiedUser />
                        )}
                      </CardCircle>
                    </div>
                  </div>
                </RecruitmentCard>
              </Link>
            </div>
          )}
          {pathName == "employee" && (
            <div className="col-md-3">
              {/* <Link
                // to={`/todos/overview?type=completedtodos&stage=presented&template=candidateCorner&candidateId=${candidateId}`}
                to={""}
                state={{ origin: "candidateCorner" }}
              > */}
              <RecruitmentCard
                className={`card border-0 card-shadow justify-content-center`}
              >
                <div className="row">
                  <div className="col-xxl-10 col-xl-9 col-lg-8 align-self-center col-md-6">
                    <Count
                      totalValue={getTotalCountForStage("Presented")}
                      className={`recruitment-card-num`}
                    />
                    <CardTitle
                      title={t("Presented")}
                      className={`recruitment-card-title`}
                    />
                  </div>
                  <div className="col-xxl-2 col-xl-3 col-lg-4 px-0 align-self-center col-md-6">
                    <CardCircle
                      {...CardStyle}
                      className="float-end justify-content-center"
                    >
                      <Staricons />
                    </CardCircle>
                  </div>
                </div>
              </RecruitmentCard>
              {/* </Link> */}
            </div>
          )}

          {pathName == "employee" && (
            <div className="col-md-3">
              {/* <Link
                //to={`/todos/overview?type=completedtodos&stage=interview&template=candidateCorner&candidateId=${candidateId}`}
                to={""}
                state={{ origin: "candidateCorner" }}
              > */}
              <RecruitmentCard
                className={`card border-0 card-shadow justify-content-center`}
              >
                <div className="row">
                  <div className="col-xxl-10 col-xl-9 col-lg-8 align-self-center col-md-6">
                    <Count
                      totalValue={getTotalCountForStage("Interview")}
                      className={`recruitment-card-num`}
                    />
                    <CardTitle
                      title={t("Interviewed")}
                      className={`recruitment-card-title`}
                    />
                  </div>
                  <div className="col-xxl-2 col-xl-3 col-lg-4 px-0 align-self-center col-md-6">
                    <CardCircle
                      {...CardStyle}
                      className="float-end justify-content-center"
                    >
                      <Microphone />
                    </CardCircle>
                  </div>
                </div>
              </RecruitmentCard>
              {/* </Link> */}
            </div>
          )}

          {pathName == "employee" && (
            <div className="col-md-3">
              {/* <Link
                //to={`/todos/overview?type=mytodos&stage=shortlist&template=candidateCorner&candidateId=${candidateId}`}
                to={""}
                state={{ origin: "candidateCorner" }}
              > */}
              <RecruitmentCard
                className={`card rounded-3 p-4 border-0 card-shadow justify-content-center`}
              >
                <div className="row">
                  <div className="col-xxl-10 col-xl-9 col-lg-8 align-self-center col-md-6">
                    <Count
                      totalValue={getTotalCountForStage("Wage")}
                      className={`recruitment-card-num`}
                    />
                    <CardTitle
                      title={t("Shortlist")}
                      className={`recruitment-card-title`}
                    />
                  </div>
                  <div className="col-xxl-2 col-xl-3 col-lg-4 px-0 align-self-center col-md-6">
                    <CardCircle
                      {...CardStyle}
                      className="float-end justify-content-center"
                    >
                      <ShortlistIcon />
                    </CardCircle>
                  </div>
                </div>
              </RecruitmentCard>
              {/* </Link> */}
            </div>
          )}
        </div>
        <div className="row equal-cols recruiter-action-tabs" style={{ marginTop: "1vw", marginBottom: "1vw" }}>
          <div className="col-md-6 align-self-center recruitment-card-num ">
            <div>
              {t(`The ${pathName}`)}
              &nbsp;
              <span className="frauncesItalic color-dark-skyblue">
                {t("corner")} !
                {/* <span className="ms-3">
                  <img
                    src="/static/images/Trophy.png"
                    alt="Trophy"
                    width={25}
                  />
                </span> */}
              </span>
            </div>
          </div>
          {location?.state?.element == "employee" && (
            <div className="col-md-3">
              {/* <Link
                //to={`/todos/overview?type=mytodos&stage=starter&template=candidateCorner&candidateId=${candidateId}`}
                to={""}
                state={{ origin: "candidateCorner" }}
              > */}
              <RecruitmentCard
                className={`card border-0 card-shadow justify-content-center`}
              >
                <div className="row">
                  <div className="col-xxl-10 col-xl-9 col-lg-8 align-self-center col-md-6">
                    <CardTitle
                      title={t("Starter")}
                      subTitle={t("ja")}
                      className={`recruitment-card-title`}
                    />
                  </div>
                  <div className="col-xxl-2 col-xl-3 col-lg-4 px-0 align-self-center col-md-6">
                    <CardCircle
                      {...CardStyle}
                      className="float-end justify-content-center"
                    >
                      {" "}
                      <StarterIcon />
                      {/* {/ {getTotalCountForStage("Shortlist") === 1 ? ( /}
                        <StarterIcon />
                        {/ ) : ( /}
                        {/ <VerifiedUser /> /}
                        {/ )} /} */}
                    </CardCircle>
                  </div>
                </div>
              </RecruitmentCard>
              {/* </Link> */}
            </div>
          )}
          {pathName == "employee" && (
            <div className="col-md-3">
              {/* <Link
                //to={`/todos/overview?type=mytodos&stage=stopper&template=candidateCorner&candidateId=${candidateId}`}
                to={""}
                state={{ origin: "candidateCorner" }}
              > */}
              <RecruitmentCard
                className={`card border-0 card-shadow justify-content-center`}
              >
                <div className="row">
                  <div className="col-xxl-10 col-xl-9 col-lg-8 align-self-center col-md-6">
                    <CardTitle
                      title={t("Stopper")}
                      subTitle={t("nee")}
                      className={`recruitment-card-title`}
                    />
                  </div>
                  <div className="col-xxl-2 col-xl-3 col-lg-4 px-0 align-self-center col-md-6">
                    <CardCircle
                      {...CardStyle}
                      className="float-end justify-content-center"
                    >
                      <XmarkIcon />
                    </CardCircle>
                  </div>
                </div>
              </RecruitmentCard>
              {/* </Link> */}
            </div>
          )}
        </div>
        <div className="row equal-cols">
          <div className="col-6 position-relative">
            <div
              className="card border-0 card-shadow position-relative text-white"
              style={{
                backgroundColor: "var(--primaryBrandColor)",
                padding: "1vw"
              }}
            >
              <div style={{ padding: "1vw" }}>
                <div className="position-relative jobDescription about-name" style={{ marginBottom: "1.5vw" }}>
                  {t("About ")} {profileCardData?.firstName}
                  {/* <span className="position-absolute starIcon">
                    <StarIcon />
                  </span> */}
                </div>
                <div
                  className={`d-flex ${personalInformation.internalInfo &&
                    personalInformation.internalInfo !== ""
                    ? ""
                    : "justify-content-center noInfoText"
                    } fw-bold`}
                >
                  {personalInformation.internalInfo &&
                    personalInformation.internalInfo !== ""
                    ? personalInformation.internalInfo
                    : t("No information available")}
                </div>
              </div>
            </div>
            {/* <div className="px-4 mt-4 candidateJobSteps">
              <div className="p-2">
                <div className="row">
                  <div className="col-6 marginBottomPoint5">
                    <Link to="" className="text-decoration-none">
                      <div className="link-btn py-3 px-4 rounded-3 shadow-none d-flex align-items-center justify-content-between">
                        {t("Request an interview")}
                        <FontAwesomeIcon icon={faAngleRight} className="ms-4" />
                      </div>
                    </Link>
                  </div>
                  <div className="col-6 marginBottomPoint5">
                    <Link to="" className="">
                      <div className="link-btn py-3 px-4 rounded-3 shadow-none d-flex align-items-center justify-content-between">
                        {t("Send message")}
                        <FontAwesomeIcon icon={faAngleRight} className="ms-4" />
                      </div>
                    </Link>-
                  </div>
                  <div className="col-6 marginBottomPoint5">
                    <Link to="" className="">
                      <div className="link-btn py-3 px-4 rounded-3 shadow-none d-flex align-items-center justify-content-between">
                        {t("Reject")}
                        <FontAwesomeIcon icon={faAngleRight} className="ms-4" />
                      </div>
                    </Link>
                  </div>
                  <div className="col-6 marginBottomPoint5">
                    <Link to="" className="">
                      <div className="link-btn py-3 px-4 rounded-3 shadow-none d-flex align-items-center justify-content-between">
                        {t("Save")}
                        <FontAwesomeIcon icon={faAngleRight} className="ms-4" />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="col-6">
            <div className="card border-0 card-shadow position-relative dashboard-myBudget-wrapper" style={{ padding: "1vw" }}>
              <div className="row">
                <div className="col-md-12 marginBottomPoint5">
                  <div className="d-flex align-items-center">
                    {/* <span>
                      <img
                        src="/static/images/Handrise.png"
                        alt="Hand rise"
                        width={28}
                      />
                    </span> */}
                    <span className="fw-bold">{t("Personal Information")}</span>
                  </div>
                </div>
                <div className="col-md-12 position-relative">
                  <div className="marginBottomPoint5">
                    {t(`Current organisation: `)}
                    <span className="personal-info">
                      {personalInformation.currentOrganization &&
                        personalInformation.currentOrganization !== ""
                        ? personalInformation.currentOrganization
                        : t("NA")}
                    </span>
                  </div>
                  {/* <div className="marginBottomPoint5">
                      {t(`Current salary: `)}
                      <span className="personal-info">
                        {t("NA")}
                      </span>
                    </div> */}
                  <div className="marginBottomPoint5">
                    {t(`Salary expectation: `)}
                    <span className="personal-info">
                      {personalInformation.salaryExpectation &&
                        personalInformation.salaryExpectation !== ""
                        ? personalInformation.salaryExpectation
                        : t("NA")}
                    </span>
                  </div>
                  <div className="marginBottomPoint5">
                    {t(`Total experience: `)}
                    <span className="personal-info">
                      {personalInformation.totalExperience &&
                        personalInformation.totalExperience !== ""
                        ? personalInformation.totalExperience
                        : t("NA")}
                    </span>
                  </div>
                  {/* <div className="marginBottomPoint5">
                      {t(`Relevant experience: `)}
                      <span className="personal-info">
                        {t("NA")}
                      </span>
                    </div> */}
                  {/* <div className="marginBottomPoint5">
                    {t(`Language skills: `)}
                    <span className="personal-info">
                      {personalInformation.languages ?? t("NA")}
                    </span>
                  </div> */}
                  <div className="marginBottomPoint5">
                    {t(`Skills: `)}
                    <span className="personal-info">
                      {personalInformation.skills ?? t("NA")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="border rounded-3 p-3">
          <div className="p-2">
            <div className="posiiton-relative">
              <div className="row">
                <div className="col-md-12 mb-5">
                  <div className="d-flex align-items-center">
                    <span>
                      <img src='../static/images/arrow-target.png' alt='Arrow dot' width={22} />
                    </span>
                    <span className='ms-3 recruitment-card-num'>
                      Inside the talent treasure vault
                    </span>
                  </div>
                </div>
                <div className="col-md-12 candidateCornerTabs">
                  <TabView scrollable>
                    {scrollableTabs.map((tab) => {
                      return (
                        <TabPanel key={tab.title} header={tab.title}>
                          <ul className='list-unstyled mt-4'>
                            <li>
                              <div className='border rounded-3 p-3 candidateCornerTable'>
                                <table className='table table-hover mb-0'>
                                  <tr>
                                    <td className='p-0'>
                                      <GripVerticalIcon />
                                    </td>
                                    <td>
                                      Title 1
                                    </td>
                                    <td>
                                      <Link to={''} className='link-btn p-3 rounded-3 float-end'>Add experience <span className='ms-4'> + </span></Link>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </li>
                          </ul>
                        </TabPanel>
                      );
                    })}
                  </TabView>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          <BackButton />
        </div>
      </div>
    </>
  );
};

export default CandidateCorner;
