import React, {
  ChangeEvent,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
} from "react";

interface CheckBoxFieldProps {
  name: string;
  label?: string;
  customStyle?: React.CSSProperties;
  className?: string;
  id?: string;
  isChecked?: boolean;
  onChangeHandler?: (event: ChangeEvent<HTMLInputElement>) => void;
  isClicked?: MouseEventHandler<HTMLInputElement>;
  disable?: boolean;
  value?: string | number;
  lineHeight?: string | number;
  children?: ReactNode;
}

let styles_id: React.CSSProperties = {};

/**
 * 
 * @param param0 
 * @returns
 * @example
  <CheckBoxField
    label={t("")}
    // customStyle={}
    // className={}
    name={"myFieldName"}
    id={"myFieldNameId"}
    isChecked={}
    onChangeHandler={}
    // isClicked={}
    disable={false}
  ></CheckBoxField>;  
 */
const CheckBoxField: React.FC<CheckBoxFieldProps> = ({
  name,
  label,
  customStyle = {},
  className,
  id = "",
  isChecked,
  onChangeHandler,
  isClicked,
  disable = false,
  value,
  lineHeight,
  children,
}) => {
  const handleClick = (event: MouseEvent<HTMLLabelElement>) => {
    // Only trigger the checkbox when clicking on the input element
    if ((event.target as HTMLInputElement).tagName !== "INPUT") {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <>
      <div className="form-check d-inline-block" style={{ minHeight: "1vw" }}>
        <input
          name={name}
          type="checkbox"
          checked={isChecked || false}
          id={id}
          disabled={disable}
          onChange={onChangeHandler}
          onClick={isClicked}
          value={value}
          className="form-check-input shadow-none rounded-0 cursor-pointer"
          style={{ border: "1px solid #4759694D" }}
        />

        <label
          style={{ ...styles_id, ...customStyle, lineHeight }}
          className={`${className} color-dark-skyblue ${
            disable ? "" : "checkBoxLabel"
          } `}
          htmlFor={id}
        >
          {label}
        </label>
        {children}
      </div>
    </>
  );
};

export default CheckBoxField;
