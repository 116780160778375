export const PREVIOUS_STEP = "PREVIOUS_STEP";
export const NEXT_STEP = "NEXT_STEP";
export const UPDATE_TAB_DETAILS = "UPDATE_TAB_DETAILS";
export const UPDATE_TAB_ERROR = "UPDATE_TAB_ERROR";

export const SET_DROPDOWN_DATA = "SET_DROPDOWN_DATA";
export const UPDATE_FIELD = "UPDATE_FIELD";
export const UPDATE_ERROR_FIELD = "UPDATE_ERROR_FIELD";
export const EDIT_FIELD = "EDIT_FIELD";
export const UPDATE_TAB_DATA= "UPDATE_TAB_DATA";

//Catgeory
export const ADD_CATEGORY = "ADD_CATEGORY";
export const REMOVE_CATEGORY = "REMOVE_CATEGORY";
export const ADD_FUNCTION = "ADD_FUNCTION";
export const REMOVE_FUNCTION = "REMOVE_FUNCTION";
export const ADD_AGE_DATA = "ADD_AGE_DATA";
export const REMOVE_AGE_DATA = "REMOVE_AGE_DATA";

//tab details
export const PC_DETAILS = "pcDetails";
export const CATEGORY_AND_FUNCTIONS = "categoryAndFunctions";
export const CATEGORIES = "categories";
export const FUNCTIONS = "functions";
export const AGE = "age";
export const EMPLOYEE_TYPE = "employeeType";
export const SALARY_BENEFITS = "salaryBenefits";
