import PlannedEventBox from "../atoms/plannedEventBox";
import { dateUtils } from "./dateUtils";
export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  }
}


export const getRandomInt = (min, max) => {
  return Date.now() + Math.random().toString(36).substring(7);
  // min = Math.ceil(min);
  // max = Math.floor(max);
  // return Math.floor(Math.random() * (max - min + 1)) + min; // The maximum is inclusive and the minimum is inclusive
}

export const setCurrentViewUnit = (view) => {
    let week = getWeek(view)
    let startdate = dateUtils.getStartOfCurrentWeek()
    let enddate = dateUtils.getDateAfterWeeks(startdate, week)
    return [startdate, enddate];
}

export const getWeek = (view) => {
  let week = null;
  let type = (view != null && view.value != undefined) ? view.value : "deafult";
  //view structure =>  { value: "day", label: "Day" },
  switch (type) {
    case "week":
    case "week4":
      week=4;
      break;
    case "week2":
      week=2;
      break;
    case "week8":
      week=8;
      break
      // case "day":
      // case "month":
      //   return dateUtils.getCurrentMonth();
      // case "year":
      //   return dateUtils.getCurrentMonth();
    default:
      week=null
      break;
      // return null;

    }
    return week;
}
export const renderPlannedEvents = (planningState, employee, date, highlightedDiv, handleWeeklyClickEventOnPlannedUnit, handleClick, handleRightClick, handleAction, contextMenuId, tooltipPosition ) => {
  let planslist = {};
  let employeePlans = planningState.planningEvents &&
    planningState.planningEvents[employee.id] &&
    planningState.planningEvents[employee.id].plans;

  if (employeePlans) {
    employeePlans.forEach((plan) => {
      let plandate = dateUtils.getFormattedDates(plan.plan_date);
      if (plandate === date['value']) {
        if (!planslist[plandate]) {
          planslist[plandate] = [];
        }
        planslist[plandate].push(plan);
      }
    });
  }

  return Object.entries(planslist).map(([date, plans]) => (
    <div className="position-absolute top-0 d-flex w-100 h-100">
      <PlannedEventBox
        key={date} // Use date as the unique key for each element
        date={date}
        employee_id={employee.id}
        handleWeeklyClickEventOnPlannedUnit={handleWeeklyClickEventOnPlannedUnit}
        plan={plans} // Pass the entire plans array for the date
        // frequency={frequency}
        name = {employee.name}
        handleRightClick={handleRightClick}
        handleClick={handleClick}
        handleAction={handleAction}
        contextMenuId={contextMenuId}
        tooltipPosition={tooltipPosition}
        highlightedDiv={highlightedDiv}
      />
    </div>
  ));
}

export const getEmployeeNameById = (employeeData, id) => {
  const employee = employeeData.employees.find(emp => emp.id == id.toString());
  if (employee) {
    return employee.name;
  } else {
    return null; // Return null if employee with given ID is not found
  }
}

export const getList = (data, type, value, option = false) => {
  let list = [];
  try {
    if(option) {
      list = data.filter((item) => (item[type] == value && item['proposal_status'] == 1))
    } else {
      list = data.filter((item) => (item[type] == value))
    }
    return list;
  } catch (error) {
    return [];
  }
}

export const getFilteredTitle = (list, name, id, field = '') => {
  if (id == null) return "";
  let fieldval = field != "" ? field : "label";
  const fieldvalue = list
    ?.filter((item) => item[name] == id)
    ?.map((item) => item[fieldval]);
    if(fieldvalue?.length > 1) {
      return fieldvalue?.join(', ');
    } else {
      return fieldvalue;
    }
}

export const getTitle = (list, id, truncate = null, value = 'value', label = 'label') => {
  let titleArray = list
      ?.filter((item) => item[value] == id)
      ?.map((item) => item[label]) || [];

  // Join the array into a string or return a default string
  let title = titleArray.length ? titleArray.join(', ') : titleArray;
  if (truncate != null && title.length > truncate) {
      return (title.substring(0, truncate)).toUpperCase();
  }
  return title;
}