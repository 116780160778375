// components/LoadingOverlay.tsx
import LoadingIcon from './LoadingIcon';
import 'static/css/common.css';

interface Props {
  display?: string
}

const LoadingOverlay: React.FC<Props> = ({ display }) => {
  const divStyle: React.CSSProperties = {
    display: display || 'none', // Set a default value for display if not provided
  };
  return (
    <>
      <div id="overlay" style={divStyle} className="fullscreen-loading-container">
        <LoadingIcon width={"5vw"} />
      </div>
    </>
  );
};

export default LoadingOverlay;