import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "components/atoms/Button";
import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Option,
  validateEmail,
  validateForm,
  validateMultiSelectField,
  validatePhoneNumber,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import PhoneInput from "react-phone-number-input";
import { ApiCall } from "services/ApiServices";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";

import { t } from "pages/microservices/masterData/translation/Translation";
import CloseFile from "static/images/CloseFile";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import LabelField from "components/atoms/LabelField";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";

import {
  CREATE_CONTACT_PERSON,
  EDIT_CONTACT_PERSON,
  FETCH_LOCATION_BY_COMP_ID,
  GET_COMPANY,
} from "routes/ApiEndpoints";
import {
  CONTACT_PERSON_CREATED_SECCESFULL,
  M_COMPANY_CREATION,
} from "constants/Constants";

import { OptionProps } from "utils/TypeAnnotations";
import { MANAGE_CONTACT_PERSON } from "constants/Paths";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { E164Number } from "libphonenumber-js";
import Title from "components/atoms/Title";
import BackButton from "components/atoms/BackButton";
import Calender from "pages/microservices/CandidateCreation/helpers/Calender";

interface InputItem {
  firstName: any;
  lastName: any;
  company: Option | null;
  tittle: Option | null;
  //gender: Option | null;
  email: any;
  language: Option | null;
  location: [];
  role: [];
  linkedIn?: any;
  functionData: any;
  info: any;
  dob: Date | string | null | any;
  // phoneNumber: any;
  mobileNumber?: E164Number | string;
  businessUnit?: Option | null;
}

interface ValidationRules {
  [key: string]: Function[];
}

function CreateContact() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const editId = urlSearchParams.get("edit");
  const viewId = urlSearchParams.get("view");
  const isView = !!viewId;
  const isDisabled = !!editId;
  const [companyOption, setCompanyOption] = useState<OptionProps[]>([]);
  const [tittleOption, setTittleOption] = useState<OptionProps[]>([]);
  const [genderOption, setgenderOption] = useState<OptionProps[]>([]);
  const [languageOption, setLanguageOption] = useState<OptionProps[]>([]);
  const [locationOption, setLocationOption] = useState<OptionProps[]>([]);
  const [roleOption, setRoleOption] = useState<OptionProps[]>([]);
  const [functionOption, setFunctionOption] = useState<OptionProps[]>([]);
  // const [isEdit, setIsEdit] = useState(editId ? true : false);
  const [businessUnitOption, setBusinessUnitOption] = useState<OptionProps[]>(
    []
  );
  const [formData, setFormData] = useState<InputItem[]>([
    {
      firstName: "",
      lastName: "",
      company: null,
      //  gender: null,
      email: "",
      language: null,
      location: [],
      role: [],
      tittle: null,
      linkedIn: "",
      functionData: "",
      info: "",
      dob: "",
      //  phoneNumber: "",
      mobileNumber: "" as E164Number,
      businessUnit: null,
    },
  ]);

  const [errors, setErrors] = useState<any>([]);
  const navigate = useNavigate();

  const changeHandler = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;
    const list = formData.map((item) => item);
    list[index][name as keyof InputItem] = value;
    validateInput(name, value, index);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiCall.getService(
          GET_COMPANY,
          "GET",
          M_COMPANY_CREATION,
          false
        );

        setCompanyOption(response.company);
        setgenderOption(response.gender);
        setLanguageOption(response.language);
        setRoleOption(response.role);
        setTittleOption(response.tittle);
        //setLocationOption(response.location);
        setBusinessUnitOption(response.businessUnit);
      } catch (error) {
        // console.error(error);
      }
    };
    fetchData();
  }, []);
  const handleSelectChange = (
    selectedOption: any,
    name: string,
    index: number
  ) => {
    const handleCommonLogic = (value: any) => {
      setFormData((prevData) => {
        const newData = [...prevData];
        const updatedObject = { ...newData[index], [name]: value };
        newData[index] = updatedObject;
        validateInput(name, value, index);
        return newData;
      });
    };
    switch (name) {
      case "company":
        const companyId = selectedOption ? selectedOption.value : null;
        const getContactPersonCompany = async (companyId: any) => {
          try {
            const compId = { id: companyId };
            const companyIDResponse = await ApiCall.service(
              FETCH_LOCATION_BY_COMP_ID,
              "POST",
              compId,
              false,
              M_COMPANY_CREATION
            );
            setLocationOption(companyIDResponse.data);
            setFormData((prevFormData) =>
              prevFormData.map((item) => ({
                ...item,
                businessUnit: companyIDResponse.businessUnit,
              }))
            );
          } catch (error) {
            console.error(error);
          }
        };
        handleCommonLogic(companyId);
        getContactPersonCompany(companyId);
        break;
      case "businessUnit":
        const buId =
          selectedOption !== null
            ? selectedOption.map((value: any) => value)
            : [];
        handleCommonLogic(buId);
        break;
      case "tittle":
        const tittlId = selectedOption ? selectedOption.value : null;
        handleCommonLogic(tittlId);
        break;
      case "language":
        const languageId = selectedOption ? selectedOption.value : null;
        handleCommonLogic(languageId);
        break;
      case "location":
        const locationId =
          selectedOption !== null
            ? selectedOption.map((value: any) => value.value)
            : [];
        handleCommonLogic(locationId);
        break;
      case "role":
        const roleId =
          selectedOption !== null
            ? selectedOption.map((value: any) => value.value)
            : [];
        handleCommonLogic(roleId);
        break;
      // case "functionData":
      //   const functionId = selectedOption ? selectedOption.value : null;
      //   handleCommonLogic(functionId);
      //   break;
      default:
        break;
    }
  };
  const handlePhoneNumberChange = (
    mobileNumber: string | E164Number,
    index: number
  ) => {
    const updatedFormData = [...formData]; // Create a copy of the array
    updatedFormData[index] = {
      ...updatedFormData[index],
      mobileNumber: mobileNumber,
    };
    setFormData(updatedFormData);
    validateInput("mobileNumber", mobileNumber, index);
  };
  useEffect(() => {
    if (editId) {
      getContactPerson();
    }
  }, [editId]);

  const getContactPerson = async () => {
    try {
      const url = `${EDIT_CONTACT_PERSON}/${editId}`;
      const response = await ApiCall.getService(
        url,
        "GET",
        M_COMPANY_CREATION,
        false
      );
      if (response?.status === 200) {
        const { data } = response;
        setLocationOption(data.locationOption);
        setFormData([
          {
            firstName: response.data.first_name,
            lastName: response.data.last_name,
            email: response.data.email,
            //  phoneNumber: response.data.phoneNumber,
            mobileNumber: response.data.mobile_number,
            functionData: response.data.functionData,
            info: response.data.info,
            language: response.data.language,
            company: response.data.company,
            location: response.data.location,
            linkedIn: response.data.linkedIn,
            //gender: response.data.gender,
            role: response.data.role,
            dob: response.data.dob,
            businessUnit: response.data.businessUnit,
            tittle: response.data.title,
          },
        ]); // Wrap the object in an array if formData is an array
      } else {
        CustomNotify({ type: "error", message: `Error: ${response.message}` });
      }
    } catch (error) {
      //   console.error(error);
    }
  };

  const validateInput = (
    name: string,
    value: string | boolean | Date | object[] | undefined | Option | null,
    index: number
  ): boolean => {
    const validationRules: ValidationRules = {
      firstName: [validateRequired],
      lastName: [validateRequired],
      email: [validateRequired, validateEmail],
      company: [validateSelectField],
      gender: [validateSelectField],
      //language: [validateSelectField],
      mobileNumber: [validatePhoneNumber],
      //  functionData: [validateSelectField],
      //  info: [],
    };

    const formDataAtIndex = { ...formData[index], [name]: value };
    const validationErrors = validateForm(formDataAtIndex, validationRules);
    setErrors((prevErrors: any) => {
      const newErrors = [...prevErrors];
      newErrors[index] = {
        ...newErrors[index],
        [name]: validationErrors[name],
      };
      return newErrors;
    });
    return !validationErrors[name];
  };

  const handleRemove = (index: number) => {
    const list = [...formData];
    const errorList = [...errors];
    list.splice(index, 1);
    errorList.splice(index, 1);
    setFormData(list);
    setErrors(errorList);
  };

  const handleAddClick = () => {
    setFormData([
      ...formData,
      {
        firstName: "",
        lastName: "",
        company: null,
        // gender: null,
        email: "",
        language: null,
        location: [],
        role: [],
        linkedIn: "",
        functionData: null,
        info: "",
        dob: null,
        //  phoneNumber: "",
        mobileNumber: "",
        businessUnit: null,
        tittle: null,
      },
    ]);
    setErrors([
      ...errors,
      {
        firstName: "",
        lastName: "",
        company: null,
        email: "",
        language: null,
        location: [],
        role: [],
        info: "",
        mobileNumber: "",
        businessUnit: "",
      },
    ]);
  };
  const checkForDuplicates = () => {
    const emailSet = new Set();
    const phoneSet = new Set();
    let hasDuplicate = false;
    formData.forEach((contact, index) => {
      if (contact.email.trim() !== "") {
        if (emailSet.has(contact.email.trim())) {
          errors[index].email = "Duplicate email";
          hasDuplicate = true;
        } else {
          emailSet.add(contact.email);
        }
      }

      if (
        contact.mobileNumber != undefined &&
        contact.mobileNumber.trim() !== ""
      ) {
        if (phoneSet.has(contact.mobileNumber)) {
          errors[index].mobileNumber = "Duplicate phone number";
          hasDuplicate = true;
        } else {
          phoneSet.add(contact.mobileNumber);
        }
      }
    });

    return hasDuplicate;
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const duplicates = checkForDuplicates();
    const { name, value } = e.target as HTMLInputElement;
    const isValid = formData.map((item: any, index: number) => {
      const data = Object.entries(item).map(([key, value]: [string, any]) => {
        return validateInput(key, value, index);
      });
      return !data.includes(false);
    });

    if (!isValid.includes(false) && !duplicates) {
      const formDataToSave = formData.map((item) => ({
        firstName: item.firstName,
        lastName: item.lastName,
        company: item.company,
        location: item.location,
        language: item.language,
        linkedIn: item.linkedIn,
        role: item.role,
        email: item.email,
        dob: item.dob,
        tittle: item.tittle,
        functionData: item.functionData,
        info: item.info,
        // phoneNumber: item.phoneNumber,
        mobileNumber: item.mobileNumber,
        businessUnit: item.businessUnit,
        // gender: item.gender,
        id: editId,
      }));
      const url = editId
        ? `${CREATE_CONTACT_PERSON}/${editId}`
        : CREATE_CONTACT_PERSON;
      const response = await ApiCall.service(
        url,
        "POST",
        formDataToSave,
        false,
        M_COMPANY_CREATION
      );

      if (response?.status === 200) {
        CustomNotify({
          type: "success",
          message: response.msg,
        });
        navigate(MANAGE_CONTACT_PERSON);
      } else if (response?.status === 400) {
        for (let item of Object.keys(response?.errors)) {
          let keys = item.split(".");
          let index = parseInt(keys[0]);
          let particularkey = keys[1];
          errors[index][particularkey] = response?.errors[item][0];
        }
        setErrors(errors);
      } else {
        //   console.error(t("error"));
      }
    }
  };

  // const handleEdit = () => {
  //   setIsEdit(false);
  // };

  const location = useLocation();
  const path = location.pathname;

  const permissionType =
    path === "/create/contact-person" ? "create" : "update";
  const permissionObject: any = {
    permission: "Contact person",
  };
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);
  const handleDateChange = (date: Date | null, index: number) => {
    if (date && !isNaN(date.getTime())) {
      const formattedDate = `${date.getFullYear()}-${
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1
      }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;
      const updatedFormData = [...formData]; // Create a copy of the array
      updatedFormData[index] = {
        ...updatedFormData[index],
        dob: formattedDate,
      };
      setFormData(updatedFormData);
    } else {
      console.error("Invalid date selected");
    }
  };
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <Title
            title={
              path === "/create/contact-person"
                ? t("Create contact person")
                : t("Edit  contact person")
            }
          />
          {/*<div className="row">
            <div className="col-12" style={{ paddingBottom: "0.5vw" }}>
              {editId !== null && (
                <Button
                  title={t("Edit")}
                  handleClick={() => handleEdit()}
                  className="form-button float-end"
                />
              )}
            </div>
          </div>*/}
        </div>
        <form>
          <div className="row">
            <div className="col-12">
              <div className="form-border createContactPerson">
                {formData.map((contact, i) => (
                  <>
                    <div key={i} className="bg-white locationBg">
                      <div className="row">
                        <div className="col-md-12 table-action-icons">
                          {i !== 0 && (
                            <span
                              title={t("Delete")}
                              onClick={() => handleRemove(i)}
                              className="table-action-btn float-end cursor-pointer"
                            >
                              <CloseFile />
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-2">
                          <SelectWithSearch
                            title={t("Title")}
                            name="tittle"
                            isMandatory={false}
                            search={true}
                            options={tittleOption}
                            placeHolder={t("Select")}
                            value={contact.tittle}
                            onChange={(e) => handleSelectChange(e, "tittle", i)}
                            isMulti={false}
                            className="select-field"
                            error={errors[i]?.tittle?.toString() ?? ""}
                            isDisabled={isView}
                          />
                        </div>
                        <div className="col-5">
                          <LabelWithInputField
                            isMandatory={true}
                            name="firstName"
                            handleChange={(e) => changeHandler(e, i)}
                            value={contact.firstName ?? " "}
                            id={`firstName-${i}`}
                            label={t("First name")}
                            error={errors[i]?.firstName}
                            isDisabled={isView}
                          />
                        </div>
                        <div className="col-5">
                          <LabelWithInputField
                            isMandatory={true}
                            name="lastName"
                            handleChange={(e) => changeHandler(e, i)}
                            value={contact.lastName ?? ""}
                            id={`lastName-${i}`}
                            label={t("Last name")}
                            error={errors[i]?.lastName}
                            isDisabled={isView}
                          />
                        </div>
                        <div className="col-4">
                          <SelectWithSearch
                            title="Company"
                            name="company"
                            isMandatory={true}
                            search={true}
                            options={companyOption}
                            placeHolder="Select"
                            value={
                              companyOption.filter(
                                (company: any) =>
                                  company.value === contact.company
                              ) ?? ""
                            }
                            onChange={(e) =>
                              handleSelectChange(e, "company", i)
                            }
                            isMulti={false}
                            className="select-field"
                            error={errors[i]?.company?.toString() ?? ""}
                            isDisabled={isDisabled}
                          />
                        </div>
                        <div className="col-4">
                          <LabelWithInputField
                            isMandatory={true}
                            name="email"
                            handleChange={(e) => changeHandler(e, i)}
                            value={contact.email ?? ""}
                            id={`email-${i}`}
                            label={t("Email")}
                            type="email"
                            error={errors[i]?.email}
                            isDisabled={isView}
                          />
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <LabelField
                            title="Mobile number"
                            isMandatory={true}
                            key="PhoneInput"
                          />
                          <div className="form-control field-shadow">
                            <PhoneInput
                              defaultCountry="BE"
                              international
                              placeholder="Enter phone number"
                              onChange={(mobileNumber: E164Number) =>
                                handlePhoneNumberChange(mobileNumber, i)
                              }
                              value={contact.mobileNumber}
                              disabled={isView}
                            />
                          </div>
                          <div className="height-20 mb-2 text-danger">
                            {errors[i]?.mobileNumber && (
                              <span className="text-danger">
                                {errors[i]?.mobileNumber}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-4">
                          <Calender
                            onChange={(date) => handleDateChange(date, i)}
                            label={t("Date of birth")}
                            isMandatory={false}
                            name={"dob"}
                            selectedDate={
                              contact.dob ? new Date(contact.dob) : null
                            }
                            maxDate={new Date()}
                            placeHolder="dd-mm-yyyy"
                            // minDate={new Date()} //enable dates from current date
                          ></Calender>
                        </div>
                        <div className="col-4">
                          <SelectWithSearch
                            title={t("Roles")}
                            name="role"
                            isMandatory={false}
                            search={true}
                            options={roleOption}
                            placeHolder={t("Select")}
                            value={
                              roleOption.filter((role) =>
                                contact.role.some((loc) => loc === role.value)
                              ) ?? ""
                            }
                            onChange={(e) => handleSelectChange(e, "role", i)}
                            isMulti={true}
                            className="select-field"
                            error={errors[i]?.role?.toString() ?? ""}
                            isDisabled={isView}
                          />
                        </div>
                        <div className="col-4">
                          <LabelWithInputField
                            isMandatory={false}
                            name="functionData"
                            handleChange={(e) => changeHandler(e, i)}
                            value={contact.functionData ?? " "}
                            id={`functionData-${i}`}
                            label={t("Function")}
                            error={""}
                            isDisabled={isView}
                          />
                        </div>
                        <div className="col-4">
                          <SelectWithSearch
                            title={t("Language")}
                            name="language"
                            isMandatory={false}
                            search={true}
                            placeHolder={t("Select")}
                            options={languageOption}
                            value={
                              languageOption.filter(
                                (language: any) =>
                                  language.value === contact.language
                              ) ?? ""
                            }
                            onChange={(e) =>
                              handleSelectChange(e, "language", i)
                            }
                            isMulti={false}
                            className="select-field"
                            error={errors[i]?.language?.toString() ?? ""}
                            isDisabled={isView}
                          />
                        </div>
                        <div className="col-4">
                          <LabelWithInputField
                            isMandatory={false}
                            name="linkedIn"
                            handleChange={(e) => changeHandler(e, i)}
                            value={contact.linkedIn ?? " "}
                            id={`linkedIn-${i}`}
                            label={t("Linkedin website url")}
                            error={""}
                            isDisabled={isView}
                          />
                        </div>
                        <div className="col-4">
                          <SelectWithSearch
                            title={t("Location")}
                            name="location"
                            isMandatory={false}
                            search={true}
                            placeHolder={t("Select")}
                            options={locationOption}
                            value={
                              locationOption.filter((location) =>
                                contact.location.some(
                                  (loc) => loc === location.value
                                )
                              ) ?? ""
                            }
                            onChange={(e) =>
                              handleSelectChange(e, "location", i)
                            }
                            isMulti={true}
                            className="select-field"
                            error={errors[i]?.location?.toString() ?? ""}
                            isDisabled={isView}
                          />
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <SelectWithSearch
                            title={t("Business unit")}
                            name="businessUnit"
                            isMandatory={false}
                            search={true}
                            options={businessUnitOption}
                            placeHolder="Select"
                            value={formData[i].businessUnit}
                            onChange={(e) =>
                              handleSelectChange(e, "businessUnit", i)
                            }
                            isMulti={true}
                            className="select-field"
                            isDisabled={isView}
                            error={""}
                          />
                        </div>
                        <div className="col-lg-12">
                          <LabelWithTextAreaField
                            label={t("Info")}
                            name="info"
                            value={formData[i].info}
                            placeholder=""
                            handleChange={(event: any) =>
                              changeHandler(event, i)
                            }
                            isDisabled={isView}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      {isView && (
                        <div className="col-md-12">
                          {path === "/create/contact-person" &&
                            i === formData.length - 1 && (
                              <Button
                                title={"+ " + t("Add another")}
                                handleClick={handleAddClick}
                                className="form-button float-end"
                                disabled={false}
                              />
                            )}
                        </div>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
          <div className="row" style={{ padding: "0.8vw 0" }}>
            <div className="col-6 align-self-center">
              <BackButton />
            </div>
            <div className="col-6 align-self-center">
              <Button
                title={t("Save")}
                handleClick={handleSubmit}
                className="form-button float-end"
                disabled={false}
              />
            </div>
          </div>
        </form>
      </>
    </AccessControl>
  );
}
export default CreateContact;
