import TitleFieldMolecule from "components/molecules/TitleField";
import { t } from "../translation/Translation";
import { Link, useNavigate } from "react-router-dom";
import Search from "components/atoms/Search";
import Button from "components/atoms/Button";
import {
  ADD_LEAVE,
  APPLY_LEAVE_OF_EMPLOYEE,
  EMPLOYER_LEAVE_COUNT,
  VIEW_LEAVE_THROUGH_EMPLOYER,
} from "constants/Paths";
import Pagination from "components/atoms/Pagination";
import ModalPopup from "components/atoms/ModalPopup";
import { useEffect, useState } from "react";
import CustomNotify from "components/atoms/CustomNotify";
import TableActions from "components/organism/Actions/TableAction";
import { ApiCall } from "services/ApiServices";
import {
  APPROVE_OR_REJECT_LEAVE,
  GET_ALL_EMPLOYEES_LEAVE_DATA,
  GET_OPTIONS_FOR_EMPLOYER_MANAGE,
  LEAVE_ACTION,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { IconData } from "services/IconData";
import SearchWithLabel from "components/molecules/SearchWithLabel";
import BackButton from "components/atoms/BackButton";
import LinkTo from "components/atoms/LinkTo";
import ResetBtn from "components/atoms/ResetBtn";

interface SearchData {
  leave_status: any;
  business_unit: any;
  company: any;
  contract_type: any;
  employee: any;
  from_date: any;
  to_date: any;
}

interface DataProps {
  page: number;
  search?: any;
  userId: any;
}

interface Options {
  business_units: any;
  companies: any;
  contract_types: any;
  employees: any;
}
interface EmployeeLeave {
  id: number;
  status: number;
  employee_name: string;
  contract_type: string;
  business_unit: string;
  company: string;
  leaves: string;
  from: string;
  to: string;
  unit: string;
}
// {
//   "status": 200,
//   "data": [
//       {
//           "id": 36,
//           "employee_name": "Aman Tiwari",
//           "leave_type": "Full day",
//           "reason": "Half-moring",
//           "status": 1,
//           "start_date": "2024-11-25",
//           "end_date": "2024-11-29",
//           "holiday_code": "AIR",
//           "holiday_name": "AIR",
//           "contract_type": "Permanent",
//           "unit": "Full day",
//           "created_at": "2024-11-25T09:46:03.000000Z"
//       }
//   ],
//   "totalPages": 1
// }

const ManageLeavesForEmployer = () => {
  const leaveStatus = [
    { name: "Approved", label: "Approved", value: 2 },
    { name: "Cancelled", label: "Cancelled", value: 3 },
    { name: "New", label: "New", value: 1 },
  ];

  const queryParams = new URLSearchParams(window.location.search);
  const userId = useSelector(selectAuth).userId;
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState({
    leave_status: queryParams.get("leave_status") ?? leaveStatus[2]["value"],
    business_unit: queryParams.get("business_unit") ?? "",
    company: queryParams.get("company") ?? "",
    contract_type: queryParams.get("contract_type") ?? "",
    employee: queryParams.get("employee") ?? "",
    from_date: queryParams.get("from_date") ?? "",
    to_date: queryParams.get("to_date") ?? "",
  });
  const [options, setOptions] = useState<Options>({
    business_units: [],
    companies: [],
    contract_types: [],
    employees: [],
  });

  const [employerLeaveData, setEmployeesLeaveData] = useState<EmployeeLeave[]>(
    []
  );
  const [id, setId] = useState<number | undefined>(undefined);
  const [approveId, setApproveId] = useState<number | undefined>(undefined);
  const [actionData, setActionData] = useState({
    id: "",
    type: "",
  });

  useEffect(() => {
    fetchOptionsData();
  }, []);

  const fetchOptionsData = async () => {
    try {
      const response = await ApiCall.getService(
        GET_OPTIONS_FOR_EMPLOYER_MANAGE,
        "GET",
        M_MASTER_DATA,
        false
      );
      if (response?.status === 200) {
        const { businessUnits, companies, contractTypes, employees } =
          response.data;

        setOptions({
          business_units: businessUnits || [],
          companies: companies || [],
          contract_types: contractTypes || [],
          employees: employees || [],
        });
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    const data: DataProps = {
      page: currentPage,
      search: search,
      userId: userId,
    };
    fetchLeavesDataOfEmployees(data); //To get leave count details of all employees
  }, [currentPage, submit]);

  const fetchLeavesDataOfEmployees = async (data: DataProps) => {
    try {
      const response = await ApiCall.service(
        GET_ALL_EMPLOYEES_LEAVE_DATA,
        "POST",
        data,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setEmployeesLeaveData(response?.data);
        setTotalPages(response?.totalPages);
      } else {
        CustomNotify({ type: "warning", message: response?.message });
      }
    } catch (error) {
      console.log("Error occured ", error);
    }
  };

  //onChange event handler for select element
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value, id } = e.target;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };

  //onSubmit event handler
  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    handleSubmitAction(clear);
  };

  const handleSubmitAction = (clear = false) => {
    const newPage = 1;
    setCurrentPage(newPage);
    if (clear) {
      setSearch({
        leave_status: leaveStatus[2]["value"],
        business_unit: "",
        company: "",
        contract_type: "",
        employee: "",
        from_date: "",
        to_date: "",
      });
      navigate({
        pathname: window.location.pathname,
        search: `?page=${newPage}`,
      });
    } else {
      const queryParams = createQueryParams(search);
      if (queryParams) {
        navigate({
          pathname: window.location.pathname,
          search: `?${queryParams}&page=${newPage}`,
        });
      } else {
        CustomNotify({
          type: "warning",
          message: t("Search input not provided."),
        });
      }
    }
    setSubmit((prevSubmit) => !prevSubmit);
  };

  // Function to create query parameters from the search object
  const createQueryParams = (data: SearchData) => {
    const queryParams = Object.entries(data)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    return queryParams;
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleReject = (id: any) => {
    setActionData((prevState) => ({ ...prevState, type: "Delete", id }));
    setShowModal(true);
  };

  const handleSaveChanges = async () => {
    try {
      const response = await ApiCall.service(
        APPROVE_OR_REJECT_LEAVE,
        "POST",
        actionData,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response?.message });
        setSubmit((prevState) => !prevState);
      } else {
        CustomNotify({ type: "error", message: response?.message });
      }
      setShowModal(false);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleView = (id: any) => {
    navigate(`${VIEW_LEAVE_THROUGH_EMPLOYER}/${id}`);
  };

  const handleApprove = async (id: any) => {
    setActionData((prevState) => ({ ...prevState, type: "Approve", id }));
    setShowModal(true);
    setSubmit((prevState) => !prevState);
  };
  return (
    <>
      <div className="form-height-dashboard">
        <div className="row header-sticky position-sticky">
          <TitleFieldMolecule title={t("")} />
        </div>
        <div className="row search-bar">
          <div className="col-3 mb-3">
            <div className="">
              <label className="form-label field-label mb-0">
                {t("Leave status")}
              </label>
            </div>
            <select
              className="form-select field-shadow form-control"
              name="leave_status"
              id="leave_status"
              onChange={handleSearchChange}
              value={search.leave_status}
            >
              {leaveStatus && leaveStatus.length > 0 ? (
                <>
                  <option value="">{t("Select leave status")}</option>
                  {leaveStatus.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </>
              ) : (
                <option value="">{t("No leave status available")}</option>
              )}
            </select>
          </div>
          <div className="col-3">
            <div className="">
              <label className="form-label field-label mb-0">
                {t("  Business unit")}
              </label>
            </div>
            <select
              className="form-select field-shadow form-control"
              name="business_unit"
              id="business_unit"
              onChange={handleSearchChange}
              value={search.business_unit}
            >
              {options.business_units && options.business_units.length > 0 ? (
                <>
                  <option value="">{t("Select business unit")}</option>
                  {options.business_units.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </>
              ) : (
                <option value="">{t("No business available")}</option>
              )}
            </select>
          </div>
          <div className="col-3">
            <div className="">
              <label className="form-label field-label mb-0">Company</label>
            </div>
            <select
              className="form-select field-shadow form-control"
              name="company"
              id="company"
              onChange={handleSearchChange}
              value={search.company}
            >
              {options.companies && options.companies.length > 0 ? (
                <>
                  <option value="">{t("Select company")}</option>
                  {options.companies.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </>
              ) : (
                <option value="">{t("No company available")}</option>
              )}
            </select>
          </div>
          <div className="col-3">
            <div className="">
              <label className="form-label field-label mb-0">
                {t("Contract type")}
              </label>
            </div>
            <select
              className="form-select field-shadow form-control"
              name="contract_type"
              id="contract_type"
              onChange={handleSearchChange}
              value={search.contract_type}
            >
              {options.contract_types && options.contract_types.length > 0 ? (
                <>
                  <option value="">{t("Select contract type")}</option>
                  {options.contract_types.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </>
              ) : (
                <option value="">{t("No contract type available")}</option>
              )}
            </select>
          </div>
          <div className="col-3">
            <div className="">
              <label className="form-label field-label mb-0">Employee</label>
            </div>
            <select
              className="form-select field-shadow form-control"
              name="employee"
              id="employee"
              onChange={handleSearchChange}
              value={search.employee}
            >
              {options.employees && options.employees.length > 0 ? (
                <>
                  <option value="">{t("Select employee")}</option>
                  {options.employees.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </>
              ) : (
                <option value="">{t("No employee available")}</option>
              )}
            </select>
          </div>
          <div className="col-3">
            <SearchWithLabel
              type="text"
              showLabel
              label={t("From")}
              handleChange={handleSearchChange}
              placeholder="From date"
              name="from_date"
              value={search.from_date}
              className="col-12"
            />
          </div>
          <div className="col-3">
            <SearchWithLabel
              type="text"
              showLabel
              label={t("To")}
              handleChange={handleSearchChange}
              placeholder="To date"
              name="to_date"
              value={search.to_date}
              className="col-12"
            />
          </div>
          <div className="col text-end mt-auto">
            <div className="d-flex align-items-center justify-content-end">
              <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />{" "}
              <Button
                title="Search"
                type="submit"
                handleClick={handleSubmit}
                className="form-button shadow-none text-uppercase search-btns"
              />
            </div>
          </div>
        </div>
        <div className="tableMainWrapper position-relative">
          <div className="row">
            <div className="ManageCreateBtn">
              <Link to="" className="form-button marginRightPoint5">
                {t("Calender view")}
              </Link>
              <LinkTo
                pagelink={`${APPLY_LEAVE_OF_EMPLOYEE}`}
                title={t("Apply for holiday")}
                className="me-3"
              />
              <Link
                to={`${ADD_LEAVE}`}
                className="form-button marginRightPoint5"
              >
                {t("Add leave")}
              </Link>
              <Link to={`${EMPLOYER_LEAVE_COUNT}`} className="form-button">
                {t("Leave counts")}
              </Link>
            </div>
          </div>
          <div className="table-responsive Manage-candidates tableSection">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader">
                  <th>{t("Employee name")}</th>
                  <th>{t("Contract type")}</th>
                  <th>{t("Business unit")}</th>
                  <th>{t("Company")}</th>
                  <th>{t("Holiday code")}</th>
                  <th>{t("From")}</th>
                  <th>{t("To")}</th>
                  <th>{t("Unit")}</th>
                  <th className="table_actions">{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {employerLeaveData.length > 0 ? (
                  employerLeaveData?.map((eachLeaveData: any) => (
                    <tr key={eachLeaveData.id}>
                      <td>{eachLeaveData.employee_name}</td>
                      <td>{eachLeaveData.contract_type}</td>
                      <td>{eachLeaveData.business_unit}</td>
                      <td>{eachLeaveData.company}</td>
                      <td>{eachLeaveData.leaves}</td>
                      <td>{eachLeaveData.from}</td>
                      <td>{eachLeaveData.to}</td>
                      <td>{eachLeaveData.unit}</td>
                      <td>
                        {eachLeaveData.status === 1 ? (
                          <TableActions
                            isAction={true}
                            handleApprove={handleApprove}
                            handleView={handleView}
                            handleReject={handleReject}
                            value={eachLeaveData.id}
                            permission={"Leaves"}
                          />
                        ) : (
                          <TableActions
                            isAction={true}
                            handleView={handleView}
                            value={eachLeaveData.id}
                            permission={"Leaves"}
                          />
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      {t("No record available")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6 align-self-center my-4">
        <BackButton />
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title={t(
          actionData.type === "Approve"
            ? "Approve leave request"
            : "Reject leave request"
        )}
        body={t(
          actionData.type === "Approve"
            ? "Are you sure want to approve?"
            : "Are you sure want to reject?"
        )}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle="No"
        confirmTitle="Yes"
      />
    </>
  );
};
export default ManageLeavesForEmployer;
