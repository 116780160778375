import Button from 'components/atoms/Button';
import FilterOrganism from 'components/organism/FilterOrganism';
import { M_MASTER_DATA } from 'constants/Constants';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CREATE_PC_FUNC_DOCS, CREATE_PC_SALARY_BENEFITS, GET_PC_FUNC_DOCS, GET_PC_SALARY_BENEFITS } from 'routes/ApiEndpoints';
import { ApiCall } from 'services/ApiServices';
import { selectPageHistory, updatePageFilters } from 'store/pageHistory/pageHistorySlice';
import { mapToSelect } from 'utils/MapToSelect';
import { Option, ValidationRules } from 'utils/TypeAnnotations';
import ModalPopup from 'components/atoms/ModalPopup';
import SelectWithSearch from 'components/atoms/SelectWithSearch';
import { validateForm, validateMultiSelectField, validateSelectField } from 'services/validation/ValidationService';
import EditIcon from 'static/images/EditIcon';
import TitleAtom from 'components/atoms/Title';
import { t } from '../../translation/Translation';
import RadioField from 'components/atoms/RadioField';
import PaginationWithPerPage from 'components/molecules/PaginationWithPerPage';
import BackButton from 'components/atoms/BackButton';

export interface DocumentTypeProps {
    value: number | null;
    label: string;
}

interface DropdownData {
    pc: Option | null;
    functions: Option[];
    documentType: [];
}

interface FormDataProps {
    pc: string | undefined;
    function: any;
    documentType: DocumentTypeProps[] | null;
    isRequired: boolean;
    wishToHave: boolean;
}

export const PcFunctionDocuments = () => {
    const params = useParams();
    const pcId = params.id;
    const pageHistory = useSelector(selectPageHistory);
    const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
    const dispatch = useDispatch();
    const [pageData, setPageData] = useState({
        totalPages: 0,
        totalRecords: 0,
        refresh: true,
        initialCall: true
    });
    const [dropdownData, setDropdownData] = useState({
        pc: null,
        functions: [],
        documentTypes: [],
    });

    const [modalPopup, setModalPopup] = useState(false);
    const initalFormData: FormDataProps = {
        pc: pcId,
        function: "",
        documentType: [],
        isRequired: true,
        wishToHave: false,
    }
    const [formData, setFormData] = useState<FormDataProps>(initalFormData)
    const [errors, setErrors] = useState<any>({
        function: "",
        documentType: "",
    });
    const [manageData, setManageData] = useState([]);

    const [pcName, setPcName] = useState();

    useEffect(() => {
        const data: any = {
            currentPage: Number(history?.filters?.currentPage ?? 1),
            itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
            search: getSearchData(),
            initialCall: pageData?.initialCall,
            id: pcId
        };
        fetchData(data);
    }, [pageData?.refresh, pageData?.initialCall]);

    const filters = [
        { name: 'pc', fieldType: "singleSelect", value: pcName, options: dropdownData?.pc, placeholder: "Paritair comitee name", filterType: 'search', isDisable: true },
        { name: 'functions', fieldType: "singleSelect", options: dropdownData?.functions, placeholder: "Function", filterType: 'search' },
        { name: 'documentType', fieldType: "multiSelect", options: dropdownData?.documentTypes, placeholder: "Document type", filterType: 'search' },
    ];

    const getSearchData = () => {
        return {
            pc: pcId,
            functions: history?.filters?.functions ?? "",
            salaryBenefits: history?.filters?.salaryBenefits ?? "",
        }
    };

    const validation = (
        name: any = null,
        value: any = null,
        isSingleFieldValidation = false,
    ) => {
        let validationRules: any = {
            documentType: [validateMultiSelectField],
            function: [validateSelectField],
        };


        const validationErrors: any = validateForm(
            { ...formData, [name]: value },
            isSingleFieldValidation ? validationRules[name] : validationRules
        );

        if (isSingleFieldValidation) {
            setErrors((prevErrors: any) => {
                if (validationErrors.hasOwnProperty(name)) {
                    return { ...prevErrors, [name]: validationErrors[name] };
                }
                const { [name]: ty, ...remains } = prevErrors;
                return { ...remains };
            });
        } else {
            setErrors(validationErrors);
            if (Object.keys(validationErrors).length > 0) {
                return false;
            } else {
                return true;
            }
        }
    };

    const fetchData = async (data: any) => {
        const response = await ApiCall.service(GET_PC_FUNC_DOCS, "POST", data, false, M_MASTER_DATA);
        if (response?.status === 200) {
            if (pageData?.initialCall) {
                const { pcs, functions, documentType, particularPc } = response?.data?.options;
                setPcName(pcs[0]?.value);
                setDropdownData((prevOptions: any) => ({ ...prevOptions, documentTypes: documentType, pc: pcs, functions: functions }));
            }
            setManageData(response?.data?.data);
            setPageData((prev: any) => ({
                ...prev,
                totalPages: response?.data?.totalPages,
                totalRecords: response?.data?.totalRecords,
                initialCall: false
            }));
        }
    }

    const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

    const handleAddRequest = () => {
        setModalPopup(true);
    }

    const handleClosePopup = () => {
        setModalPopup(false);
        setFormData(initalFormData);
    }

    const handleSelectChange = (selectedOption: Option | Object[] | any, fieldName: string, index?: number) => {
        // Update the form data for other fields
        setFormData((prevData: FormDataProps) => ({
            ...prevData,
            [fieldName]: selectedOption,
        }));
        validation(fieldName, selectedOption, true);
    }

    const dependencyFields: any = {
        isRequired: 'wishToHave',
        wishToHave: 'isRequired'
    }

    const handleFieldChange = (event: any, index?: number) => {
        const { name, value, type, checked } = event?.target;

        setFormData((prevData: FormDataProps) => {
            const updatedData: any = {
                ...prevData,
                [name]: type === 'radio' ? checked : value
            };

            // Check if the current field has a dependency and update its value
            if (dependencyFields[name]) {
                updatedData[dependencyFields[name]] = false;
            }

            return updatedData;
        });

        if (type !== 'radio') {
            validation(name, value, true);
        }
    };

    const handleAddDocFunction = async () => {
        try {
            if (validation()) {
                const response = await ApiCall.service(CREATE_PC_FUNC_DOCS, "POST", formData, false, M_MASTER_DATA);
                if (response?.status == 200) {
                    handleRefresh();
                    setModalPopup(false);
                    setFormData(initalFormData); // Reset form data
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };


    const handleEdit = (data: FormDataProps) => {
        setFormData((prevData: any) => ({
            ...prevData,
            function: data?.function,
            documentType: data?.documentType,
            isRequired: data?.isRequired,
            wishToHave: !data?.isRequired,
        }));
        setModalPopup(true);
    }

    return (
        <>
            <div className="search-bar">
                <TitleAtom title={t("Manage function document types")} />
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="position-relative tableMainWrapper">
                        <div className="row">
                            <div className="ManageCreateBtn">
                                <span onClick={handleAddRequest} className='form-button cursor-pointer'>
                                    {"+ " + t("Add document type")}
                                </span>
                            </div>
                        </div>
                        <div className="table-responsive tableSection manageSalaryBenefit">
                            <FilterOrganism
                                dispatch={dispatch}
                                history={history}
                                updatePageFilters={updatePageFilters}
                                filters={filters}
                                handleRefresh={handleRefresh}
                            />
                            <div className="manageSalaryBenefitHeight">
                                <table cellPadding="5" className='table'>
                                    <thead className='TableHeader'>
                                        <tr>
                                            <th>{t("Paritair comitee")}</th>
                                            <th>{t("Functions")}</th>
                                            <th>{t("Document type")}</th>
                                            <th>{t("Actions")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {manageData && Object.entries(manageData).length > 0 && Object.entries(manageData).map(([key, value]: [string, any]) => (
                                            <tr key={key}>
                                                <td>{value?.pc?.label}</td>
                                                <td>{value?.function?.label}</td>
                                                <td>{value?.documentType && value.documentType.map((documents: any) =>
                                                    documents?.label
                                                ).join(',  ')}</td>
                                                <td><span title={t("Edit")} onClick={() => handleEdit(value)} className='table-action-btn cursor-pointer'> <EditIcon /></span></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <PaginationWithPerPage
                                handleRefresh={handleRefresh}
                                dispatch={dispatch}
                                history={history}
                                pageData={pageData}
                            />
                        </div>

                    </div>
                </div>
            </div>
            <div style={{ padding: "0.85vw 0" }} className="row">
                <div className="col-12">
                    <BackButton />
                </div>
            </div>
            {modalPopup &&
                <ModalPopup
                    show={modalPopup}
                    onHide={handleClosePopup}
                    title={t("Add function document type")}
                    modalBodyClassName="py-0"
                    modalFooterClassName="py-0"
                    body={
                        <>
                            <div className="row">
                                <SelectWithSearch
                                    title={t("Functions")}
                                    isMandatory={false}
                                    search={true}
                                    options={dropdownData.functions}
                                    onChange={(e) => handleSelectChange(e, "function")}
                                    isMulti={false}
                                    name="function"
                                    value={formData.function}
                                    error={errors?.function}
                                    placeHolder="Select"
                                />
                                <SelectWithSearch
                                    title={t("Document types")}
                                    isMandatory={false}
                                    search={true}
                                    options={dropdownData.documentTypes}
                                    onChange={(e) => handleSelectChange(e, "documentType")}
                                    isMulti={true}
                                    name="documentType"
                                    value={formData.documentType}
                                    error={errors?.documentType}
                                    placeHolder="Select"
                                />
                                <RadioField
                                    name={"isRequired"}
                                    label={t("Required")}
                                    ischecked={formData?.isRequired}
                                    handleChange={handleFieldChange}
                                    disable={false}
                                    containerClassName="col-4"
                                />

                                <RadioField
                                    name={"wishToHave"}
                                    label={t("Wish to have")}
                                    ischecked={formData?.wishToHave}
                                    handleChange={handleFieldChange}
                                    disable={false}
                                    containerClassName="col-4"
                                />
                            </div>
                        </>
                    }
                    onConfirmButtonClick={handleAddDocFunction}
                    confirmTitle={t("Add")}
                    className='salaryBenefitPopup'
                />}


        </>
    );
}