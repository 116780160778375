import { Reducer } from "react";
import { pcFormData } from "../annotations/PCInterface";
import { FormAction } from "./Actions";
import * as CONST from "../annotations/PCConstants";
import { initialPCCategoryList } from "../helpers/PCHelperFunctions";

export const PCReducer: Reducer<pcFormData, FormAction> = (state: any, action: any) => {
    switch (action.type) {
        case CONST.NEXT_STEP:
            const currentTab = state.tabDetails.find((tab: any) => tab.showStatus);
            if (currentTab) {
                const currentIndex = state.tabDetails.findIndex(
                    (tab: any) => tab.id === currentTab.id
                );
                const nextIndex = (currentIndex + 1) % state.tabDetails.length;

                const updatedTabDetails = state.tabDetails.map((tab: any) => ({
                    ...tab,
                    showStatus: tab.id === state.tabDetails[nextIndex].id,
                }));

                return {
                    ...state,
                    tabDetails: updatedTabDetails,
                };
            }
            return state;

        case CONST.PREVIOUS_STEP:
            const currentTabPrev = state.tabDetails.find(
                (tab: any) => tab.showStatus
            );
            if (currentTabPrev) {
                const currentIndexPrev = state.tabDetails.findIndex(
                    (tab: any) => tab.id === currentTabPrev.id
                );
                const previousIndex =
                    (currentIndexPrev - 1 + state.tabDetails.length) %
                    state.tabDetails.length;

                const updatedTabDetailsPrev = state.tabDetails.map((tab: any) => ({
                    ...tab,
                    showStatus: tab.id === state.tabDetails[previousIndex].id,
                }));

                return {
                    ...state,
                    tabDetails: updatedTabDetailsPrev,
                };
            }
            return state;

        case CONST.UPDATE_TAB_DETAILS:
            return {
                ...state,
                tabDetails: action.tabDetails,
            };

        case CONST.UPDATE_TAB_ERROR:
            const { tabIndex, error } = action;
            return {
                ...state,
                tabDetails: state.tabDetails.map((tab: any, index: any) => ({
                    ...tab,
                    error: index === tabIndex ? error : tab.error,
                })),
            };


        case CONST.SET_DROPDOWN_DATA:
            return {
                ...state,
                dropDownData: {
                    ...state.dropDownData,
                    ...action.payload
                }
            };

        case CONST.UPDATE_FIELD:
            return {
                ...state,
                [action.tab]: { ...state[action.tab], [action.field]: action.value }
            };

        case CONST.UPDATE_ERROR_FIELD:
            return { ...state, errors: { ...state.errors, [action.field]: action.error } };

        case CONST.EDIT_FIELD:
            const { index: editIndex, field: editField, value: editValue, tab: editTab } = action;
            const updatedList = [...state[editTab]];
            // Update the specific item in the list
            updatedList[editIndex] = {
                ...updatedList[editIndex],
                [editField]: editValue,
            };
            // Return the updated state with the modified list under the specific tab
            return {
                ...state,
                [editTab]: updatedList,
            };
        case CONST.UPDATE_TAB_DATA:
            const { tab, data } = action;
            return {
                ...state,
                [tab]: {
                    ...state[tab],
                    ...data
                }
            };

        case CONST.ADD_CATEGORY:
            const { data: categoryData, index: categoryIndex } = action;
            const newCategories = [
                ...state.categories,
            ];
            // Update the specific coefficient at the given index
            newCategories[categoryIndex] = {
                ...categoryData
            };
            // Return the new state, updating remenurationDetails specifically
            return {
                ...state,
                categories: newCategories
            };

        case CONST.REMOVE_CATEGORY:
            const existingCategories = [...state.categories];
            //Remove category of specified index
            existingCategories.splice(action.indexToRemove, 1);
            //return updated state with updated details
            return {
                ...state,
                categories: existingCategories,
                //Remove funtion's categoryif any function has above removing category
                functions: state.functions.map((fun: any) => {
                    if (fun?.category?.value === action?.category?.id) {
                        return {
                            ...fun,
                            category: null
                        };
                    }
                })
            };

        case CONST.ADD_FUNCTION:
            const { data: functionData, index: functionIndex } = action;
            const newFunctions = [
                ...state.functions,
            ];
            // Update the specific coefficient at the given index
            newFunctions[functionIndex] = {
                ...functionData,
            };
            // Return the new state, updating remenurationDetails specifically
            return {
                ...state,
                functions: newFunctions
            };

        case CONST.REMOVE_FUNCTION:
            const existingFunctions = [...state.functions];
            //Remove function of specified index
            existingFunctions.splice(action.indexToRemove, 1);
            //return updated state with updated details
            return {
                ...state,
                functions: existingFunctions
            };

        case CONST.ADD_AGE_DATA:
            const { data: ageData, index: ageIndex } = action;
            const newAgeDataSet = [
                ...state.age,
            ];
            // Update the specific coefficient at the given index
            newAgeDataSet[ageIndex] = {
                ...ageData,
            };
            // Return the new state, updating remenurationDetails specifically
            return {
                ...state,
                age: newAgeDataSet
            };

        case CONST.REMOVE_AGE_DATA:
            const existingAgeData = [...state.age];
            //Remove age of specified index
            existingAgeData.splice(action.indexToRemove, 1);
            //return updated state with updated details
            return {
                ...state,
                age: existingAgeData
            };

    }
}