import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { t } from "pages/microservices/masterData/translation/Translation";
import CardTitle from "components/atoms/CardTitle";
import { IconData } from "services/IconData";

const Budget: React.FC<{ params: any }> = ({ params }) => {
  const { userAuth } = params;
  const percentage = 66;

  return (
    <div className="w-25 ms-auto">
      <div className="card border-0 card-shadow position-relative dashboard-myBudget-wrapper justify-content-between" style={{ padding: "1vw" }}>
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faAngleLeft} />
          <CardTitle title={t("My budget")} className={`my-budget-title`} />
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
        <div className="position-relative">
          <div className={`circular-progress-bar-custom recruitment-card-title`} data-label=" €0" >
            <div>
              <CircularProgressbarWithChildren value={percentage} className="CircularProgressbarWithChildren">
                <div className="text-center CircularProgressbar-text text-uppercase">
                  {t("Goal")}
                  <br />
                  <div> € 0</div>
                </div>
              </CircularProgressbarWithChildren>
            </div>
          </div>
        </div>
        <div>
          <span className="text-center marginRightPoint5  ">{IconData.EclipseIcon}</span>
          {t("Achieved")}
        </div>
      </div>
    </div>
  );
};
export default Budget;
