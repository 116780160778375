import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "components/atoms/Button";
import React, { useState, ChangeEvent, useEffect } from "react";
import { t } from "../translation/Translation";
import {
  validateForm,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiCall } from "services/ApiServices";
import {
  CREATE_EMPLOYEE_TYPE,
  GET_PARTICULAR_EMPLOYEE_TYPE,
} from "routes/ApiEndpoints";
import { EMPLOYEE_TYPE_EXISTS, M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PATH_MANAGE_EMPLOYEE_TYPE } from "constants/Paths";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { Option } from "components/common/CommonInterfaces";
import TitleAtom from "components/atoms/Title";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import BackButton from "components/atoms/BackButton";

interface InputItem {
  employee_type_name: any;
  category: Option | null;
}

interface ValidationRules {
  [key: string]: Function[];
}

function CreateEmployeeType() {
  const [formData, setFormData] = useState<any>({
    employee_type_name: "",
    category: null,
  });
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [tableData, setTableData] = useState<InputItem[]>([]);
  const [errors, setErrors] = useState<InputItem | any>({
    employee_type_name: "",
    category: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const editData = { id: id };
          const response = await ApiCall.service(
            GET_PARTICULAR_EMPLOYEE_TYPE,
            "POST",
            editData,
            false,
            M_MASTER_DATA
          );
          if (response?.status === 200) {
            setFormData(response?.data);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchData();
  }, [id]);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevData: any) => ({ ...prevData, [name]: checked }));
    } else {
      setFormData((prevData: any) => ({ ...prevData, [name]: value }));
    }

    validateInput(name, value);
  };

  const validateInput = (
    name: string | any,
    value: string | boolean | any,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      employee_type_name: [validateRequired],
      category: [validateSelectField],
    };

    // Validate the form data based on the rules
    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules
    );

    // Update errors state based on the validation results
    if (!isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    // Check if there are any validation errors
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleRemove = (index: number) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  const handleEdit = (index: number) => {
    const editedData = tableData[index];
    setFormData(editedData);
    setEditIndex(index);
  };

  const handleAddClick = () => {
    const isEmployeeValid = validateInput(
      "employee_type_name",
      formData.employee_type_name,
      true
    );
    const isCategoryValid = validateInput("category", formData.category, true);

    if (isEmployeeValid && isCategoryValid) {
      if (editIndex !== null) {
        const updatedTableData = [...tableData];
        updatedTableData[editIndex] = formData;
        setTableData(updatedTableData);
        resetFormData();
        setEditIndex(null);
      } else {
        const isDuplicate = tableData.some(
          (entry) => entry.employee_type_name === formData.employee_type_name
        );

        if (isDuplicate) {
          setErrors({
            employee_type_name: t(`${EMPLOYEE_TYPE_EXISTS}`),
          });
        } else {
          setTableData((prevTableData) => [...prevTableData, formData]);
          resetFormData();
        }
      }
    }
  };

  const resetFormData = () => {
    setFormData({
      employee_type_name: "",
      category: null,
    });
    setErrors({
      employee_type_name: "",
      category: "",
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let finalData = [...tableData];
    let isFormValid = true;

    isFormValid = validateInput(
      "employee_type_name",
      formData.employee_type_name,
      true
    );
    
    if (isFormValid) {
      finalData.push(formData);
    }
    
    if (isFormValid || finalData?.length !== 0) {
      const response = await ApiCall.service(CREATE_EMPLOYEE_TYPE, "POST", finalData, true, M_MASTER_DATA);
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        navigate(`${PATH_MANAGE_EMPLOYEE_TYPE}`);
      } else if (response?.status === 422) {
        CustomNotify({ type: "warning", message: response.message });
      }
    }
  };

  const getRowClass = (index: any) => {
    return editIndex === index ? "highlighted-row" : "";
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Employee type",
  };
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);
  const handleSelectChange = (selectedOption: Option, name: string) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: selectedOption,
    }));

    validateInput(name, selectedOption);
  };

  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <div className="row">
            <TitleAtom title={t("Create employee type")} />
          </div>
        </div>
        <form onSubmit={handleSubmit} className="marginBotttom1">
          <div className="row">
            <div className="col-12">
              <div className="form-border">
                <div className="row">
                  <div className="form-group col-md-6">
                    <LabelWithInputField
                      isMandatory={true}
                      name="employee_type_name"
                      handleChange={(e) => changeHandler(e)}
                      value={formData.employee_type_name}
                      id="employee_type_name"
                      label={t("Employee type name")}
                      placeholder={t("Employee type name")}
                      type="text"
                      error={errors.employee_type_name}
                    />
                  </div>
                  <div className="col-6">
                    <SelectWithSearch
                      title={t("Category")}
                      name="category"
                      id="category"
                      isMandatory={true}
                      search={true}
                      options={[
                        { value: 2, label: "Blue collar" },
                        { value: 1, label: "White collar" },
                      ]}
                      placeHolder="Select"
                      value={formData.category}
                      onChange={(e) => handleSelectChange(e, "category")}
                      isMulti={false}
                      className="select-field"
                      error={errors.category}
                      isDisabled={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 text-lg-end text">
                    {id === undefined && (
                      <Button
                        title={
                          editIndex !== null
                            ? t("Save")
                            : t("+ Add another employee type")
                        }
                        handleClick={handleAddClick}
                        className="form-button float-end shadow-none"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className="row">
          <div className="col-12">
            {/* table below the form */}
            <div style={{ maxHeight: "calc(100vh - 27vw)", overflow: "auto" }} className="table-responsive tableSection p-0 border-0 rounded-0">
              {tableData.length > 0 && (
                <table className="table table-hover mb-0">
                  <thead className="TableHeader">
                    <tr>
                      <th style={{ width: "30%" }}>{t("Employee type name")}</th>
                      <th style={{ width: "30%" }}>{t("Category")}</th>
                      <th>{t("Actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item, index) => (
                      <tr key={index} className={getRowClass(index)}>
                        <td className="align-middle">{item.employee_type_name}</td>
                        <td className="align-middle">{item.category?.label}</td>
                        <td className="table-action-icon">
                          <span
                            onClick={() => handleEdit(index)}
                            className="table-action-btn me-2"
                            title={t("Edit")}
                          >
                            <EditIcon />
                          </span>
                          <span
                            onClick={() => handleRemove(index)}
                            className="table-action-btn me-2"
                            title={t("Delate")}
                          >
                            <DeleteIcon />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <div className="row" style={{ padding: "0.85vw 0" }}>
          <div className="col-6 align-self-center">
            <BackButton />
          </div>

          <div className="col-6">
            <Button
              title={t("Save")}
              handleClick={handleSubmit}
              className="form-button float-end"
            />
          </div>
        </div>
      </>
    </AccessControl>
  );
}

export default CreateEmployeeType;
