import Button from "components/atoms/Button";
import React, { useEffect, useState } from "react";
import { t } from "../translation/Translation";
import SearchWithLabel from "components/molecules/SearchWithLabel";
import Pagination from "components/atoms/Pagination";
import { ApiCall } from "services/ApiServices";
import { MONTHS_TRANSLATABLE, M_MASTER_DATA } from "constants/Constants";
import AddPartenaCalculation from "./AddPartenaCalculation";
import LabelField from "components/atoms/LabelField";
import { useLocation, useNavigate } from "react-router-dom";
import { PartenaData, initialExportSearch } from "./PartenaInterface";
import MonthPicker from "components/atoms/MonthPicker";
import { Option } from "utils/TypeAnnotations";
import { IconData } from "services/IconData";
import TitleAtom from "components/atoms/Title";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import ModalPopup from "components/atoms/ModalPopup";

const ManagePartena = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState(initialExportSearch);
  const [partenaData, setPartenaData] = useState<PartenaData[]>();
  const [showModal, setShowModal] = useState(false);
  const [isReset, setIsReset] = useState(false);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    fetchData();
  }, [location, isReset]);

  const fetchData = async () => {
    const postData: any = {
      searchData: search
    }
    const response = await ApiCall.service("getPartenaOverview", "POST", postData, true, M_MASTER_DATA);
    setPartenaData(response?.data);
    const partenaData: PartenaData[] = response?.data;
    // setShowModal(!response?.modalStatus);
  }

  const handleClick = (event?: any, status: string = "", reset: boolean = true) => {
    if (status == "search") {
      if (!reset) {
        setSearch(initialExportSearch);
      }
      setIsReset((prevState: any) => !prevState)
      //fetchData();
    }
    const popUpStatus = status === "add" ? true : status === "close" ? false : false;
    setShowModal(popUpStatus);
    status === "partenaCalculation" && navigate(`/partena/calculation/${event.id}`);
  }

  //Handle field changes
  const handleSearchChange = (e: any) => {
    const { name, value } = e.target;
    setSearch(prev => ({ ...prev, [name]: value }));
  }

  //Handle page Changes
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  function hanldeChange(e: Option, fieldName: string): void {
    setSearch(prev => ({ ...prev, [fieldName]: e.value }))
  }

  return (
    <>
      <div className="search-bar">
        <div className="row">
          <TitleAtom title={t("Partena")} />
          <div className="text-end col-12">
            <Button
              title={t("Add")}
              handleClick={(e) => handleClick(e, "add")}
              className={"form-button shadow-none"}
            />
          </div>
          <div className="col-12">
            <div className="d-grid" style={{ gridTemplateColumns: "repeat(7, 1fr)", gap: "1vw", marginTop: "0.1vw" }}>
              <div className="partenaMonthPicker">
                <MonthPicker
                  name="month"
                  handleChange={(e) => hanldeChange(e, "month")}
                  value={search?.month}
                  error=""
                />
              </div>
              <div>
                <SearchWithLabel
                  label={t("Business unit")}
                  isMandatory={false}
                  showLabel={false}
                  name={"businessUnit"}
                  className="me-3"
                  type={"text"}
                  value={search?.businessUnit}
                  placeholder={t("Business unit")}
                  isDisabled={false}
                  handleChange={(e) => handleSearchChange(e)}
                />
              </div>
              <div>
                <SearchWithLabel
                  label={t("Number of employees")}
                  isMandatory={false}
                  showLabel={false}
                  name={"numberOfEmployees"}
                  className="me-3"
                  type={"text"}
                  value={search?.numberOfEmployees}
                  placeholder={t("Number of employees")}
                  isDisabled={false}
                  handleChange={(e) => handleSearchChange(e)}
                />
              </div>
              <div>
                <SearchWithLabel
                  label={t("Number sent")}
                  isMandatory={false}
                  showLabel={false}
                  name={"numberSent"}
                  className="me-3"
                  type={"text"}
                  value={search?.numberSent}
                  placeholder={t("Number sent")}
                  isDisabled={false}
                  handleChange={(e) => handleSearchChange(e)}
                />
              </div>
              <div>
                <SearchWithLabel
                  label={t("Quantity not sent")}
                  isMandatory={false}
                  showLabel={false}
                  name={"quantityNotSent"}
                  className="me-3"
                  type={"text"}
                  value={search?.quantityNotSent}
                  placeholder={t("Quantity not sent")}
                  isDisabled={false}
                  handleChange={(e) => handleSearchChange(e)}
                />
              </div>
              <div>
                <SearchWithLabel
                  label={t("Created by")}
                  isMandatory={false}
                  showLabel={false}
                  name={"created_by"}
                  className="me-3"
                  type={"text"}
                  value={search?.created_by}
                  placeholder={t("Created by")}
                  isDisabled={false}
                  handleChange={(e) => handleSearchChange(e)}
                />
              </div>
              <div>
                <div className="d-flex align-items-center">
                  <ResetBtn handleResetClick={(e) => handleClick(e, "search", false)} />
                  <SearchBtn handleSearchClick={(e) => handleClick(e, "search", true)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <div className="table-responsive tableSection">
              <table className="table table-borderless">
                <thead className="TableHeader">
                  <th>{t("Month")}</th>
                  <th>{t("Business unit")}</th>
                  <th>{t("Number of employees")}</th>
                  <th>{t("Number sent")}</th>
                  <th>{t("Quantity not sent")}</th>
                  <th>{t("Created by")}</th>
                  <th>{t("Time of creation")}</th>
                  <th>{t("Status")}</th>
                </thead>
                <tbody>
                  {partenaData && partenaData.length > 0 ?
                    (partenaData.map((record: any) => (<tr className="cursor-pointer" onClick={() => handleClick(record, "partenaCalculation")}>
                      <td>{MONTHS_TRANSLATABLE[record.month - 1]}</td>
                      <td>{record.business_units && record.business_units.map((record: any) => (record.business_unit_name)).join(', ')}</td>
                      <td>{record.business_units && record.business_units.map((record: any) => (record.no_of_employees))}</td>
                      <td>{record.business_units && record.business_units.map((record: any) => (record.export_employees))}</td>
                      <td>{record.quantityNotSent}</td>
                      <td>{record.name}</td>
                      <td>{record.creationTime}</td>
                      <td>{record.status}</td>
                    </tr>
                    ))) : (<tr className="w-100 m-auto text-center bg-warning">
                      <td className="text-danger" colSpan={8}>
                        {t(" No records found")}
                      </td>
                    </tr>)

                  }
                </tbody>
                <tr></tr>
              </table>
            </div>
            <div className="pagination justify-content-center align-items-center">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
      {showModal &&
        <>
          <ModalPopup
            show={showModal}
            // onHide={(e) => handleClick(e, "close")}
            onHide={() => handleClick(undefined, "close")} // Wrap `handleClick` in a no-arg function
            title={t("Partena add new calculation")}
            body={<AddPartenaCalculation />}
            className="partenaPopup"
          />
        </>
      }
    </>
  );
}

export default ManagePartena;