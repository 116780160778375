import React from "react";

const AddProjectIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 2048 2048" width="512" height="512">
    <path transform="translate(160)" d="m0 0h836l1 3v265l-1 175h442l3 2v585l-2 2-47 3-30 3-30 5-32 7-31 9-29 10-27 11-33 16-21 11-23 14-15 10-17 12-18 14-11 9-15 13-17 16-14 14-7 8-12 13-6 8h-2l-2 4-14 18-13 18-13 20-12 20-10 18-13 26-13 31-10 28-9 30-7 28-5 26-4 27-3 36-1 27v13l2 38 4 36 4 24 6 28 8 30 8 24v4h-745l-1-1v-1790zm500 237-5 5-12 13-111 111-4 1-71-71-4 2-80 80 1 4 69 69 5 6 7 6 5 6 7 6 5 6 7 6 5 6 8 7 36 36 4-1 4-4v-2h2v-2l3-1 5-6 7-6 3-3v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2v-2l4-2v-2l4-2v-2l4-2v-2l4-2v-2l4-2v-2l4-2v-2l4-2v-2l4-2v-2l4-2v-2l4-2 20-20v-2h2v-2h2v-2h2l5-5 1-3 4-2 3-3-1-4-81-81zm-248 448-33 1v119l91 1h655l96-1v-119l-34-1zm-24 241-9 1v119h841l1-2v-117l-8-1zm17 241-26 1v119h548v-119l-50-1zm-26 242v119h359l1-1v-118z" fill="currentColor"/>
    <path transform="translate(1424,1151)" d="m0 0h31l29 2 31 4 34 7 28 8 28 10 25 11 16 8 19 10 19 12 19 13 18 14 11 9 12 11 8 7 14 14v2h2l7 8 9 10 13 17 11 15 10 15 11 18 12 22 13 28 11 29 9 30 7 31 5 33 2 25v52l-2 24-4 28-6 29-8 28-8 24-11 26-11 23-13 23-13 20-10 14-9 12-11 13-9 11-8 8-2 3h-2l-2 4-15 14-13 12-14 11-16 12-21 14-22 13-23 12-24 11-30 11-31 9-33 7-36 6h-82l-35-6-26-5-26-7-33-11-26-11-30-15-25-15-16-11-16-12-16-13-10-9-8-7-26-26-9-11-11-13-14-19-10-15-11-18-12-22-11-23-11-28-10-31-7-30-5-30-3-29-1-19v-24l2-32 4-30 7-34 7-25 10-29 11-26 8-16 10-19 11-18 8-12 12-17 14-17 9-11 12-13 22-22 11-9 13-11 19-14 24-16 21-12 23-12 26-11 19-7 29-9 31-7 32-5 21-2zm-43 220-1 16v153h-169v119l160 1 9 1v167l1 1h118l1-168 1-1h165l2-1 1-6v-112l-1-1-168-1-1-168z"fill="currentColor"/>
    <path transform="translate(1116,60)" d="m0 0 4 2 7 8 71 71 6 5 6 7 6 5 6 7 6 5 6 7 6 5 6 7 6 5 6 7 6 5 6 7 6 5 6 7 6 5 6 7 6 5 6 7 6 5 7 8 19 19 6 5 5 5 1 3 4 2 4 4v2h2l7 8 13 13v1h-264l-1-1v-260z"fill="currentColor"/>
    <path transform="translate(1390,2047)" d="m0 0h5v1h-5z"fill="currentColor"/>
    </svg>  
  );
};

export default AddProjectIcon;
