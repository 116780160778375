import React from "react";
import MatchingSearch from "./matching";
import { FilterOptionsProvider } from "pages/microservices/planning/planning-redux/context/FiltersContext/FiltersContext";

const Matching = () => {
  return <>
    <FilterOptionsProvider>
      <MatchingSearch />
    </FilterOptionsProvider>
  </>;
};
export default Matching;
