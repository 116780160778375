import React from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { UserStatus } from "constants/Constants";
import TableActions from "components/organism/Actions/TableAction";
import StatusComponent from "./ManageStatus";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import CheckBoxField from "./CheckBoxField";
import SortAtomForManagePage from "./SortAtomForManagePage";
import { useDispatch } from "react-redux";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";

interface Props {
  sort?: any;
  data?: any;
  headers?: any;
  values?: any;
  isAction?: boolean;
  actionText?: string;
  actionClass?: string;
  editClass?: string;
  deleteClass?: string;
  archiveClass?: string;
  barcodeClass?: string;
  handleSort?: (value: string) => void;
  handleEdit?: (value: any) => void;
  handleDelete?: (value: any) => void;
  handleArchive?: (value: any) => void;
  handleUnArchive?: (value: any) => void;
  handleBarcode?: (value: any) => void;
  handleDownload?: (value: any) => void;
  handleView?: (value: any) => void;
  handleAddProject?: (value: any) => void;
  handleAddPlanning?: (value: any) => void;
  handleMoveBackItem?: (value: any) => void;
  handleClone?: (value: any) => void;
  handleSelect?: (e: any) => void;
  handleRowClick?: (value: any) => void;
  isChecked?: (value: any) => boolean;
  handleSalaryBenefits?: (e: any) => void;
  handleDocuments?: (e: any) => void;
  selectAll?: boolean;
  initialStatus?: number;
  name?: string;
  permission?: any;
  history?: any;
  handleRefresh?: () => void;
}

const DynamicTableStructure: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const userData = useSelector(selectAuth);
  return (
    <table className="table table-responsive table-hover">
      <thead className="TableHeader">
        <tr>
          {(props?.handleSelect && props.data?.length > 0) && (
            <th key={"select_all"} className="border-bottom-0 ps-3" style={{ width: '5%' }} >
              <CheckBoxField
                name="select_all"
                isChecked={props?.selectAll}
                onChangeHandler={props?.handleSelect}
              />
            </th>
          )}
          {props.headers?.map((value: any) => (
            <th
              key={value.name}
              className="border-bottom-0 ps-3"
              style={{ width: `${value.width}%` }}
            >
              {value.name}{" "}
              {value?.issort && (
                <SortAtomForManagePage value={value} dispatch={dispatch} history={props?.history} updatePageFilters={updatePageFilters} handleRefresh={props?.handleRefresh} />
              )}
            </th>
          ))}
          {props.isAction && (
            <th className={`border-bottom-0 ${props.actionClass ?? "table-action-icons"}`} >
              <AccessControl
                requiredPermissions={[{
                  permission: props.permission,
                  update: true,
                  delete: true
                }]}
                actions={true}
                strict={false}
                override={userData.isSuperAdmin}
              >
                {props.actionText ?? "Actions"}
              </AccessControl>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {props.data &&
          Object.values(props.data).map((value: any, index) => (
            <tr key={index} className={`cursor-pointer `} onClick={() => props?.handleRowClick?.(value)}>
              {props.headers?.map((column: any, colIndex: number) => (
                <React.Fragment key={colIndex}>
                  {(props?.handleSelect && colIndex == 0) && (
                    <td key={("select" + column?.alias + index)} className="border-bottom-0 ps-3" style={{ width: '5%' }} >
                      <CheckBoxField
                        name={("checkbox" + column?.alias + index)}
                        isChecked={props?.isChecked ? props.isChecked(value) : false}
                        isClicked={(e) => {
                          e.stopPropagation();
                          props?.handleSelect?.(value);
                        }}
                      />
                    </td>
                  )}
                  <td key={colIndex} className="text-break ps-3">
                    {column.alias !== "status" ? (
                      Array.isArray(value[column.alias]) ? value[column.alias].join(', ') : value[column.alias]
                    ) : (
                      <StatusComponent
                        initialStatus={
                          typeof value[column.alias] === "boolean"
                            ? value[column.alias]
                              ? 1
                              : 3
                            : value[column.alias]
                        }
                        tooltip={
                          (value[column.alias] === 1 || value[column.alias] === true)
                            ? UserStatus[1]
                            : UserStatus[2]
                        }
                      />
                    )}
                  </td>
                </React.Fragment>
              ))}
              <td className="table-action-icons px-2">
                <TableActions
                  isAction={true}
                  handleEdit={props.handleEdit}
                  handleDelete={props.handleDelete}
                  handleArchive={props.handleArchive}
                  handleUnArchive={props.handleUnArchive}
                  handleView={props.handleView}
                  handleDownload={props.handleDownload}
                  handleAddProject={props.handleAddProject}
                  handleAddPlanning={props.handleAddPlanning}
                  handleSalaryBenefits={props.handleSalaryBenefits}
                  handleDocuments={props.handleDocuments}
                  handleClone={props.handleClone}
                  value={value}
                  permission={props.permission}
                />
              </td>
            </tr>
          ))}
        {props.data?.length === 0 && (
          <tr>
            <td colSpan={props.headers?.length || 1} className="text-center">{t("No data found")}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default DynamicTableStructure;
