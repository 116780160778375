import { useFormContext } from "../../context/Context";
import { useLocation, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";

import StudiesTab from "../form-content/StudiesTab";
import WorkExperienceTab from "../form-content/WorkExperienceTab";
import JobProfileTab from "../form-content/JobProfileTab";
import DocumentsTab from "../form-content/DocumentsTab";
import React, { useEffect, useState } from "react";
import { COMPETENCE, DOCUMENTS, GENERAL, INTERNAL_INFO, JOB_PROFILE, SET_COMPETENCE_DATA, STUDIES, UPDATE_TAB_DETAILS, WORK_EXPERIENCE } from "../../annotations/CandidateConstants";
import GeneralTab from "../form-content/GeneralTab";
import Title from "components/atoms/Title";
import { t } from "pages/microservices/masterData/translation/Translation";
import { ApiCall } from "services/ApiServices";
import { M_IDENTITY_MANAGER, M_MASTER_DATA } from "constants/Constants";
import "../css/candidate.css";
import { FETCH_CANDIDATE_DATA, PROJECTS_OPTIONS } from "routes/ApiEndpoints";
import CompetenceTab from "../form-content/CompetenceTab";
import CandidateProfileCard from "./CandidateProfileCard";
import { SetCandidateData } from "./SetCandidateData";
import { InternalInfoTab } from "../form-content/InternalInfoTab";

const CandidateForm = () => {
  const { state, dispatch } = useFormContext();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const sourcePage = params.get('sourcePage');
  const isEdit = location.pathname?.includes('edit');
  const isView = location.pathname?.includes('view');
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 20) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    candidateDetails();
  }, [id]);

  const candidateDetails = async () => {
    const postData = {
      id: id,
    }
    const response = await ApiCall.service(FETCH_CANDIDATE_DATA, 'POST', postData, true, M_IDENTITY_MANAGER);
    //set competence options
    let payload = [];
    let options: any = [];
    const competenceResponse = await ApiCall.service(PROJECTS_OPTIONS, "POST", { options: ["Competence"], }, true, M_MASTER_DATA);
    options = response?.data;
    if (id !== undefined) {
      dispatch({ type: SET_COMPETENCE_DATA, payload: response.data?.candidateData?.competences });
    } else {
      dispatch({ type: SET_COMPETENCE_DATA, payload: competenceResponse?.data?.Competence ?? [] });
    }
    response?.data && SetCandidateData(options, dispatch, id, state);
  }
  //For handling the tab navigation
  const handleTabClick = (tabId: string) => {
    const currentTab = state?.tabDetails.findIndex(
      (tab: any) => tabId === tab.id
    );
    state.tabDetails[currentTab].error = false;
    const updatedTabDetails = state.tabDetails.map((tab) => ({
      ...tab,
      showStatus: tab.id === tabId,
    }));

    dispatch({ type: UPDATE_TAB_DETAILS, tabDetails: updatedTabDetails });
  };

  const ConstructTabs = (id: string, showStatus: boolean) => {
    switch (id) {
      case GENERAL:
        return <GeneralTab />;
      case STUDIES:
        return <StudiesTab />;
      case COMPETENCE:
        return (
          <CompetenceTab
            competenceOptions={state?.competence}
          />
        );
      case WORK_EXPERIENCE:
        return <WorkExperienceTab />;
      case JOB_PROFILE:
        return <JobProfileTab />;
      case DOCUMENTS:
        return <DocumentsTab />;
      case INTERNAL_INFO:
        return <InternalInfoTab />;
      default:
        break;
    }
  };

  return (
    // <AccessControl
    //   requiredPermissions={[permissionObject]}
    //   renderNoAccess={true}
    // >
    <>
      <div className="search-bar">
        <Title title={isEdit && sourcePage === null ? t("Edit medewerker") : isView ? t("View medewerker") : t("Create medewerker")} />
      </div>
      <div style={{ paddingBottom: "2.5vw", paddingTop: "0.5vw" }}>
        <CandidateProfileCard />
      </div>
      {state.tabDetails[0].error === true && (
        <div style={{ height: "4vw" }}>
          <div className="row">
            <div className="error-msg-tabs col-12 ">
              <div
                className="alert alert-danger alert-dismissible fade show "
                style={{ padding: "0.5vw" }}
                role="alert"
              >
                <span className="me-2">
                  <FontAwesomeIcon icon={faCircleExclamation} />
                </span>
                {t("Please fill the mandatory fields to create a candidate.")}
                <button
                  type="button"
                  className="btn-close shadow-none candidateformAlertClose"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="position-relative candidateForm">
        <nav
          className={`projectTabs company-step-tabs createFormTabs ${isSticky
            ? "createTabsSticky position-sticky"
            : "company-step-tabs-absolute position-absolute w-100"
            }`}
        >
          <div
            className="nav nav-tabs border-0 col-xxl-11 m-auto col-xl-12 col-lg-12 px-5 justify-content-center"
            id="nav-tab"
            role="tablist"
          >
            {state.tabDetails.map((item: any, index) => (
              <React.Fragment key={item.id}>
                <button
                  key={item.id}
                  className={` nav-link  border-0 text-break createStepBtn  cursor-pointer ${item.error ? "error" : ""
                    } ${item.showStatus == true ? "active" : "inactive"}`}
                  id={`${item.id}-tab`}
                  data-bs-toggle="tab"
                  data-bs-target={`#${item.id}`}
                  type="button"
                  role="tab"
                  aria-controls={`nav-${item.id}`}
                  aria-selected={item.showStatus}
                  onClick={() => handleTabClick(item.id)}
                  style={{ borderRadius: "0.4vw", padding: "0.5vw", marginRight: "0.75vw" }}
                >
                  <div className={`d-flex align-items-center  justify-content-center ${item.error ? "error" : ""
                    } ${item.showStatus ? "active" : "inactive"} `}>
                    {t(`${item.title}`)}

                    {item.error && (
                      <span
                        className="exclamation-icon ms-2 text-danger"
                        title={`${t("Please fill all the mandatory fields")}`}
                        data-toggle="tooltip"
                      >
                        <FontAwesomeIcon icon={faTriangleExclamation} />
                      </span>
                    )}
                  </div>
                </button>
              </React.Fragment>
            ))}
          </div>
        </nav>

        <div className="tab-content py-0" style={isView ? { pointerEvents: "none" } : {}} id="nav-tabContent">
          {state.tabDetails.map((item: any, index: any) => (
            <React.Fragment key={item.id}>
              <div
                key={item.id}
                className={`tab-pane fade ${item.showStatus ? "show" : ""} ${item.showStatus ? "active" : ""
                  }`}
                id={item.id}
                role="tabpanel"
                aria-labelledby={`${item.id}-tab`}
              >
                {ConstructTabs(item.id, item.showStatus)}
              </div>
            </React.Fragment>
          ))
          }
        </div >
      </div >
    </>
    // </AccessControl>
  );
};

export default CandidateForm;