import React, { useState } from "react";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { ApplicationStatus, UserStatus } from "constants/Constants";
import { UserData } from "../users/ManageUsers";
import ModalPopup from "components/atoms/ModalPopup";
import CustomNotify from "components/atoms/CustomNotify";
import { ApiCall } from "services/ApiServices";
import Button from "components/atoms/Button";
import "static/css/users.css";
import { DELETE_USER, UNARCHIVE_USER } from "routes/ApiEndpoints";
import { t } from "pages/microservices/masterData/translation/Translation";
import TableStructure from "components/atoms/TableStructure";
import { ApplicantTableHeader } from "TableHeader";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { CandidateData } from "./ManageApplicants";
import { EDIT_CANDIDATE, PATH_CANDIDATE_CORNER, PATH_CANDIDATE_SCREENING, PATH_JOB_APPLICATION_OVERVIEW } from "constants/Paths";
import { IconData } from "services/IconData";
import { convertArrayToOptions } from "services/util/UtilService";
import FilterOrganism from "components/organism/FilterOrganism";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import Title from "components/atoms/Title";
import ResetBtn from "components/atoms/ResetBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BackButton from "components/atoms/BackButton";
import "./css/applicants.css";
interface ManageUsersPageProps {
  usersObj: CandidateData[];
  deleteFilter: (deleteId: number) => void;
  dispatch: any;
  history: any;
  pageData: any;
  handleRefresh: () => void;
  dropDownData?: any;
}

const ManageApplicantsPage: React.FC<ManageUsersPageProps> = ({
  usersObj,
  deleteFilter,
  dispatch,
  history,
  pageData,
  handleRefresh,
  dropDownData,
}) => {
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  // start of index
  const columns = ["businessUnits", "name", "email", "phoneNumber", "job_offer", "company_name", "dateOfApplication", "channel", "status"];
  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${DELETE_USER}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET");
      if (response?.status === 200) {
        // const url = `archiveUser/${deleteID}`; //archive user in candidate-creation
        // await ApiCall.getService(url, "GET", "candidate-creation");
        deleteFilter(deleteID);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  const handleUnArchiveChanges = async (userId: number) => {
    if (userId) {
      const url = `${UNARCHIVE_USER}/${userId}`;
      const response = await ApiCall.getService(url, "GET");
      if (response?.status === 200) {
        deleteFilter(userId);
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };


  const handleArchive = (userData: UserData | any) => {
    setShowModal(true);
    setDeleteId(userData.id);
  };

  const handleUnArchive = (userData: UserData | any) => {
    if (userData.id) {
      handleUnArchiveChanges(userData.id);
    }
  };

  const userData = useSelector(selectAuth);
  const handleScreening = (userData: UserData | any) => {
    navigate(`${PATH_CANDIDATE_SCREENING}/${userData.id}`);
  };

  const handleView = (userData: UserData | any) => {
    if (userData.id) {
      sessionStorage.setItem("originPage", "ManageCandidates");
      navigate(`/applicant/view/${userData.id}`);
    }
  };

  function handleClick(userData: UserData | any): void {
    navigate(`${PATH_CANDIDATE_CORNER}/${userData.id}`, { state: { element: 'applicant' } });
  }

  const filters = [
    { name: 'businessUnits', fieldType: "multiSelect", options: dropDownData?.businessUnits, placeholder: "Business unit", filterType: 'search' },
    { name: 'candidate', fieldType: "text", placeholder: "Candidate name", filterType: 'search' },
    { name: 'email', fieldType: "text", placeholder: "Email", filterType: 'search' },
    { name: 'phoneNumber', fieldType: "text", placeholder: "Phone number", filterType: 'search' },
    { name: 'jobOffer', fieldType: "text", placeholder: "Vacancy", filterType: 'search' },
    { name: 'company_name', fieldType: "text", placeholder: "Company name", filterType: 'search' },
    { name: 'startDate', fieldType: "date", placeholder: "Start", filterType: 'search' },
    { name: 'endDate', fieldType: "date", placeholder: "End", filterType: 'search' },
    { name: 'channel', fieldType: "text", placeholder: "Vacancy", filterType: 'search' },
    { name: 'status', fieldType: "singleSelect", options: ApplicationStatus, placeholder: "Status", filterType: 'search' },
  ];

  const handleAddCandidate = (data: any) => {
    navigate(`${EDIT_CANDIDATE}/${data.id}`);
  }

  const handleProceed = (data: any) => {
    navigate(`${PATH_JOB_APPLICATION_OVERVIEW}/${data.id}`);
  }

  const handleViewResume = (data: any) => {
    window.open(data?.cv, '_blank');
  }

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Candidates",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="search-bar">
        <div className="row">
          <Title title={t("Applications")} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <div className="table-responsive tableSection manageApplicantsWrapper">
              <FilterOrganism
                dispatch={dispatch}
                history={history}
                updatePageFilters={updatePageFilters}
                filters={filters}
                handleRefresh={handleRefresh}
              />
              <div className="table-top-buttons-block">
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Candidates",
                      create: true,
                    },
                  ]}
                  override={userData.isSuperAdmin}
                >
                  {/* <div className="row">
                    <div className="ManageCreateBtn">
                      <LinkTo
                        pagelink="/applicant/create"
                        title={t("Add applicant")}
                        icon={faPlus}
                      />
                    </div>
                  </div> */}
                </AccessControl>
              </div>
              <div className="table-responsive manageApplicantsTableHeight">
                <TableStructure
                  isAction
                  headers={ApplicantTableHeader}
                  data={usersObj}
                  values={columns}
                  // handleView={handleView}
                  // handleScreening={handleScreening}
                  // handleArchive={handleArchive}
                  // handleUnArchive={handleUnArchive}
                  permissionType={"Candidates"}
                  handleClick={handleClick}
                  clickStatus={true}
                  handleRefresh={handleRefresh}
                  history={history}
                  //handleAddCandidate={handleAddCandidate}
                  handleProceed={handleProceed}
                  handleViewDocument={handleViewResume}
                />
              </div>
              <div>
                <PaginationWithPerPage
                  handleRefresh={handleRefresh}
                  dispatch={dispatch}
                  history={history}
                  pageData={pageData}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-6 align-self-center backPadding">
          <BackButton />
        </div> */}
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title={t("Archive confirmation")}
        body={t("Are you sure want to archive?")}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </AccessControl>
  );
};

export default ManageApplicantsPage;
