import React from 'react'

const SalaryBenefitIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="71.354" height="53.804" viewBox="0 0 71.354 53.804">
      <path id="Salary_benefits" data-name="Salary benefits" d="M0,22.864H14.42V48.52H0ZM47.463,0a12.84,12.84,0,1,1-12.84,12.84A12.841,12.841,0,0,1,47.463,0Zm2.451,14.623H46.947a2.59,2.59,0,0,0,.679,1.853,2.981,2.981,0,0,0,2.085.6,4.549,4.549,0,0,0,1.673-.285l.407,2.555a11.246,11.246,0,0,1-2.5.285A6.039,6.039,0,0,1,45.2,18.3a4.833,4.833,0,0,1-1.6-3.676H42.034V13.1H43.6V11.946H42.034v-1.51h1.6A5.061,5.061,0,0,1,45.4,6.76a6.371,6.371,0,0,1,4.193-1.318,9.257,9.257,0,0,1,2.207.3L51.389,8.3a4.229,4.229,0,0,0-1.585-.3,2.99,2.99,0,0,0-2,.592,2.646,2.646,0,0,0-.819,1.841H49.92v1.51H46.952v1.156H49.92v1.516h-.006ZM17.323,46.366V24.919h9.646c4.088.732,8.177,2.95,12.265,5.523h7.491c3.391.2,5.169,3.641,1.87,5.9-2.625,1.928-6.092,1.818-9.646,1.5-2.451-.122-2.555,3.171,0,3.182.889.07,1.853-.139,2.695-.139,4.437-.006,8.084-.854,10.32-4.356l1.121-2.619,11.144-5.523c5.575-1.835,9.536,4,5.43,8.055A146,146,0,0,1,44.844,51.047c-6.15,3.74-12.3,3.612-18.444,0l-9.077-4.681Z" fill="currentColor" fillRule="evenodd" />
    </svg>
  )
}

export default SalaryBenefitIcon