import React, { useState } from "react";
import { useFormContext } from "../../context/Context";
import { initialPCCategoryList } from "../../helpers/PCHelperFunctions";
import { categories } from "../../annotations/PCInterface";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { t } from "pages/microservices/masterData/translation/Translation";
import Button from "components/atoms/Button";
import ModalPopup from "components/atoms/ModalPopup";
import DeleteIcon from "static/images/DeleteIcon";
import EditIcon from "static/images/EditIcon";
import LabelField from "components/atoms/LabelField";
import { ADD_CATEGORY, CATEGORIES, REMOVE_CATEGORY, SET_DROPDOWN_DATA, UPDATE_FIELD } from "../../annotations/PCConstants";
import { ValidationRules } from "utils/TypeAnnotations";
import { validateForm, validateNumberIfNotRequired } from "services/validation/ValidationService";

export const Categories = () => {
  const { state, dispatch } = useFormContext();
  const [categoryList, setCatgeoryList] = useState<categories>(initialPCCategoryList);
  const [modalPopup, setModalPopup] = useState(false);
  const [index, setIndex] = useState(0);
  const intialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{
    [key: string]: string;
  }>(intialErrors);

  const validation = (
    name: string,
    value: string | number | null | boolean | Date | object[] | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      minSalary: [validateNumberIfNotRequired],
      maxSalary: [validateNumberIfNotRequired],
    };
    const validationErrors = validateForm(
      { ...categoryList, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleFieldChange = (event: any) => {
    const { name, value } = event.target;
    setCatgeoryList((prevData: any) => ({
      ...prevData,
      [name]: value
    }));
    validation(name, value, true);
  }

  const addCategory = (length: number) => {
    setIndex(length); // Set the index for dispatch
    setModalPopup(true);
    setErrors({});
    setCatgeoryList(initialPCCategoryList);
  }

  const handleClosePopup = () => {
    setModalPopup(false);
    setErrors({});
    setCatgeoryList(initialPCCategoryList);
  }

  const editData = (index: number) => {
    setIndex(index); // Set the index for dispatch
    setModalPopup(true);
    const categoryToEdit =
      state.categories[index]; // Get the category to edit
    if (categoryToEdit) {
      // Populate the  data in the modal fields
      setCatgeoryList({
        id: categoryToEdit.id,
        name: categoryToEdit.name,
        minSalary: categoryToEdit.minSalary,
        maxSalary: categoryToEdit.maxSalary,
      });
    }
  };

  const removeFieldSet = (indexToRemove: number, category: any) => {
    dispatch({ type: REMOVE_CATEGORY, indexToRemove, category });
  };

  const onOkButtonClick = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    // if (validation(name, value)) {
    if (categoryList.name !== '') {
      dispatch({
        type: ADD_CATEGORY,
        data: categoryList,
        index: index,
      });

    }
    setModalPopup(false); // Close modal
    resetFields();
    setErrors({});
    // }
  };

  const resetFields = () => {
    setCatgeoryList(initialPCCategoryList);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <LabelField title={t("Add category")} className="tab-subtitle" />
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader bg-white">
                  <th className="border-0 align-middle col">
                    {t("Category name")}
                  </th>
                  <th className="border-0 align-middle col">
                    {t("Maximum salary")}
                  </th>
                  <th className="border-0 align-middle col">
                    {t("Minimum salary ")}
                  </th>
                  <th
                    className="border-0 align-middle col">
                    {t("Action")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {state.categories && state.categories.length > 0 && state.categories.map(
                  (category, index) => (
                    <tr key={index}>
                      <td
                        className="align-middle text-break"
                        data-label={t("Category name")}
                      >
                        {category.name}
                      </td>
                      <td
                        className="align-middle text-break"
                        data-label={t("Minimum salary")}
                      >
                        {category.minSalary}
                      </td>
                      <td
                        className="align-middle text-break"
                        data-label={t("Maximum salary")}
                      >
                        {category.maxSalary}
                      </td>
                      <td className="align-middle text-break table-action-icons">
                        <span
                          title={t("Edit")}
                          onClick={() => editData(index)}
                          className="table-action-btn cursor-pointer shadow-none marginRightPoint5"
                        >
                          <EditIcon />
                        </span>

                        <span
                          title={t("Delete")}
                          onClick={() => removeFieldSet(index, category)}
                          className="table-action-btn cursor-pointer shadow-none"
                        >
                          <DeleteIcon />
                        </span>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row" style={{ marginTop: "1vw" }}>
        <div className="col me-0">
          <Button
            title={"+ " + t("Add")}
            handleClick={() =>
              addCategory(state.categories.length)
            }
            className="form-button float-end"
          />
        </div>
      </div>

      {
        modalPopup &&
        <ModalPopup
          show={modalPopup}
          onHide={handleClosePopup}
          title={t("Categories")}
          body={
            <>
              <div className="d-grid" style={{ gridTemplateColumns: "repeat(2, 1fr)", gap: "0 1vw" }}>
                <div>
                  <LabelWithInputField
                    isMandatory={false}
                    name="name"
                    handleChange={(e) => handleFieldChange(e)}
                    value={categoryList.name}
                    id="name"
                    label={t("Category name")}
                    type="text"
                    placeholder="Category name"
                  />
                </div>
                <div>
                  <LabelWithInputField
                    isMandatory={false}
                    name="minSalary"
                    handleChange={(e) => handleFieldChange(e)}
                    value={categoryList.minSalary}
                    id="minSalary"
                    label={t("Minimum salary")}
                    type="text"
                    error={errors.minSalary}
                    placeholder="Minimum salary"
                  />
                </div>
                <div>
                  <LabelWithInputField
                    isMandatory={false}
                    name="maxSalary"
                    handleChange={(e) => handleFieldChange(e)}
                    value={categoryList.maxSalary}
                    id="maxSalary"
                    label={t("Maximum salary")}
                    type="text"
                    error={errors.maxSalary}
                    placeholder="Maximum salary"
                  />
                </div>
              </div>
              <div className="text-end">
                <Button
                  title={t('Add')}
                  handleClick={onOkButtonClick}
                  className="form-button"
                />
              </div>
            </>
          }
        />
      }
    </>
  );
}
