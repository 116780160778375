import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "components/atoms/Button";
import React, { useState, ChangeEvent, useEffect } from "react";
import { t } from "../translation/Translation";
import {
  validateForm,
  validateRequired,
  validateTimeFormat,
  validateTimeRange,
} from "services/validation/ValidationService";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckBoxField from "components/atoms/CheckBoxField";
import { ApiCall } from "services/ApiServices";
import { CREATE_SHIFT, GET_SHIFT } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PATH_MANAGE_SHIFTS } from "constants/Paths";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import BackButton from "components/atoms/BackButton";
import TimeField from "react-simple-timefield";
import TimeFieldWithLabel from "components/molecules/TimeFieldWithLabel";

interface InputItem {
  id?: number;
  shift_name: string | any;
  from: any;
  to: any;
  pause: any;
  break_invoiced: any;
  break_paid: any;
}

interface ValidationRules {
  [key: string]: Function[];
}

function CreateShifts() {
  const [formData, setFormData] = useState<any>({
    shift_name: "",
    from: "",
    to: "",
    pause: "",
    break_invoiced: true,
    break_paid: "",
  });
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [tableData, setTableData] = useState<InputItem[]>([]);
  const [errors, setErrors] = useState<InputItem | any>({
    shift_name: "",
    from: "",
    to: "",
    pause: "",
    break_invoiced: "",
    break_paid: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const editData = { id: id };
          const response = await ApiCall.service(
            GET_SHIFT,
            "POST",
            editData,
            false,
            M_MASTER_DATA
          );
          if (response?.status === 200) {
            console.log(response?.data);
            const data = {
              ...response.data,
              from: response.data.from.slice(0, 5),
              to: response.data.to.slice(0, 5),
              pause: response.data.pause.slice(0, 5),
            };
            setFormData(data);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchData();
  }, [id]);

  const changeHandler = (e: any) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevData: any) => ({ ...prevData, [name]: checked }));
    } else {
      setFormData((prevData: any) => ({ ...prevData, [name]: value }));
    }

    validateInput(name, value);
  };

  const validateInput = (
    name: string | any,
    value: string | boolean | any,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      shift_name: [validateRequired],
      from: [validateRequired, validateTimeFormat],
      to: [validateRequired, validateTimeFormat],
      pause: [validateRequired],
    };

    // Validate the form data based on the rules
    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules
    );
    if (name === "pause") {
      const timeRangeError = validateTimeRange(
        formData?.from,
        formData.to,
        value
      );
      if (timeRangeError) {
        validationErrors[name] = timeRangeError;
      }
    } else if (name === "from" && formData.to) {
      const timeRangeError = validateTimeRange(value, formData.to);
      if (timeRangeError) {
        validationErrors[name] = timeRangeError;
      }
    } else if (name === "to" && formData.from) {
      const timeRangeError = validateTimeRange(formData.from, value);
      if (timeRangeError) {
        validationErrors[name] = timeRangeError;
      }
    }

    // Update errors state based on the validation results
    if (!isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    // Check if there are any validation errors
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleRemove = (index: number) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  const handleEdit = (index: number) => {
    const editedData = tableData[index];
    setFormData(editedData);
    setEditIndex(index);
  };

  const getRowClass = (index: any) => {
    return editIndex === index ? "highlighted-row" : "";
  };

  const handleAddClick = () => {
    const isFormValid =
      validateInput("shift_name", formData.shift_name, true) &&
      validateInput("from", formData.from, true) &&
      validateInput("to", formData.to, true) &&
      validateInput("pause", formData.pause, true);

    if (isFormValid) {
      // Check if editing or adding new entry
      if (editIndex !== null) {
        // Editing existing entry
        const updatedTableData = [...tableData];
        updatedTableData[editIndex] = formData;
        setTableData(updatedTableData);

        // Reset the form and edit state
        setFormData({
          shift_name: "",
          from: "",
          to: "",
          pause: "",
          break_invoiced: true,
          break_paid: "",
        });
        setEditIndex(null);
      } else {
        setTableData((prevTableData) => [...prevTableData, formData]);
        setFormData({
          shift_name: "",
          from: "",
          to: "",
          pause: "",
          break_invoiced: true,
          break_paid: "",
        });
        setErrors({
          shift_name: "",
          from: "",
          to: "",
          pause: "",
          break_invoiced: true,
          break_paid: "",
        });
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let finalData = [...tableData];
    let isFormValid = true;

    isFormValid =
      validateInput("shift_name", formData.shift_name, true) &&
      validateInput("from", formData.from, true) &&
      validateInput("to", formData.to, true) &&
      validateInput("pause", formData.pause, true);

    if (Object.keys(formData).length !== 0) {
      finalData.push(formData);
    }

    if (isFormValid) {
      const response = await ApiCall.service(
        CREATE_SHIFT,
        "POST",
        finalData,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({
          type: "success",
          message: `Shifts ${id ? "updated" : "created"} successfully`,
        });
        navigate(`${PATH_MANAGE_SHIFTS}`);
        return;
      }
      CustomNotify({ type: "error", message: "Error while creating shifts" });
    }
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Shifts",
  };
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);

  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <div className="row">
            <Title title={id ? t("Edit shifts") : t("Create shifts")} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={handleSubmit} style={{ marginBottom: "1vw" }}>
              <div className="form-border">
                <div className="row">
                  <div className="col-md-12">
                    <LabelWithInputField
                      isMandatory
                      name="shift_name"
                      handleChange={(e) => changeHandler(e)}
                      value={formData.shift_name}
                      id="shift_name"
                      label={t("Name")}
                      placeholder={t("Name")}
                      type="text"
                      error={errors.shift_name}
                    />
                  </div>
                  <LabelField
                    title={t("Shift timings")}
                    className="tab-subtitle"
                  />
                  <div className="col-md-2">
                    <TimeFieldWithLabel
                      isMandatory
                      name="from"
                      handleChange={(value) => changeHandler({ target: { name: 'from', value } })}
                      value={formData.from}
                      id="from"
                      label={t("From")}
                      placeholder={t("From")}
                      error={errors.from}
                    />
                  </div>
                  <div className="col-md-2">
                    <TimeFieldWithLabel
                      isMandatory
                      name="to"
                      handleChange={(value) => changeHandler({ target: { name: 'to', value } })}
                      value={formData.to}
                      id="to"
                      label={t("To")}
                      placeholder={t("To")}
                      error={errors.to}
                    />
                  </div>
                  <div className="col-md-2">
                    <TimeFieldWithLabel
                      isMandatory
                      name="pause"
                      handleChange={(value) => changeHandler({ target: { name: 'pause', value } })}
                      value={formData.pause}
                      id="pause"
                      label={t("Pause")}
                      placeholder={t("Pause")}
                      error={errors.pause}
                    />
                  </div>
                  <div className="col-md-3 plusIconSPace">
                    <CheckBoxField
                      name="break_invoiced"
                      label="Break Invoiced"
                      isChecked={formData.break_invoiced}
                      id="break_invoiced"
                      lineHeight="1.5vw"
                      onChangeHandler={changeHandler}
                      className="marginRight1"
                    />
                    <CheckBoxField
                      name="break_paid"
                      label="Break Paid"
                      isChecked={formData.break_paid}
                      id="break_paid"
                      onChangeHandler={changeHandler}
                      lineHeight="1.5vw"
                    />
                  </div>
                  <div className="col-md-3 searchBtnWrapper">
                    {id === undefined && (
                      <Button
                        title={
                          editIndex !== null ? t("Update") : t("+ Add another shift")
                        }
                        handleClick={handleAddClick}
                        className="form-button float-end"
                      />
                    )}
                  </div>
                </div>
              </div>
            </form>

            {/* table below the form */}
            <div className="table-responsive">
              {tableData.length > 0 && (
                <table className="table table-hover">
                  <thead className="TableHeader">
                    <tr>
                      <th style={{ width: "15%" }} className="ps-4">
                        {t("Name")}
                      </th>
                      <th style={{ width: "10%" }}>{t("From")}</th>
                      <th style={{ width: "10%" }}>{t("To")}</th>
                      <th style={{ width: "10%" }}>{t("Pause")}</th>
                      <th style={{ width: "10%" }}>{t("Break Invoiced")}</th>
                      <th style={{ width: "10%" }}>{t("Break Paid")}</th>
                      <th style={{ width: "15%" }}>{t("Actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item, index) => (
                      <tr key={index} className={getRowClass(index)}>
                        <td className="align-middle ps-4">{item.shift_name}</td>
                        <td className="align-middle">{item.from}</td>
                        <td className="align-middle">{item.to}</td>
                        <td className="align-middle">{item.pause}</td>
                        <td className="align-middle">
                          {item.break_invoiced ? (
                            <FontAwesomeIcon
                              icon="check"
                              style={{ color: "green" }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon="times"
                              style={{ color: "red" }}
                            />
                          )}
                        </td>
                        <td className="align-middle">
                          {item.break_paid ? (
                            <FontAwesomeIcon
                              icon="check"
                              style={{ color: "green" }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon="times"
                              style={{ color: "red" }}
                            />
                          )}
                        </td>
                        <td className="table-action-icons">
                          <span
                            onClick={() => handleEdit(index)}
                            className="table-action-btn me-2 cursor-pointer"
                            title={t("Edit")}
                          >
                            <EditIcon />
                          </span>
                          <span
                            onClick={() => handleRemove(index)}
                            className="table-action-btn cursor-pointer"
                            title={t("Delete")}
                          >
                            <DeleteIcon />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <div className="d-flex justify-content-between backPadding align-items-center">
              <div>
                <BackButton />
              </div>

              <div>
                <Button
                  title={t("Save")}
                  handleClick={handleSubmit}
                  className="form-button float-end shadow-none"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </AccessControl>
  );
}

export default CreateShifts;
