import { t } from "pages/microservices/masterData/translation/Translation";
import React from "react";
import DeleteIcon from "static/images/DeleteIcon";
import EditIcon from "static/images/EditIcon";

interface dataProps {
  data: object[];
  pcChangeHandler: (value: any, type: string) => void;
  pcDeleteHandler: (value: any, type: string) => void;
  type: string;
}

const PCTable: React.FC<dataProps> = ({
  data,
  pcChangeHandler,
  type,
  pcDeleteHandler,
}) => {
  return (
    <>
      {data && data.length > 0 && (
        <div className="col-lg-12">
          <table className="table table-hover general-agreement-table">
            <thead>
              <tr className="TableHeader bg-white">
                <th className="ps-lg-4">
                  {t("Paritair committee")} {type}
                </th>
                <th>{t("Employee types")}</th>
                <th>{t("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => (
                <tr
                  key={index}
                  className="border-bottom mb-3 box-shadow align-middle"
                >
                  <td
                    className="text-break ps-lg-4"
                    data-label={t("Paritair committee")}
                  >
                    {item.pc.label}
                  </td>
                  <td className="text-break" data-label={t("Employee types")}>
                    {item.employeeType.map((empType: any, index: number) =>
                      index === 0 ? t(empType.label) : `, ${t(empType.label)}`
                    )}
                  </td>
                  <td className="table-action-icons">
                    <button
                      type="button"
                      onClick={() => pcChangeHandler(index, type)}
                      className="border-0 shadow-none table-action-btn cursor-pointer marginRightPoint5"
                      title={t("Edit")}
                    >
                      <EditIcon />
                    </button>
                    <button
                      type="button"
                      onClick={() => pcDeleteHandler(index, type)}
                      className="border-0 shadow-none table-action-btn cursor-pointer"
                      title={t("Delete")}
                    >
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default PCTable;
