import React, { useEffect, useState } from "react";
import { filterValues, MANAGE_TRANSLATION_CONSTANTS } from "./State";
import { t } from "./Translation";
import Button from "components/atoms/Button";
import { renderFormElements } from "services/form/FormElements";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const ManageFilters: React.FC<any> = ({
  handleChange,
  options,
  data,
  handleSubmit,
  handleClear,
}) => {
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    let row = filterValues(options);
    setRows(row);
  }, [options]);

  return (
    <>
      <div className="d-flex" style={{ gap: "1vw" }}>
        {rows.map((value: any) => (
          <div className={`col-3 hideLabel`} key={value?.name}>
            {renderFormElements(
              { type: value?.type, name: value?.name, value },
              (e: any) => handleChange(e, value?.name),
              data
            )}
          </div>
        ))}
        {/* <div className="d-flex m-auto">
                    <Button
                        className="btn delete-btn  shadow-none text-uppercase me-3 "
                        handleClick=
                        // title={t(MANAGE_TRANSLATION_CONSTANTS?.RESET)}
                        title={IconData.ResetIcon}
                    />
                    <Button
                        className='btn form-button shadow-none text-uppercase px-3 search-btns'
                        handleClick=
                        title={t(MANAGE_TRANSLATION_CONSTANTS?.SUBMIT)}
                    />
                </div> */}
        <div className="col-3">
          <div className="d-flex align-items-center">
            <ResetBtn handleResetClick={handleClear} />
            <SearchBtn handleSearchClick={handleSubmit} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageFilters;
