import { TIMESHEET_FILTER_ACTIONS } from "../../actions/TimesheetFilterActions";

export const filtersReducer = (filterState, actions) => {    
    switch(actions.type){
        case TIMESHEET_FILTER_ACTIONS.SET_OPTIONS: 
            return {
                ...filterState,
                optionState: {
                    ...filterState.optionState, ...actions?.payload
                }
            }

        case TIMESHEET_FILTER_ACTIONS.SET_SELECTED_STATE: 
            if (actions?.payload.selectAll) {
                return {
                    ...filterState,
                    selectedState: { ...filterState.selectedState, ...actions?.payload }
                }
            }
            return {
                ...filterState,
                selectedState: {
                    ...filterState.selectedState,
                    [actions.payload.name]: actions?.payload.value
                }
            }

        case TIMESHEET_FILTER_ACTIONS.SET_FILTERED_OPTIONS: 
            return {
                ...filterState,
                filteredOptions: {
                    ...filterState.filteredOptions, ...actions?.payload
                }
            }

        default:
            break;
    }
}
