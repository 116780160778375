import Modal from "react-bootstrap/Modal";
import { t } from "../masterData/translation/Translation";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import Button from "components/atoms/Button";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import Calender from "components/molecules/Calender";

const FleetEmployeeModal = (props: any) => {
  const { data, setFleetData, handleClose, handleSave, handleRemove, errors, validationFn } = props;

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFleetData((prevState: any) => ({ ...prevState, [name]: value }));
    validationFn(name, value, true);
  }

  const handleSelectChange = (event: any, category: string) => {
    setFleetData((prevState: any) => ({ ...prevState, [category]: event }));
    validationFn(category, event, true);
  }

  const handleDateChange = (event: any, categoryName: string) => {
    if (event) {
      const date = `${event.getFullYear()}-${event.getMonth() + 1 < 10
        ? "0" + (event.getMonth() + 1)
        : event.getMonth() + 1
        }-${event.getDate() < 10 ? "0" + event.getDate() : event.getDate()}`;
      setFleetData((prevState: any) => ({ ...prevState, [categoryName]: date }));
      validationFn(categoryName, date, true);
    }
  };

  return (
    <Modal
      size="lg"
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className="fleetModalHeader">
        <Modal.Title className="font-CalbriBold">
          <div className="row">
            <div className="col-12">
              {t(`Select assign from and assign to dates`)}
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0">
        {data?.type == 'clothes' && (<div className="row"><div className="col-12 font-CalbriBold" style={{ padding: "0.5vw 0" }}>{t("Remaining units")}: {data?.remainingUnits}</div></div>)}
        <div className="row">
          <div className="col-6">
            <div className="position-relative">
              <Calender
                onChange={(event) => {
                  handleDateChange(event, "fromDate");
                }}
                selectedDate={data.fromDate}
                label={t("Date")}
                isMandatory={true}
                name={"fromDate"}
                error={errors.fromDate}
                isDisabled={false}
                minDate={new Date()}
                placeHolder="dd-mm-yyyy"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="position-relative">
              <Calender
                onChange={(event) => {
                  handleDateChange(event, "toDate");
                }}
                selectedDate={data?.toDate}
                label={t("To date")}
                isMandatory={true}
                name={"toDate"}
                error={errors?.toDate}
                isDisabled={false}
                minDate={new Date()}
                placeHolder="dd-mm-yyyy"
              />
            </div>
          </div>
          <div className={`${data?.type == 'clothes' ? "col-6" : "col-12"}`}>
            <SelectWithSearch
              onChange={(event) => {
                handleSelectChange(event, 'fleetStatus')
              }}
              title={t("Status")}
              placeHolder={t("Status")}
              search={true}
              options={[{
                name: "Assigned",
                label: "Assigned",
                value: 3
              },
              {
                name: "Reserved",
                label: "Reserved",
                value: 4
              }]}
              value={data?.fleetStatus}
              isDisabled={false}
              isMandatory={true}
              error={errors?.fleetStatus}
              name={"fleetStatus"}
              id={"fleetStatus"}
            />
          </div>

          {data?.type == 'clothes' && (<div className="col-6">
            <LabelWithInputField
              type={"number"}
              placeholder={t("No of units")}
              handleChange={handleInputChange}
              label={t("No of units")}
              value={data?.count}
              isMandatory={true}
              error={errors.count}
              name={"count"}
              id={"count"}
              min={0}
            />
          </div>)}
        </div>
        <div className="row">
          <div className="col-6">
            <Button handleClick={handleClose} className="delete-btn shadow-none" title={t("Cancel")} />
          </div>
          <div className="col-6 text-end">
            <Button
              title={data.status ? t("update") : t("Add")}
              handleClick={handleSave}
              className="form-button shadow-none float-end"
            />
            {data.status && (<Button
              title={"Remove"}
              handleClick={handleRemove}
              className="form-button shadow-none float-end marginRightPoint5"
            />)}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default FleetEmployeeModal