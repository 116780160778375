import React, { ChangeEvent } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { LabelWithInputFieldAndUnit } from "../atoms/LabelWithInputFieldAndUnit";
import SelectWithSearch from "components/atoms/SelectWithSearch";

interface GeneralMoleculeProps {
  unit?: any;
  field: any;
  name?: any;
  value?: any;
  disabled: boolean;
  error?: any;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const GeneralMolecule: React.FC<GeneralMoleculeProps> = ({
  name,
  value,
  unit,
  disabled,
  error,
  handleChange,
  field,
}) => {

  const renderField = () => {
    switch (field?.field_name) {
      case "Template name":
        return (
          <div className="col-12">
            <LabelWithInputFieldAndUnit
              isMandatory={true}
              label={t(name)}
              value={value}
              handleChange={handleChange}
              unit={unit}
              type="text"
              readOnly={false}
              isDisabled={disabled}
              placeholder={t(field?.field_name)}
              error={t(error)}
            />
          </div>
        );
      case "Total amount":
      case "Number of months":
      case "Time period":
        return (
          <>
            {field?.field_name === "Total amount" && (
              <LabelWithInputFieldAndUnit
                isMandatory={true}
                label={t(name)}
                value={value}
                handleChange={handleChange}
                unit={unit}
                type="text"
                isDisabled={disabled}
                placeholder={t(field?.field_name)}
                error={t(error)}
              />
            )}

            {field?.field_name === "Number of months" && (
              <LabelWithInputFieldAndUnit
                isMandatory={true}
                label={t(name)}
                value={value}
                handleChange={handleChange}
                unit={unit}
                type="text"
                isDisabled={disabled}
                placeholder={t(field?.field_name)}
                error={t(error)}
              />
            )}

            {field?.field_name === "Time period" && (
              <SelectWithSearch
                search={true}
                title={t(name)}
                value={value}
                name={name}
                options={[
                  {
                    label: t("Biannually"),
                    value: "biannually",
                  },
                  {
                    label: t("Monthly"),
                    value: "monthly",
                  },
                  {
                    label: t("Quarterly"),
                    value: "quarterly",
                  },
                  {
                    label: t("Weekly"),
                    value: "weekly",
                  },
                  {
                    label: t("Yearly"),
                    value: "yearly",
                  },
                ]}
                onChange={(e) => { handleChange(e) }}
                isDisabled={false}
                placeHolder={t("Select")}
                error={t(error)}
                containerClassName="col-6"
                className="field-shadow"
                isMandatory={true}
              />
            )}
          </>
        );
      default:
        return null;
    }
  };

  return <>{renderField()}</>;
};

export default GeneralMolecule;
