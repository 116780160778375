import CustomNotify from "components/atoms/CustomNotify";
import { M_MASTER_DATA } from "constants/Constants";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ApiCall } from "services/ApiServices";
import {
  validateForm,
  validateRequired,
} from "services/validation/ValidationService";
import { t } from "../translation/Translation";
import AccessControl from "services/AccessControl";
import Title from "components/atoms/Title";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import Button from "components/atoms/Button";
import LoadingIcon from "utils/LoadingIcon";
import {
  COEFFICIENT_EDIT,
  CREATE_UPDATE_COEFFICIENT,
} from "routes/ApiEndpoints";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import BackButton from "components/atoms/BackButton";

interface FormProps {
  code: number | null;
  coeffDevname: string;
  // coeffType: Option | null;
  // refCoeff: Option | null;
  // coeffValue: string;
}

interface optionProps {
  value?: number;
  name?: string;
}

interface ValidationRules {
  [key: string]: Function[];
}

const CoeffDeviation = () => {
  const navigate = useNavigate();
  const userData = useSelector(selectAuth);
  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );

  const editId = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);
  // const [refCoeffDropdown, setRefCoeffDropdown] = useState<optionProps[]>([]);
  const [formData, setFormData] = useState<FormProps>({
    code: null,
    coeffDevname: "",
    // coeffType: null,
    // refCoeff: null,
    // coeffValue: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = {
      id: editId.id,
      type: "coeffDeviation",
    };
    const response = await ApiCall.service(
      COEFFICIENT_EDIT,
      "POST",
      data,
      false,
      M_MASTER_DATA
    );
    if (response.status === 200) {
      console.log(response);
      // const dropdown = response.data['refCoeffDropdown'];
      const data = response.data;

      // const refCoeffDropdown = dropdown.map((item:any)=> {
      //     return {
      //         'value' : item.id,
      //         'label' : item.coeff_name
      //     };
      // })
      // setRefCoeffDropdown(refCoeffDropdown);
      setFormData(data);
    }
  };

  const validation = (
    name: string,
    value: string | boolean,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      coeffDevname: [validateRequired],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validation(name, value, true);
  };

  // const handleSelectChange = (selectedOption: any, fieldName: string) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [fieldName]: selectedOption,
  //   }));
  //   validation(fieldName, selectedOption, true);
  // };

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    const data = {
      id: editId.id,
      type: "coeffDeviation",
      ...formData,
    };
    if (validation(name, value)) {
      const response = await ApiCall.service(
        CREATE_UPDATE_COEFFICIENT,
        "POST",
        data,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: t(response?.message) });
        navigate(-1);
      } else if (response.status === 400) {
        setErrors({
          coeffDevname: response.errors.coeffDevname[0],
        });
      }
    }
    setLoading(false);
  };

  const permissionType = editId ? "update" : "create";
  const permissionObject: any = {
    permission: "Coefficient deviation",
  };
  permissionObject[permissionType] = true;
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      override={userData.isSuperAdmin}
      renderNoAccess={true}
    >
      <div className="row">
        <div className="col-12">
          <Title title={t("Edit coefficient deviation")} />
          <form onSubmit={handleSubmit} className="form-group">
            <div className="create-user-height form-height-short-md">
              <div className="position-relative">
                <div className="form-border p-5 mb-4">
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="code"
                        handleChange={changeHandler}
                        value={formData.code ?? ""}
                        id="code"
                        label={t("Code")}
                        placeholder={"code"}
                        error={errors.code}
                        isDisabled={true}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="coeffDevname"
                        handleChange={changeHandler}
                        value={formData.coeffDevname}
                        id="coeffDevname"
                        label={t("Name")}
                        placeholder={t("Name")}
                        error={errors.coeffDevname}
                        isDisabled={false}
                      />
                    </div>
                    {/* <div className="col-sm-12 col-md-6">
                <SelectWithSearch
                  title="Select coefficient value type"
                  isMandatory={true}
                  search={true}
                  options={[
                    { value: 1, label: "Default value" },
                    { value: 2, label: "Refer coefficient value" },
                  ]}
                  placeHolder="Select"
                  onChange={(e) => handleSelectChange(e, "coeffType")}
                  isMulti={false}
                  name="coeffType"
                  value={formData.coeffType}
                  error={errors.coeffType}
                />
              </div>
              {formData.coeffType?.value === 2 && (
                <div className="col-sm-12 col-md-6">
                  <SelectWithSearch
                    title="Select reference coefficient"
                    isMandatory={true}
                    search={true}
                    options={refCoeffDropdown}
                    placeHolder="Select"
                    onChange={(e) => handleSelectChange(e, "refCoeff")}
                    isMulti={false}
                    name="refCoeff"
                    value={formData.refCoeff}
                    error={errors.refCoeff}
                  />
                </div>
              )}
              {formData.coeffType?.value === 1 && (
                <div className="col-sm-12 col-md-6">
                  <LabelWithInputField
                    isMandatory={true}
                    name="coeffValue"
                    handleChange={changeHandler}
                    value={formData.coeffValue ?? ''}
                    id="coeffValue"
                    label={t("Coefficient value")}
                    placeholder={t("Coefficient value")}
                    error={errors.coeffValue}
                  />
                </div>
              )} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row backPadding">
              <div className="col-md-4 align-self-center">
                <BackButton />
              </div>
              <div className="col-md-8 text-end">
                {!loading ? (
                  <Button
                    title={t("Save")}
                    type="submit"
                    className="form-button shadow-none "
                  />
                ) : (
                  <LoadingIcon
                    iconType="bars"
                    color="#ff4dae"
                    className="float-end"
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </AccessControl>
  );
};
export default CoeffDeviation;
