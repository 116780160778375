import React from 'react'
import { dateUtils } from '../utils/dateUtils';
import { useNavigate } from "react-router-dom";
import { usePlanningsDispatch } from "../planning-redux/context/PlanningContext/PlanningContext";
import { PATH_MANAGE_TIMESHEET_OVERVIEW } from 'constants/Paths';

const PlanningContextMenu = ({ id, handleWeeklyClickEventOnPlannedUnit, handleAction, employee_id, date, tooltipPosition, plan = null }) => {
  const { dispatchPlanning } = usePlanningsDispatch();

  const navigate = useNavigate();
  // let id = 'context-menu-' + employee_id + '-' + date + ((plan != null && plan.planid) ? ('-' + plan.planid) : null);
  const redirectToPage = (e, type) => {
    e.preventDefault();
    switch (type) {
      case 'company':
        navigate(`/company/${plan.company_id}`)
        break;
      case 'project':
        navigate(`/project/edit/${plan.project_id}`)
        break;
      case 'employee':
        navigate(`/applicant/details/${employee_id}?source=candidate`)
        break;
      case 'timesheet':
        let timesheetdate = dateUtils.getDate(date).toISOString();
        navigate(`${PATH_MANAGE_TIMESHEET_OVERVIEW}?project_id=${plan.project_id}&employee_id=${employee_id}&currentDate=${timesheetdate}`);
      default:
        break;
    }
  }

  return (
    <div id={id} className={`${tooltipPosition}`} style={{
      color: 'black',
      position: 'absolute',
      width: '10vw',
      border: "0.052vw solid #000",
      fontFamily: "calbri-regular",
      background: '#fff', zIndex: '999999'
    }}>
      {plan == null &&
        <>
          <div onClick={(e) => handleAction(e, 'new')}>New Planning</div>
          <div onClick={(e) => handleAction(e, 'paste')}>Placing</div>
        </>
      }
      {plan !== null &&
        <>
          <div onClick={(e) => handleWeeklyClickEventOnPlannedUnit(e, employee_id, date, id, plan)} className='planningPopupContent'>Open</div>
          <div onClick={(e) => handleAction(e, 'copy', plan)} className='planningPopupContent'>Copy</div>
          <div onClick={(e) => handleAction(e, 'delete', plan)} className='planningPopupContent'>Delete</div>
          <div onClick={(e) => handleAction(e, 'new')} className='planningPopupContent'>New planning</div>
          <div onClick={(e) => redirectToPage(e, 'employee')} className='planningPopupContent'>Open employee profile</div>
          <div onClick={(e) => redirectToPage(e, 'project')} className='planningPopupContent'>Open project profile</div>
          <div onClick={(e) => redirectToPage(e, 'company')} className='planningPopupContent'>Open company profile</div>
          <div onClick={(e) => redirectToPage(e, 'timesheet')} className='planningPopupContent'>Open workorder</div>
          {/* <p>Open work order</p> */}
        </>
      }
    </div>
  );
}

export default PlanningContextMenu
