import React, { Dispatch } from 'react';
import { validateForm, validateMultiSelectField, validateRequired, validateSelectField } from 'services/validation/ValidationService';
import { validationRules } from '../validationRules/validationRules';
import { AGE, CATEGORY_AND_FUNCTIONS, EMPLOYEE_TYPE, FUNCTIONS, PC_DETAILS, UPDATE_ERROR_FIELD, UPDATE_TAB_ERROR } from '../annotations/PCConstants';
import { t } from '../../translation/Translation';

export interface MandatoryFields {
    [key: string]: string[];
}

export const validateMandatoryFields = (
    state: any,
    dispatch: Dispatch<any>,
    type: string
): boolean => {
    const activeTab = state.tabDetails.find((tab: any) => tab.showStatus);
    const activeTabId = activeTab?.id;

    const mandatoryFieldMapping: { [key: string]: string[] } =
    {
        pcDetails: ["pcName", "pcNumber", "bufferTiming", "minWorkTimingPerDay", "maxWorkTimingPerDay", "workUpto", "maxWorkTimingPerWeekWarning", "maxWorkTimingPerWeekError"],
        employeeType: ["pcCategory", "employeeType"],
    };

    const errorsByTab: { [tabId: string]: { [fieldName: string]: string } } = {};

    state.tabDetails
        .slice(
            0,
            state.tabDetails.findIndex((tab: any) => tab.id === activeTabId) + 1
        )
        .forEach((tab: any) => {
            const tabId = tab.id;
            let tabFields = {};

            switch (tabId) {
                case PC_DETAILS:
                    tabFields = state.pcDetails;
                    break;
                case CATEGORY_AND_FUNCTIONS:
                    tabFields = state.functions;
                    break;
                case AGE:
                    tabFields = state.age;
                    break;
                case EMPLOYEE_TYPE:
                    tabFields = state.employeeType
                    break;
                default:
                    break;
            }

            const validationRules: {
                [fieldName: string]: ((value: any) => string | null | undefined)[];
            } = {
                pcName: [validateRequired],
                pcNumber: [validateRequired],
                pcAliasName: [validateRequired],
                bufferTiming: [validateRequired],
                minWorkTimingPerDay: [validateRequired],
                maxWorkTimingPerDay: [validateRequired],
                workUpto: [validateRequired],
                maxWorkTimingPerWeekWarning: [validateRequired],
                maxWorkTimingPerWeekError: [validateRequired],
                pcCategory: [validateSelectField],
                employeeType: [validateMultiSelectField],
            };

            const mandatoryFields = mandatoryFieldMapping[tabId] || [];
            const errorsInTab: { [fieldName: string]: string } = {};
            for (const fieldName of mandatoryFields) {
                const fieldValidationRules = validationRules[fieldName];
                const fieldValue = tabFields[fieldName as keyof typeof tabFields];
                if (fieldValidationRules) {
                    for (const rule of fieldValidationRules) {
                        const validationError = rule(fieldValue);


                        if (validationError) {
                            errorsInTab[fieldName] = validationError;
                            dispatch({
                                type: UPDATE_ERROR_FIELD,
                                field: fieldName,
                                error: validationError,
                            });
                            break;
                        } else {
                            dispatch({
                                type: UPDATE_ERROR_FIELD,
                                field: fieldName,
                                error: "",
                            });
                        }
                    }
                }
            }

            if (Object.keys(errorsInTab).length > 0) {
                errorsByTab[tabId] = errorsInTab;
            }
        });

    updateTabsStatus(state, dispatch, errorsByTab);
    return Object.keys(errorsByTab).length === 0;
};

export const updateTabsStatus = (
    state: any,
    dispatch: Dispatch<any>,
    errors: object
) => {
    const keysToGet = [
        PC_DETAILS,
        CATEGORY_AND_FUNCTIONS,
        AGE,
        EMPLOYEE_TYPE,
    ];

    const selectKeys = keysToGet
        .map((key) => {
            if (errors.hasOwnProperty(key)) {
                const tabIndex = findTabObjectById(state, key);
                if (tabIndex !== undefined && tabIndex !== null) {
                    dispatch({
                        type: UPDATE_TAB_ERROR,
                        tabIndex,
                        error: true,
                    });
                }
                return tabIndex;
            }
            return null;
        })
        .filter((key) => key !== null);

    const tabIndexes = [0, 1, 2, 3, 4, 5];
    tabIndexes.forEach((index) => {
        if (!selectKeys.includes(index)) {
            dispatch({
                type: UPDATE_TAB_ERROR,
                tabIndex: index,
                error: false,
            });
        }
    });
};

const findTabObjectById = (state: any, idToFind: string) => {
    return state.tabDetails.findIndex((tab: any) => tab.id === idToFind);
};

export const validateDynamicTabs = (state: any, dispatch: any, fieldName: string, tabIndex: number) => {
    const condition = tabIndex === 1 ? state.functions.length === 0 : state.age.length === 0;
    if (condition) {
        dispatch({
            type: UPDATE_TAB_ERROR,
            tabIndex: tabIndex,
            error: true,
        });
        dispatch({
            type: UPDATE_ERROR_FIELD,
            field: fieldName,
            error: t("Please add atleast one " + fieldName),
        });
        return true;
    } else {
        dispatch({
            type: UPDATE_TAB_ERROR,
            tabIndex: tabIndex,
            error: false,
        });
        dispatch({
            type: UPDATE_ERROR_FIELD,
            field: fieldName,
            error: "",
        });
        return false;
    }
}