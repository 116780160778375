// Function to get dates for current week and next week

function getWeekDates(startWeekNumber, numOfWeeks) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const weekDates = [];

  for (let i = 0; i < numOfWeeks; i++) {
    // Calculate the first day of the year
    const firstDayOfYear = new Date(currentYear, 0, 1);

    // Calculate the day of the week for the first day of the year (0 for Sunday, 1 for Monday, etc.)
    const firstDayOfWeek = firstDayOfYear.getDay();

    // Calculate the number of days to add to get to the first Monday of the year
    const daysToAdd = 1 - firstDayOfWeek;

    // Calculate the date of the first Monday of the year
    const firstMondayOfYear = new Date(firstDayOfYear);
    firstMondayOfYear.setDate(firstDayOfYear.getDate() + daysToAdd);

    // Calculate the date of the first Monday of the specified week
    const daysToAddForStartWeek = (startWeekNumber - 1) * 7;
    const firstMondayOfStartWeek = new Date(firstMondayOfYear);
    firstMondayOfStartWeek.setDate(firstMondayOfYear.getDate() + daysToAddForStartWeek);

    // Calculate the date of the first Monday of the current week
    const firstMondayOfCurrentWeek = new Date(firstMondayOfStartWeek);
    firstMondayOfCurrentWeek.setDate(firstMondayOfStartWeek.getDate() + i * 7);

    // Get all dates for the current week
    const currentWeekDates = [];
    for (let j = 0; j < 7; j++) {
      const date = new Date(firstMondayOfCurrentWeek);
      date.setDate(firstMondayOfCurrentWeek.getDate() + j);
      currentWeekDates.push(formatDateForWeeklyView(getFormattedDates(date))); // Assuming formatDate is a function to format the date
    }

    weekDates.push(currentWeekDates);
  }

  return weekDates;
}

function getStartOfCurrentWeek(date) {
  const givendate =  date ? new Date(date) : new Date();
  const dayOfWeek = givendate.getDay(); // Sunday - 0, Monday - 1, ..., Saturday - 6
  const diffToMonday = (dayOfWeek === 0 ? 6 : dayOfWeek - 1); // Adjust so Monday is the start of the week
  const startOfWeek = new Date(givendate);
  startOfWeek.setDate(givendate.getDate() - diffToMonday);
  startOfWeek.setHours(0, 0, 0, 0); // Set time to midnight
  return new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate()); // Only date
}

function getDateAfterWeeks(startDate, weeks, type = "next", required = true) {
  const endDate = new Date(startDate);
  
  if (type === "next") {
    endDate.setDate(startDate.getDate() + (weeks * 7) + (required ? -1 : 0));
  } else if (type === "prev") {
    endDate.setDate(startDate.getDate() - (weeks * 7) + (required ? 1 : 0));
  }
  return new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()); // Only date
}

// Function to generate the required date groups by week
function getGroupedDatesByWeek(startDate, endDate) {
  const result = [];
  let currentDate = new Date(startDate);
  let weekGroup = [];
  while (currentDate <= new Date(endDate)) {
    let day = currentDate.getDate().toString().padStart(2, '0');
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    let year = currentDate.getFullYear();

    let week = getWeekNumbers(currentDate); // Get the week number
    let formattedMonth = currentDate.toLocaleString('default', { month: 'short', year: 'numeric' });

    // Create the current date object
    const dateObj = {
        "label": `${day}-${month}`,
        "value": `${day}-${month}-${year}`,
        "week": `Week ${week}`,
        "month": formattedMonth
    };

    // Add to the current week's group
    // if (weekGroup.length === 0 || weekGroup[0].week === `Week ${week}`) {
    //     weekGroup.push(dateObj);
    // } else {
        result.push(dateObj);
    //     weekGroup = [dateObj]; // Start a new week group
    // }

    // Increment the date by one day
    currentDate.setDate(currentDate.getDate() + 1);
  }


  // Add the last group if it exists
  // if (weekGroup.length > 0) {
  //     result.push(weekGroup);
  // }
  return result;
}

// Helper function to get the week number of a given date
function getWeekNumbers(date) {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const dayOfWeek = (firstDayOfYear.getDay() + 6) % 7; // Adjust for week starting on Monday
  const pastDaysOfYear = (date - firstDayOfYear + dayOfWeek * 86400000) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() - dayOfWeek) / 7);
}

function getWeekNumber(d) {
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  return weekNo;
}

function formatDateForWeeklyView(date) {
  const [day, month, year] = date.split('-');
  let newdate = new Date(`${year}-${month}-${day}`);
  const monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"];

  let monthName = (monthNames[newdate.getMonth()]).slice(0, 3);
  let formattedDate = `${day}-${month}`;
  let weekNumber = getWeekNumber(newdate);
  
  return {
    label: formattedDate,
    value: date,
    week: 'Week ' + weekNumber,
    month: monthName + ' ' + year
  };
}

function getDate(date) {
  const [day, month, year] = date.split('-');
  return(new Date(`${year}-${month}-${day}`));
}

function getFormattedDates(inputDate, reverse = false) {
  const dateObj = new Date(inputDate);
  const dd = String(dateObj.getDate()).padStart(2, '0');
  const mm = String(dateObj.getMonth() + 1).padStart(2, '0');
  const yyyy = dateObj.getFullYear();
  if(reverse) {
    return `${yyyy}-${mm}-${dd}`
  };
  return `${dd}-${mm}-${yyyy}`;
}

function getCurrentWeek(date) {
  // Create a new Date object
  const now = date ? new Date(date) : new Date();

  // Set the date to the beginning of the year
  const startOfYear = new Date(now.getFullYear(), 0, 0);

  // Calculate the difference between the current date and the beginning of the year
  const diff = now - startOfYear;

  // Calculate the number of milliseconds in a week
  const oneWeekMilliseconds = 1000 * 60 * 60 * 24 * 7;

  // Calculate the current week number
  const weekNumber = Math.floor(diff / oneWeekMilliseconds);
  return weekNumber + 1;
}


function getCurrentMonth() {
  // Create a new Date object
  const now = new Date();

  // Get the current month (0-indexed)
  const month = now.getMonth() + 1; // Adding 1 to convert from 0-indexed to 1-indexed

  return month;
}


function formatDateForMonthlyView(date) {
  const day = String(date.getDate()).padStart(2, '0'); // Get the day with leading zero if necessary
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month with leading zero if necessary
  const year = date.getFullYear(); // Get the full year

  const formattedDate = `${day}-${month}-${year}`;

  return {
    label: `${month}.${day}`,
    value: formattedDate
  };
}


function getMonthlyDates(startMonthNumber, numberOfMonths, year) {
  const monthlyDates = [];

  // Iterate over the specified number of months
  for (let i = 0; i < numberOfMonths; i++) {
    let month = startMonthNumber + i; // Calculate the month number for the current iteration
    // Ensure month number is within the valid range (1 to 12)
    if (month < 1 || month > 12) {
      // throw new Error("Invalid month number");
    }

    // Get the number of days in the current month
    const lastDayOfMonth = new Date(year, month, 0).getDate();
    // console.log("LAst day:" , lastDayOfMonth, "  " , year);
    let currentMonthDates = [];
    // Iterate over each day of the current month
    for (let day = 1; day <= lastDayOfMonth; day++) {
      // Format the date as desired (assuming you have a function for this)
      const formattedDate = formatDateForMonthlyView(new Date(year, month - 1, day)); // Month is 0-indexed, so subtract 1

      // Add the formatted date to the array
      currentMonthDates.push(formattedDate);
    }
    monthlyDates.push(currentMonthDates);
  }

  return monthlyDates;
}

function getYearlyDates(startMonthNumber, numberOfMonths, year) {
  const yearlyDates = [];

  // Iterate over the specified number of months
  for (let i = 0; i < numberOfMonths; i += 3) {
    const endMonth = Math.min(startMonthNumber + 2, 12); // Ensure not to exceed 12
    const monthlyDates = getMonthlyDates(startMonthNumber, 3, year); // Get 3 months data

    // Push the monthly dates into the yearly dates array
    yearlyDates.push(monthlyDates);

    // Update the startMonthNumber for the next iteration
    startMonthNumber += 3;

    // Break if the end month exceeds the total number of months in the year
    // if (startMonthNumber > numberOfMonths) {
    if (startMonthNumber > 12) {
      break;
    }
  }

  return yearlyDates;
}
function preFillDateFormat(originalDate) {
  const [day, month, year] = originalDate.split("-");
  return `${year}-${month}-${day}`;
};

function convertTime24hourto12hourformat(date) {
  if (date == '') return;
  const time = new Date(date); // Set the date to any date as we are only interested in the time
  const formattedTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const suffix = time.getHours() >= 12 ? 'PM' : 'AM';

  const formattedTimeString = `${formattedTime} ${suffix}`;
  console.log(formattedTimeString);
};


function getCurrentDate() {
  let dateObj= new Date();
  const dd = String(dateObj.getDate()).padStart(2, '0');
  const mm = String(dateObj.getMonth() + 1).padStart(2, '0');
  const yyyy = dateObj.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
}

function checkIsWeekend(date) {
  const [day, month, year] = date.split("-");
  const formattedDate = `${year}-${month}-${day}`;
  const dayOfWeek = new Date(formattedDate).getDay();
  return (dayOfWeek === 0 || dayOfWeek === 6);

}
export const dateUtils = {
  getWeekDates,
  formatDateForWeeklyView,
  getFormattedDates,
  getCurrentDate,
  getCurrentWeek,
  getCurrentMonth,
  getMonthlyDates,
  getYearlyDates,
  preFillDateFormat,
  convertTime24hourto12hourformat,
  getWeekNumber,
  getStartOfCurrentWeek,
  getDateAfterWeeks,
  getGroupedDatesByWeek,
  checkIsWeekend,
  getDate
}