import { log } from "console";

function updateStaticSection(
  state: any,
  dataObj: any,
  index: any,
  customFn: any
) {
  return state.map((item: any, mainIndex: number) => {
    if (item.dynamic) return item;
    if (item.dependencyAction) {
      let payload = {
        type: item.dependencyAction,
        state: state,
        fieldData: {
          field: item.name,
          value: dataObj[item.alias],
          error: "",
          index: index,
          dynamic: false,
          mainIndex: mainIndex,
          subIndex: 0,
        },
      };
      customFn(payload, dataObj);
    }
    item.value = setValue(item, dataObj);
    return item;
  });
}

export const setValue = (item: any, dataObj: any) => {
  let resultvalue: any;
  switch (item.type) {
    case "multi-select":
      if (dataObj[item.alias] != "" && dataObj[item.alias] != null) {
        let values: any = [];
        if (item.isMultiSelect === true && Array.isArray(dataObj[item.alias])) {
          dataObj[item.alias].forEach((element: any) => {
            let filteredOptions = item.options.find(
              (option: any) => option.value === element
            );
            values = values.concat(filteredOptions);
          });
        } else {
          values = item.options.find(
            (option: any) => option.value === dataObj[item.alias]
          );
        }
        resultvalue = values;
      }
      break;
    case "competence":
      resultvalue = dataObj;
      break;
    case "accordian":
      resultvalue = dataObj;
      break;

    default:
      resultvalue = dataObj[item.alias];
      break;
  }
  return resultvalue;
};

// export const updateDynamicSectionWithMultipleTypes = (
//   state: any,
//   dataObj: any,
//   customFn: any = null,
// ) => {
//   const updatedState = JSON.parse(JSON.stringify(state));
//   let newState: any = [];
//   dataObj.forEach((element: any, index: number) => {
//     const valuesu = updateFormData(updatedState[0], element, index, customFn)
//     console.log(valuesu);
//     newState.push(valuesu);
//   });
//   return newState;
// };

export const updateDynamicSectionWithMultipleTypes = async (
  state: any,
  dataObj: any,
  customFn: any = null
) => {
  const updatedState = JSON.parse(JSON.stringify(state));
  const promises = dataObj.map((element: any, index: number) => {
    return updateFormData(updatedState[0], element, index, customFn);
  });

  const newState = await Promise.all(promises);
  return newState;
};


export const updateDynamicTableIndex = (state: any) => {
  const updatedState = JSON.parse(JSON.stringify(state));
  return (updatedState?.form?.length - 1).toString();
};

// export const updateFormData = (state: any, dataObj: any, index: number, customFn: any = null) => {
//   return state.map((field: any, subIndex: any) => {
// if (field.dependencyAction) {
//   let payload = {
//     type: field.dependencyAction,
//     state: field,
//     fieldData: {
//       field: field.name,
//       value: dataObj[field.alias],
//       error: "",
//       mainIndex: subIndex
//     },
//   };
//   const data =await customFn(payload, dataObj)
//   console.log(data)
//   return { ...data };
// }
//     return {
//       ...field,
//       value: dataObj[field.alias] || field.value,
//       error: "",
//       name: `${field.name}-${index}`
//     }
//   });
// };

export const updateFormData = async (state: any, dataObj: any, index: number, customFn: any = null) => {
  // adding id attribute to dynamic fields
  let id: any = null;
  if (dataObj.id) {
    id = dataObj.id;
  }
  const updatedState = await Promise.all(
    state.map(async (field: any, subIndex: any) => {
      if (field.dependent) {
        let payload = {
          type: field.dependent,
          state: field,
          fieldData: {
            field: field.name,
            value: dataObj[field.alias],
            error: "",
            mainIndex: subIndex,
            fieldId: id
          },
        };
        const data = await customFn(payload, dataObj);
        return { ...data };
      }
      return {
        ...field,
        value: dataObj[field.alias] || field.value,
        error: "",
        name: `${field.name}-${index}`,
        fieldId: id
      };
    })
  );

  return updatedState;
};


// export const updateStateValues = (
//   dataObj: any,
//   state: any[],
//   tabIndex: any,
//   customFn: any
// ) => {
//   let dynamicIndex = 0;
//   let newState = state.map((item, idx) => {
//     if (item.dynamic === true && item.dynamicTable === true) {
//       let data = dataObj.filter((value: any) =>
//         value.hasOwnProperty(item.form[0]?.[0]?.alias)
//       );
//       const updatedItem = {
//         ...item,
//         form: updateDynamicSectionWithMultipleTypes(item.form, data, customFn),
//       };

//       let updatedCurrentFormIndex = updateDynamicTableIndex(updatedItem);
//       return {
//         ...updatedItem,
//         currentFormIndex: updatedCurrentFormIndex,
//       };
//     }

//     if (item.dynamic === true && !item.dynamicTable) {
//       if (item.type != undefined) {
//         const updatedItem = {
//           ...item,
//           form: updateDynamicSectionWithMultipleTypes(item.form, dataObj['dynamicData'][dynamicIndex], customFn),
//         };
//         dynamicIndex++;
//         return updatedItem;
//       }
//       const updatedItem = {
//         ...item,
//         form: updateDynamicSectionWithMultipleTypes(item.form, dataObj, customFn),
//       };
//       return updatedItem;
//     } else {
//       return updateStaticSection(state, dataObj, idx, customFn)[idx];
//     }
//   });

//   return newState;
// };
export const updateStateValues = async (
  dataObj: any,
  state: any[],
  tabIndex: any,
  customFn: any
) => {
  let dynamicIndex = 0;
  let initialIndex = false;

  const newStatePromises = state.map(async (item, idx) => {

    if (item.dynamic === true && item.dynamicTable === true) {
      let data = dataObj.filter((value: any) =>
        value.hasOwnProperty(item.form[0]?.[0]?.alias)
      );
      const updatedForm = await updateDynamicSectionWithMultipleTypes(item.form, data, customFn);
      const updatedItem = {
        ...item,
        form: updatedForm,
        isEdit: true,
      };

      let updatedCurrentFormIndex = updateDynamicTableIndex(updatedItem);
      return {
        ...updatedItem,
        currentFormIndex: updatedCurrentFormIndex,
      };
    }

    if (item.dynamic === true && !item.dynamicTable) {

      //When static and dynamic elements are there
      if (item.type !== undefined) {
        let index = 0;
        if (dataObj['dynamicData'].length > 1) {
          index = initialIndex ? index + 1 : 0;
          initialIndex = index === 0 ? true : false;
        }
        const updatedForm = await updateDynamicSectionWithMultipleTypes(item.form, dataObj['dynamicData'][index], customFn);
        dynamicIndex++;
        return {
          ...item,
          form: updatedForm,
        };
      }

      //When element has dynamic in creation and other represntations in edit
      if (item?.dynamic && item?.create == 'form') {
        return {
          ...item,
          data: dataObj,
        }
      }

      const updatedForm = await updateDynamicSectionWithMultipleTypes(item.form, dataObj, customFn);
      return {
        ...item,
        form: updatedForm,
      };
    }
    else if (item.dynamic == false && item?.edit) {
      return {
        ...item,
        data: dataObj,           // When create is form and edit is other
      }
    }

    else if (item.dynamic == false && (item?.edit == undefined)) {
      const updatedForm = await updateDynamicSectionWithMultipleTypes(item.form, dataObj, customFn);
      return {
        ...item,
        form: updatedForm
      };
    }


    else {
      return updateStaticSection(state, dataObj, idx, customFn)[idx];
    }
  });

  const newState = await Promise.all(newStatePromises);

  return newState;
};
