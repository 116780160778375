import { t } from "pages/microservices/masterData/translation/Translation";
import React, { useState } from "react";
const LabelBox = (props) => {
  const { planTimingsData, header1, header2, name } = props;

  const renderItems = () => {
    const weekDays = [
      <td key={"heading1"} className="border text-start">
        {t(header1)}
      </td>,
      <td key={"heading2"} className="border text-start">{t(header2)}</td>,
    ];
    const dataToRender = planTimingsData && planTimingsData.length > 0 ? planTimingsData : Array(7).fill({});
    
    dataToRender?.forEach((plan, i) => {
      weekDays?.push(
        <td key={i} className="border ">
          <div>
            {plan[name] ? plan[name] : ""}
          </div>
        </td>
      );
    });
    return weekDays;
  };

  return (
    <tr>
      {renderItems()}
      <td className="border"></td>
    </tr>
  );
};
export default LabelBox;
