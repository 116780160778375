import React from "react";
import PageLayout from "./timesheetsTemplate";
import TimesheetsOverview from "./organisms/timesheetsOverview";
import TimesheetProvider from "./timesheets-redux/context/TimesheetContext/TImesheetContex";
import TimeSheetModalProvider from "./timesheets-redux/context/TimesheetModalContext/TimesheetModalContext";
import WindowHeightComponent from "components/atoms/WindowHeightComponent";
import { FilterOptionsProvider } from "./timesheets-redux/context/FiltersContext/FiltersContext";

export default function Timesheet() {
  return (
    // <WindowHeightComponent>
      <FilterOptionsProvider>
        <TimesheetProvider>
          <TimeSheetModalProvider>
            <PageLayout>
              <TimesheetsOverview />
            </PageLayout>
          </TimeSheetModalProvider>
        </TimesheetProvider>
      </FilterOptionsProvider>
    // </WindowHeightComponent>
  );
}
