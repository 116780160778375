import TextEllipsis from "components/atoms/TextEllipsis";
import { t } from "pages/microservices/masterData/translation/Translation";
import React from "react";

const CommonBox = ({
  width,
  data,
  bg_color,
  color,
  handleBoxClick,
  box_id,
}) => {
  return (
    <span
      title={t(data)}
      className="d-flex align-items-center justify-content-start"
      style={{
        width: `${width}`,
        minHeight: "2vw",
        background: `${bg_color ? bg_color : "grey"}`,
        color: `${color ? color : "white"}`,
        textAlign:"left"
      }}
      onClick={(e) => handleBoxClick(e, box_id)}
    >
      <TextEllipsis title={data} label={data} width="6vw" />
    </span>
  );
};

export default CommonBox;
