import React from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useFormContext } from "pages/microservices/project/context/Context";
import CheckBoxField from "components/atoms/CheckBoxField";
import RadioField from "components/atoms/RadioField";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import Accordion from "react-bootstrap/Accordion";

interface ProjectsProps {
  id?: string | number;
  type?: string;
}

const ProjectQuestion: React.FC<ProjectsProps> = ({ type }) => {
  const { state } = useFormContext();

  const className = type === "View" ? "form-border disabled" : "form-border";

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    questionIndex: number
  ) => {
    console.log("Handle field change", { event, questionIndex });
  };

  const projectQuestions = state.options?.Question || [];
  const noOfEle = projectQuestions.map((_: any, index: number) =>
    index.toString()
  );

  return (
    <form>
      <div className={className} style={{ paddingTop: "3vw" }}>
        <div className="row">
          <div className="col-12">
            <Accordion defaultActiveKey={noOfEle} alwaysOpen>
              {projectQuestions.length > 0 ? (
                projectQuestions.map((question: any, index: number) => (
                  <Accordion.Item eventKey={`${index}`} key={index}>
                    <Accordion.Header>
                      {question.questionTittle}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div
                        className={`p-3 rounded-3 mb-3 border ${
                          question.disabled
                            ? "border question-not-applicable-section opacity-50"
                            : ""
                        }`}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <h5
                            className="questionName"
                            dangerouslySetInnerHTML={{
                              __html: `${index + 1}) ${question.question}`,
                            }}
                          />
                        </div>

                        {/* Render Checkbox Options */}
                        {question.questionType === 1 &&
                          question.options?.length > 0 && (
                            <div className="my-1">
                              {question.options.map(
                                (option: string, optionIndex: number) => (
                                  <div
                                    key={optionIndex}
                                    className="screening-checkbox d-flex align-items-center mb-2"
                                  >
                                    <CheckBoxField
                                      name={`answers-${index}-${optionIndex}`}
                                      id={`answers-${index}-${optionIndex}`}
                                      onChangeHandler={(event) =>
                                        handleFieldChange(event, index)
                                      }
                                      value={optionIndex}
                                      disable={question.disabled}
                                      isChecked={question.answers.includes(
                                        optionIndex
                                      )}
                                    />
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: option,
                                      }}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          )}

                        {/* Render Radio Options */}
                        {question.questionType === 3 &&
                          question.options?.length > 0 && (
                            <div className="my-1">
                              {question.options.map(
                                (option: string, optionIndex: number) => (
                                  <div
                                    key={optionIndex}
                                    className="d-flex screening-checkbox align-items-center mb-2"
                                  >
                                    <RadioField
                                      label=""
                                      className="ps-1"
                                      id={`answers-${index}-${optionIndex}`}
                                      value={optionIndex}
                                      name={`answers-${index}`}
                                      handleChange={(event) =>
                                        handleFieldChange(event, index)
                                      }
                                      disable={question.disabled}
                                      ischecked={question.answers.includes(
                                        optionIndex
                                      )}
                                    />
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: option,
                                      }}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          )}

                        {/* Render Text Area */}
                        {question.questionType === 2 && (
                          <LabelWithTextAreaField
                            name={`description-${index}`}
                            handleChange={(event) =>
                              handleFieldChange(event, index)
                            }
                            label=""
                            value={question.answers[0] || ""}
                            isDisabled={question.disabled}
                          />
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))
              ) : (
                <div className="text-danger p-2 text-center">
                  {t("No general and function related questions added")}
                </div>
              )}
            </Accordion>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ProjectQuestion;
