import React from "react";
import { MANAGE_PAYROLL_CONSTANTS } from "../constants/WorkPayBillRollConstants";
import { t } from "pages/microservices/masterData/translation/Translation";
import { handleFilterChange } from "../utils/Utils";
import ExportPopup from "../atoms/ExportPopup";
import DatePicker from "react-multi-date-picker";
import { Button } from "react-bootstrap";

interface ManagePayrollHeadersProps {
  handlePrint: (type: string, page?: string) => void;
}

const ManagePayrollHeaders: React.FC<ManagePayrollHeadersProps> = ({
  handlePrint,
}) => {
  return (
    <div
      className="d-flex align-items-center"
      style={{ marginBottom: "0.6vw" }}
    >
      <div className="d-flex ms-auto">
        <Button
          onClick={() => handlePrint("print")}
          className="btn form-button shadow-none text-uppercase search-btns me-3"
        >
          {t(MANAGE_PAYROLL_CONSTANTS?.PRINT)}
        </Button>
        <Button
          onClick={() => handlePrint("export")}
          className="btn form-button shadow-none text-uppercase search-btns "
        >
          {t(MANAGE_PAYROLL_CONSTANTS?.EXPORT)}
        </Button>
      </div>
    </div>
  );
};

export default ManagePayrollHeaders;
