import React, { useEffect, useState } from "react";
import Calender from "components/molecules/Calender";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { useTimesheetModalContext, useTimesheetModalDispatch } from "../timesheets-redux/context/TimesheetModalContext/TimesheetModalContext";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import { useTImesheetContext, useTimesheetDispatchContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { TIMESHEET_ACTIONS } from "../timesheets-redux/actions/TimesheetActions";
import Button from "components/atoms/Button";
import { TIMESHEET_MODAL_ACTIONS } from "../timesheets-redux/actions/TimesheetModalActions";
import { calculatePerformanceTotalPrice, formatToTime, formatWithSeparator, getTotalPercentage, normalizedForm } from "../utils/calculateUtils";
import { calculateWorkedTime } from "../utils/utils";
import { t } from "pages/microservices/masterData/translation/Translation";
import TimeFieldWithLabel from "components/molecules/TimeFieldWithLabel";

export default function PerformanceModal() {
  const { initialTimesheetModalState } = useTimesheetModalContext();
  const { filterOptionsState } = useFilterOptions();
  const { initialTimesheetState } = useTImesheetContext();
  const { timesheetDispatch } = useTimesheetDispatchContext();
  const { timesheetModalDispatch } = useTimesheetModalDispatch();
  const [errors, setErrors] = useState({
    // date: "",
    type: "",
    hours: "",
    standardPrice: "",
    percentage: "",
    totalPrice: ""
  })
  const employee = filterOptionsState?.optionState?.employees.filter((item) => {
    if (filterOptionsState?.selectedState?.employee_id == item.value) {
      return item;
    }
  });
  const project = filterOptionsState?.optionState?.projects?.filter((item) => {
    if (filterOptionsState?.selectedState?.project_id == item?.value) {
      return item;
    }
  });

  const performanceData = initialTimesheetState?.timesheet_data?.find(
    (item) => item?.plan_id && item?.plan_id === initialTimesheetModalState?.performance?.planId
  ) || {};
  
  const totalHours = (performanceData?.start_time && performanceData?.end_time && performanceData?.start_time !== "" && performanceData?.end_time !== "" )
    ? calculateWorkedTime(
        performanceData?.start_time?.split?.(" ")?.[1],
        performanceData?.end_time?.split?.(" ")?.[1],
        performanceData?.pause_time || "00:00:00"
      )
    : "00,00";

  useEffect(() => {
    if (initialTimesheetModalState.performance.data && initialTimesheetModalState.performance.data?.length <= 0) {
      let Data = {
        date: initialTimesheetModalState?.performance?.date,
        type: initialTimesheetModalState?.performance?.data?.type ?? null,
        hours: totalHours,
        standardPrice: initialTimesheetModalState?.performance?.data?.standardPrice ?? filterOptionsState?.optionState?.prjectParameters?.['project_rate']?.value,
        percentage: getTotalPercentage({timesheet: performanceData, parameters: filterOptionsState?.optionState?.prjectParameters, overttime: 0})?.general,
        totalPrice: "00,00",
      }
      Data.totalPrice = calculatePerformanceTotalPrice(Data);
      timesheetModalDispatch({
        type: TIMESHEET_MODAL_ACTIONS.UPDATE_MODAL_DATA,
        payload: {
          modalType: 'performance',
          data: Data,
        },
      });
    }
  }, [initialTimesheetModalState.performance.data]);

  const handleChange = async (value, field, type) => {    
    const updatedData = { ...initialTimesheetModalState?.performance?.data };
    switch (type) {
      case "select":
        updatedData[field] = value?.value;
        break;
      case "input":
        const inputValue = value.target.value;
        const regex = /^[0-9,:.]+$/;
        if (inputValue === "" || regex.test(inputValue)) {
          updatedData[field] = inputValue;
        }
        updatedData['totalPrice'] = calculatePerformanceTotalPrice(updatedData);
        break;
      case 'default':
        break;
    }
    timesheetModalDispatch({
      type: TIMESHEET_MODAL_ACTIONS.UPDATE_MODAL_DATA,
      payload: {
        modalType: 'performance',
        data: updatedData,
      },
    });
  };

  const validate = () => {
    let error = { ...errors };    
    Object.keys(errors)?.map((item) => {
      let value = initialTimesheetModalState?.performance?.data;
      error[item] = (value?.[item] && value?.[item] != "") ? "" : "The feild is required"
    })
    setErrors(error);    
    return !Object?.values(error)?.some((value) => value != "");
  }

  const handleSubmit = async (e) => {
    e?.preventDefault();   
    const isValidated = validate();    
    if (isValidated) {
      const { planId } = initialTimesheetModalState?.performance || {};
      if (planId) { 
        let updatedPerformanceData = { ...performanceData?.performance ?? {} };
        if (typeof initialTimesheetModalState?.index === 'number') {
          const performanceArray = Object.entries(updatedPerformanceData);
          performanceArray[initialTimesheetModalState.index][1] = { ...performanceArray[initialTimesheetModalState.index][1], ...initialTimesheetModalState?.performance?.data };
          updatedPerformanceData = Object.fromEntries(performanceArray);
        } else {
          updatedPerformanceData = {
            ...updatedPerformanceData,
            [initialTimesheetModalState?.performance?.data?.type]: initialTimesheetModalState?.performance?.data,
          };
        }
        timesheetDispatch({
          type: TIMESHEET_ACTIONS.UPDATE_TIMESHEET_DATA,
          payload: {
            planId: planId,
            value: updatedPerformanceData,
            name: 'performance',
          },
        });
        await handleTimeSheetModelDispatch()
      }
    }
  };

  const handleDelete = async () => {
    const { planId } = initialTimesheetModalState?.performance || {};
    if (planId) {
      if (performanceData?.performance && Object.keys(performanceData?.performance).length > 0) {
        const { [initialTimesheetModalState?.performance?.data?.type]: removed, ...updatedPerformanceData } = performanceData?.performance;        
        timesheetDispatch({
          type: TIMESHEET_ACTIONS.UPDATE_TIMESHEET_DATA,
          payload: {
            planId: initialTimesheetModalState?.performance?.planId,
            value: updatedPerformanceData,
            name: 'performance',
          },
        });
      }
    }
    await handleTimeSheetModelDispatch();
  }

  const handleTimeSheetModelDispatch = async () => {
    timesheetModalDispatch({
      type: TIMESHEET_MODAL_ACTIONS.OPEN_AND_CLOSE_MODAL,
      payload: {
        status: false,
        modalType: 'performance',
        date: null,
        planId: null,
        data: []
      },
    });
  }
  return (
    <div className="popup-container">
      <div className="popup">
        <form onSubmit={handleSubmit}>
          <div className="d-flex row mb-2">
            <p>{`${t("Project")}: ${project[0] ? project[0].label : ""}`}</p>
            <p>{`${t("Employee")}: ${employee[0] ? employee[0].label : ""}`}</p>
          </div>
          <div className="d-grid" style={{gridTemplateColumns:"repeat(2, 1fr)", gap:"0 1vw"}}>
            <div className="form-group position-relative">
              <Calender
                onChange={(date) => handleChange(date, "start_date", "date")}
                selectedDate={initialTimesheetModalState?.performance?.date ? new Date(initialTimesheetModalState?.performance?.date) : null}
                label={t("Date")}
                isMandatory
                name={"start_date"}
                error={errors?.date}
                isDisabled
              />
            </div>

            <div>
              <SelectWithSearch
                search
                onChange={(value) => handleChange(value, "type", "select")}
                title={t("Type")}
                placeHolder={t("Select")}
                options={filterOptionsState?.optionState?.performanceTypes}
                value={initialTimesheetModalState?.performance?.data?.type}
                isMandatory
                error={errors?.type}
                name={"type"}
                id={"type"}
                emptyOptionNotRequired
              />
            </div>
            <div>
              <TimeFieldWithLabel
                isMandatory
                name="hours"
                handleChange={(value) => handleChange(
                  {target: {name: 'hours', value}}, "hours", "input"
                )}
                value={formatToTime(initialTimesheetModalState?.performance?.data?.hours ?? "00:00") ?? ""}
                id="hours"
                label={t("Hours")}
                placeholder={t("Hours")}
                error={errors?.hours}
              />
            </div>
            <div>
              <LabelWithInputField
                label={t("Standard price")}
                name="standardPrice"
                isMandatory
                value={initialTimesheetModalState?.performance?.data?.standardPrice ?? ""}
                handleChange={(value) => handleChange(value, "standardPrice", "input")}
                id="standardprice"
                type="text"
                error={errors?.standardPrice}
              />
            </div>
            <div>
              <LabelWithInputField
                label={t("Percentage")}
                name="percentage"
                isMandatory
                value={initialTimesheetModalState?.performance?.data?.percentage ?? ""}
                handleChange={(value) => handleChange(value, "percentage", "input")}
                id="percentage"
                type="text"
                error={errors?.percentage}
              />
            </div>
            <div>
              <LabelWithInputField
                label={t("Total price")}
                name="totalPrice"
                isMandatory
                value={calculatePerformanceTotalPrice(initialTimesheetModalState?.performance?.data)}
                handleChange={(value) => handleChange(value, "totalPrice", "input")}
                id="totalPrice"
                type="text"
                error={errors?.totalPrice}
                isDisabled
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <Button
              title={t("Cancel")}
              type="cancel"
              handleClick={handleTimeSheetModelDispatch}
              className="close-button shadow-none text-uppercase search-btns"
            />
            <div className="d-flex align-items-center justify-content-end">
              <Button
                title={t("Delete")}
                type="delete"
                handleClick={handleDelete}
                className="form-button shadow-none text-uppercase marginRightPoint5 search-btns"
              />
              <Button
                title={t("Save")}
                type="submit"
                handleClick={handleSubmit}
                className="form-button shadow-none text-uppercase search-btns"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
