import React, { useEffect, useState } from "react";
import { ApiCall } from "services/ApiServices";
import { useNavigate } from "react-router-dom";
import { GET_USERS } from "routes/ApiEndpoints";
import ManageCandidatesPage from "./ManageApplicantsPage";
import { useSelector } from "react-redux";
import { selectPageHistory } from "store/pageHistory/pageHistorySlice";
import { useDispatch } from "react-redux";
import { formatDate } from "pages/microservices/CandidateCreation/helpers/CandidateHelperFunctions";
import { channel } from "diagnostics_channel";

export interface CandidateData {
  user_id?: number;
  email: string;
  status: any;
  first_name: string;
  last_name: string;
}

interface DataProps {
  page: number;
  nOR: number;
  search?: CandidateData;
  role?: string;
}

const ManageApplicants: React.FC = () => {
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const statusValue = queryParams.get("status");
  const status: number | string =
    statusValue !== null ? parseInt(statusValue) : '';
  const [users, setUsers] = useState<CandidateData[]>([]);
  const [options, setOptions] = useState({
    businessUnits: [],
    initialCall: true
  });
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true
  })

  const getSearchData = () => {
    return {
      businessUnit: history?.filters?.businessUnit ?? "",
      candidate: history?.filters?.candidate ?? "",
      email: history?.filters?.email ?? "",
      phoneNumber: history?.filters?.phoneNumber ?? "",
      jobOffer: history?.filters?.jobOffer ?? "",
      startDate: history?.filters?.dateOfApplication ? formatDate(history?.filters?.startDate) : "",
      endDate: history?.filters?.dateOfApplication ? formatDate(history?.filters?.endDate) : "",
      channel: history?.filters?.channel ?? "",
      status: history?.filters?.status ?? "",
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getSearchData(),
      role: "Applicant",
      initialCall: options?.initialCall,
    };
    fetchUsers(postData);
  }, [pageData?.refresh, pageData?.initialCall]);

  const fetchUsers = async (data: any) => {
    const response = await ApiCall.service(GET_USERS, "POST", data);
    if (response?.status === 200) {
      setUsers(response.data);
      if (options?.initialCall) {
        setOptions((prev: any) => ({ ...prev, businessUnits: response?.options?.businessUnits, initialCall: false }));
      }
      setPageData((prev: any) => ({ ...prev, totalPages: response?.totalPages, totalRecords: response?.totalRecords, initialCall: false }))
    }
  };

  const handleDeleteData = (deleteId: number) => {
    setUsers((prevdata) =>
      prevdata.filter((users) => users.user_id !== deleteId)
    );
    setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh, currentPage: 1 }))
  };
  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  return (
    <>
      <ManageCandidatesPage
        usersObj={users}
        deleteFilter={handleDeleteData}
        dispatch={dispatch}
        history={history}
        handleRefresh={handleRefresh}
        pageData={pageData}
        dropDownData={options}
      />
    </>
  );
};

export default ManageApplicants;
