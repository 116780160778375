import React from "react";

const addedProjectIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 2048 2048" width="512" height="512">
      <path transform="translate(403,204)" d="m0 0h967l21 2 19 5 18 8 15 9 14 11 12 12 10 13 9 16 7 16 5 19 3 21-1 5-3 1-92 1-34 3-26 4-36 8-30 9-29 11-20 9-17 8-23 13-18 11-23 16-15 12-11 9-15 13-29 29-9 11-9 10-15 20-12 17-12 19-11 19-8 16-11 24-11 29-9 29-7 29-4 22-3 21-3 39v33l3 38 4 28 6 29 8 29 8 24 9 22 11 24 13 25 15 24 8 12 13 18 9 11 11 13 9 10 14 15 13 12 11 10 11 9 15 12 19 13 15 10 26 15 17 9 20 9 31 12 21 7 36 9 35 6 26 3 16 1 30 1 62 1v421l-3 20-7 21-8 16-9 14-11 13-13 12-15 10-15 8-21 7-14 3-8 1-33 1h-907l-43-1-18-3-16-5-19-9-12-8-10-8-3-2v-2l-4-2-11-12-12-18-9-19-6-20-2-12-1-15v-1373l2-17 4-17 8-20 8-14 8-11 9-10 10-10 15-11 18-10 21-7 15-3zm113 205-38 1v135l2 1h269l1-1v-135l-117-1zm-10 273-28 1v136h271l1-1v-135l-38-1zm-28 274v136h271l1-1v-135zm2 273-2 2v134l67 1h297l113-1v-136zm46 272-48 1v136h818v-136z" fill="currentColor" />
      <path transform="translate(1415,477)" d="m0 0h35l32 3 28 5 29 8 27 10 33 16 20 12 17 12 14 11 14 12 10 10 6 5 7 8 9 10 13 17 12 17 9 15 10 18 12 27 11 33 6 25 4 24 2 19v51l-4 32-5 23-7 25-9 25-10 22-12 22-16 24-12 16-9 10-14 16h-2l-2 4-8 7-11 10-14 11-14 10-19 12-26 14-26 11-24 8-24 6-22 4-25 3-14 1h-26l-34-3-28-5-26-7-25-9-25-11-24-13-21-14-12-9-10-8-10-9-8-7-16-16-7-8-13-16-13-18-15-25-8-16-8-17-10-28-6-21-6-30-3-27-1-27 1-24 4-32 6-28 10-32 10-24 8-16 8-15 12-19 12-16 10-13 12-13 23-23 11-9 17-13 15-10 15-9 23-12 23-10 30-10 26-6 26-4zm151 192-5 4-7 8-141 141-4 2-5-5h-2l-1-3-7-6-5-6-6-5-6-7-7-6-68-68-4 2-5 4-7 8-54 54-3 2v2l8 7 172 172 7-6 221-221-1-4-67-67h-2z" fill="currentColor" />
    </svg>
  );
};
export default addedProjectIcon;
