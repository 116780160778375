export const initialPCCategoryList = {
  id: null,
  name: "",
  minSalary: "",
  maxSalary: "",
};

export const initialPCFunctionList = {
  id: null,
  name: "",
  category: null,
  minSalary: "",
  maxSalary: "",
};

export const initialAgeList = {
  id: null,
  age: undefined,
  minSalary: undefined,
  endTime: "",
};

export const pcCategoryOptions = [
  {
    value: 1,
    label: "White collar",
  },
  {
    value: 2,
    label: "Blue collar",
  },
];
