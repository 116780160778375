import React from "react";
import { Link } from "react-router-dom";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import { PATH_VACANCY_CREATE } from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import { VACANCIES, VACANCY_CREATE, VACANCY_DELETE } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import Pagination from "components/atoms/Pagination";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import StatusComponent from "components/atoms/ManageStatus";
import Title from "components/atoms/Title";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import CheckBoxField from "components/atoms/CheckBoxField";
import { fetchProjectOptions, getPCAndItsParameters } from "./utils/Utils";
import BackButton from "components/atoms/BackButton";
import FilterOrganism from "components/organism/FilterOrganism";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import { VacancyTableHeader } from "TableHeader";
import TextEllipsis from "components/atoms/TextEllipsis";

interface ManageVacanciesProps {
  state: any;
  setState: any;
  dispatch: any;
  history: any;
}

export const ManageVacancies: React.FC<ManageVacanciesProps> = ({
  state,
  setState,
  dispatch,
  history,
}) => {
  const userData = useSelector(selectAuth);
  const navigate = useNavigate();

  const handleSaveChanges = async () => {
    let postData = {
      id: state?.popup?.delete?.data?.id,
      changeStatus: state?.popup?.delete?.data?.status,
      user_id: userData.userId,
    };
    if (state?.popup?.delete?.data?.id) {
      const response = await ApiCall.service(
        VACANCY_DELETE,
        "POST",
        postData,
        true,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleModal();
        setState((prev: any) => ({
          ...prev,
          data: state?.data?.map((item: any) => {
            if (item?.id == state?.popup?.delete?.data?.id) {
              return {
                ...item,
                status:
                  state?.popup?.delete?.data?.status === "active"
                    ? "inactive"
                    : "active",
              };
            }
            return item;
          }),
        }));
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  const handleModal = (value?: any) => {
    setState((prev: any) => ({
      ...prev,
      popup: {
        ...prev?.popup,
        delete: {
          ...prev?.popup?.delete,
          data: value ?? {},
          show: value ? true : false,
        },
      },
    }));
  };

  const handleHotList = async (e: any) => {
    let postData = {
      hotlist: true,
      id: e?.id,
      ishotlisted: !e?.ishotlisted,
      user_id: userData.userId,
    };
    if (e?.id) {
      const response = await ApiCall.service(
        VACANCY_CREATE,
        "POST",
        postData,
        true,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        setState((prev: any) => ({
          ...prev,
          data: prev?.data?.map((item: any) => {
            if (item?.id === e?.id) {
              return { ...item, ishotlisted: !e?.ishotlisted };
            }
            return item;
          }),
        }));
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  const handleEdit = (value: any) => {
    navigate(`${PATH_VACANCY_CREATE}/${value?.id}`);
  };

  const handleRefresh = () => {
    setState((prev: any) => ({ ...prev, refresh: !state?.refresh }));
  };

  const handleTabChange = (e: any, tab: any) => {
    dispatch(updatePageFilters({ filters: { hotlist: tab } }));
    handleRefresh();
  };

  const filters = [
    {
      name: "businessUnits",
      fieldType: "multiSelect",
      options: state?.options?.BusinessUnit,
      placeholder: "Business unit",
      filterType: "search",
    },
    {
      name: "company",
      fieldType: "text",
      placeholder: "Company",
      filterType: "search",
    },
    {
      name: "project",
      fieldType: "text",
      placeholder: "Project",
      filterType: "search",
    },
    {
      name: "pcFunction",
      fieldType: "text",
      placeholder: "Function",
      filterType: "search",
    },
    {
      name: "updated_by",
      fieldType: "text",
      placeholder: "Updated by",
      filterType: "search",
    },
    // {
    //   name: "updated_at",
    //   fieldType: "date",
    //   placeholder: "Updated at",
    //   filterType: "search",
    // },
    {
      name: "postingMedium",
      fieldType: "singleSelect",
      options: state?.options?.PostMediums,
      placeholder: "Posting",
      filterType: "search",
    },
    {
      name: "status",
      fieldType: "singleSelect",
      options: state?.options?.VacancyStatus,
      placeholder: "Status",
      filterType: "search",
    },
    // {name: 'hotlist', fieldType: "checkbox", label: "isHotListed?", filterType: 'search' },
  ];

  return (
    <AccessControl
      requiredPermissions={[{ permission: "Vacancy", read: true }]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="search-bar" style={{ paddingBottom: "0.25vw" }}>
        <div className="row">
          <Title title={t("Vacancies")} />
          <div className="col-md-12 createNavTabs">
            <button
              className={`link ${!history?.filters?.hotlist ? "active nav-subtab" : "nav-subtab"
                } marginRightPoint5 border-0 bg-transparent ps-0 btn`}
              onClick={(e) => handleTabChange(e, false)}
              style={{ cursor: "pointer" }}
            >
              {t("All")}
            </button>
            <button
              className={`link ${history?.filters?.hotlist ? "active nav-subtab" : "nav-subtab"
                } btn marginRightPoint5 border-0 bg-transparent ps-0`}
              onClick={(e) => handleTabChange(e, true)}
              style={{ cursor: "pointer" }}
            >
              {t("Hotlist")}
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <div>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Vacancy",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <div className="row">
                  <div className="ManageCreateBtn">
                    <LinkTo
                      pagelink={PATH_VACANCY_CREATE}
                      title={t("Create Vacancy")}
                      icon={faPlus}
                    />
                  </div>
                </div>
              </AccessControl>
            </div>
            <div className="tableSection manage-Vacancies">
              <FilterOrganism
                dispatch={dispatch}
                history={history}
                updatePageFilters={updatePageFilters}
                filters={filters}
                handleRefresh={handleRefresh}
              />
              <div className="table-responsive manage-Vacancies tableWithSearchBar tablePadding">
                <table className="table table-hover">
                  <thead>
                    <tr className="TableHeader">
                      {VacancyTableHeader.map((header) => (
                        <th key={header.alias} className={"table_actions"}>
                          {header.alias === "updated_by" ? (
                            <>
                              <div>
                                {t("Updated by")}
                                {header.issort && (
                                  <SortAtomForManagePage
                                    value={header}
                                    dispatch={dispatch}
                                    history={history}
                                    updatePageFilters={updatePageFilters}
                                    handleRefresh={handleRefresh}
                                  />
                                )}
                              </div>
                              <div>
                                {t("Updated at")}
                                {header.issort && (
                                  <SortAtomForManagePage
                                    value={{ alias: "updated_at" }}
                                    dispatch={dispatch}
                                    history={history}
                                    updatePageFilters={updatePageFilters}
                                    handleRefresh={handleRefresh}
                                  />
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              {t(header.name)}
                              {header.issort && (
                                <SortAtomForManagePage
                                  value={header}
                                  dispatch={dispatch}
                                  history={history}
                                  updatePageFilters={updatePageFilters}
                                  handleRefresh={handleRefresh}
                                />
                              )}
                            </>
                          )}
                        </th>
                      ))}
                      {/* <th>{t("Status")}</th> */}
                      <th className="table_actions">
                        <AccessControl
                          requiredPermissions={[
                            { permission: "Vacancy", update: true, delete: true },
                          ]}
                          actions={true}
                          strict={false}
                          override={userData.isSuperAdmin}
                        >
                          {t("Action")}
                        </AccessControl>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {state?.data && state?.data?.length > 0 ? (
                      state?.data?.map((record: any, key: any) => {
                        return (
                          <tr key={key}>
                            <td className="align-middle">
                              <TextEllipsis
                                label={record?.businessUnits}
                                title={record?.businessUnits}
                                width="5vw"
                              />
                            </td>
                            <td className="align-middle cursor-pointer" onClick={() => navigate(`/company/${record?.company_id}?mode=view`)}>
                              <TextEllipsis
                                classname="cursor-pointer"
                                label={record?.company}
                                title={record?.company}
                                width="8vw"
                              />
                            </td>
                            <td className="align-middle cursor-pointer" onClick={() => navigate(`/project/view/${record?.project_id}`)}>
                              <TextEllipsis
                                classname="cursor-pointer"
                                label={record?.project}
                                title={record?.project}
                                width="12vw"
                              />
                            </td>
                            <td className="align-middle">
                              <TextEllipsis
                                label={record?.pcFunction}
                                title={record?.pcFunction}
                                width="14.5vw"
                              />
                            </td>
                            <td className="align-middle">
                              <div>{record?.updated_by}</div>
                              <div>{new Date(record?.updated_at)
                                .toLocaleDateString("en-GB")
                                .split("/")
                                .join("-")}
                              </div>
                            </td>
                            {/* <td>
                          {new Date(record?.updated_at)
                            .toLocaleDateString("en-GB")
                            .split("/")
                            .join("-")}
                        </td> */}
                            <td className="align-middle">
                              <TextEllipsis label={record?.postingMedium} title={record?.postingMedium} width="8vw" />
                            </td>
                            <td className="align-middle">
                              <StatusComponent
                                initialStatus={
                                  record?.vacancy_status?.toLowerCase() === "open"
                                    ? 1
                                    : 2
                                }
                                tooltip={
                                  record?.vacancy_status?.toLowerCase() === "open"
                                    ? t("Open")
                                    : t("Closed")
                                }
                              />
                            </td>
                            {/* <td className="align-middle">
                          <StatusComponent
                            initialStatus={record?.status?.toLowerCase() === "active" ? 1 : record?.status?.toLowerCase() === "draft" ? 0 : 2}
                            tooltip={record?.status?.toLowerCase() === "active" ? t("Active") : record?.status?.toLowerCase() === "draft" ? t("Draft") : t("Inactive")}
                          />
                        </td> */}
                            <td className="align-middle">
                              <TableActions
                                isAction={true}
                                handleEdit={handleEdit}
                                handleArchive={
                                  record?.status === "draft"
                                    ? undefined
                                    : handleModal
                                }
                                value={record}
                                handleAddHotlist={
                                  record?.status === "draft"
                                    ? undefined
                                    : handleHotList
                                }
                                permission={"Vacancy"}
                                signature={record.project_pdf_path}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={9} className="text-center">
                          {t("No records found")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="pagination d-block" style={{ paddingTop: "0.25vw" }}>
                <PaginationWithPerPage
                  handleRefresh={handleRefresh}
                  dispatch={dispatch}
                  history={history}
                  pageData={{
                    totalPages: state?.totalPages,
                    totalRecords: state?.totalRecords,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPopup
        show={state?.popup?.delete?.show}
        onHide={() => handleModal()}
        title={t("Delete confirmation")}
        body={
          t(
            `Are you sure want to ${state?.popup?.delete?.data?.status === "active"
              ? "archive"
              : "unarchive"
            }`
          ) + "?"
        }
        onCloseButtonClick={() => handleModal()}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </AccessControl>
  );
};
export default ManageVacancies;
