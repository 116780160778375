import React, { useEffect, useRef, useState } from "react";
import WorkPayBillRollFilters from "./organisms/WorkPayBillRollFilters";
import { t } from "../masterData/translation/Translation";
import { MANAGE_PAYROLL_CONSTANTS } from "./constants/WorkPayBillRollConstants";
import { useReactToPrint } from "react-to-print";
import { getDate } from "./utils/Utils";
import TitleAtom from "components/atoms/Title";
import html2pdf from "html2pdf.js";
import BackButton from "components/atoms/BackButton";
import { useSelector } from "react-redux";
import { selectPageHistory } from "store/pageHistory/pageHistorySlice";
import WorkPayBillRollOrganism from "./organisms/WorkPayBillRollOrganism";

const WorkPayBillRollPage: React.FC = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const externalElement = document.getElementById("overlay") as HTMLDivElement | null;
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}

  const getDocTitle = (overview?: boolean) => {
    return `${t("Payroll of")} ${history?.filters?.business_unit} - ${history?.filters?.employee} ${t("on")} ${getDate(history?.filters?.selected_date, { month: "short", year: "numeric"})}`
  };

  const handlePrint = async (type: any, page?: string) => {
    switch (type) {
      // case "export":
        // let filters: any = { filterState, tableDispatch, tableState, export: true };    
        // await getManageData(filters)
        //   .then(async () => {
        //     setTimeout(() => {
        //       setLoading(true);
        //     }, 500);
        //   }).catch((error) => {
        //     setLoading(false);
        //     console.error("Error during data management:", error);
        //   });
        // break;
      case "export":
        if (externalElement) externalElement.style.display = "flex";
        await downloadPdf();
        if (externalElement) externalElement.style.display = "none";
        break;
      case "print":
        printPdf(null, () => componentRef.current);
        break;
      default:
        break;
    }
  };

  const downloadPdf = async () => {
    const options = {
      filename: `Export-payroll-${new Date().getDate()}-${new Date().toLocaleString('default', { month: 'short' })}-${new Date().getFullYear()}-${new Date().toLocaleTimeString().replace(/:/g, '-')}`,
      jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
      pagebreak: { mode: ['css', 'legacy'] },
      html2canvas: { scale: 3, useCORS: true },
    };
    if (componentRef?.current) {
      (componentRef.current as HTMLDivElement).style.padding = '40px';
      await html2pdf().from(componentRef.current).set(options).save();
      (componentRef.current as HTMLDivElement).style.padding = '0px';
    }
  };

  const printPdf = useReactToPrint({
    onBeforePrint: () => {
      console.log("before printing...");
    },
    onAfterPrint: () => {
      console.log("after printing...");
    },
    content: () => componentRef.current,
    documentTitle: getDocTitle(),
    removeAfterPrint: true,
    bodyClass: "mt-5",
    pageStyle: "",
  });

  return (
    <>
      <div className="search-bar">
        <TitleAtom title={t(MANAGE_PAYROLL_CONSTANTS?.MANAGE_PAYROLL)} />
        <WorkPayBillRollFilters handlePrint={handlePrint} />
      </div>
      <div ref={componentRef} className="content">
        <WorkPayBillRollOrganism filters={history?.filters} />
      </div>
      <div className="col-md-6 align-self-center" style={{ padding: "1vw 0" }}>
        <BackButton />
      </div>
    </>
  );
};

export default WorkPayBillRollPage;
