import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Search from "components/atoms/Search";
import Pagination from "components/atoms/Pagination";
import EditIcon from "static/images/EditIcon";
import { t } from "pages/microservices/masterData/translation/Translation";
import { CREATE_CANDIDATE, EDIT_CANDIDATE, MANAGE_CONTRACT_PROPOSAL, MANAGE_WAGE_PROPOSAL, PATH_CANDIDATE_CORNER, PATH_MATCHING_EMPLOYEE, VIEW_CANDIDATE, PATH_EMPLOYEE_FLEET_OVERVIEW } from "constants/Paths";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import { ApiCall } from "services/ApiServices";
import { M_IDENTITY_MANAGER } from "constants/Constants";
import { ADD_CANDIDATE_TO_HOTLIST, CANDIDATE_ARCHIVE, EMPLOYEE_CONVERSION, FETCH_CANDIDATE_OVERVIEW_DATA } from "routes/ApiEndpoints";
import { OptionProps } from "react-select";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import Matching from "static/images/matchingIcon";
import Button from "components/atoms/Button";
import FollowUpIcon from "static/images/FollowUpIcon";
import RightSideBar from "pages/microservices/masterData/follow-up-todo/RightSideBar";
import ArchieveIcon from "static/images/ArchiveIcon";
import ModalPopup from "components/atoms/ModalPopup";
import CustomNotify from "components/atoms/CustomNotify";
import { useSelector, useDispatch } from "react-redux";
import { addPageToHistory, selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { convertArrayToOptions, convertObjectToOptions } from "services/util/UtilService";
import FilterOrganism from "components/organism/FilterOrganism";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import { CandidatesTableHeader } from "TableHeader";
import AddWageProposalIcon from "static/images/AddWageProposalIcon";
import AddContractIcon from "static/images/addContractIcon";
import Unarchive from "static/images/UnarchiveIcon";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { PAGENAMES } from "store/pageHistory/ConstantPageNames";
import EmployeeConversionIcon from "static/images/EmployeeConversionIcon";
import { CandidateStatus } from "../helpers/CandidateHelperFunctions";
import BackButton from "components/atoms/BackButton";
import { Spinner } from "react-bootstrap";
import TextEllipsis from "components/atoms/TextEllipsis";
import ActionIconWrapper from "components/atoms/ActionIconWrapper";
import ViewIcon from "static/images/ViewIcon";
import FleetIcon from "static/images/FleetIcon";
import { recruitmentStatus } from "pages/microservices/identityManager/Applicants/jobApplication/JobApplicationsInterface";
import { Option } from "utils/TypeAnnotations";
import WhiteHeartIcon from "static/images/whiteHeartIcon";
import BlackHeartIcon from "static/images/BlackHeartICon";
import TitleAtom from "components/atoms/Title";

export interface CandidateData {
  id?: number;
  email: string;
  status: number | null;
  function: string;
  name: string;
  mobileNumber: string;
  businessUnits: string;
  businessUnitId: number | null;
  isEmployee: boolean;
  ssn: string;
  recruitmentStatus: any;
  role: [];
  contractType: string;
  isHotlist: boolean;
}

export interface DropdownData {
  businessUnits: Option | null;
  users: Option | null;
  contractTypes: Option | null;
}

export const initialDropdownData = {
  businessUnits: null,
  users: null,
  contractTypes: null,
}
const ManageCandidates: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const [candidates, setCandidates] = useState<CandidateData[]>([]);
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true
  });

  const getSearchData = () => {
    return {
      businessUnits: history?.filters?.businessUnits ?? [],
      businessUnitId: history?.filters?.businessUnitId ?? "",
      name: history?.filters?.name ?? "",
      email: history?.filters?.email ?? "",
      contractType: history?.filters?.contractType ?? [],
      mobileNumber: history?.filters?.mobileNumber ?? "",
      function: history?.filters?.function ?? "",
      ssn: history?.filters?.ssn ?? "",
      status: history?.filters?.status ?? "",
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
      recruitmentStatus: history?.filters?.recruitmentStatus ?? "",
      role: history?.filters?.role ?? []
    }
  };
  const [dropdownData, setDropdownData] = useState<DropdownData>(initialDropdownData);
  const [followupSideBar, setFollowupSideBar] = useState<boolean>(false);
  const [sideBarWidth, setSidebarWidth] = useState("");
  const [entity, setEntity] = useState<{
    entityId: string | number | null;
    businessUnit: string | number | null;
  }>({ entityId: null, businessUnit: null });
  const [archiveModal, setArchiveModal] = useState(false);
  const [candidateId, setCandidateId] = useState<number | undefined>(undefined);
  const roles = [
    { 'value': 'Candidate', 'label': 'Candidate' },
    { 'value': 'Employee', 'label': 'Employee' },
  ];

  const [candidateType, setCandidateType] = useState<number | undefined>(0);

  useEffect(() => {
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getSearchData(),
      initialCall: pageData?.initialCall,
      candidateType: candidateType
    };
    fetchCandidates(postData);
  }, [pageData?.refresh, pageData?.initialCall]);

  const fetchCandidates = async (postData: any) => {
    const response = await ApiCall.service(FETCH_CANDIDATE_OVERVIEW_DATA, "POST", postData, false, M_IDENTITY_MANAGER);
    if (response?.status === 200) {
      if (pageData?.initialCall) {
        setDropdownData(response?.dropDownData);
      }
      setCandidates(response?.data || []);
      setPageData((prev: any) => ({ ...prev, totalPages: response?.totalPages, totalRecords: response?.totalRecords, initialCall: false }));
      setLoading(false);
    }
  };

  const handleClick = (id: number | undefined) => {
    if (id) {
      navigate(`${PATH_CANDIDATE_CORNER}/${id}?source=candidate`);
    }
  }

  const handleCloseSidebar = () => {
    setFollowupSideBar(false);
  };

  const getWidth = (width: any) => {
    setSidebarWidth(width);
  };

  const handleFollowup = (
    id: number | undefined,
    businessUnit: string | number | null
  ) => {
    if (entity.entityId === id) {
      setEntity({
        entityId: null,
        businessUnit: null,
      });
      setFollowupSideBar(false);
    } else {
      setEntity({
        entityId: id ?? null,
        businessUnit: businessUnit ?? null,
      });
      setFollowupSideBar(true);
    }
  };

  const handleArchive = (id: number | undefined, status: number | null) => {
    if (status === 3) {
      setArchiveModal(false);
    } else {
      setArchiveModal(true);
    }
    setCandidateId(id);
  }
  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  const handleArchiveConfirm = async () => {
    if (candidateId) {
      const response = await ApiCall.getService(`${CANDIDATE_ARCHIVE}/${candidateId}`, "GET", M_IDENTITY_MANAGER, true);
      if (response.status === 200) {
        handleRefresh();
        setArchiveModal(false);
        CustomNotify({ type: 'success', message: response.message });
      }
    }
  }

  const handleCloseArchiveModal = () => {
    setArchiveModal(false);
  }

  const filters = [
    { name: 'businessUnits', fieldType: "multiSelect", options: dropdownData?.businessUnits, placeholder: "Business unit", filterType: 'search' },
    { name: 'name', fieldType: "text", placeholder: "Name", filterType: 'search' },
    { name: 'email', fieldType: "text", placeholder: "Email", filterType: 'search' },
    { name: 'contractType', fieldType: "singleSelect", options: dropdownData?.contractTypes, placeholder: "Contract type", filterType: 'search' },
    { name: 'mobileNumber', fieldType: "text", placeholder: "Mobile number", filterType: 'search' },
    { name: 'recruitmentStatus', fieldType: "singleSelect", options: recruitmentStatus, placeholder: "Recruitment status", filterType: 'search' },
    { name: 'status', fieldType: "singleSelect", options: convertObjectToOptions(CandidateStatus), placeholder: "Status", filterType: 'search' },
    { name: 'role', fieldType: "singleSelect", options: roles, placeholder: "Role", filterType: 'search' },
  ];

  const handleEmployee = async (id: number | undefined) => {
    const response = await ApiCall.getService(`${EMPLOYEE_CONVERSION}/${id}`, "GET", M_IDENTITY_MANAGER, true);
    if (response.status === 200) {
      handleRefresh();
      CustomNotify({ type: 'success', message: response.message });
    }
  }
  const handleMatching = (record: any) => {
    let competence: any = {};
    record?.employeeCompetence && record?.employeeCompetence
      ?.filter((item: any) => item !== null && item?.competence_id)
      ?.map((item: any) => {
        competence[item.competence_id] = {
          options: item?.user_competence_type?.map((type: any) => ({
            value: type?.competence_type_id,
            label: type?.competence_type?.competence_type ?? "",
            range: type?.range
          }))
        };
      });
    dispatch(addPageToHistory({
      pageName: PAGENAMES?.EMPLOYEE_MATCHING,
      filters: {
        function: record?.employeeFunctions ?? [],
        competance: competence,
      },
      route: `${PATH_MATCHING_EMPLOYEE}/${record?.id}`
    }));
    navigate(`${PATH_MATCHING_EMPLOYEE}/${record?.id}`);
  };

  const handleView = (id: number | undefined) => {
    navigate(`${VIEW_CANDIDATE}/${id}`);
  }

  //2 => Employee 1 => client for directing to fleet emp overview used these values in path parameter

  const filterUserType = (userData: any) => {
    const filteredRole = userData?.filter((eachItem: string) =>
      eachItem.toLowerCase() !== 'candidate' && eachItem.toLowerCase() !== 'applicant');

    return filteredRole.length > 0 ? true : false;
  }

  const handleHotlist = async (id: any, type: boolean) => {
    const postData = {
      id: id,
      type: type
    };
    const response = await ApiCall.service(ADD_CANDIDATE_TO_HOTLIST, "POST", postData, true, M_IDENTITY_MANAGER);
    if (response?.status === 200) {
      handleRefresh();
    }
  }

  const handleStatusFilter = (candidateType: number | undefined) => {
    setCandidateType(candidateType);
      handleRefresh();
  }

  return (
    <>
      <div className="row">
        <TitleAtom title={t("Medewerkers")} />
        <div className="col-md-12 createNavTabs">
          <button
            className={`link ${candidateType === undefined || candidateType === 0
              ? "active nav-subtab"
              : "nav-subtab"
              } marginRightPoint5 border-0 bg-transparent p-0 btn`}
            onClick={(e) => handleStatusFilter(0)}
            style={{ cursor: "pointer" }}
          >
            {t("All")}
          </button>
          <button
            className={`link ${candidateType === 1 ? "active nav-subtab" : "nav-subtab"
              } btn marginRightPoint5 border-0 bg-transparent p-0`}
            onClick={(e) => handleStatusFilter(1)}
            style={{ cursor: "pointer" }}
          >
            {t("Hotlist")}
          </button>
        </div>
      </div>
      <div className="position-relative tableMainWrapper">
        <div className="row">
          <div className="ManageCreateBtn">
            <LinkTo
              pagelink={CREATE_CANDIDATE}
              title={t("Create medewerker")}
              icon={faPlus}
            />
          </div>
        </div>
        <div className="table-responsive Manage-candidates tableSection">
          <FilterOrganism
            dispatch={dispatch}
            history={history}
            updatePageFilters={updatePageFilters}
            filters={filters}
            handleRefresh={handleRefresh}
          />
          <div className="TableHeight">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader">
                  {CandidatesTableHeader?.map((header: any) => (
                    <th key={header?.alias}>
                      <>
                        {t(header.name)}
                        {header?.issort && (
                          <SortAtomForManagePage
                            value={{ alias: header?.alias }}
                            dispatch={dispatch}
                            history={history}
                            updatePageFilters={updatePageFilters}
                            handleRefresh={handleRefresh}
                          />
                        )}
                      </>
                    </th>
                  ))}
                  <th>{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {loading ? <tr className="border-0">
                  <td
                    className="text-center text-info border-0 spinner-wrapper "
                    colSpan={10} rowSpan={4}
                  >
                    <Spinner size="sm" className="me-2 spinnerStyle" />{" "}
                    {t("Loading")}
                  </td>
                </tr> :
                  <>
                    {candidates?.length > 0 ? (
                      candidates?.map((candidate: CandidateData) => (
                        <tr key={candidate.id}>
                          <td><TextEllipsis title={candidate?.businessUnits} label={Array.isArray(candidate?.businessUnits) && candidate?.businessUnits?.join(', ')} width="6.5vw" /></td>
                          <td style={{ cursor: 'pointer' }} onClick={() => handleClick(candidate.id)}>
                            <TextEllipsis title={candidate?.name} label={candidate?.name} width="8vw" />
                          </td>
                          <td><TextEllipsis title={candidate?.email} label={candidate?.email} width="15vw" /></td>
                          <td><TextEllipsis title={candidate?.contractType} label={candidate?.contractType} width="8vw" /></td>
                          <td>{candidate?.mobileNumber}</td>
                          <td>
                            <TextEllipsis title={[...recruitmentStatus]?.find((eachItem) => eachItem?.value == candidate?.recruitmentStatus)?.label ?? null}
                              label={[...recruitmentStatus]?.find((eachItem) => eachItem?.value == candidate?.recruitmentStatus)?.label ?? null}
                              width="8vw" />
                          </td>
                          <td>
                            <span
                              className={`d-inline-block rounded-circle status-icon bg-${candidate?.status === 1
                                ? "success" : candidate.status === 4 ? "warning"
                                  : "secondary"
                                }`}
                              data-toggle="tooltip"
                              title={candidate.status === 4 ?
                                CandidateStatus[0] :
                                candidate?.status === 1
                                  ? CandidateStatus[1]
                                  : CandidateStatus[3]
                              }
                            ></span>
                          </td>
                          <td><TextEllipsis title={candidate?.role?.join(', ')} label={candidate?.role?.join(', ')} width="8vw" /></td>
                          <td>
                            <ActionIconWrapper initialVisibleCount={4}>
                              <span className="table-action-btn cursor-pointer" onClick={() => handleFollowup(candidate?.id, candidate?.businessUnitId)} title={t("Follow up")}>
                                <FollowUpIcon />
                              </span>
                              {(candidate?.status == 1 && candidate?.isEmployee) &&
                                <span className="table-action-btn cursor-pointer" onClick={() => handleMatching(candidate)} title={t("Matching")}>
                                  <Matching />
                                </span>
                              }
                              {/* Hotlist */}
                              {/* resume */}
                              <span className="table-action-btn cursor-pointer" onClick={() => navigate(`${EDIT_CANDIDATE}/${candidate.id}`)} title={t("Edit")}>
                                <EditIcon />
                              </span>
                              <span className="table-action-btn cursor-pointer" onClick={() => handleView(candidate?.id)} title={t("Add contract")}>
                                <ViewIcon />
                              </span>
                              <span className="table-action-btn cursor-pointer" onClick={() => handleArchive(candidate.id, candidate.status)} title={candidate.status === 3 ? t("Unarchive") : t("Archive")}>
                                {candidate.status === 3 ? <Unarchive /> : <ArchieveIcon />}
                              </span>
                              <span className="table-action-btn cursor-pointer" onClick={() => navigate(`${MANAGE_WAGE_PROPOSAL}/${candidate.id}`)} title={t("Wage proposal")}>
                                <AddWageProposalIcon />
                              </span>
                              <span className="table-action-btn cursor-pointer" onClick={() => navigate(`${MANAGE_CONTRACT_PROPOSAL}/${candidate.id}`)} title={t("Add contract")}>
                                <AddContractIcon />
                              </span>
                              {filterUserType(candidate.role) && (
                                <span className="table-action-btn cursor-pointer" onClick={() => navigate(`${PATH_EMPLOYEE_FLEET_OVERVIEW}/${candidate.id}/2`)} title={t("Add fleet")}>
                                  <FleetIcon />
                                </span>
                              )}
                              {
                                candidate?.isHotlist ? <span className="table-action-btn cursor-pointer" onClick={() => handleHotlist(candidate?.id, false)} title={t("Add hotlist")}>
                                  <BlackHeartIcon />
                                </span> :
                                  <span className="table-action-btn cursor-pointer" onClick={() => handleHotlist(candidate?.id, true)} title={t("Add hotlist")}>
                                    <WhiteHeartIcon />
                                  </span>
                              }


                              {/* <Button
                                    title=""
                                    className="table-action-btn border-0  p-0"
                                    handleClick={() => handleEmployee(candidate?.id)}
                                  >
                                    <EmployeeConversionIcon />
                                  </Button> */}
                            </ActionIconWrapper >
                            <div>
                            </div>

                          </td >
                        </tr >
                      ))
                    ) : (
                      <tr className="border">
                        <td colSpan={10} className="border-0 text-center py-3">
                          <span className="text-danger fw-bold">{t("No records")}</span>
                        </td>
                      </tr>
                    )}
                  </>
                }
              </tbody >
            </table >
          </div >
          {
            !loading && <div className="">
              <PaginationWithPerPage
                handleRefresh={handleRefresh}
                dispatch={dispatch}
                history={history}
                pageData={pageData}
              />
            </div>
          }
        </div >

      </div >
      {/* <div className="col-md-6 align-self-center backPadding">
        <BackButton />
      </div> */}
      {
        followupSideBar && (
          <RightSideBar
            title={"Follow-ups"}
            entityType={"candidate"}
            businessUnit={entity.businessUnit}
            entityId={entity.entityId}
            onClose={handleCloseSidebar}
            getWidth={getWidth}
            recordsLimit={10}
            className="right-sidebar p-3"
            titleClassName="mt-4"
            destination="/manage-candidates"
          />
        )
      }
      {
        archiveModal && (
          <ModalPopup
            title={"Archive"}
            show={archiveModal}
            onHide={handleCloseArchiveModal}
            body={t("Are you sure you want to archive this candidate ?")}
            onConfirmButtonClick={handleArchiveConfirm}
            onCloseButtonClick={handleCloseArchiveModal}
            confirmTitle={t("Yes")}
            closeTitle={t("No")}
          />
        )
      }
    </>
  );
};

export default ManageCandidates;
