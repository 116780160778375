import { GENERAL, UPDATE_FIELD } from "../../annotations/CandidateConstants";

export const SetAddressData = async (data: any, dispatch: any, state: any) => {    
    let { street, nr, bus, postal, city, country, coordinates } = data;

    //** set lat and lang /
    let lat = "";
    let lng = "";

    if (coordinates !== "") {
        [lat, lng] = coordinates
            .replace("Lat: ", "")
            .replace("Lng: ", "")
            .split(", ");
    }

    dispatch({
        type: UPDATE_FIELD,
        tab: GENERAL,
        field: 'initialLat',
        value: lat
    });


    dispatch({
        type: UPDATE_FIELD,
        tab: GENERAL,
        field: 'initialLn',
        value: lng
    });

    //***  /

    // Match country with case-insensitive comparison
    if (country !== "") {
        country = state.dropDownData?.countriesList.find((option: any) =>
            option.label.toLowerCase().includes(country.toLowerCase())
        );
    }

    let resedentialAdd = {
        street: street,
        number: nr,
        city: city,
        country: country,
        zipCode: postal,
        box: bus,
    };

    Object.entries(resedentialAdd).forEach(([key, value]) => {
        dispatch({
            type: UPDATE_FIELD,
            tab: GENERAL,
            field: key,
            value: value
        });
    });

    if (state.general.sameAsResidence === 1 || state.general.sameAsResidence === true) {
        Object.entries(resedentialAdd).forEach(([key, value]) => {
            const domField = `dom${key.charAt(0).toUpperCase()}${key.slice(1)}`; // Convert key to domKey format
            dispatch({
                type: UPDATE_FIELD,
                tab: GENERAL,
                field: domField,
                value: value
            });
        });
    }
};
