import React from "react";
import { useFormContext } from "pages/microservices/project/context/Context";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import {
  ADD_ANOTHER_SEQUENCE,
  DELETE_SEQUENCE,
  EDIT_SEQUENCE,
  SET_SEQUENCE_NAME,
  SET_WEEKLY_SHIFTS,
  TITLE_ALREADY_EXISTS,
  UPDATE_ERRORS_SEQUENCE_TAB,
  WEEKS,
} from "../context/Constants";
import Button from "components/atoms/Button";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface ProjectsSequenceProps {
  id?: string | number;
  type?: string;
}

const Sequence: React.FC<ProjectsSequenceProps> = ({ type }) => {
  const { state, dispatch } = useFormContext(); // state object

  const sequenceData = state.sequence.data;
  const sequenceErrors = state.sequence.errors;
  const shifts = state.shifts.data.shifts;

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    // handling the change of sequence name
    if (event.target) {
      const { name, value } = event.target as HTMLInputElement;
      dispatch({
        type: SET_SEQUENCE_NAME,
        field: name,
        value: value,
      });
    }
  };

  const handleSelect = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    name: string
  ) => {
    // handling the change of the select fields
    dispatch({
      type: SET_WEEKLY_SHIFTS,
      field: "weeklyShifts",
      value: event,
      handle: name,
    });
  };

  const buttonClick = (action: string, ref: string = "") => {
    // On click of the add or save button
    let type: any = "";

    if (action === "edit") {
      type = EDIT_SEQUENCE;
    } else if (action === "delete") {
      type = DELETE_SEQUENCE;
    } else {
      if (validateTitle() === "") {
        // validating the title, returns true if it is validated
        type = ADD_ANOTHER_SEQUENCE;
      } else {
        dispatch({
          type: UPDATE_ERRORS_SEQUENCE_TAB,
          errors: {
            sequenceName: validateTitle(),
          },
        });
      }
    }

    dispatch({
      type: type,
      handle: ref,
    });
  };

  const validateTitle = () => {
    // handling the validation of title
    if (sequenceData.sequenceName.trim() === "") {
      return "This field is required";
    }
    const existingIndex = sequenceData.sequence
      .filter(
        (sequence: any) =>
          sequence.name.toLocaleLowerCase() !==
          sequenceData.edit?.toLocaleLowerCase()
      )
      .findIndex(
        (item: any) =>
          item.name.toLocaleLowerCase() ===
          sequenceData.sequenceName?.toLocaleLowerCase()
      );

    if (existingIndex !== -1) {
      return TITLE_ALREADY_EXISTS;
    }

    return "";
  };

  const className = type == 'View' ? "form-border disabled" : "form-border";


  return (
    <form>
      <div
        className={className}
        style={{ paddingTop: "3vw", paddingBottom: "0.5vw" }}
      >
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <LabelWithInputField
              handleChange={handleFieldChange}
              label={t("Sequence name")}
              value={sequenceData?.sequenceName}
              readOnly={false}
              isDisabled={false}
              isMandatory={true}
              error={sequenceErrors?.sequenceName}
              name={"sequenceName"}
              id={"sequenceNameId"}
            />
          </div>
          {Object.values(WEEKS).map((week: any) => (
            <div key={week.weeknumber} className="col-6">
              <SelectWithSearch
                title={t(week.label) + " " + week.weeknumber}
                name={week.handle}
                search={true}
                options={shifts}
                placeHolder={t("Select")}
                value={sequenceData?.weeklyShifts[week.handle]}
                onChange={(e) => handleSelect(e, week.handle)}
                isMulti={false}
                className="select-field"
              />
            </div>
          ))}
          <div className="col-6 searchBtnWrapper">
            <Button
              title={sequenceData?.edit ? t("Save") : t("Add")}
              className="form-button float-end"
              handleClick={() => buttonClick("add")}
            // icon={sequenceData?.edit ? undefined : faPlus}
            />
          </div>
        {sequenceData?.sequence.length > 0 && (
          <div className="col-12">
            <table className="table" style={{ marginBottom: "1.5vw" }}>
              <tbody>
                {sequenceData?.sequence.map((sequence: any) => (
                  <tr key={sequence.name}>
                    <td className="align-middle ps-4" style={{ width: "60%" }}>
                      {sequence.name}
                    </td>
                    <td>
                      <span
                        className="cursor-pointer table-action-btn marginRightPoint5"
                        onClick={() => buttonClick("edit", sequence.name)}
                      >
                        <EditIcon />
                      </span>
                      <span
                        className="cursor-pointer table-action-btn"
                        onClick={() => buttonClick("delete", sequence.name)}
                      >
                        <DeleteIcon />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        </div>
      </div>
    </form>
  );
};

export default Sequence;
