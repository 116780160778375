import React, { useState } from "react";
import { useFormContext } from "../context/Context";
import { EMPLOYEE_TYPE, NEXT_STEP, PC_DETAILS, PREVIOUS_STEP, SALARY_BENEFITS, UPDATE_TAB_DETAILS } from "../annotations/PCConstants";
import Button from "components/atoms/Button";
import { t } from "../../translation/Translation";
import LoadingIcon from "utils/LoadingIcon";
import BackButton from "components/atoms/BackButton";
import { ApiCall } from "services/ApiServices";
import { PC_CREATE } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { useNavigate, useParams } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import { PATH_PC_MANAGE } from "constants/Paths";
import { validateDynamicTabs, validateMandatoryFields } from "./FormValidation";

export const Navigation = () => {
  const { state, dispatch } = useFormContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const id = params.id;
  const currentTab = state.tabDetails.find((tab) => tab?.showStatus === true);
  const isPCDetailsTab = currentTab && currentTab?.id === PC_DETAILS ? true : false;
  const isEmployeeTypeTab = currentTab && currentTab?.id === EMPLOYEE_TYPE ? true : false;

  const handleSubmit = async () => {
    const update = state.tabDetails.map((tab, index) => ({
      ...tab,
      draft: false,
    }));
    dispatch({ type: UPDATE_TAB_DETAILS, tabDetails: update });
    const isValid = validateMandatoryFields(state, dispatch, 'submit');
    const isCategoryAndFunctionTabValid = validateDynamicTabs(state, dispatch, 'categoryAndFunctions', 1);
    const isAgeTabValid = validateDynamicTabs(state, dispatch, 'age', 2);
    if (isValid && !isCategoryAndFunctionTabValid && !isAgeTabValid) {
      handleSubmitFormData('submit');
    }
  };

  const handleNextStep = () => {
    dispatch({ type: NEXT_STEP });
    handleSubmitFormData("next");
  };

  const handlePreviousStep = () => {
    dispatch({ type: PREVIOUS_STEP });
  };

  const handleSubmitFormData = async (type: string) => {
    const { dropDownData, errors, tabDetails, ...postData } = state;
    Object.assign(postData, { 'id': id }
      // , { 'formStatus': formStatus }
    );
    if (type === "submit") {
      const response = await ApiCall.service(PC_CREATE, "POST", postData, false, M_MASTER_DATA);
      if (response?.status === 200) {
        CustomNotify({ type: 'success', 'message': response?.message });
        navigate(PATH_PC_MANAGE);
      }
    }


  }

  return (
    <div className="dflex align-items-center justify-content-between">
      <div>
        {isPCDetailsTab && (
          <BackButton />
        )}
        {!isPCDetailsTab && (
          <Button
            type="button"
            title={t("Back")}
            handleClick={handlePreviousStep}
            className="back-btn btn border-0 text-decoration-underline p-0"
          />
        )}
      </div>
      <div>
        {!loading ? (
          <Button
            type="submit"
            title={isEmployeeTypeTab ? t("Submit") : t("Next")}
            handleClick={isEmployeeTypeTab ? handleSubmit : handleNextStep}
            className="float-end form-button ms-3 shadow-none"
          />
        ) : (
          <LoadingIcon
            iconType="bars"
            color="#e5007d"
            className="ms-auto mb-3"
            height={"3vw"}
            width={"3vw"}
          />
        )}
      </div>
    </div>
  );
}