import React from "react";
import GeneralMolecule from "./molecules/General";
import ProjectRateMolecule from "./molecules/ProjectRate";
import MovingCostsMolecule from "./molecules/MovingCosts";
import CapacityMolecule from "./molecules/Capacity";
import SurchargeMolecule from "./molecules/Surcharge";
import { t } from "../translation/Translation";
import WeekendAndHolidayMolecule from "./molecules/WeekendAndHoliday";
import OvertimeMolecule from "./molecules/Overtime";
import PremiumMolecule from "./molecules/Premium";
import CheckBoxField from "components/atoms/CheckBoxField";

interface FormSectionProps {
  section?: string;
  fields?: any;
  handleInputChange: any;
  formMode?: string;
  formErrors?: { [key: string]: { [key: string]: string } };
  templateTitle?: string;
  type?: string;
}

const FormSection: React.FC<FormSectionProps> = ({
  section,
  fields,
  handleInputChange,
  formMode,
  formErrors,
  templateTitle,
  type,
}) => {
  let prevFormSection: string | null = null;
  const renderParagraph = (form_section_title: string) => {
    if (form_section_title !== prevFormSection) {
      prevFormSection = form_section_title;
      return (
        <span>
          <strong>{prevFormSection}</strong>
        </span>
      );
    }
    return null;
  };

  function getFieldValue(field: any, section: any) {
    if (field?.[section]?.hasOwnProperty(field.field_id)) {
      return field[section][field.field_id];
    } else if (field.field_value !== null) {
      return field.field_value;
    }
    return "";
  }

  const fieldsArray = Object.values(fields);

  {
    /* Custom sort function to sort fields by section_weight and then by field_weight */
  }
  fieldsArray.sort((a: any, b: any) => {
    // Compare section_weight first
    if (a.section_weight < b.section_weight) {
      return -1;
    } else if (a.section_weight > b.section_weight) {
      return 1;
    } else {
      // If section_weight is the same, compare field_weight
      if (a.field_weight < b.field_weight) {
        return -1;
      } else if (a.field_weight > b.field_weight) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  switch (section) {
    case "General":
      return (
        <>
          <div className="col-6">
            <div className="tab-subtitle" style={{ paddingBottom: "0.5vw" }}>
              {section}
            </div>
          </div>
          {
            fieldsArray.map((field: any, index: any) => (
              <React.Fragment key={section + "-" + index}>
                <GeneralMolecule
                  name={field?.field_name}
                  value={getFieldValue(field, section)}
                  field={field}
                  handleChange={(e) =>
                    handleInputChange(
                      field.form_section_title,
                      field?.field_id,
                      e?.target?.value ?? e,
                      field?.field_type
                    )
                  }
                  unit={field.field_name === "Total amount" ? t("€") : ""}
                  disabled={
                    type == "project" && field?.field_name == "Template name"
                      ? true
                      : formMode === "view"
                  }
                  error={field?.field_error}
                />
              </React.Fragment>
            ))
          }
        </>
      );
    case "Project rate":
      return (
        <>
          <div className="tab-subtitle" style={{ paddingBottom: "0.5vw" }}>
            {section}
          </div>
          {fieldsArray.map((field: any, index: any) => (
            <React.Fragment key={index}>
              {/* {renderParagraph(field.form_section_title)} */}
              <ProjectRateMolecule
                field={field}
                name={field?.field_name}
                value={getFieldValue(field, section)}
                handleChange={(e) => {
                  let v: any = e;
                  const value = v.target?.value ?? v?.value;
                  return handleInputChange(
                    section,
                    field?.field_id,
                    value,
                    field?.field_type
                  );
                }}
                unit={t("€")}
                disabled={formMode === "view"}
                error={field?.field_error}
              />
            </React.Fragment>
          ))}
        </>
      );
    case "Moving costs":
      return (
        <>
          <div className="tab-subtitle" style={{ paddingBottom: "0.5vw" }}>
            {section}
          </div>
          <div className="col-12">
            <div
              className="bg-white"
              style={{
                borderRadius: "1vw",
                padding: "1.5vw 1.5vw",
                marginBottom: "0.7vw",
              }}
            >
              <div className="row">
                {fieldsArray.map((field: any, index: any) => (
                  <React.Fragment key={index}>
                    {/* {renderParagraph(field.form_section_title)} */}

                    <MovingCostsMolecule
                      field={field}
                      name={field?.field_name}
                      value={getFieldValue(field, section)}
                      handleChange={(e: any) => {
                        return handleInputChange(
                          section,
                          field?.field_id,
                          e?.target?.value ?? e,
                          field?.field_type
                        );
                      }}
                      colClass={
                        fieldsArray
                          .map((element: any) => element.field_name)
                          .includes("Package per day($)")
                          ? "col-3"
                          : "col-6"
                      }
                      disabled={formMode === "view"}
                      data={fields}
                      error={field?.field_error}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    case "Capacity":
      return (
        <>
          <div className="tab-subtitle" style={{ paddingBottom: "0.5vw" }}>
            {section}
          </div>
          <div className="col-12">
            <div
              className="bg-white"
              style={{
                borderRadius: "1vw",
                padding: "1.5vw 1.5vw 0vw",
                marginBottom: "0.7vw",
              }}
            >
              <div className="row">
                {fieldsArray.map((field: any, index: any) => (
                  <React.Fragment key={index}>
                    {/* {renderParagraph(field.form_section_title)} */}
                    <CapacityMolecule
                      field={field}
                      name={field?.field_name}
                      value={getFieldValue(field, section)}
                      handleChange={(e) =>
                        handleInputChange(
                          section,
                          field?.field_id,
                          e.target.value,
                          field?.field_type
                        )
                      }
                      disabled={formMode === "view"}
                      error={field?.field_error}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    case "Surcharge":
      return (
        <>
          <div className="tab-subtitle" style={{ paddingBottom: "0.5vw" }}>
            {section}
          </div>
          <div className="col-12">
            <div
              className="bg-white"
              style={{
                borderRadius: "1vw",
                padding: "1.5vw 1.5vw 0vw",
                marginBottom: "0.7vw",
              }}
            >
              <div className="row">
                {fieldsArray.map((field: any, index: any) => (
                  <React.Fragment key={index}>
                    {/* {renderParagraph(field.form_section_title)} */}
                    <SurchargeMolecule
                      field={field}
                      unit={"%"}
                      name={field?.field_name}
                      value={getFieldValue(field, section)}
                      handleChange={(e) =>
                        handleInputChange(
                          section,
                          field?.field_id,
                          e.target.value,
                          field?.field_type
                        )
                      }
                      disabled={formMode === "view"}
                      error={field?.field_error}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    case "Weekend & Holidays":
      return (
        <>
          <div className="tab-subtitle" style={{ paddingBottom: "0.5vw" }}>
            {section}
          </div>
          <div className="col-12">
            <div
              className="bg-white"
              style={{
                borderRadius: "1vw",
                padding: "1.5vw 1.5vw 0vw",
                marginBottom: "0.7vw",
              }}
            >
              <div className="row">
                {fieldsArray.map((field: any, index: any) => (
                  <React.Fragment key={index}>
                    {/* {renderParagraph(field.form_section_title)} */}
                    <WeekendAndHolidayMolecule
                      field={field}
                      name={field?.field_name}
                      unit={"%"}
                      value={getFieldValue(field, section)}
                      handleChange={(e) =>
                        handleInputChange(
                          section,
                          field?.field_id,
                          e.target.value,
                          field?.field_type
                        )
                      }
                      disabled={formMode === "view"}
                      error={field?.field_error}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    case "Overtime":
      return (
        <>
          <div className="tab-subtitle" style={{ paddingBottom: "0.5vw" }}>
            {section}
          </div>
          <div className="col-12">
            <div
              className="bg-white"
              style={{
                borderRadius: "1vw",
                padding: "1.5vw 1.5vw 1.5vw",
                marginBottom: "0.7vw",
              }}
            >
              <div className="row">
                {fieldsArray.map((field: any, index: any) => (
                  <React.Fragment key={index}>
                    {/* {renderParagraph(field.form_section_title)} */}
                    <OvertimeMolecule
                      field={field}
                      name={field?.field_name}
                      value={getFieldValue(field, section)}
                      handleChange={(e) => {
                        return handleInputChange(
                          section,
                          field?.field_id,
                          e.target.value,
                          field?.field_type
                        );
                      }}
                      disabled={formMode === "view"}
                      error={field?.field_error}
                      data={fields}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    case "Premium":
      const dependentField: any = fieldsArray.find(
        (eachItem: any) => eachItem.field_id == "44"
      );
      return (
        <>
          <div className="tab-subtitle" style={{ paddingBottom: "0.5vw" }}>
            {section}
          </div>
          <div className="col-12">
            <div
              className="bg-white"
              style={{
                borderRadius: "1vw",
                padding: "1.5vw 1.5vw 0.5vw",
                marginBottom: "0.7vw",
              }}
            >
              <div className="row">
                {fieldsArray.map((field: any, index: any) => (
                  <React.Fragment key={index}>
                    {/* {renderParagraph(field.form_section_title)} */}

                    <PremiumMolecule
                      field={field}
                      name={field?.field_name}
                      value={getFieldValue(field, section)}
                      handleChange={(e) =>
                        handleInputChange(
                          section,
                          field?.field_id,
                          e.target.value,
                          field?.field_type
                        )
                      }
                      disabled={formMode === "view"}
                      dependency={dependentField}
                      error={field?.field_error}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    default:
      return null;
  }
};

export default FormSection;
