import React from 'react';
import { getRandomInt } from "../utils/utils";
import { useFilterOptions } from '../planning-redux/context/FiltersContext/FiltersContext';
import { PROJECT_VIEW_CHARACTERS } from '../constants/planningConstants';
import { SALES_VIEW_LIST } from '../constants/planningConstants';
import { dateUtils } from '../utils/dateUtils';
import { usePlanning } from "../planning-redux/context/PlanningContext/PlanningContext";
import PlanningContextMenu from './planningContextMenu';
import { getTitle } from '../utils/utils';
import TextEllipsis from 'components/atoms/TextEllipsis';

function PlannedEventBox({ date, employee_id, handleWeeklyClickEventOnPlannedUnit, plan = [], name, handleClick, handleRightClick, handleAction, contextMenuId, tooltipPosition, highlightedDiv }) {

    const { filterOptionsState } = useFilterOptions();
    const { planningState } = usePlanning();
    let view = planningState.salesview.value

    const getMainTitle = (eplan, type, truncate = null) => {
        let title = '';
        if (view == 'all') {
            title = (SALES_VIEW_LIST.includes(name)) ? getSuffix(name, eplan['salesvalue'])
                : getTitle(filterOptionsState.state[type], eplan.project_id, truncate)
        } else {
            title = employee_id == 'total' ? getSuffix(name, eplan['salesvalue'])
                : getSuffix(name, eplan[view])
        }
        if (eplan.leave) {
            title = getTitle(filterOptionsState.state['holidaycode'], eplan.code_id)
        }
        return title;
    }

    const getSuffix = (key, value) => {
        switch (key) {
            case 'Margin %':
                value = value + "%"
                break;
            case 'Total employee':
                value = value;
                break;

            default:
                value = "€" + value
                break;
        }
        return value;
    }

    const getBGColor = (eachplan) => {
        let col = '#B9E0A5';
        if (SALES_VIEW_LIST.includes(name)) {
            const isweekend = dateUtils.checkIsWeekend(date);
            col = isweekend ? '' : '#ebebeb';
        } else {
            switch (eachplan.plantype) {
                case "leave":
                    col = '#FFFF99';
                    break;
                case "timesheet":
                    col = '#FFCCCC';
                    break;
                case "invoiced":
                    col = '#3333FF';
                    break;
                default:
                    break;
            }
        }
        return col;
    }

    const getColor = (eachplan) => {
        let list = ['leave', 'timesheet']
        let colour = (SALES_VIEW_LIST.includes(name) || list.includes(eachplan.plantype)) ? 'black' : '#000';
        return colour
    }

    return (
        plan.map((eachplan) => {
            return (

                <>
                    {(() => {
                        let id = employee_id + '-' + date + '-' + eachplan.planid
                        let bordr = highlightedDiv == id ? "11px solid blue !important" : "none"
                        return (
                            <div key={getRandomInt(1, 100)}
                                className={`e-appointment planned-event-box e-lib e-draggable e-appointment-border d-flex align-items-center justify-content-center`}
                                dataid="Appointment_10"
                                role="button"
                                tabIndex="0"
                                aria-disabled="false"
                                aria-label="Planned"
                                data-guid="6a05ce81-bc5b-87cc-087b-faa1bb4ab242"
                                data-group-index="1"
                                aria-grabbed="false"
                                id={id}
                                onDoubleClick={(e) => handleWeeklyClickEventOnPlannedUnit(e, employee_id, date, id, eachplan)}
                                onClick={(e) => handleClick(e, id, employee_id, date, eachplan)}
                                onContextMenu={(e) => handleRightClick(e, id, employee_id, date, eachplan)}
                                style={{
                                    color: getColor(eachplan),
                                    backgroundColor: getBGColor(eachplan),
                                    flex: 1,
                                    height: "1.5vw",
                                    borderRadius: "0",
                                    border: bordr
                                }}>
                                <div className="e-event-resize e-left-handler">
                                    <div className="e-left-right-resize"></div>
                                </div>
                                <div className="e-planned_details">
                                    <div className={`${SALES_VIEW_LIST.includes(name) ? 'e-inner-wrap' : ''}`}>
                                        <TextEllipsis title={getMainTitle(
                                            eachplan,
                                            "projects",
                                            PROJECT_VIEW_CHARACTERS
                                        )} label={getMainTitle(
                                            eachplan,
                                            "projects",
                                            PROJECT_VIEW_CHARACTERS
                                            // frequency == YEAR_KEYWORD ? 1 : PROJECT_VIEW_CHARACTERS
                                        )} width='2.5vw' />
                                    </div>
                                </div>
                                {contextMenuId === id && (
                                    <PlanningContextMenu
                                        handleWeeklyClickEventOnPlannedUnit={handleWeeklyClickEventOnPlannedUnit}
                                        handleAction={handleAction}
                                        employee_id={employee_id}
                                        date={date}
                                        plan={eachplan}
                                        tooltipPosition={tooltipPosition}
                                        id={id} />
                                )}
                                {!SALES_VIEW_LIST.includes(name) &&
                                    <>
                                        <div className={`e-planned-details ${tooltipPosition}`}>
                                            {eachplan.leave &&
                                                <>
                                                    <div>Leave: True</div>
                                                    <div>Holiday code: {getTitle(filterOptionsState.state['holidaycode'], eachplan.code_id)}</div>
                                                </>
                                            }
                                            {eachplan.plan &&
                                                <>
                                                    <div>Company: {getTitle(filterOptionsState.state['companies'], eachplan.company_id)}</div>
                                                    <div>Project: {getTitle(filterOptionsState.state['projects'], eachplan.project_id)}</div>
                                                    <div>Shift hours: {eachplan.start_time + "-" + eachplan.end_time}</div>
                                                    <div>Break hours: {eachplan.break_hours}</div>
                                                </>
                                            }
                                        </div>
                                        <div className="e-event-resize e-right-handler">
                                            <div className="e-left-right-resize"></div>
                                        </div>
                                    </>
                                }
                            </div>
                        );
                    })()}
                </>
            )
        })
    )
}

export default PlannedEventBox
