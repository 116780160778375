import { t } from "pages/microservices/masterData/translation/Translation";
import { Link } from "react-router-dom";
import ActiveLocation from "static/images/ActiveLocation";
import AddAddressLoactionViaMap, { AddressDetails } from "utils/AddAddressLoactionViaMap";
import { UPDATE_ADDRESS_DATA, UPDATE_MAP_SHOW_POPUP } from "utils/multiTabForm/context/Constants";
import { useFormContext } from "utils/multiTabForm/context/Context";

const LocationMolecule = (props: any) => {

    const { state, dispatch } = useFormContext();
    const { currentTab, form } = state;

    const formData: any = form?.[currentTab]?.[0];

    const addressData = formData?.form;

    const handleAddPopup = () => {
        dispatch({
            type: UPDATE_MAP_SHOW_POPUP,
            payload: {
                key: 'showPopUp',
                index: currentTab,
            }
        });
    }

    const locationObj: any = {};
    const coordinates = addressData[0]?.filter((eachEle: any) => (
        eachEle?.alias == 'initialLat' || eachEle?.alias == 'initialLn')).forEach((eachItem: any) => {
            locationObj[eachItem?.alias] = eachItem['value'];
        });

    const dependencyFields = formData?.mapDependencyFields;

    const handleConfirmData = (details: any) => {
        if (details) {
            const { street, nr, bus, postal, city, country, coordinates } = details;
            let companyOption: any = []; // Declare it as an empty array or object

            const countryList = addressData[0]?.filter((eachEle: any) => {
                return eachEle?.label?.toLowerCase() == 'country'
            });


            // Find the country option if country is not empty
            if (country !== "") {
                companyOption =
                    countryList[0]?.options?.find((option: any) =>
                        option.label.toLowerCase().includes(country.toLowerCase())
                    ) || {}; // If no match found, default to an empty object
            }

            // // Ensure coordinates is in the correct format before processing
            if (
                coordinates &&
                coordinates.includes("Lat: ") &&
                coordinates.includes("Lng: ")
            ) {
                const [lat, lng] = coordinates
                    .replace("Lat: ", "")
                    .replace("Lng: ", "")
                    .split(", ")
                    .map(Number); // Convert the values to numbers

                dispatch({
                    type: UPDATE_ADDRESS_DATA,
                    payload: {
                        index: currentTab,
                        data: {
                            d_street: street,
                            d_number: nr,
                            d_box: bus,
                            d_postalCode: postal,
                            d_city: city,
                            initialLat: lat,
                            initialLn: lng,
                            d_country: companyOption
                        }
                    }
                });
            } else {
                console.error("Invalid coordinates format");
            }
        }

        handleAddPopup() // Close the popup after confirming the address
    };

    return (
        <div className="marginBottomPoint5">
            <div className="d-flex align-items-center">
                <Link
                    to={""}
                    title={t("Search address via google map...")}
                    onClick={handleAddPopup}
                    className="color-dark-pink marginRightPoint5"
                >
                    <ActiveLocation />
                </Link>
                <Link to={""} className="link-color" onClick={handleAddPopup}>
                    {t("Search address via google map...")}
                </Link>
            </div>
            <AddAddressLoactionViaMap
                initialLat={
                    locationObj?.initialLat === ""
                        ? 50.8503
                        : parseFloat(locationObj?.initialLat)
                } // Replace with your initial latitude
                initialLng={
                    locationObj?.initialLn === ""
                        ? 4.3517
                        : parseFloat(locationObj?.initialLn)
                } // Replace with your initial longitude
                onConfirm={(details) => { handleConfirmData(details) }}
                showAddPopup={formData?.showPopUp}
                onHide={handleAddPopup}
            />
        </div>
    );
}

export default LocationMolecule
