import { SET_DROPDOWN_DATA } from "../../annotations/CandidateConstants";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";

export const SetJobProfileData = async (
  dispatch: any,
  selectedOption: any,
  fieldName: string,
  state?: any,
  entity?: any,
) => {
  let response = null;
  if (fieldName === "desiredPartiarComitte" || fieldName === "pc") {
    response = await ApiCall.service(
      "getPcCategoriesforMulPcs",
      "POST",
      selectedOption,
      true,
      M_MASTER_DATA
    );
    if (response !== null) {
      if (entity === 'company') {
        dispatch({
          type: "SET_DROPDOWN",
          dropdownValues: response.data.pcCategoriesList,
          field: "domainList",
        });
        dispatch({
          type: "SET_DROPDOWN",
          dropdownValues: response.data.pcFunctionsList,
          field: "functionList",
        });

      } else {
        dispatch({
          type: SET_DROPDOWN_DATA,
          payload: response?.data,
        });
      }
    }
  } else if (fieldName === "desiredCategory" || fieldName === "domain") {
    response = await ApiCall.service(
      "getPcFunctionsforMulCategories",
      "POST",
      selectedOption,
      true,
      M_MASTER_DATA
    );
    if (
      response?.data?.pcFunctionsList &&
      Object.entries(response?.data?.pcFunctionsList)?.length === 0
    ) {
      response = null;
    } else {
      if (entity === 'company') {
        dispatch({
          type: "SET_DROPDOWN",
          dropdownValues: response.data.pcFunctionsList,
          field: "functionList",
        });

      }
    }
  }

  if (response !== null && entity != 'company') {
    dispatch({
      type: SET_DROPDOWN_DATA,
      payload: response?.data,
    });
  }

};
