import React from "react";

interface Props {
  title?: any;
  label?: any;
  width?: string;  // Allow passing a custom width as a string (e.g., '200px', '50%')
  onClick?: (value: any) => void; // Corrected casing
  classname?: string;
}

const TextEllipsis: React.FC<Props> = ({ title, label, width, onClick, classname = ""}) => {
  return (
    <div
      className={classname + " textEllipsis"}
      title={title}
      style={{ width, cursor: onClick ? "pointer" : "default" }}
      onClick={onClick} // Corrected prop usage
    >
      {label}
    </div>
  );
};

export default TextEllipsis;
