import React from 'react'

const ArrowsIcon: React.FC = () => {
  return (
    <svg width="1.5vw" height="1.5vw" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 28L29 23.5M29 23.5L24 19M29 23.5H1M6 1L1 6M1 6L6 11M1 6H29" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default ArrowsIcon;
