import TitleFieldMolecule from "components/molecules/TitleField";
import { M_MASTER_DATA } from "constants/Constants";
import React, { useEffect, useState } from "react";
import {
  ARCHIVE_CONTRACT_PROPOSAL,
  GET_CONTRACT_PROPOSAL_DATA,
} from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { t } from "../translation/Translation";
import EditIcon from "static/images/EditIcon";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  CREATE_CONTRACT,
  PATH_CONTRACT_OVERVIEW,
  PATH_WAGE_PROPOSAL,
} from "constants/Paths";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import ModalPopup from "components/atoms/ModalPopup";
import ArchieveIcon from "static/images/ArchiveIcon";
import ProfileCard from "utils/ProfileCard";
import { useSelector } from "react-redux";
import {
  selectPageHistory,
  updatePageFilters,
} from "store/pageHistory/pageHistorySlice";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import { useDispatch } from "react-redux";
import FilterOrganism from "components/organism/FilterOrganism";
import {
  ManageDropdownData,
  ManagecontractProps,
  initialDropdownData,
  initialPageDetails,
} from "./ContractInterfaces";
import EncryptDecryptService from "services/EncryptDecryptService";
import SendMailIcon from "static/images/sendMailIcon";
import Download from "static/images/downloadIcon";
import ViewIcon from "static/images/ViewIcon";

const ManageContractProposal = () => {
  const queryParam = useParams();
  const candidateId = Number(queryParam.candidateId) ?? undefined;
  const navigate = useNavigate();
  const initialManageContractData = {
    id: null,
    userId: Number(candidateId),
    startDate: null,
    endDate: null,
    employeeContrcatType: "",
    company: "",
    function: "",
    salary: "",
  };
  const [manageData, setManageData] = useState<ManagecontractProps[]>([
    initialManageContractData,
  ]);
  const [archievePopup, setArchievePopup] = useState(false);
  const [userName, setUserName] = useState("");
  const dispatch = useDispatch();
  const pageHistory = useSelector(selectPageHistory);
  const history =
    pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {};
  const [pageData, setPageData] = useState(initialPageDetails);
  const [dropdownData, setDropdownData] =
    useState<ManageDropdownData>(ManageDropdownData);
  const [contractId, setcontractId] = useState<number | undefined>();

  const getSearchData = () => {
    return {
      startDate: history?.filters?.startDate ?? "",
      endDate: history?.filters?.endDate ?? "",
      company: history?.filters?.company ?? "",
      function: history?.filters?.function ?? "",
      employeeContractType: history?.filters?.employeeContractType ?? "",
      salary: history?.filters?.salary ?? "",
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
      status: history?.filters?.status ?? "",
    };
  };

  useEffect(() => {
    const postData = {
      userId: candidateId,
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getSearchData(),
      initialCall: pageData?.initialCall,
    };
    ApiCall.service(
      GET_CONTRACT_PROPOSAL_DATA,
      "POST",
      postData,
      true,
      M_MASTER_DATA
    ).then((response) => {
      if (response.status === 200) {
        const name =
          response?.userDetails?.first_name +
          " " +
          response?.userDetails?.last_name;
        setUserName(name);
        setManageData(response?.data?.data);
        setDropdownData(response?.dropdownData);
        setPageData((prev: any) => ({
          ...prev,
          totalPages: response?.data?.totalPages,
          totalRecords: response?.data?.totalRecords,
          initialCall: false,
        }));
      }
    });
  }, [pageData?.refresh, pageData?.initialCall]);
  const statusOptions = [
    { value: 0, label: "Created" },
    { value: 1, label: "sended to employee" },
    { value: 2, label: "Approved" },
    { value: 4, label: "ejected" },
    { value: 5, label: "Inactive" },
  ];
  const filters = [
    {
      name: "startDate",
      fieldType: "date",
      end: history?.filters?.endDate,
      placeholder: "Start date",
      filterType: "search",
    },
    {
      name: "endDate",
      fieldType: "date",
      start: history?.filters?.startDate,
      placeholder: "End date",
      filterType: "search",
    },
    {
      name: "employeeContractType",
      fieldType: "singleSelect",
      options: dropdownData?.employeeContractType,
      placeholder: "Employee contract type",
      filterType: "search",
    },
    {
      name: "company",
      fieldType: "singleSelect",
      options: dropdownData?.company,
      placeholder: "Company ",
      filterType: "search",
    },
    {
      name: "function",
      fieldType: "singleSelect",
      options: dropdownData?.function,
      placeholder: "Function",
      filterType: "search",
    },
    {
      name: "salary",
      fieldType: "text",
      placeholder: "Salary",
      filterType: "search",
    },
    {
      name: "status",
      fieldType: "singleSelect",
      options: statusOptions,
      placeholder: "Status",
      filterType: "search",
    },
  ];

  const handleArcheive = async (id: number | undefined, apiCall?: boolean) => {
    if (apiCall) {
      const postData = {
        id: id,
      };
      const response = await ApiCall.service(
        ARCHIVE_CONTRACT_PROPOSAL,
        "POST",
        postData,
        true,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setArchievePopup(false);
        handleRefresh();
        CustomNotify({ type: "success", message: response?.message });
      }
    } else {
      setArchievePopup(true);
      setcontractId(id);
    }
  };

  const handleArcheivePopup = () => {
    setArchievePopup(false);
  };
  const handleSendWageMail = (
    contractId: any,
    businessUnit: any,
    userId: any,
    companyId: any,
    scheduleTypeId: any,
    employmentTypeId: any
  ) => {
    const data = {
      contractId: contractId,
      userId: userId,
      companyId: companyId,
      businessUnitId: businessUnit,
      employmentTypeId: employmentTypeId,
      scheduleTypeId: scheduleTypeId,
      isSignaturePresent: false,
      throughMail: false,
    };
    const append = EncryptDecryptService.encryptData(JSON?.stringify(data));
    const encodedData = encodeURIComponent(append);
    navigate(`${PATH_CONTRACT_OVERVIEW}/?data=${encodedData}`);
  };

  const handleRefresh = () =>
    setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));
  const handleWageDownload = (wagePdfPath: string | undefined) => {
    if (!wagePdfPath) {
      console.error("Invalid PDF path");
      return;
    }

    // Open the PDF in a new tab
    window.open(wagePdfPath, "_blank");
  };
  const handleView = (id: any, userId: any) => {
    navigate(`${CREATE_CONTRACT}/${id}/${userId}?mode=view`);
  };
  return (
    <>
      <div className="search-bar">
        <TitleFieldMolecule title={t("Contract proposal overview")} />
      </div>

      <div style={{ paddingBottom: "1vw" }}>
        <ProfileCard id={candidateId} />
      </div>

      <div className="position-relative tableMainWrapper">
        <div className="row">
          <div className="ManageCreateBtn">
            <LinkTo
              pagelink={`${CREATE_CONTRACT}/${candidateId}`}
              title={t("Create contract proposal")}
              icon={faPlus}
            />
          </div>
        </div>
        <div className="table-responsive Manage-contract-proposal tableSection">
          <FilterOrganism
            dispatch={dispatch}
            history={history}
            updatePageFilters={updatePageFilters}
            filters={filters}
            handleRefresh={handleRefresh}
          />
          <div className="wageProposalTable">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader">
                  <th>{t("Start date")}</th>
                  <th>{t("End date")}</th>
                  <th>{t("Contract type")}</th>
                  <th>{t("Company")}</th>
                  <th>{t("Function")}</th>
                  <th>{t("Salary")}</th>
                  <th>{t("Status")}</th>
                  <th>{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {manageData && manageData.length > 0 ? (
                  manageData.map((data: any, index: number) => (
                    <tr key={index}>
                      <td>{data.startDate}</td>
                      <td>{data.endDate}</td>
                      <td>{data.employeeContractType}</td>
                      <td>{data.companyName}</td>
                      <td>{data.function}</td>
                      <td>{data.salary}</td>
                      <td>
                        <span
                          className={`d-inline-block rounded-circle status-icon ${data.status === 0
                            ? "secondary"
                            : data.status === 1
                              ? "cyan"
                              : data.status === 2
                                ? "success"
                                : data.status === 3
                                  ? "success"
                                  : data.status === 4
                                    ? "orange"
                                    : data.status === 5
                                      ? "danger"
                                      : "success"
                            }`}
                          data-toggle="tooltip"
                          title={
                            data.status === 0
                              ? t("Created, not sent to candidate")
                              : data.status === 1
                                ? t("Proposal sent to employee")
                                : data.status === 2
                                  ? t("Sent and approved by employee")
                                  : data.status === 3
                                    ? t("Created and approved by Maxicon")
                                    : data.status === 4
                                      ? t("Proposal sent, rejected by employee")
                                      : data.status === 5
                                        ? t("Inactive")
                                        : ""
                          }
                        ></span>
                      </td>

                      <td>
                        {![1, 2, 3, 4, 5].includes(data.status) && (
                          <Link
                            to={`${CREATE_CONTRACT}/${data.userId}/${data.id}`}
                            className={"table-action-btn cursor-pointer marginRightPoint5"}
                          >
                            <EditIcon />
                          </Link>
                        )}
                        {![4, 5].includes(data.status) && (
                          <span onClick={() => handleArcheive(data?.id)} className="table-action-btn cursor-pointer marginRightPoint5" title={t("Archive")}>
                            <ArchieveIcon />
                          </span>
                        )}
                        {![2, 3, 4, 5].includes(data.status) && (
                          <span onClick={() =>
                            handleSendWageMail(
                              data.id,
                              data.businessUnit,
                              data.userId,
                              data.company,
                              data.scheduleType,
                              data.employmentType
                            )
                          } className="table-action-btn cursor-pointer marginRightPoint5" title={t("Send mail")}>
                            <SendMailIcon />
                          </span>
                        )}
                        {data.documentPath && (
                          <span onClick={() =>
                            handleWageDownload(data.documentPath)
                          }
                            className="table-action-btn cursor-pointer marginRightPoint5" title={t("Download")}>
                            <Download />
                          </span>
                        )}
                        <span onClick={() => handleView(data.userId, data.id)}
                          className="table-action-btn cursor-pointer" title={t("View")}>
                          <ViewIcon />
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr className="border">
                      <td colSpan={8} className="border-0 text-center py-3">
                        <span className="text-danger fw-bold">{t("No records")}</span>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className="">
            <PaginationWithPerPage
              handleRefresh={handleRefresh}
              dispatch={dispatch}
              history={history}
              pageData={pageData}
            />
          </div>
        </div>
      </div>
      {archievePopup && (
        <ModalPopup
          show={archievePopup}
          onHide={handleArcheivePopup}
          title={t("Archive confirmation")}
          body={t("Are you sure you want to archive contract proposal")}
          confirmTitle={t("Yes")}
          closeTitle={t("No")}
          onConfirmButtonClick={() => handleArcheive(contractId, true)}
          onCloseButtonClick={handleArcheivePopup}
        />
      )}
      <div className="row" style={{ padding: "0.8vw 0" }}>
        <div className="col-md-6 align-self-center">
          <Link
            to=""
            className="back-btn text-decoration-underline"
            onClick={() => navigate(-1)}
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </>
  );
};

export default ManageContractProposal;
